import { useDispatch, useSelector } from 'react-redux';
import { setSelectedStore } from '../../redux/actions/locations';

export function useStore() {
  const dispatch = useDispatch();
  const { selectedStore } = useSelector((state) => state.location);

  const changeStore = (store) => {
    dispatch(setSelectedStore(store));
  };

  const clearStore = () => {
    dispatch(setSelectedStore({}));
  };

  return {
    selectedStore,
    changeStore,
    clearStore,
  };
}

export default useStore;
