import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { format, addMinutes } from 'date-fns';
import clsx from 'clsx';
import Button from 'src/components/Button';
import Drawer from 'src/components/Drawer';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LogoBacuIcon,
  CloseIcon,
} from 'src/components/icons';
import { useDelivery } from 'src/hooks/actions/useDelivery';
import config from 'src/config';
import { formatCurrency } from 'src/utils/currency';
import { useOrderStatus, useOrderRating } from 'src/hooks/services/useOrder';
import useGoogleAnalytics from 'src/hooks/useGoogleAnalytics';
import { useShoppingCart } from 'src/hooks/actions/useShoppingCart';
import InputRating from 'src/components/InputRating';
import { useLogin } from 'src/contexts/Login.context';
import Loader from 'src/components/Loader';
import HelpButton from '../components/HelpButton';

function ConfirmationSuccess() {
  const {
    markToRemove,
    clearCart,
    clearSession,
  } = useShoppingCart();
  const [modalStatus, setModalStatus] = useState('LOADING');
  const { orderID } = useParams();
  const [showDetails, setShowDetails] = useState(true);
  const [openRating, setopenRating] = useState(false);
  const [drawerConfirmed, setDrawerConfirmed] = useState(false);
  const hideRating = () => { setopenRating(false); };
  const { order, isLoading: loadingOrder } = useOrderStatus({
    orderID,
    status: modalStatus,
    onStatusChange: setModalStatus,
    onCloseModal: () => null,
  });

  const navigate = useNavigate();
  const handleToggleDetails = () => setShowDetails((prev) => !prev);
  const { deliveryData } = useDelivery();
  const { isLoading, rate, setRating } = useOrderRating({ orderID });

  const handleSubmit = (rating) => setRating(rating);
  const deliveryflag = deliveryData.isDelivery;
  const { userInfo } = useLogin();

  const preparationEta = 25;
  const deliveryETA = 20;
  useEffect(() => {
    const timer = setTimeout(() => {
      setopenRating(true);
    }, (preparationEta + deliveryETA) * 60 * 1000);
    return () => clearTimeout(timer);
  }, []);
  const { sendEvent } = useGoogleAnalytics();
  sendEvent('checkout end', {
    transaction_status: order.transaction_status,
    email: order.customer?.email,
  });
  useEffect(() => {
    if (markToRemove && modalStatus === config.paymentStatus.SUCCEEDED) {
      clearCart();
      clearSession();
    }
  }, [markToRemove, modalStatus]);
  if (loadingOrder) {
    return (<Loader title="Cargando datos de orden" />);
  }
  const iniEta = format(addMinutes(new Date(order?.creationDate), preparationEta), 'hh:mm a');
  const endEta = format(addMinutes(new Date(order?.creationDate), preparationEta + deliveryETA), 'hh:mm a');
  const dshippingCost = order?.paymentDetails?.shippingCost;
  const Subtotal = Number(order?.paymentDetails?.dataPayment?.amount?.value) - Number(dshippingCost);
  const total = Number(order?.paymentDetails?.dataPayment?.amount?.value);

  if (deliveryflag) {
    return (
      <div
        className="absolute bg-baco-primary top-0 bottom-0 w-screen px-4 pt-12 h-full overflow-y-auto overflow-x-hidden"
      >
        <LogoBacuIcon
          className="relative w-[129px] h-[78px] fill-white m-auto mb-8"
        />
        <div className="w-full max-w-3xl mx-auto">
          <h2 className="text-white text-3xl mt-0 mb-2 font-medium font-sans">
            ¡Hemos recibido tu pedido!
          </h2>
          <h2 className="text-white text-base mt-0 mb-5 font-medium">
            Llegará a tu domicilio entre:
            <br />
          </h2>
          <div
            className="flex justify-center w-full mb-1"
          >
            <h2 className="text-baco-yellow text-[32px] mt-0 font-bold lowercase">
              {iniEta}
              <span> - </span>
              {endEta}
            </h2>
          </div>
          <hr
            className="bg-baco-yellow h-1 w-3/4 border-none mb-3"
            style={{ marginLeft: 'auto', marginRight: 'auto', width: '50%' }}
          />
          <div className="text-white text-sm lg:text-base text-center font-bold leading-6 mb-7">
            <h2 className="text-white text-base font-medium">
              No. de orden:
              <span> </span>
              {(orderID.substr(-4))}
            </h2>
          </div>
          <div className="bg-baco-secondary-100 rounded-lg py-6 px-4 mb-10">
            <h2 className="text-baco-primary text-base font-bold mb-0 mt-0">
              Cliente
            </h2>
            <h3 className="text-baco-primary text-sm mb-6 mt-0">
              {order?.customer?.name}
              {/* {`${userInfo?.first_name} ${userInfo?.last_name}`} */}
              <br />
              +(
              {userInfo?.phone?.country_code ? userInfo?.phone?.country_code : '57'}
              )
              <span> </span>
              {order?.customer?.phone}
            </h3>
            <h2 className="text-baco-primary text-base font-bold mb-0 mt-0">
              Dirección de envío
            </h2>
            <h3 className="text-baco-primary text-sm mb-2 mt-0">
              {deliveryData.address}
              <br />
              {deliveryData.addressDetail}
            </h3>
          </div>
          <div
            className={clsx('transition-all overflow-hidden px-5', {
              'max-h-[35px]': !showDetails,
              'max-h-full': showDetails,
            })}
          >
            <h2
              className={clsx('flex justify-between items-center w-full', {
                'mb-4': showDetails,
                'mb-2': !showDetails,
              })}
            >
              <span className="text-base text-white font-bold">
                Detalles del Pedido
              </span>
              <button className="text-slate-800" onClick={handleToggleDetails} type="button">
                {showDetails ? (
                  <span className="flex justify-start text-white items-center gap-2">
                    <ChevronDownIcon className="h-9 w-9" />
                  </span>
                ) : (
                  <span className="flex justify-start items-center gap-2">
                    <ChevronUpIcon className="h-9 w-9" />
                  </span>
                )}
              </button>
            </h2>
            <ul className="text-white text-xs mb-6">
              {order?.items?.map((item) => (
                <li key={item.product.id} className="flex flex-col justify-between">
                  <span className="flex justify-between mb-1">
                    <span className="w-7">{`${item.quantity} x`}</span>
                    <span className="w-full">{item.product.name}</span>
                  </span>
                </li>
              ))}
              <hr className="border-none h-[1px] w-full bg-white mb-2 mt-2 mx-auto" />
              <li className="flex justify-end pt-2 text-white">
                <span>Subtotal</span>
                <span className="pl-2">
                  {formatCurrency(Number(Subtotal))}
                </span>
              </li>
              <li className="flex justify-end pt-1 text-white">
                <span className="pb-2">Costo de envío </span>
                <span className="pl-1">
                  {formatCurrency(Number(dshippingCost))}
                </span>
              </li>
              <li className="flex justify-end text-sm font-bold">
                <span>Total</span>
                <span className="pl-2">
                  {formatCurrency(Number(total))}
                </span>
              </li>
            </ul>
          </div>
          <Drawer
            open={openRating}
            direction="bottom"
            keyboard="true"
            classes={{
              drawer: 'bg-gray-50',
              backdrop: 'z-20',
              content: 'h-full w-full max-w-4xl mx-auto',
            }}
          >
            <div className="flex flex-col w-full checked:bg-baco-primary">
              <nav className="flex justify-end px-2 items-end">
                <button
                  className="flex w-5 h-5 justify-between items-center"
                  type="button"
                  onClick={hideRating}
                >
                  <CloseIcon
                    className="w-5 h-5 scale-110"
                  />
                </button>
              </nav>
              {
                !drawerConfirmed ? (
                  <>
                    <div className="font-bold text-xl py-2 px-3">
                      ¡Gracias por pedir en Bacu!
                    </div>
                    <div className="text-md text-left px-4">
                      Califica tu experiencia y ayúdanos a mejorar.
                    </div>
                    <div className="flex flex-col items-center py-8">
                      <InputRating
                        size="lg"
                        value={rate}
                        onChange={handleSubmit}
                        disabled={isLoading}
                      />
                    </div>
                    <Button
                      type="submit"
                      size="lg"
                      fullWidth
                      className="!text-base font-bold"
                      onClick={() => setDrawerConfirmed(true)}
                    >
                      Enviar
                    </Button>
                    <button
                      type="button"
                      className="underline text-center text-sm mt-4 mb-6 hover:cursor-pointer"
                      onClick={() => navigate('/')}
                    >
                      Ir al inicio
                    </button>
                  </>
                ) : (
                  <>
                    <div className="font-bold text-xl py-24 px-3 text-center">
                      ¡Gracias por calificarnos!
                    </div>
                    <Button
                      size="lg"
                      fullWidth
                      className="!text-base font-bold"
                      onClick={() => navigate('/')}
                    >
                      Volver al inicio
                    </Button>
                  </>
                )
              }
            </div>
          </Drawer>
          <HelpButton
            className="mt-6 mb-12 font-semibold underline bg-transparent hover:bg-transparent
            text-baco-secondary-50 hover:text-baco-secondary-50 active:bg-transparent"
            style={{
              backgroundColor: 'transparent',
              hover: 'transparent',
              active: 'transparent',
            }}
          />
        </div>
      </div>
    );
  }
}
export default ConfirmationSuccess;
