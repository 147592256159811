import PropTypes from 'prop-types';
import clsx from 'clsx';

function SelectBox(props) {
  const {
    className,
    size,
    value,
    placeholder,
    onChange,
    fullWidth,
    options,
    fieldState,
    ...args
  } = props;

  const {
    invalid,
    isDirty,
  } = fieldState;

  return (
    <select
      className={
        clsx(
          'disabled:opacity:60 rounded-sm outline outline-0 disabled:text-gray-400 transition-colors',
          'h-[58px] flex flex-col justify-center',
          {
            'px-4 py-2 text-sm': size === 'sm',
            'px-4 py-[17px] text-middle': size === 'md',
            'p-6 text-lg': size === 'lg',
            'w-full': fullWidth,
            'bg-gray-100 border-gray-100 !text-black': !invalid && isDirty,
            'bg-gray-100 border-gray-100 !text-gray-400': !(invalid && isDirty),
            'bg-red-300 border-baco-error-normal text-baco-error-normal': invalid && isDirty,
          },
        )
      }
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      {...args}
    >
      {
        placeholder
        && (
        <option
          disabled
          hidden
          value=""
          className="!hidden"
        >
          {placeholder}
        </option>
        )
      }
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
}

SelectBox.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  fieldState: PropTypes.any,
};
SelectBox.defaultProps = {
  className: '',
  size: 'md',
  value: '',
  placeholder: '',
  onChange: () => null,
  fullWidth: false,
  options: [],
  fieldState: {},
};

export default SelectBox;
