import PropTypes from 'prop-types';
import { Fragment } from 'react';
import clsx from 'clsx';
import RadioBoxControlled from '../RadioBox/RadioBox.cotrolled';
import InputTextControlled from '../InputText/InputText.controlled';
import InputCounterNumberControlled from '../InputCounterNumber/InputCounterNumber.controlled';
import RadioBoxWithIconControlled from '../RadioBoxWithIcon/RadioBoxWithIcon.controlled';
import TextAreaControlled from '../TextArea/TextArea.controlled';
import SelectBoxControlled from '../SelectBox/SelectBox.controlled';
import CheckBoxControlled from '../CheckBox/CheckBox.cotrolled';
import RadioButtonGroupControlled from '../RadioButton/RadioButtonGroup.controlled';
import TimePickerControlled from '../TimePicker/TimePicker.controlled';
import InputRatingControlled from '../InputRating/InputRating.controlled';
import DiscountInputControlled from '../DiscountInput/DiscountInput.controlled';
import ButtonGroupControlled from '../ButtonGroup/ButtonGroup.controlled';
import InputPasswordControlled from '../InputPassword/InputPassword.controlled';
import CustomSelectControlled from '../CustomSelect/CustomSelect.controlled';
import InputPhoneNumberControlled from '../InputPhoneNumber/InputPhoneNumber.controlled';

const inputs = new Map([
  ['text', InputTextControlled],
  ['email', InputTextControlled],
  ['password', InputPasswordControlled],
  ['number', InputTextControlled],
  ['tel', InputPhoneNumberControlled],
  ['hidden', InputTextControlled],
  ['textarea', TextAreaControlled],
  ['select', SelectBoxControlled],
  ['customSelect', CustomSelectControlled],
  ['radio', RadioBoxControlled],
  ['checkbox', CheckBoxControlled],
  ['radioButtonGroup', RadioButtonGroupControlled],
  ['buttonGroup', ButtonGroupControlled],
  ['paymentMethod', RadioBoxWithIconControlled],
  ['counter', InputCounterNumberControlled],
  ['timePicker', TimePickerControlled],
  ['rating', InputRatingControlled],
  ['discount', DiscountInputControlled],
]);

function getInput(props) {
  const Component = inputs.get(props.type);

  if (!Component) {
    return <p key={props.name} className={clsx(props.classes)}>{props.label}</p>;
  }

  return <Component key={props.name} {...props} />;
}

function FormInputPanel(props) {
  const {
    panelElements,
    id,
  } = props;

  return (
    <Fragment key={id}>
      {panelElements.map((panelElement) => getInput(panelElement))}
    </Fragment>
  );
}

FormInputPanel.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  panelElements: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.node,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      helper: PropTypes.node,
      rows: PropTypes.number,
      size: PropTypes.oneOf(['sm', 'md', 'lg']),
      classes: PropTypes.array,
      min: PropTypes.number,
      max: PropTypes.number,
      allowMinValue: PropTypes.bool,
      step: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.any,
          icon: PropTypes.any,
          value: PropTypes.any,
          label: PropTypes.any,
        }),
      ),
      readonly: PropTypes.bool,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      multiValueInput: PropTypes.bool,
    }),
  ),
};
FormInputPanel.defaultProps = {
  panelElements: [],
};

export default FormInputPanel;
