import {
  trackEvent,
} from '../services/amplitude';

export function useCartPageEvents() {
  const trackViewCart = (productIds, quantityProducts, amountProducts) => {
    trackEvent('view_cart', {
      product_ids: productIds, quantity_products: quantityProducts, amount_products: amountProducts,
    });
  };

  const trackAddItemToCart = (productId) => {
    trackEvent('add_item_to_cart', { product_id: productId });
  };

  const trackConfirmCart = () => {
    trackEvent('confirm_cart');
  };

  return {
    trackViewCart,
    trackAddItemToCart,
    trackConfirmCart,
  };
}

export default useCartPageEvents;
