import PropTypes from 'prop-types';

function VisibilityOff({ className }) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M16.1 13.2998L14.65 11.8498C14.8 11.0665 14.575 10.3331 13.975
        9.6498C13.375 8.96647 12.6 8.6998 11.65 8.8498L10.2 7.39981C10.4833 7.26647
        10.7707 7.16647 11.062 7.0998C11.354 7.03314 11.6667 6.9998 12 6.9998C13.25
        6.9998 14.3127 7.43714 15.188 8.3118C16.0627 9.18714 16.5 10.2498 16.5
        11.4998C16.5 11.8331 16.4667 12.1458 16.4 12.4378C16.3333 12.7291 16.2333
        13.0165 16.1 13.2998ZM19.3 16.4498L17.85 15.0498C18.4833 14.5665 19.046
        14.0371 19.538 13.4618C20.0293 12.8871 20.45 12.2331 20.8 11.4998C19.9667
        9.81647 18.7707 8.47881 17.212 7.4868C15.654 6.49547 13.9167 5.9998
        12 5.9998C11.5167 5.9998 11.0417 6.03314 10.575 6.0998C10.1083 6.16647 9.65
        6.26647 9.2 6.3998L7.65 4.8498C8.33333 4.56647 9.03333 4.3538 9.75 4.2118C10.4667
        4.07047 11.2167 3.9998 12 3.9998C14.5167 3.9998 16.7583 4.69547 18.725 6.0868C20.6917
        7.4788 22.1167 9.28314 23 11.4998C22.6167 12.4831 22.1127 13.3958 21.488 14.2378C20.8627
        15.0791 20.1333 15.8165 19.3 16.4498ZM19.8 22.5998L15.6 18.4498C15.0167 18.6331 14.4293
        18.7708 13.838 18.8628C13.246 18.9541 12.6333 18.9998 12 18.9998C9.48333 18.9998 7.24167
        18.3041 5.275 16.9128C3.30833 15.5208 1.88333 13.7165 1 11.4998C1.35 10.6165 1.79167 9.79547
        2.325 9.0368C2.85833 8.2788 3.46667 7.5998 4.15 6.9998L1.4 4.1998L2.8 2.7998L21.2 21.1998L19.8
        22.5998ZM5.55 8.3998C5.06667 8.83314 4.625 9.30814 4.225 9.8248C3.825 10.3415 3.48333 10.8998
        3.2 11.4998C4.03333 13.1831 5.229 14.5205 6.787 15.5118C8.34567 16.5038 10.0833 16.9998 12
        16.9998C12.3333 16.9998 12.6583 16.9791 12.975 16.9378C13.2917 16.8958 13.6167 16.8498 13.95
        16.7998L13.05 15.8498C12.8667 15.8998 12.6917 15.9371 12.525 15.9618C12.3583 15.9871 12.1833
        15.9998 12 15.9998C10.75 15.9998 9.68733 15.5625 8.812 14.6878C7.93733 13.8125 7.5 12.7498 7.5
        11.4998C7.5 11.3165 7.51233 11.1415 7.537 10.9748C7.56233 10.8081 7.6 10.6331 7.65 10.4498L5.55
        8.3998Z"
        />
      </g>
    </svg>
  );
}

VisibilityOff.propTypes = {
  className: PropTypes.string,
};

VisibilityOff.defaultProps = {
  className: '',
};

export default VisibilityOff;
