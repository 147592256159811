import { useMutation } from '@tanstack/react-query';
import { restoreForgot } from '../../services/api';

export function useForgotRestore() {
  const {
    mutate: forgotRestore,
    isLoading: isLoadingForgotRestore,
  } = useMutation(({ password, hash }) => restoreForgot({ password, hash }));

  return { isLoadingForgotRestore, forgotRestore };
}

export default useForgotRestore;
