export const setRedirectUrl = (data) => ({
  type: 'REDIRECT_URL',
  data,
});

export const setSessionToken = (data) => ({
  type: 'USER_TOKEN',
  data,
});

export const setRefreshToken = (data) => ({
  type: 'REFRESH_TOKEN',
  data,
});

export default setRedirectUrl;
