import { useMutation } from '@tanstack/react-query';
import { LoginScreens, useLogin } from '../../contexts/Login.context';
import { requestForgot } from '../../services/api';

export function useForgotRequest(setFormError) {
  const { handleChangeScreen } = useLogin();

  const {
    mutate: forgotRequest,
    isLoading: isLoadingForgotRequest,
  } = useMutation(({ email }) => requestForgot({ email }), {
    onSuccess: () => {
      handleChangeScreen(LoginScreens.SUCCES_RESTORE);
    },
    onError: () => {
      setFormError('email', {
        type: 'custom',
        message: 'Lo sentimos, no encontramos una cuenta asociada a este correo electrónico ',
      });
    },
  });

  return { isLoadingForgotRequest, forgotRequest };
}

export default useForgotRequest;
