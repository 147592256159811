import clsx from 'clsx';
import PropTypes from 'prop-types';
import config from '../../config';
import IllustratedMessage from '../IllustratedMessage';
import Button from '../Button';
import { ReactComponent as SucceededImg } from './assets/succeeded.svg';
import { ReactComponent as DeclinedImg } from './assets/declined.svg';
import { ReactComponent as ErrorImg } from './assets/error.svg';
import { ReactComponent as PendingImg } from './assets/pending.svg';
import Portal from '../Portal';
import { SpinIcon } from '../icons';

const {
  SUCCEEDED,
  REJECTED,
  ERROR,
  DECLINED,
  PENDING,
  CANCELED,
  EXPIRED,
  AWAITING_WEBHOOK,
  ENROLLMENT_FAILED,
} = config.paymentStatus;

const messages = {
  [SUCCEEDED]: {
    illustration: <SucceededImg className="mb-10" />,
    title: 'Pago exitoso',
    description: 'Su pago fue procesado exitosamente.',
    ctaTitle: 'Continuar',
  },
  [REJECTED]: {
    illustration: <DeclinedImg className="mb-10" />,
    title: 'Pago rechazado',
    description: 'Lo sentimos, tu pago no ha sido aprobado. Por favor intenta de nuevo.',
    ctaTitle: 'Regresar al carrito',
  },
  [ERROR]: {
    illustration: <ErrorImg className="mb-10" />,
    title: 'Oops algo falló',
    description: 'Lo sentimos ha ocurrido un error, por favor intenta de nuevo.',
    ctaTitle: 'Inténtalo de nuevo',
  },
  [DECLINED]: {
    illustration: <DeclinedImg className="mb-10" />,
    title: 'Pago no realizado',
    description: 'Lo sentimos, tu pago no pudo ser procesado. Por favor intenta de nuevo.',
    ctaTitle: 'Regresar al carrito',
  },
  [PENDING]: {
    illustration: <PendingImg className="mb-10" />,
    title: 'Pago Pendiente',
    description: 'Tu transacción está pendiente de confirmación. Te notificaremos cuando sea aprobada.',
    ctaTitle: 'Ver orden',
  },
  [CANCELED]: {
    illustration: <DeclinedImg className="mb-10" />,
    title: 'Pago cancelado',
    description: 'El proceso de pago ha sido cancelado',
    ctaTitle: 'Regresar al carrito',
  },
  [EXPIRED]: {
    illustration: <DeclinedImg className="mb-10" />,
    title: 'Pago expirado',
    description: 'Lo sentimos, el tiempo para realizar el pago ha expirado. Intenta de nuevo.',
    ctaTitle: 'Regresar al carrito',
  },
  [AWAITING_WEBHOOK]: {
    illustration: <PendingImg className="mb-10" />,
    title: 'Procesando el pago',
    description: 'Estamos esperando que el banco responda el estado de la transaccion',
  },
  [ENROLLMENT_FAILED]: {
    illustration: <DeclinedImg className="mb-10" />,
    title: 'Pago rechazado',
    description: 'Lo sentimos, algo salió mal al registrar tu método de pago. Inténtalo con otro medio.',
    ctaTitle: 'Regresar al carrito',
  },
  LOADING: {
    illustration: <SpinIcon className="animate-spin w-10 h-10" />,
    title: 'Procesando el pago',
    description: 'No cierres esta pantalla mientras se procesa el pago',
  },
};

function PaymentStatusPopUp(props) {
  const {
    status,
    onClick,
  } = props;

  if (!messages[status]) {
    return <div />;
  }

  const {
    ctaTitle,
    ...message
  } = messages[status];

  return (
    <Portal
      className="payment-status-pop-up"
    >
      <div
        className={clsx(
          'fixed top-0 left-0 w-screen h-full flex flex-col justify-between items-center',
          'z-50 bg-white',
        )}
      >
        <div
          className="w-full h-full flex justify-center items-center"
        >
          <IllustratedMessage
            {...message}
          />
        </div>
        {ctaTitle && (
          <div
            className="w-full px-4 pt-4 pb-8 bg-white box-shadow-up"
          >
            <Button
              size="lg"
              fullWidth
              onClick={onClick}
            >
              {ctaTitle}
            </Button>
          </div>
        )}
      </div>
    </Portal>
  );
}

PaymentStatusPopUp.propTypes = {
  status: PropTypes.oneOf([SUCCEEDED, REJECTED, ERROR, DECLINED, AWAITING_WEBHOOK, ENROLLMENT_FAILED, 'LOADING']),
  onClick: PropTypes.func,
};
PaymentStatusPopUp.defaultProps = {
  status: undefined,
  onClick: () => null,
};

export default PaymentStatusPopUp;
