import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../Button';
import config from '../../config';

const MIN_MS = 60000;
const PERIODS = {
  hour: 60 * MIN_MS,
  minute: MIN_MS,
};

function TimePicker(props) {
  const {
    value,
    minuteStep,
    hourStep,
    minTime,
    maxTime,
    className,
    onChange,
  } = props;
  const interval = minuteStep * MIN_MS;
  const date = new Date(Math.round(value / interval) * interval);
  const [disabledMin, setDisabledMin] = useState(date <= minTime);
  const [disableMax, setDisableMax] = useState(date >= maxTime);

  const handleChange = (period, increments) => {
    const time = Math.min(Math.max(+date + PERIODS[period] * increments, minTime), maxTime);
    onChange(time);
    setDisabledMin(time <= minTime);
    setDisableMax(time >= maxTime);
  };

  return (
    <div className={clsx('flex flex-row justify-start items-center space-x-2', className)}>
      <div className="flex flex-col space-y-4">
        <Button
          onClick={() => handleChange('hour', hourStep)}
          className="w-10"
          color="secondary"
          size="sm"
          disabled={disableMax}
        >
          +
        </Button>
        <span className="text-center text-4xl font-bold">
          {`${date.getHours() % 12 || 12}`.padStart(2, '0')}
        </span>
        <Button
          onClick={() => handleChange('hour', -hourStep)}
          className="w-10"
          color="secondary"
          size="sm"
          disabled={disabledMin}
        >
          -
        </Button>
      </div>
      <span className="text-center text-4xl font-bold">:</span>
      <div className="flex flex-col justify-center space-y-4">
        <Button
          onClick={() => handleChange('minute', minuteStep)}
          className="w-10"
          color="secondary"
          size="sm"
          disabled={disableMax}
        >
          +
        </Button>
        <span
          className="text-center text-4xl font-bold"
        >
          {`${date.getMinutes()}`.padStart(2, '0')}
        </span>
        <Button
          onClick={() => handleChange('minute', -minuteStep)}
          className="w-10"
          color="secondary"
          size="sm"
          disabled={disabledMin}
        >
          -
        </Button>
      </div>
      <span className="text-center text-4xl font-bold">
        {date.getHours() < 12 ? 'am' : 'pm'}
      </span>
    </div>
  );
}

TimePicker.propTypes = {
  value: PropTypes.number,
  minuteStep: PropTypes.number,
  hourStep: PropTypes.number,
  minTime: PropTypes.number,
  maxTime: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

TimePicker.defaultProps = {
  value: 0,
  minuteStep: config.minutesInterval,
  hourStep: 1,
  minTime: +new Date(1970, 0, 1),
  maxTime: +new Date(2100, 11, 31),
  className: '',
  onChange: () => null,
};

export default TimePicker;
