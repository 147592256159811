import clsx from 'clsx';
import { MotionConfig, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useDelivery from 'src/hooks/actions/useDelivery';
import { getNearestStore, validateAddress } from 'src/services/api';
import * as yup from 'yup';
import Button from '../../components/Button';
import FormInputPanel from '../../components/FormInputPanel';
import {
  ChevronLeftIcon,
  DashIcon,
  LogoIcon,
  NumeralIcon,
} from '../../components/icons';
import { useYupValidationResolver } from '../../hooks/useYupValidatorResolver';

const addresType = [
  {
    id: '1',
    value: 'Avenida',
    label: 'Avenida',
  },
  {
    id: '2',
    value: 'Calle',
    label: 'Calle',
  },
  {
    id: '8',
    value: 'Av Cll',
    label: 'Av Cll',
  },
  {
    id: '3',
    value: 'Carrera',
    label: 'Carrera',
  },
  {
    id: '7',
    value: 'Av Cra',
    label: 'Av Cra',
  },
  {
    id: '4',
    value: 'Diagonal',
    label: 'Diagonal',
  },
  {
    id: '5',
    value: 'Transversal',
    label: 'Transversal',
  },
  // {
  //   id: '6',
  //   value: 'Autopista',
  //   label: 'AU',
  // },
];

const AddressInputSchema = yup.object({
  addressBox2: yup.string().required('Ingresa un número válido.'),
  addressBox3: yup.string().required('Ingresa un valor válido.'),
  addressBox4: yup.string().required('Ingresa un número válido.'),
});

function AddressInput() {
  const resolver = useYupValidationResolver(AddressInputSchema);
  const methods = useForm({
    defaultValues: '',
    mode: 'onChange',
    resolver,
  });
  const [detailDisabled, setDetailDisabled] = useState(false);
  const [detailDisabledCheck, setDetailDisabledCheck] = useState(false);
  const [addressCoordinates, setAddressCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [errorToShow, setErrorToShow] = useState('');
  const [detailError, setDetailError] = useState('');
  const [messageValidationToShow, setMessageValidationToShow] = useState({
    success: false,
    message: '',
    style: [],
  });
  const [storeId, setStoreId] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleGoBack = () => {
    navigate(-1);
  };
  const { updateDelivery } = useDelivery();
  const validateAddressDetail = () => {
    if (!methods.getValues().addressDetail && [false, undefined].includes(methods.getValues().addressDetailCheckbox)) {
      setDetailError('Rellena el detalle de tu dirección o, de lo contrario, marca la casilla.');
      return false;
    }
    return true;
  };

  const toggleAddressDetail = () => {
    setDetailError('');
    if (methods.getValues().addressDetail) {
      setDetailDisabledCheck(true);
      methods.setValue('addressDetailCheckbox', false);
    } else {
      setDetailDisabledCheck(false);
    }

    if (methods.getValues().addressDetailCheckbox === true) {
      setDetailDisabled(true);
      methods.setValue('addressDetail', '');
      delete methods.formState.errors.addressDetail;
    } else {
      setDetailDisabled(false);
    }
  };

  const handleSubmit = (data) => {
    if (validateAddressDetail()) {
      const address = `${data.addressBox1} ${data.addressBox2} #${data.addressBox3} - ${data.addressBox4}`;
      updateDelivery({
        lat: addressCoordinates.lat,
        lng: addressCoordinates.lng,
        address,
        addressDetail: methods.getValues().addressDetail,
        addressDetailCheckbox: methods.getValues().addressDetailCheckbox,
        storeId,
      });
      navigate('/addressMap');
    }
  };

  methods.register('addressDetail', {
    onChange: () => {
      toggleAddressDetail();
    },
  });

  methods.register('addressDetailCheckbox', {
    onChange: () => {
      toggleAddressDetail();
    },
  });

  const addressValidate = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      if (!methods.getValues().addressBox1 && methods.getFieldState('addressBox1').isDirty) {
        methods.setError('addressBox1', {
          message: 'Selecciona una opción.',
          type: 'required',
        });

        methods.setValue('addressBox1', '', {
          shouldDirty: true,
        });

        return;
      }
      if (!errorToShow) {
        const {
          addressBox1, addressBox2, addressBox3, addressBox4,
        } = methods.getValues();
        if (addressBox1 && addressBox2 && addressBox3 && addressBox4) {
          setMessageValidationToShow({
            success: null,
            message: '',
          });
          const address = encodeURIComponent(`${addressBox1} ${addressBox2} #${addressBox3} - ${addressBox4}, ${
            process.env.REACT_APP_CITY}, ${process.env.REACT_APP_COUNTRY}`);
          try {
            const resp = await validateAddress(address);
            setAddressCoordinates(resp);
            try {
              const { storeId: respStoreId } = await getNearestStore(resp.lat, resp.lng);
              setStoreId(respStoreId);
              setMessageValidationToShow({
                success: true,
                message: '¡Dirección dentro del rango de cobertura!',
                style: ['w-full', 'mt-2', 'text-sm', 'text-baco-success'],
              });
            } catch {
              setMessageValidationToShow({
                success: false,
                message: '¡Lo sentimos! Esta dirección no está dentro del rango de cobertura.',
                style: ['w-full', 'mt-2', 'text-sm', 'text-baco-error-normal'],
              });
            }
          } catch {
            setMessageValidationToShow({
              success: false,
              message: 'No podemos reconocer esta dirección. Verifica si está bien escrita.',
              style: ['w-full', 'mt-2', 'text-sm', 'text-baco-error-normal'],
            });
          } finally {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    }, 1500);
  };

  methods.register('addressBox1', {
    onChange: () => {
      addressValidate();
    },
  });

  methods.register('addressBox2', {
    onChange: () => {
      addressValidate();
    },
  });

  methods.register('addressBox3', {
    onChange: () => {
      addressValidate();
    },
  });

  methods.register('addressBox4', {
    onChange: () => {
      addressValidate();
    },
  });

  useEffect(() => {
    const fields = ['addressBox1', 'addressBox2', 'addressBox3', 'addressBox4'];
    if (Object.keys(methods.formState.errors).some((field) => fields.includes(field))) {
      const errorsArr = Object.keys(methods.formState.errors);
      setErrorToShow(methods.formState.errors[errorsArr[0]].message);
    } else {
      setErrorToShow('');
    }
  }, [methods.formState]);

  useEffect(() => {
    if (errorToShow) {
      setMessageValidationToShow(
        {
          success: false,
          message: '',
          style: [],
        },
      );
    }
  }, [errorToShow]);

  return (
    <MotionConfig transition={{ duration: 0.5 }}>
      <motion.div
        className={
          clsx(
            'fixed w-screen h-full overflow-auto z-10',
          )
        }
        initial={{ top: '100%', opacity: 1 }}
        animate={{ top: 0, opacity: 1 }}
        exit={{ top: '100%', opacity: 1 }}
      >
        <FormProvider {...methods}>
          <form
            className="flex flex-col items-center h-full fill-baco-primary"
            onSubmit={methods.handleSubmit(handleSubmit)}
            noValidate
          >
            <div className="w-full h-full overflow-y-auto pb-4">
              <nav className="flex justify-between py-6 px-4 lg:w-[80%] lg:mx-auto">
                <button
                  type="button"
                  // onClick={() => navigate(-1)}
                  onClick={handleGoBack}
                >
                  <ChevronLeftIcon
                    className="fill-baco-primary scale-150"
                  />
                </button>
                <button type="button" onClick={() => navigate('/')}>
                  <LogoIcon
                    className="fill-baco-primary w-5 h-8 scale-110"
                  />
                </button>
              </nav>
              <h1 className="text-2xl text-center text-baco-primary mb-6">
                <b>Datos de envío</b>
              </h1>
              <div className="flex flex-col items-center">
                <div className="w-full max-w-md px-4">
                  <div className="self-start mb-1 font-bold">
                    Dirección de envío*
                  </div>
                  <div className="fill-baco-primary flex flex-row justify-between flex-wrap">
                    <FormInputPanel
                      id="delivery-address"
                      panelElements={[
                        {
                          type: 'select',
                          name: 'addressBox1',
                          placeholder: '-',
                          defaultValues: '',
                          fullWidth: true,
                          options: addresType,
                          size: 'md',
                          classes: ['text-gray-400', 'h-14', 'w-16'],
                          multiValueInput: true,
                        },
                        {
                          type: 'text',
                          name: 'addressBox2',
                          placeholder: '14 BIS',
                          fullWidth: true,
                          size: 'md',
                          classes: ['w-20', 'max-w-[90px]', 'lg:max-w-[90px]'],
                          multiValueInput: true,
                        },
                        {
                          type: 'text',
                          name: 'addressBox3',
                          placeholder: '86a',
                          fullWidth: true,
                          size: 'md',
                          startAdornment: <NumeralIcon className="fill-baco-primary w-4 scale-110 m-auto" />,
                          classes: ['w-28', 'max-w-[90px]', 'lg:max-w-[90px]'],
                          multiValueInput: true,
                        },
                        {
                          type: 'number',
                          name: 'addressBox4',
                          placeholder: '15',
                          fullWidth: true,
                          size: 'md',
                          startAdornment: <DashIcon className="fill-baco-primary w-3 scale-110 m-auto" />,
                          classes: ['w-20', 'max-w-[70px]', 'lg:max-w-[75px]'],
                          multiValueInput: true,
                        },
                        {
                          name: 'message',
                          label: <span>{errorToShow}</span>,
                          classes: ['w-full', 'mt-2', 'text-sm', 'text-baco-error-normal'],
                        },
                        {
                          name: 'validationMessage',
                          label: <span>{messageValidationToShow.message}</span>,
                          classes: messageValidationToShow.style,
                        },
                        {
                          type: 'text',
                          name: 'addressDetail',
                          placeholder: 'Apto 303, Torre B',
                          fullWidth: true,
                          size: 'md',
                          label: <b>Detalle de dirección*</b>,
                          classes: ['w-full max-w-md mt-8'],
                          disabled: detailDisabled,
                        },
                        {
                          type: 'checkbox',
                          name: 'addressDetailCheckbox',
                          placeholder: 'Apto 303, Torre B',
                          fullWidth: true,
                          size: 'md',
                          classes: ['w-full max-w-md mt-4'],
                          label: 'Esta dirección no tiene detalle',
                          direction: 'row-reverse',
                          disabled: detailDisabledCheck,
                        }, {
                          name: 'detailError',
                          label: <span>{detailError}</span>,
                          classes: ['w-full', 'mt-2', 'text-sm', 'text-baco-error-normal'],
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full max-w-md px-4">
              <Button
                type="submit"
                isLoading={isLoading}
                size="lg"
                fullWidth
                className="!text-base mx-auto lg:mb-12 mb-6 font-bold"
                disabled={!methods.formState.isValid || errorToShow !== '' || !messageValidationToShow.success}
              >
                <span className="mr-2">Continuar</span>
              </Button>
            </div>
          </form>
        </FormProvider>
      </motion.div>
    </MotionConfig>
  );
}

export default AddressInput;
