import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import clsx from 'clsx';
import { useYupValidationResolver } from '../../hooks/useYupValidatorResolver';
import Button from '../../components/Button';
import FormInputPanel from '../../components/FormInputPanel';
import { useForgotRestore } from '../../hooks/services/useForgotRestore';
import { useAlert } from '../../contexts/Alert.context';
import { LoginScreens, useLogin } from '../../contexts/Login.context';
import HeaderWithButton from '../../components/HeaderWithButton';
import { LogoIcon } from '../../components/icons';

const passwordSchema = yup.object({
  newPassword: yup.string()
    .required('La contraseña es obligatoria').min(8, 'La contraseña es muy corta'),
  newPasswordConfirmation: yup.string().required('La contraseña es obligatoria')
    .min(8, 'La contraseña es muy corta')
    .oneOf([yup.ref('newPassword')], 'Las contraseñas no coinciden'),
});

function FormNewPassword() {
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(passwordSchema);
  const { forgotRestore, isLoadingForgotRestore } = useForgotRestore();
  const { showAlert } = useAlert();
  const { handleChangeScreen } = useLogin();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const methods = useForm({
    defaultValues: {
      newPassword: '',
      newPasswordConfirmation: '',
    },
    mode: 'onChange',
    resolver,
  });

  const onSubmit = (data) => {
    forgotRestore({
      password: data.newPassword,
      hash: searchParams.get('hash'),
    }, {
      onSuccess: () => {
        handleChangeScreen(LoginScreens.LOGIN);
        navigate('/login');
        showAlert({
          title: 'Has restablecido tu contraseña! ',
          description: 'Inicia sesión con tu correo y nueva contraseña para continuar.',
          type: 'success',
        });
      },
      onError: () => {
        handleChangeScreen(LoginScreens.LOGIN);
        navigate('/login');
        showAlert({
          title: 'Has restablecido tu contraseña! ',
          description: 'Inicia sesión con tu correo y nueva contraseña para continuar.',
          type: 'success',
        });
      },
    });
  };

  const handleBack = () => {
    handleChangeScreen(LoginScreens.LOGIN, 'right');
    navigate('/login');
  };

  return (
    <div className="w-full max-w-6xl mx-auto h-full flex flex-col overflow-hidden">
      <HeaderWithButton
        buttonVariant="normal"
        onClick={handleBack}
        endAdornment={(
          <LogoIcon className={clsx(
            'h-16 w-8 sm:h-14 sm:w-7 md:h-10 md:w-10',
            'fill-baco-primary  mr-5 md:mr-24',
          )}

          />
        )}
      />
      <div className="w-full flex flex-col justify-start items-center flex-1 overflow-auto">
        <div className="flex flex-col py-10 h-full px-3 w-full max-w-lg overflow-auto">
          <h2 className="text-baco-primary font-bold text-3xl mb-2">Nueva contraseña</h2>
          <span className="text-gray-700">Recupera tu cuenta, ingresando una nueva contraseña para tu cuenta</span>

          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="flex flex-col h-full justify-between md:justify-start"
              noValidate
            >
              <div>
                <FormInputPanel
                  id="login"
                  panelElements={[{
                    type: 'password',
                    name: 'newPassword',
                    placeholder: 'Nueva contraseña',
                    fullWidth: true,
                    classes: ['my-4'],
                    autoComplete: 'new-password',
                    helper: <span className="text-gray-700 text-xs">Mínimo 8 caracteres</span>,
                  },
                  {
                    type: 'password',
                    name: 'newPasswordConfirmation',
                    placeholder: 'Confirmar contraseña',
                    fullWidth: true,
                    classes: ['my-4'],
                    autoComplete: 'new-password',
                  },
                  ]}
                />

              </div>
              <Button
                type="submit"
                size="lg"
                fullWidth
                className="md:mt-12"
                disabled={!methods.formState.isValid}
                isLoading={isLoadingForgotRestore}
              >
                Guardar
              </Button>
            </form>
          </FormProvider>

        </div>
      </div>
    </div>
  );
}

export default FormNewPassword;
