import PropTypes from 'prop-types';

function MapPin(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      width="38"
      height="58"
      viewBox="0 0 38 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_274_10148)">
        <path
          d="M16.7815 2C13.0588 2.05425 9.48926 3.491 6.76705 6.0309C4.04484 8.5708 2.36448
        12.0323 2.05271 15.7423C1.74094 19.4524 2.82005 23.1458 5.08021 26.1043C7.34036 29.0629
        10.62 31.0752 14.2815 31.75V49.5C14.2815 50.163 14.5449 50.7989 15.0137 51.2678C15.4826 51.7366
        16.1184 52 16.7815 52C17.4445 52 18.0804 51.7366 18.5492 51.2678C19.0181 50.7989 19.2815 50.163
        19.2815 49.5V31.75C22.9429 31.0752 26.2226 29.0629 28.4827 26.1043C30.7429 23.1458 31.822 19.4524 31.5102
        15.7423C31.1985 12.0323 29.5181 8.5708 26.7959 6.0309C24.0737 3.491 20.5042 2.05425 16.7815 2ZM16.7815
        22C15.7926 22 14.8259 21.7068 14.0036 21.1573C13.1814 20.6079 12.5405 19.827 12.1621 18.9134C11.7836 17.9998
        11.6846 16.9945 11.8775 16.0245C12.0705 15.0546 12.5467 14.1637 13.2459 13.4645C13.9452 12.7652 14.8361 12.289
        15.806 12.0961C16.7759 11.9031 17.7813 12.0022 18.6949 12.3806C19.6085 12.759 20.3894 13.3999 20.9388
        14.2221C21.4882 15.0444 21.7815 16.0111 21.7815 17C21.7815 18.3261 21.2547 19.5978 20.317 20.5355C19.3793
        21.4732 18.1076 22 16.7815 22Z"
          fill="#0F5945"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_274_10148"
          x="0"
          y="0"
          width="37.563"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_274_10148" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_274_10148" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

MapPin.propTypes = {
  className: PropTypes.string,
};

MapPin.defaultProps = {
  className: '',
};

export default MapPin;
