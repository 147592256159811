import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Button from '../Button';
import InputText from '../InputText';
import { ChevronLeftIcon, SearchIcon, CloseIcon } from '../icons';

let timeoutSearch;
function SearchBar(props) {
  const { placeholder, onChange } = props;
  const [showBack, setShowBack] = useState(false);
  const [value, setValue] = useState('');
  useEffect(() => {
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => onChange(value), 500);

    return () => {
      clearTimeout(timeoutSearch);
    };
  }, [value, onChange]);

  const handleChange = (event) => setValue(event.target.value);
  const handleClear = () => setValue('');
  const handleBack = () => {
    setShowBack(false);
    setValue('');
  };

  return (
    <div
      className="flex items-center"
    >
      <motion.div
        className="overflow-hidden"
        initial={{
          minWidth: 0,
          width: 0,
          opacity: 0,
        }}
        animate={{
          minWidth: (showBack || value !== '') ? '72px' : 0,
          width: (showBack || value !== '') ? '72px' : 0,
          opacity: (showBack || value !== '') ? 1 : 0,
        }}
      >
        <Button
          color="outline"
          onClick={handleBack}
          size="md"
        >
          <ChevronLeftIcon />
        </Button>
      </motion.div>
      <InputText
        placeholder={placeholder}
        startAdornment={(!showBack && value === '') && (
          <SearchIcon className="fill-gray-500" />
        )}
        endAdornment={(showBack && value !== '') && (
          <button
            type="button"
            onClick={handleClear}
          >
            <CloseIcon className="fill-gray-600" />
          </button>
        )}
        onFocus={() => setShowBack(true)}
        onBlur={() => setShowBack(value !== '')}
        onChange={handleChange}
        value={value}
        fullWidth
      />
    </div>
  );
}

SearchBar.defaultProps = {
  placeholder: 'Busca tus antojos de Bacu',
  onChange: () => null,
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchBar;
