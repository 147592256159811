import clsx from 'clsx';
import PropTypes from 'prop-types';
import { CloseIcon } from '../icons';

function Modal(props) {
  const {
    className,
    children,
    onClose,
  } = props;

  return (
    <div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-70">
      <div
        className={
          clsx(
            'relative w-full max-w-xl p-6 rounded-lg bg-white',
            className,
          )
        }
      >
        <button
          type="button"
          onClick={onClose}
        >
          <CloseIcon className="absolute top-4 right-4 w-6 h-6 baco-carbon" />
        </button>
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  className: '',
  onClose: () => null,
};

export default Modal;
