import { useState } from 'react';

export const MEDIA_SCREEN = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  '2XL': '2xl',
};

export const SCREENS = [
  { minWidth: 640, label: MEDIA_SCREEN.SM },
  { minWidth: 768, label: MEDIA_SCREEN.MD },
  { minWidth: 1024, label: MEDIA_SCREEN.LG },
  { minWidth: 1280, label: MEDIA_SCREEN.XL },
  { minWidth: 1536, label: MEDIA_SCREEN['2XL'] },
];

/**
 * Method to compare if a minScreen resolution is at least the compared label
 * @param {string} minScreen the resolution to compare
 * @param {string} label the current screen resolution
 */
export function isScreenMin(minScreen, label) {
  const minScreenIndex = SCREENS.findIndex((screen) => screen.label === minScreen);
  const labelIndex = SCREENS.findIndex((screen) => screen.label === label);

  return labelIndex >= minScreenIndex;
}

function getWindowQuery() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const { label } = SCREENS.reduce((found, screen) => {
    if (width >= screen.minWidth) {
      return screen;
    }

    return found;
  }, SCREENS[0]);

  return { width, height, screen: label };
}

let timmer;
export function useWindow() {
  const [infoWindow, setInfoWindow] = useState(getWindowQuery());

  const handleResize = () => {
    clearTimeout(timmer);

    timmer = setTimeout(() => setInfoWindow(getWindowQuery()), 500);
  };

  window.addEventListener('resize', handleResize);

  return infoWindow;
}

export default useWindow;
