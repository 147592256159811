import {
  trackEvent,
} from '../services/amplitude';

export function useStorePageEvents() {
  const trackSelectStorePickup = (storeID) => {
    trackEvent('select_store_pickup', { store_id: storeID });
  };

  const trackSelectItemMenu = (productId) => {
    trackEvent('select_item_menu', { product_id: productId });
  };

  return {
    trackSelectStorePickup,
    trackSelectItemMenu,
  };
}

export default useStorePageEvents;
