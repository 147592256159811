import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

function Portal(props) {
  const {
    children,
    className,
    el,
  } = props;
  const [container] = useState(() => document.createElement(el));

  useEffect(() => {
    container.classList.add(className);
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  el: PropTypes.string,
};
Portal.defaultProps = {
  className: '',
  el: 'div',
};

export default Portal;
