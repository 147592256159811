import config from '../../config';
import { b64Encode } from '../../utils/string';

const initialState = {
  items: [],
  notAvailable: [],
  markToRemove: false,
  scheduler: { fastSchedule: { from: '', to: '' } },
  total: 0,
  totalWithDiscount: 0,
  discount: null,
  pickupMethod: {
    preorder: false,
    schedule: null,
  },
};

const shoppingCartReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case 'ADD_TO_CART':
      const itemsSearchToAdd = [...(state?.items || [])];
      const itemIndexAdd = itemsSearchToAdd.findIndex((item) => {
        if (item.id !== data.id) {
          return false;
        }

        return data.modifierGroups?.every((modifierGroup) => {
          const mg = item.modifierGroups?.find((modGroup) => modGroup.id === modifierGroup.id);
          if (!mg) {
            return false;
          }

          return modifierGroup.modifiers?.every((modifier) => {
            const mod = mg.modifiers?.find((modi) => modi.id === modifier.id);
            if (!mod) {
              return false;
            }

            return mod.value === modifier.value;
          });
        });
      });

      if (itemIndexAdd >= 0) {
        const item = itemsSearchToAdd[itemIndexAdd];
        itemsSearchToAdd.splice(itemIndexAdd, 1, {
          ...item, count: item.count + 1,
        });
      } else {
        itemsSearchToAdd.push({
          ...data, count: 1,
        });
      }
      return {
        ...state,
        items: itemsSearchToAdd,
        markToRemove: false,
      };
    case 'UPDATE_CART':
      return {
        ...state,
        ...data,
      };
    case 'REMOVE_FROM_CART':
      const itemsSearchToRemove = [...(state?.items || [])];
      itemsSearchToRemove.splice(data, 1);
      return {
        ...state,
        items: itemsSearchToRemove,
        markToRemove: false,
      };
    case 'REMOVE_ALL_FROM_CART':
      return {
        ...state,
        items: [],
        markToRemove: false,
        discount: null,
        pickupMethod: {
          preorder: false,
          schedule: null,
        },
      };
    case 'MARK_TO_REMOVE':
      return {
        ...state,
        markToRemove: true,
      };
    case 'GET_SESSION_DATA':
      return {
        ...state,
        data: b64Encode(encodeURIComponent(JSON.stringify(data))),
      };
    case 'CLEAR_SESSION_DATA':
      return {
        ...state,
        data: null,
      };
    case 'SET_SCHEDULER':
      return {
        ...state,
        scheduler: data,
      };
    case 'UPDATE_QUANTITY_PRODUCT':
      return {
        ...state,
        items: [...data],
      };
    case 'SET_TOTAL_AND_DISCOUNT':
      return {
        ...state,
        total: data?.total,
        totalWithDiscount: data?.totalWithDiscount,
      };
    case 'SET_ORDER_METHOD':
      return {
        ...state,
        pickupMethod: {
          preorder: data.pick_method === config.pickupMethods.scheduled,
          schedule: data.schedule,
        },
      };
    case 'ADD_DISCOUNT':
      return {
        ...state,
        discount: data,
      };
    default:
      return state;
  }
};

export default shoppingCartReducer;
