import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { LogoIcon } from '../../components/icons';
import { useYupValidationResolver } from '../../hooks/useYupValidatorResolver';
import Button from '../../components/Button';
import FormInputPanel from '../../components/FormInputPanel';
import HeaderWithButton from '../../components/HeaderWithButton';
import { LoginScreens, useLogin } from '../../contexts/Login.context';
import { useSignIn } from '../../hooks/services/useSignIn';
import Loader from '../../components/Loader';
import { useSession } from '../../hooks/actions/useSession';
import { useAmplitude } from '../../hooks/useAmplitude';

const userDataSchema = yup.object({
  email: yup.string().email('Ingresa un correo electrónico válido')
    .required('El correo electrónico es obligatorio'),
  password: yup.string().required('La contraseña es obligatoria')
    .min(8, 'La contraseña es muy corta'),
});

function FormLogin() {
  const { handleChangeScreen } = useLogin();
  const { token } = useSession();
  const resolver = useYupValidationResolver(userDataSchema);
  const { trackLoginAttempt } = useAmplitude().useLoginPageEvents();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver,
  });

  const { signIn, isLoadingSignIn, isError } = useSignIn(methods.setError);

  const onSubmit = (data) => signIn(data);

  const handleBack = () => {
    navigate(-1);
  };

  const handleGoForgot = () => handleChangeScreen(LoginScreens.FORGOT);
  const handleGoRegister = () => handleChangeScreen(LoginScreens.REGISTER);
  const handleOnSubmit = (data) => {
    trackLoginAttempt();
    onSubmit(data);
  };

  if (isLoadingSignIn || !!token) {
    return (<Loader title="Iniciando sesión" />);
  }

  return (
    <div className="w-full max-w-6xl mx-auto h-full flex flex-col overflow-hidden">
      <HeaderWithButton
        buttonVariant="normal"
        onClick={handleBack}
        endAdornment={(
          <LogoIcon className={clsx(
            'h-16 w-8 sm:h-14 sm:w-7 md:h-10 md:w-10',
            'fill-baco-primary  mr-5 md:mr-24',
          )}
          />
        )}
      />

      <div className="w-full flex flex-col justify-start items-center flex-1 overflow-auto">
        <div className="py-10 h-full px-3 w-full md:w-1/2 lg:w-2/5 overflow-auto">
          <h2 className="text-baco-primary font-bold text-3xl mb-2">Inicia sesión</h2>
          <span className="text-gray-700">Ingresa a tu cuenta para continuar con el pago</span>

          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleOnSubmit)}
              className="flex flex-col justify-between md:justify-start"
              noValidate
            >
              <div>
                <FormInputPanel
                  id="login"
                  panelElements={[{
                    type: 'email',
                    name: 'email',
                    placeholder: 'Correo electrónico',
                    fullWidth: true,
                    classes: ['my-4'],
                    autoComplete: 'username',
                  },
                  {
                    type: 'password',
                    name: 'password',
                    placeholder: 'Contraseña',
                    fullWidth: true,
                    classes: ['my-4'],
                    autoComplete: 'current-password',
                  },
                  ]}
                />
                <button
                  className={clsx(
                    'underline text-baco-primary font-semibold text-right',
                    'inline-block w-full',
                  )}
                  type="button"
                  onClick={handleGoForgot}
                >
                  Olvidé mi contraseña
                </button>
              </div>
              <div className="mb-12 md:mt-12">
                {isError && (
                  <span className="text-center text-baco-error-normal mb-5 block">
                    Lo sentimos, el usuario o contraseña son incorrectos. Verifica tus datos e inténtalo de nuevo.
                  </span>
                )}

                <Button
                  type="submit"
                  size="lg"
                  fullWidth
                  className="mb-5"
                  disabled={!methods.formState.isValid}
                  isLoading={isLoadingSignIn}
                >
                  Ingresar
                </Button>
                <div className="inline-block w-full text-base border-t-2  pt-2 text-center text-gray-700">
                  <span className="inline-block w-full text-base pt-3 text-center text-gray-700">
                    ¿Aún no tienes cuenta?
                  </span>
                </div>
                <Button
                  type="submit"
                  size="lg"
                  fullWidth
                  color="outline-primary"
                  className="mt-1 font-bold"
                  onClick={handleGoRegister}
                >
                  Crear Cuenta
                </Button>
              </div>
            </form>
          </FormProvider>

        </div>
      </div>
    </div>

  );
}

export default FormLogin;
