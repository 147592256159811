import PropTypes from 'prop-types';

function StoreBasic(props) {
  const {
    imageUrl,
    name,
    address,
    storeId,
    onClick,
    lat,
    lng,
  } = props;

  const handleStore = () => onClick({
    storeId, name, address, lat, lng,
  });

  return (
    <div
      className="flex w-full items-stretch text-left"
    >
      <div className="flex flex-col w-[calc(100%-85px)] h-full pl-0 pr-3 pt-0 pb-4 rounded-l-lg grow">
        <h3
          className="text-lg leading-4 truncate m-0 mb-2 text-baco-primary font-medium"
        >
          {name}
        </h3>
        <p
          className="text-xs leading-4 h-4 truncate mb-6 text-baco-primary"
        >
          {address}
        </p>
        <button
          className="text-xs text-left font-bold leading-3 m-0 text-baco-primary underline"
          onClick={handleStore}
          type="button"
        >
          ¿Cómo llegar?
        </button>
      </div>
      <div
        className="min-w-[85px] bg-gray-400 bg-cover bg-center rounded-r-lg grow"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    </div>
  );
}

StoreBasic.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  storeId: PropTypes.string,
  imageUrl: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  onClick: PropTypes.func,
};
StoreBasic.defaultProps = {
  imageUrl: null,
  name: '',
  storeId: '',
  address: '',
  lat: 0,
  lng: 0,
  onClick: () => null,
};

export default StoreBasic;
