import { useMutation } from '@tanstack/react-query';
import { login } from '../../services/api';
import { useSession } from '../actions/useSession';
import { useAmplitude } from '../useAmplitude';

export function useSignIn(setFormError) {
  const { changeSessionToken, changeRefreshToken, token } = useSession();

  const { trackLoginSuccess } = useAmplitude().useLoginPageEvents();

  const {
    mutate: signIn,
    isLoading: isLoadingSignIn,
    isError,
  } = useMutation(({
    email,
    password,
  }) => login({ email, password }), {
    onSuccess: (res) => {
      trackLoginSuccess();
      changeRefreshToken(res.data.refresh_token);
      changeSessionToken(res.data.token);
    },
    onError: (e) => {
      if (e) {
        ['email', 'password'].forEach((input) => {
          setFormError(input);
        });
      }
    },
  });

  return {
    isLoadingSignIn, token, signIn, isError,
  };
}

export default useSignIn;
