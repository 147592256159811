import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import Skeleton from '../Skeleton';

function getScrollParent(childrenItem) {
  if (!childrenItem) {
    return window;
  }

  const props = window.getComputedStyle(childrenItem);
  if (['auto', 'scroll'].includes(props.getPropertyValue('overflow-y'))) {
    return childrenItem;
  }

  return getScrollParent(childrenItem.parentElement);
}

const easingAnim = Array.apply(0, new Array(10)).map((_, index) => (index + 1) ** 2 / 100);

let timmerAnimation;
function animateToCategory(element) {
  try {
    clearTimeout(timmerAnimation);
    const parentScroll = element.parentNode.parentNode;
    const { offsetWidth: parentWidth, scrollLeft } = parentScroll;
    const { offsetLeft, offsetWidth } = element;
    const desiredPosition = offsetLeft - (parentWidth - offsetWidth) / 2;
    const requiredDisplacement = desiredPosition - scrollLeft;

    const doAnimation = (index) => {
      parentScroll.scrollLeft = scrollLeft + requiredDisplacement * easingAnim[index];

      if (index < easingAnim.length - 1) {
        timmerAnimation = setTimeout(() => doAnimation(index + 1), 25);
      }
    };

    doAnimation(0);
  } catch {
    // To Do
  }
}

let timeoutScroll;
function ContainerWithMenu(props) {
  const containerRef = useRef(null);
  let parentScroll;

  const {
    isLoading,
    offsetTop,
    classes,
    menu,
    children,
  } = props;
  const [currIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    const handleScroll = (event) => {
      clearTimeout(timeoutScroll);
      timeoutScroll = setTimeout(() => {
        const { scrollTop } = event.target;
        const bestOption = menu.reduce((best, item, index) => {
          const elementChild = document.getElementById(item.id);
          const distance = elementChild.parentNode.offsetTop - scrollTop - offsetTop;
          if (elementChild && distance <= 0 && distance > best.distance) {
            return { distance, index };
          }

          return best;
        }, { index: -1, distance: -9999 });

        setCurrentIndex(bestOption.index);
      }, 250);
    };

    if (containerRef) {
      parentScroll = getScrollParent(containerRef.current.parentElement);

      if (parentScroll) {
        parentScroll.addEventListener('scroll', handleScroll);
      } else {
        window.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      clearTimeout(timeoutScroll);
      if (parentScroll) {
        parentScroll.removeEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }

      parentScroll = undefined;
    };
  }, [containerRef, menu.length, offsetTop]);

  useEffect(() => {
    const item = menu[currIndex];
    const element = document.getElementById(`${item?.id}-attached`);

    if (element) {
      animateToCategory(element);
    }
  }, [currIndex, menu]);

  const handleGoToMenu = (index) => {
    const item = menu[index];
    if (item) {
      const element = document.getElementById(item.id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  return (
    <>
      <div className={clsx('relative w-full z-10 bg-white', classes.container)}>
        <div className="relative overflow-hidden h-[50px] w-full max-w-7xl mx-auto">
          <div
            className="overflow-x-auto h-[100px] w-full"
          >
            <div
              className={
                clsx('flex justify-start gap-x-10 flex-nowrap w-max pb-4 px-4', classes.menu)
              }
            >
              {isLoading ? Array.apply(0, new Array(3)).map(() => uuid()).map((key) => (
                <button
                  type="button"
                  className={
                    clsx(
                      'text-lg font-bold pb-2',
                      classes.menuItem,
                      classes.item,
                    )
                  }
                  key={key}
                >
                  <Skeleton className="h-4 w-[80px]" />
                </button>
              )) : menu.map((item, index) => (
                <button
                  type="button"
                  onClick={() => handleGoToMenu(index)}
                  id={`${item.id}-attached`}
                  className={
                    clsx(
                      'text-lg font-bold pb-2 min-w-max',
                      classes.menuItem,
                      {
                        [classes.selected]: index === Math.max(currIndex, 0),
                        [classes.item]: index !== Math.max(currIndex, 0),
                      },
                    )
                  }
                  key={item.id}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          clsx('relative z-0', classes.content)
        }
        ref={containerRef}
      >
        {children}
      </div>
    </>
  );
}

ContainerWithMenu.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.any,
  offsetTop: PropTypes.number,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.node,
    }),
  ),
  children: PropTypes.node,
};
ContainerWithMenu.defaultProps = {
  isLoading: false,
  classes: {
    menu: '',
  },
  offsetTop: 0,
  menu: [],
  children: null,
};

export default ContainerWithMenu;
