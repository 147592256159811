import config from 'src/config';

const initialState = {
  orderType: config.deliveryType.pickup,
  isDelivery: false,
  lat: null,
  lng: null,
  address: null,
  addressDetail: null,
  addressDetailCheckbox: null,
  storeId: null,
};

const deliveryReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case 'DELIVERY_UPDATE':
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

export default deliveryReducer;
