import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import InputRating from '.';

function InputRatingControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    fullWidth,
    size,
    classes,
    readOnly,
    disabled,
    direction,
    ...args
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        fieldState,
        field: {
          onChange,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          direction={direction}
          className={clsx(classes)}
          fieldState={fieldState}
        >
          <InputRating
            {...args}
            onChange={onChange}
            value={value}
            size={size}
            readOnly={readOnly}
            disabled={disabled}
            fieldState={fieldState}
          />
        </InputContainer>
      )}
    />
  );
}

InputRatingControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  helper: PropTypes.node,
  size: PropTypes.string,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
};

InputRatingControlled.defaultProps = {
  label: null,
  startAdornment: null,
  endAdornment: null,
  helper: null,
  size: 'md',
  classes: [],
  readOnly: false,
  disabled: false,
  fullWidth: false,
  direction: 'column',
};

export default InputRatingControlled;
