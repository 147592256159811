export const updateQuantityProductOnCart = (data) => ({
  type: 'UPDATE_QUANTITY_PRODUCT',
  data,
});

export const setTotalAndDiscountCart = (data) => ({
  type: 'SET_TOTAL_AND_DISCOUNT',
  data,
});

export const addProduct2Cart = (data) => ({
  type: 'ADD_TO_CART',
  data,
});

export const updateProductsOnCart = (data) => ({
  type: 'UPDATE_CART',
  data,
});

export const removeProduct2Cart = (data) => ({
  type: 'REMOVE_FROM_CART',
  data,
});

export const clearProductsCart = () => ({
  type: 'REMOVE_ALL_FROM_CART',
});

export const markToRemoveCart = () => ({
  type: 'MARK_TO_REMOVE',
});

export const setSessionData = (data) => ({
  type: 'GET_SESSION_DATA',
  data,
});

export const clearSessionData = () => ({
  type: 'CLEAR_SESSION_DATA',
});

export const setScheduler = (data) => ({
  type: 'SET_SCHEDULER',
  data,
});

export const setOrderMethod = (data) => ({
  type: 'SET_ORDER_METHOD',
  data,
});

export const setAddDiscount = (data) => ({
  type: 'ADD_DISCOUNT',
  data,
});
