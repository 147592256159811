import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import Header from '../components/Header';
import IllustratedMessage from '../../components/IllustratedMessage';
import ProductItem from '../../components/ProductItem';
import ItemsGroup from '../../components/ItemsGroup';
import Skeleton from '../../components/Skeleton';
import ContainerWithMenu from '../../components/ContainerWithMenu';
import { useMenu } from '../../hooks/services/useMenu';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import { useStore } from '../../hooks/actions/useStore';
import { useShoppingCart } from '../../hooks/actions/useShoppingCart';
import StoreNotAvailable from '../components/StoreNotAvailable';
import { useScheduler } from '../../contexts/Scheduler.context';
import { useAmplitude } from '../../hooks/useAmplitude';

function Store() {
  const {
    selectedCategoryType,
    sessionCategoryType,
    availableCategories,
    notAvailableSchedule,
    handleCategoryType,
  } = useScheduler();
  const { selectedStore, changeStore } = useStore();
  const { items, clearCart } = useShoppingCart();
  const { storeID } = useParams();
  const navigate = useNavigate();
  const [bgImage, setBgImage] = useState('breakfast');
  const [searchValue, setSearchValue] = useState('');
  const [storeNotAvailable, setStoreNotAvailable] = useState(false);
  const storePageEvents = useAmplitude().useStorePageEvents();
  const { trackSelectStorePickup, trackSelectItemMenu } = storePageEvents;

  const { menu, isLoading, isError } = useMenu({
    searchValue, selectedCategoryType, storeID,
  });
  useGoogleAnalytics('event', 'search', { searchValue });

  const menuCategories = useMemo(() => (
    menu.menuCategories?.filter((menuCategory) => menuCategory.show).map((menuCategory) => ({
      ...menuCategory,
      products: menuCategory.products.map((product) => ({
        ...product,
        onCart: items.reduce((total, item) => total + (item.id === product.id ? item.count : 0), 0),
      })),
    }))
  ), [menu]);
  const notFoundProductsCondition = !isLoading && !!menuCategories && menuCategories.length === 0;

  const handleFilterProducts = (filterValue) => setSearchValue(filterValue);
  const handleOpenProduct = (productId) => {
    trackSelectItemMenu(productId);
    navigate(`product/${productId}`);
  };

  useEffect(() => {
    if (menu.store) {
      if (selectedStore?.storeId && menu.store.storeId !== selectedStore?.storeId) {
        clearCart();
      }

      if (!menu.store.isActive || !menu.store.isOpen) {
        setStoreNotAvailable(true);
      }

      changeStore(menu.store);
      trackSelectStorePickup(menu.store.storeId);
    }
  }, [isLoading, menu, selectedStore, items.length]);

  useEffect(() => {
    const hour = new Date().getHours();
    setBgImage(hour > 11 ? 'lunch' : 'breakfast');

    if (!sessionCategoryType) {
      clearCart();
    }
  }, [sessionCategoryType]);

  if (storeNotAvailable || notAvailableSchedule) {
    return <StoreNotAvailable {...selectedStore?.currentSchedule} />;
  }

  return (
    <div className="relative w-full h-full bg-gray-50 z-0 overflow-hidden">
      <motion.div
        className="fixed w-full h-full overflow-y-scroll z-10"
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
      >
        <Header
          categoryTypes={availableCategories}
          selectedCategoryType={selectedCategoryType}
          onChangeCategoryType={handleCategoryType}
          onSearch={handleFilterProducts}
          value={searchValue}
        />
        <div className="relative z-0">
          <ContainerWithMenu
            isLoading={isLoading}
            offsetTop={128}
            classes={{
              container: clsx(
                'sticky lg:!top-[90px]',
                {
                  'top-[72px]': searchValue === '',
                  'top-[98px]': searchValue !== '',
                },
              ),
              menu: 'max-w-7xl mx-auto',
              item: 'border-b-2 border-b-transparent text-baco-brown capitalize',
              selected: 'border-b-2 border-b-baco-primary text-baco-primary capitalize',
              content: 'max-w-7xl mx-auto',
            }}
            menu={
              menuCategories?.map((productGroup) => ({
                id: `group-${productGroup.id}`,
                label: productGroup.name,
              }))
            }
          >
            {isLoading && (
              <div className="max-w-3xl">
                <ItemsGroup
                  title={<Skeleton className="w-1/2 h-5" />}
                >
                  {Array.apply(0, new Array(3)).map(() => uuid()).map((key) => (
                    <div className="w-full md:w-1/2 mb-6 px-3" key={key}>
                      <ProductItem id={key} isLoading={isLoading} />
                    </div>
                  ))}
                </ItemsGroup>
              </div>
            )}
            {(notFoundProductsCondition || isError) ? (
              <div className="max-w-3xl mt-10">
                <IllustratedMessage
                  title="Lo sentimos"
                  description={
                    isError
                      ? 'Ocurrió un error cargando los datos. Inténtalo más tarde'
                      : 'No encontramos lo que buscas'
                  }
                />
              </div>
            ) : (
              <div className="max-w-3xl">
                {menuCategories?.map((currentProductGroup) => (
                  <ItemsGroup
                    id={`group-${currentProductGroup.id}`}
                    key={currentProductGroup.id}
                    title={currentProductGroup.name}
                    offsetTop={188}
                  >
                    {currentProductGroup.products.map((product) => (
                      <div key={product.id} className="w-full md:w-1/2 mb-6 px-3">
                        <ProductItem
                          {...product}
                          imageUrl={product.shortcutImage}
                          onClick={handleOpenProduct}
                        />
                      </div>
                    ))}
                  </ItemsGroup>
                ))}
              </div>
            )}
          </ContainerWithMenu>
        </div>
        <Outlet />
      </motion.div>
      <div
        className={
          clsx(
            'absolute top-[140px] bottom-0 right-0 w-10 m-auto bg-gray-400 z-0',
            'w-[calc(calc(100%-768px)-calc(100%-1280px)*0.5-90px)] pointer-events-none',
            'hidden [@media(min-width:1110px)]:block',
          )
        }
      >
        <img
          className="w-full h-full object-left-bottom object-cover"
          src={`/images/photos/${bgImage}.min.jpg`}
          alt="Home"
        />
      </div>
    </div>
  );
}

export default Store;
