import PropTypes from 'prop-types';
import LoginContext, { LoginScreens } from '../../contexts/Login.context';
import FormLogin from './FormLogin';
import FormCreateAccount from './FormCreateAccount';
import FormNewPassword from './FormNewPassword';
import FormForgotPassword from './FormForgotPassword';
import SuccesRestore from './SuccesRestore';

function LoginWrapper(props) {
  const { children } = props;

  return (
    <LoginContext
      screens={{
        [LoginScreens.LOGIN]: <FormLogin />,
        [LoginScreens.REGISTER]: <FormCreateAccount />,
        [LoginScreens.FORGOT_RESTORE]: <FormNewPassword />,
        [LoginScreens.FORGOT]: <FormForgotPassword />,
        [LoginScreens.SUCCES_RESTORE]: <SuccesRestore />,
      }}
    >
      {children}
    </LoginContext>
  );
}

LoginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginWrapper;
