const {
  REACT_APP_PAYMENT_PUBLIC_API_KEY,
  REACT_APP_API_URL = 'https://services.dev.baco.com.co',
  REACT_APP_API_BASE = '/api/',
  REACT_APP_TRACKING_ID,
  REACT_APP_WHATSAPP_SUPPORT = 'https://wa.me/message/YW4V3RALB6MFM1',
  REACT_APP_DEFAULT_CATEGORY = 'mixtos',
  REACT_APP_SUPER_FAST_MIN_MILLIS = 900000,
  REACT_APP_SUPER_FAST_MAX_MILLIS = 1200000,
  REACT_APP_ENVIRONMENT,
  REACT_APP_AMPLITUDE_API_KEY,
  REACT_APP_SCHEDULE_MIN_MILLIS = 2100000,
  REACT_APP_MINUTES_INTERVAL = 5,
  REACT_APP_SCHEDULE_BEFORE_CLOSE_MILLIS = 1200000,
  REACT_APP_DRAG_WIDTH = 20,
  REACT_APP_FIREBASE_VAPID_KEY,
  REACT_APP_FIREBASE_CONFIG = '{}',
  REACT_APP_MAX_RETRIES_BEFORE_ERROR = 2,
  REACT_APP_LOCALSTORAGE_KEY = 'persist:root',
  REACT_APP_BACU_ACCESS_TOKEN = 'accessToken',
} = process.env;

const config = {
  // Url base del proyecto
  baseURL: REACT_APP_API_URL,
  apiBase: REACT_APP_API_BASE,
  trackingID: REACT_APP_TRACKING_ID,

  environment: REACT_APP_ENVIRONMENT,

  isProd: REACT_APP_ENVIRONMENT === 'production',

  // API Key publica de Yuno
  yunoPublicAPIKey: REACT_APP_PAYMENT_PUBLIC_API_KEY,

  // Intervalo de tiempo en el cual crece el minutero del timePicker
  minutesInterval: Number(REACT_APP_MINUTES_INTERVAL),

  // Tiempo minimo para realizar un pedido por orden programada
  scheduleTimeMin: Number(REACT_APP_SCHEDULE_MIN_MILLIS),

  // Tiempo maximo para programar un pedido antes del cierre de la tienda
  scheduleBeforeClose: Number(REACT_APP_SCHEDULE_BEFORE_CLOSE_MILLIS),

  // Tiempo minimo y maximo para pedidos en modo super rapido
  superFast: {
    minTime: Number(REACT_APP_SUPER_FAST_MIN_MILLIS),
    maxTime: Number(REACT_APP_SUPER_FAST_MAX_MILLIS),
  },

  // Tamaño en pixeles de arrastre para mostrar opciones en una lista
  dragWidth: Number(REACT_APP_DRAG_WIDTH),

  // Metodos de pago (completar)
  paymentMethods: {
    CARD: 'CARD',
  },

  // Estados del proceso de pago
  paymentStatus: {
    SUCCEEDED: 'SUCCEEDED',
    PENDING: 'PENDING',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED',
    DECLINED: 'DECLINED',
    REJECTED: 'REJECTED',
    ERROR: 'ERROR',
    AWAITING_WEBHOOK: 'AWAITING_WEBHOOK',
    ENROLLMENT_FAILED: 'ENROLLMENT_FAILED',
  },

  // Definicion de tiempos de agendamiento para recogida
  pickupMethods: {
    superFast: 'super-fast',
    scheduled: 'scheduled',
  },

  deliveryType: {
    delivery: 'DELIVERY',
    pickup: 'PICK_UP',
  },

  // Configuracion de firebase
  firebaseConfig: {
    app: JSON.parse(REACT_APP_FIREBASE_CONFIG),
    vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
  },

  maxRetriesBeforeError: Number(REACT_APP_MAX_RETRIES_BEFORE_ERROR),

  whatsAppSupport: REACT_APP_WHATSAPP_SUPPORT,

  defaultCategory: REACT_APP_DEFAULT_CATEGORY,

  localStorageKey: REACT_APP_LOCALSTORAGE_KEY,

  bacuAccessToken: REACT_APP_BACU_ACCESS_TOKEN,

  amplitudeApiKey: REACT_APP_AMPLITUDE_API_KEY,
};

export default config;
