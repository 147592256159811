import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../../components/Button';
import { ChevronLeftIcon } from '../../components/icons';

function BackButtonProduct(props) {
  const [animateButton, setAnimateButton] = useState(true);
  const { onBack, children, animated } = props;

  if (!animated) {
    return (
      <Button
        startIcon={(
          <ChevronLeftIcon alt="bacu-arrow-left" />
        )}
        onClick={onBack}
        color="secondary"
        className="rounded-l-none"
      >
        <span
          className="block whitespace-nowrap overflow-hidden truncate"
        >
          {children}
        </span>
      </Button>
    );
  }

  const AnimatedButton = motion(Button);
  const handleAnimationEnd = () => setAnimateButton(false);

  return (
    <AnimatedButton
      startIcon={(
        <ChevronLeftIcon alt="bacu-arrow-left" />
      )}
      onClick={onBack}
      color="secondary"
      className="absolute top-5 left-0 rounded-l-none"
      initial={{
        width: 68,
      }}
      animate={
        animateButton
          ? {
            width: [68, 270, 270, 68],
            transition: {
              duration: 2,
              repeat: 0,
              times: [0.1, 0.2, 0.9, 1],
            },
          }
          : {}
        }
    >
      <motion.span
        className="block whitespace-nowrap overflow-hidden truncate"
        onAnimationComplete={handleAnimationEnd}
        initial={{
          width: 0,
        }}
        animate={
          animateButton
            ? {
              width: [0, 200, 200, 0],
              transition: {
                duration: 2,
                times: [0.1, 0.2, 0.9, 1],
              },
              transitionEnd: {
                display: 'none',
              },
            }
            : {}
        }
      >
        {children}
      </motion.span>
    </AnimatedButton>
  );
}

BackButtonProduct.propTypes = {
  onBack: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  animated: PropTypes.bool,
};

BackButtonProduct.defaultProps = {
  animated: false,
};

export default BackButtonProduct;
