import PropTypes from 'prop-types';
import clsx from 'clsx';
import ModifierItem from '../ModifierItem';

function getDescription(selectType, minAllowed, maxAllowed) {
  if (selectType === 'radioBtn') {
    return 'Escoge una opcion';
  }

  if (minAllowed === 0) {
    return `Escoge máximo ${maxAllowed} opciones`;
  }

  if (minAllowed === maxAllowed) {
    return `Escoge ${minAllowed} opciones`;
  }

  return `Escoge entre ${minAllowed} y ${maxAllowed} opciones`;
}

function ModifierGroup(props) {
  const {
    name,
    modifiers,
    selectType,
    index,
    minAllowed,
    maxAllowed,
    disables,
    disabled,
    className,
  } = props;
  const { max } = disables;

  const classes = clsx(
    'flex flex-col w-full',
    className,
  );

  return (
    <div
      className={classes}
    >
      <h3
        className="text-base text-black m-0 mb-3 font-bold"
      >
        {name}
        {minAllowed > 0 && (
          <span
            className="border border-baco-primary rounded-md p-1 ml-4 font-thin text-xs text-baco-primary"
          >
            Obligatorio
          </span>
        )}
      </h3>
      <p
        className="text-sm text-black m-0"
      >
        {getDescription(selectType, minAllowed, maxAllowed)}
      </p>
      {modifiers.map(({ imageUrl, ...modifier }, i) => (
        <div
          className="flex flex-col w-full"
          key={modifier.id}
        >
          <ModifierItem
            disabled={disabled}
            {...modifier}
            id={modifier.id}
            selectType={selectType}
            maxDisabled={max}
            inputName={`modifierGroups.${index}.modifiers.${selectType === 'radioBtn' ? 0 : i}.value`}
            value={selectType === 'radioBtn' ? modifier.name : undefined}
            className="py-6"
          />
          {i < modifiers.length - 1 && (
            <hr className="w-full h-[1px] border-none my-0 bg-gray-300" />
          )}
        </div>
      ))}
    </div>
  );
}

ModifierGroup.propTypes = {
  name: PropTypes.string.isRequired,
  modifiers: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  disables: PropTypes.shape({
    min: PropTypes.bool,
    max: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  minAllowed: PropTypes.number,
  maxAllowed: PropTypes.number,
  selectType: PropTypes.oneOf(['checkBoxes', 'counters', 'radioBtn']),
  className: PropTypes.string,
};
ModifierGroup.defaultProps = {
  selectType: 'counters',
  disables: {},
  disabled: false,
  minAllowed: 0,
  maxAllowed: 1,
  className: '',
};

export default ModifierGroup;
