import { DeliveryErrorBgIcon } from 'src/components/icons';
// import clsx from 'clsx';
import Button from 'src/components/Button';
import { motion } from 'framer-motion';
import HeaderWithHelp from 'src/components/HeaderWithHelp';
import { useState } from 'react';

const errors = {
  status1: {
    tittle: '¡Lo sentimos!',
    message: 'Tu pedido no pudo ser procesado. Por favor, inténtalo de nuevo.',
  },
  status2: {
    tittle: 'Tu orden fue cancelada',
    message: 'Recibimos una solicitud para cancelar tu pedido por nuestros canales de soporte.',
  },
  status3: {
    tittle: '¡Lo sentimos!',
    message: 'No tenemos uno o más de los productos que pediste.',
  },
  status4: {
    tittle: '¡Oops!',
    message: 'Nuestro repartidor llegó a tu dirección pero no encontró a nadie. ',
  },
};

function DeliveryError() {
  const [error] = useState(errors.status1);

  return (
    <motion.div
      className="fixed w-screen h-full overflow-auto"
      transition={{ duration: 0.5 }}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
    >
      <div className="w-screen h-screen flex flex-col items-center">
        <div className="h-full max-w-[430px] flex flex-col justify-between md:justify-start">
          <div
            className="flex flex-col lg:right-2 w-full bg-cover bg-no-repeat bg-center
              items-center bg-blend-saturation xl:right-4 bg-white"
          >
            <div className="w-full">
              <HeaderWithHelp />
            </div>
            <div className="font-bold pt-4 px-4">{error.tittle}</div>
            <div className="pb-6 px-4 text-center">
              <span>{error.message}</span>
            </div>
            <div className="pt-6 px-4 scale-110">
              <DeliveryErrorBgIcon />
            </div>
          </div>
          <div className="flex flex-col gap-y-2 w-full pt-2 px-4 mt-24">
            <Button className="rounded-2xl px-6 py-2" size="lg" fullWidth>
              <span className="inline-block w-full text-lg text-center">Necesito ayuda</span>
            </Button>
            <Button
              size="lg"
              color="secondary"
              fullWidth
              className="mb-4 !bg-transparent"
            >
              <span className="inline-block w-full text-base text-center font-bold underline text-baco-carbon">
                Volver al inicio
              </span>
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default DeliveryError;
