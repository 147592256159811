import PropTypes from 'prop-types';

function DeliveryInProgress(props) {
  const { className } = props;

  return (
    <svg
      width="263"
      height="151"
      viewBox="0 0 263 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M81.3388 133.103C116.453 124.564 118.3 99.919 100.024 87.0057C88.3124
      78.7309 92.0171 61.3996 59.3709 58.0175C26.7247 54.6353 25.6508 87.4912 30.6412
      100.196C32.9094 114.723 46.2245 141.642 81.3388 133.103Z"
        fill="#FFF9F3"
      />
      <path
        d="M129.592 13.7498C146.547 -2.60083 165.622 8.69524 173.351 14.002C185.807
      22.5538 186.057 36.0288 200.203 41.5764C213.144 46.6529 219.192 37.8319 229.637
      43.9773C239.835 49.9771 247.168 66.0953 244.851 79.5165C240.636 103.918 205.58 113.29
      187.824 114.674C176.562 115.552 151.846 117.48 135.463 96.3119C120.06 76.4121 105.624
      36.8666 129.594 13.7501L129.592 13.7498Z"
        fill="#FCE0A9"
        fillOpacity="0.48"
      />
      <path
        d="M41.2178 59.4851C38.1871 50.9653 43.1562 45.9594 46.0195 44.5214C52.4396 42.8815
      66.103 42.8376 69.3966 55.782C72.6903 68.7264 61.4982 71.4181 55.4905 71.1459C51.9957 70.8089
      44.2485 68.005 41.2178 59.4851Z"
        fill="#F6F5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.418 44.251C95.1292 44.251 90.0312 49.3489
      90.0312 55.6376V95.4909C90.0312 101.78 95.1292 106.877 101.418 106.877H158.351C164.64 106.877
      169.738 101.78 169.738 95.4909V55.6376C169.738 49.3489 164.64 44.251 158.351
      44.251H101.418ZM117.075 55.6376C112.358 55.6376 108.535 59.4611 108.535 64.1776C108.535
      68.8941 112.358 72.7176 117.075 72.7176C121.791 72.7176 125.614 68.8941 125.614 64.1776C125.614
      59.4611 121.791 55.6376 117.075 55.6376ZM102.374 88.6694C104.572 82.6865 110.32 78.4109 117.074
      78.4109C123.829 78.4109 129.577 82.6865 131.775 88.6694C132.265 90.0037 131.695 91.495 130.441
      92.1633C126.452 94.2879 121.9 95.4909 117.074 95.4909C112.249 95.4909 107.696 94.2879 103.708
      92.1633C102.453 91.495 101.884 90.0037 102.374 88.6694ZM141.271 61.3309C139.699 61.3309 138.424
      62.6054 138.424 64.1776C138.424 65.7498 139.699 67.0243 141.271 67.0243H155.504C157.077 67.0243
      158.351 65.7498 158.351 64.1776C158.351 62.6054 157.077 61.3309 155.504 61.3309H141.271ZM138.424
      75.5642C138.424 73.9921 139.699 72.7176 141.271 72.7176H155.504C157.077 72.7176 158.351 73.9921
      158.351 75.5642C158.351 77.1364 157.077 78.4109 155.504 78.4109H141.271C139.699 78.4109 138.424
      77.1364 138.424 75.5642ZM141.271 84.1042C139.699 84.1042 138.424 85.3787 138.424 86.9509C138.424
       88.523 139.699 89.7975 141.271 89.7975H155.504C157.077 89.7975 158.351 88.523 158.351
       86.9509C158.351 85.3787 157.077 84.1042 155.504 84.1042H141.271Z"
        fill="#A05F33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.418 44.251C95.1292 44.251 90.0312 49.3489
      90.0312 55.6376V95.4909C90.0312 101.78 95.1292 106.877 101.418 106.877H158.351C164.64 106.877
      169.738 101.78 169.738 95.4909V55.6376C169.738 49.3489 164.64 44.251 158.351
      44.251H101.418ZM117.075 55.6376C112.358 55.6376 108.535 59.4611 108.535 64.1776C108.535 68.8941
      112.358 72.7176 117.075 72.7176C121.791 72.7176 125.614 68.8941 125.614 64.1776C125.614 59.4611
      121.791 55.6376 117.075 55.6376ZM102.374 88.6694C104.572 82.6865 110.32 78.4109 117.074
      78.4109C123.829 78.4109 129.577 82.6865 131.775 88.6694C132.265 90.0037 131.695 91.495
      130.441 92.1633C126.452 94.2879 121.9 95.4909 117.074 95.4909C112.249 95.4909 107.696 94.2879
      103.708 92.1633C102.453 91.495 101.884 90.0037 102.374 88.6694ZM141.271 61.3309C139.699 61.3309
      138.424 62.6054 138.424 64.1776C138.424 65.7498 139.699 67.0243 141.271 67.0243H155.504C157.077
      67.0243 158.351 65.7498 158.351 64.1776C158.351 62.6054 157.077 61.3309 155.504
      61.3309H141.271ZM138.424 75.5642C138.424 73.9921 139.699 72.7176 141.271 72.7176H155.504C157.077
      72.7176 158.351 73.9921 158.351 75.5642C158.351 77.1364 157.077 78.4109 155.504
      78.4109H141.271C139.699 78.4109 138.424 77.1364 138.424 75.5642ZM141.271 84.1042C139.699 84.1042
      138.424 85.3787 138.424 86.9509C138.424 88.523 139.699 89.7975 141.271 89.7975H155.504C157.077
      89.7975 158.351 88.523 158.351 86.9509C158.351 85.3787 157.077 84.1042 155.504 84.1042H141.271Z"
        fill="#A05F33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.5207 41.4053C93.232 41.4053 88.134 46.5032
      88.134 52.7919V92.6451C88.134 98.9338 93.232 104.032 99.5207 104.032H156.454C162.743 104.032
      167.841 98.9338 167.841 92.6451V52.7919C167.841 46.5032 162.743 41.4053 156.454
      41.4053H99.5207ZM115.177 52.7919C110.461 52.7919 106.637 56.6154 106.637 61.3319C106.637 66.0484
      110.461 69.8719 115.177 69.8719C119.894 69.8719 123.717 66.0484 123.717 61.3319C123.717 56.6154
      119.894 52.7919 115.177 52.7919ZM100.477 85.8237C102.674 79.8408 108.423 75.5652 115.177
      75.5652C121.931 75.5652 127.68 79.8408 129.877 85.8237C130.368 87.158 129.798 88.6493 128.544
      89.3176C124.555 91.4422 120.002 92.6451 115.177 92.6451C110.352 92.6451 105.799 91.4422 101.811
      89.3176C100.556 88.6493 99.9867 87.158 100.477 85.8237ZM139.374 58.4852C137.802 58.4852 136.527
      59.7597 136.527 61.3319C136.527 62.9041 137.802 64.1786 139.374 64.1786H153.607C155.179 64.1786
      156.454 62.9041 156.454 61.3319C156.454 59.7597 155.179 58.4852 153.607 58.4852H139.374ZM136.527
      72.7185C136.527 71.1464 137.802 69.8719 139.374 69.8719H146.491H153.607C155.179 69.8719 156.454
      71.1464 156.454 72.7185C156.454 74.2907 155.179 75.5652 153.607 75.5652H139.374C137.802 75.5652
      136.527 74.2907 136.527 72.7185ZM139.374 81.2585C137.802 81.2585 136.527 82.533 136.527
      84.1052C136.527 85.6773 137.802 86.9518 139.374 86.9518H153.607C155.179 86.9518 156.454 85.6773
      156.454 84.1052C156.454 82.533 155.179 81.2585 153.607 81.2585H139.374Z"
        fill="#FFB869"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.5207 41.4053C93.232 41.4053 88.134 46.5032
      88.134 52.7919V92.6451C88.134 98.9338 93.232 104.032 99.5207 104.032H156.454C162.743 104.032
      167.841 98.9338 167.841 92.6451V52.7919C167.841 46.5032 162.743 41.4053 156.454
      41.4053H99.5207ZM115.177 52.7919C110.461 52.7919 106.637 56.6154 106.637 61.3319C106.637
      66.0484 110.461 69.8719 115.177 69.8719C119.894 69.8719 123.717 66.0484 123.717 61.3319C123.717
      56.6154 119.894 52.7919 115.177 52.7919ZM100.477 85.8237C102.674 79.8408 108.423 75.5652 115.177
      75.5652C121.931 75.5652 127.68 79.8408 129.877 85.8237C130.368 87.158 129.798 88.6493 128.544
      89.3176C124.555 91.4422 120.002 92.6451 115.177 92.6451C110.352 92.6451 105.799 91.4422 101.811
      89.3176C100.556 88.6493 99.9867 87.158 100.477 85.8237ZM139.374 58.4852C137.802 58.4852 136.527
      59.7597 136.527 61.3319C136.527 62.9041 137.802 64.1786 139.374 64.1786H153.607C155.179 64.1786
      156.454 62.9041 156.454 61.3319C156.454 59.7597 155.179 58.4852 153.607 58.4852H139.374ZM136.527
      72.7185C136.527 71.1464 137.802 69.8719 139.374 69.8719H146.491H153.607C155.179 69.8719 156.454
      71.1464 156.454 72.7185C156.454 74.2907 155.179 75.5652 153.607 75.5652H139.374C137.802 75.5652
      136.527 74.2907 136.527 72.7185ZM139.374 81.2585C137.802 81.2585 136.527 82.533 136.527
      84.1052C136.527 85.6773 137.802 86.9518 139.374 86.9518H153.607C155.179 86.9518 156.454 85.6773
      156.454 84.1052C156.454 82.533 155.179 81.2585 153.607 81.2585H139.374Z"
        fill="#D19161"
      />
      <path
        d="M106.637 61.3319C106.637 56.6154 110.461 52.7919 115.177 52.7919C119.894 52.7919
      123.717 56.6154 123.717 61.3319C123.717 66.0484 119.894 69.8719 115.177 69.8719C110.461 69.8719
      106.637 66.0484 106.637 61.3319Z"
        fill="#FFB869"
      />
      <path
        d="M106.637 61.3319C106.637 56.6154 110.461 52.7919 115.177 52.7919C119.894 52.7919
      123.717 56.6154 123.717 61.3319C123.717 66.0484 119.894 69.8719 115.177 69.8719C110.461 69.8719
      106.637 66.0484 106.637 61.3319Z"
        fill="#FCF2E8"
      />
      <path
        d="M115.177 75.5652C108.423 75.5652 102.674 79.8408 100.477 85.8237C99.9867 87.158
      100.556 88.6493 101.811 89.3176C105.799 91.4422 110.352 92.6451 115.177 92.6451C120.002 92.6451
      124.555 91.4422 128.544 89.3176C129.798 88.6493 130.368 87.158 129.877 85.8237C127.68 79.8408
      121.931 75.5652 115.177 75.5652Z"
        fill="#FFB869"
      />
      <path
        d="M115.177 75.5652C108.423 75.5652 102.674 79.8408 100.477 85.8237C99.9867 87.158 100.556
      88.6493 101.811 89.3176C105.799 91.4422 110.352 92.6451 115.177 92.6451C120.002 92.6451 124.555
      91.4422 128.544 89.3176C129.798 88.6493 130.368 87.158 129.877 85.8237C127.68 79.8408 121.931
      75.5652 115.177 75.5652Z"
        fill="#FCF2E8"
      />
      <path
        d="M136.527 61.3319C136.527 59.7597 137.802 58.4852 139.374 58.4852H153.607C155.179 58.4852
      156.454 59.7597 156.454 61.3319C156.454 62.9041 155.179 64.1786 153.607 64.1786H139.374C137.802
      64.1786 136.527 62.9041 136.527 61.3319Z"
        fill="#FFB869"
      />
      <path
        d="M136.527 61.3319C136.527 59.7597 137.802 58.4852 139.374 58.4852H153.607C155.179 58.4852
      156.454 59.7597 156.454 61.3319C156.454 62.9041 155.179 64.1786 153.607 64.1786H139.374C137.802
      64.1786 136.527 62.9041 136.527 61.3319Z"
        fill="#FCD1A9"
      />
      <path
        d="M139.374 69.8719C137.802 69.8719 136.527 71.1464 136.527 72.7185C136.527 74.2907 137.802
      75.5652 139.374 75.5652H153.607C155.179 75.5652 156.454 74.2907 156.454 72.7185C156.454 71.1464
      155.179 69.8719 153.607 69.8719H146.491H139.374Z"
        fill="#FFB869"
      />
      <path
        d="M139.374 69.8719C137.802 69.8719 136.527 71.1464 136.527 72.7185C136.527 74.2907 137.802
      75.5652 139.374 75.5652H153.607C155.179 75.5652 156.454 74.2907 156.454 72.7185C156.454 71.1464
      155.179 69.8719 153.607 69.8719H146.491H139.374Z"
        fill="#FCD1A9"
      />
      <path
        d="M136.527 84.1052C136.527 82.533 137.802 81.2585 139.374 81.2585H153.607C155.179 81.2585
      156.454 82.533 156.454 84.1052C156.454 85.6773 155.179 86.9518 153.607 86.9518H139.374C137.802
      86.9518 136.527 85.6773 136.527 84.1052Z"
        fill="#FFB869"
      />
      <path
        d="M136.527 84.1052C136.527 82.533 137.802 81.2585 139.374 81.2585H153.607C155.179 81.2585
      156.454 82.533 156.454 84.1052C156.454 85.6773 155.179 86.9518 153.607 86.9518H139.374C137.802
      86.9518 136.527 85.6773 136.527 84.1052Z"
        fill="#D19161"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.03 44.9737C151.03 35.5994 158.629 28 168.003
      28C177.378 28 184.977 35.5994 184.977 44.9737C184.977 54.348 177.378 61.9474 168.003
      61.9474C158.629 61.9474 151.03 54.348 151.03 44.9737ZM174.289 41.8156C174.708 41.2288 174.572
      40.4133 173.985 39.9942C173.398 39.5751 172.583 39.711 172.164 40.2978L166.531 48.1833L163.704
      45.3561C163.194 44.8462 162.367 44.8462 161.858 45.3561C161.348 45.866 161.348 46.6927 161.858
      47.2026L165.775 51.1196C166.046 51.391 166.423 51.5292 166.805 51.4976C167.188 51.4659 167.537
      51.2676 167.76 50.9553L174.289 41.8156Z"
        fill="#147053"
      />
      <path
        d="M173.985 39.9942C174.572 40.4133 174.708 41.2288 174.289 41.8156L167.76 50.9553C167.537
      51.2676 167.188 51.4659 166.805 51.4976C166.423 51.5292 166.046 51.391 165.775 51.1196L161.858
      47.2026C161.348 46.6927 161.348 45.866 161.858 45.3561C162.367 44.8462 163.194 44.8462 163.704
      45.3561L166.531 48.1833L172.164 40.2978C172.583 39.711 173.398 39.5751 173.985 39.9942Z"
        fill="white"
      />
      <path
        d="M42.87 127.97L41.88 131H42.83L45.11 124H44.16L43.15 127.13H39.2L40.22 124H39.27L36.99
      131H37.94L38.92 127.97H42.87ZM49.803 127.77C49.803 126.84 49.123 126.16 47.993 126.16C46.283 126.16
      45.033 127.43 45.033 129.01C45.033 130.26 45.903 131.1 47.023 131.1C48.253 131.1 49.043 130.61
      49.493 129.62L48.733 129.33C48.433 130 47.913 130.34 47.183 130.34C46.453 130.34 45.933 129.82
      45.933 128.98C45.933 128.88 45.933 128.81 45.943 128.76H49.623L49.673 128.59C49.763 128.3 49.803
      128.02 49.803 127.77ZM48.953 127.83C48.953 127.94 48.943 128.04 48.923 128.14H46.083C46.373 127.41
      47.013 126.92 47.883 126.92C48.523 126.92 48.953 127.24 48.953 127.83ZM50.2909 131H51.1809L52.1309
      128.1C52.4009 127.31 52.8809 126.94 53.3909 126.94C53.7909 126.94 54.0109 127.17 54.0109
      127.57C54.0109 127.66 53.9909 127.78 53.9409 127.93L52.9409 131H53.8409L54.8009 128.05C55.0509
      127.3 55.5309 126.94 56.0509 126.94C56.4409 126.94 56.6809 127.18 56.6809 127.55C56.6809 127.67
      56.6609 127.79 56.6209 127.91L55.6109 131H56.5009L57.5309 127.84C57.6009 127.61 57.6309 127.42
      57.6309 127.27C57.6309 126.59 57.2109 126.16 56.5009 126.16C55.8509 126.16 55.3309 126.44 54.9209
      127C54.8809 126.49 54.4809 126.16 53.9109 126.16C53.3609 126.16 52.9009 126.4 52.5309
      126.87L52.7209 126.26H51.8309L50.2909 131ZM58.5206 129.1C58.5206 130.24 59.3406 131.1 60.5606
      131.1C62.3806 131.13 63.6606 129.75 63.6306 128.16C63.6306 127.02 62.8506 126.16 61.6006
      126.16C59.7806 126.13 58.4906 127.51 58.5206 129.1ZM62.7106 128.23C62.7106 129.36 61.8706
      130.32 60.7006 130.32C59.9706 130.32 59.4406 129.76 59.4406 129.03C59.4406 127.9 60.2806
      126.94 61.4606 126.94C62.1906 126.94 62.7106 127.5 62.7106 128.23ZM64.2505 129.6C64.3605
      130.52 65.0505 131.1 66.1805 131.1C67.5305 131.1 68.3605 130.46 68.3605 129.47C68.3605 128.8
      67.9605 128.41 67.0905 128.22C66.3305 128.05 66.0005 127.85 66.0005 127.5C66.0005 127.11 66.3805
      126.86 66.9705 126.86C67.4805 126.86 67.8205 127.13 67.9005 127.56L68.6805 127.41C68.5905 126.64
      67.9905 126.16 67.0705 126.16C65.9005 126.16 65.1805 126.7 65.1805 127.54C65.1805 128.19 65.5905
      128.61 66.4205 128.81C67.2205 129.01 67.4705 129.16 67.4705 129.55C67.4705 130.03 66.9805 130.34
      66.2705 130.34C65.5405 130.34 65.1405 129.98 65.0705 129.37L64.2505 129.6ZM72.4054 129.15C72.4054
      130.36 73.1954 131.1 74.1554 131.1C74.9454 131.1 75.5354 130.76 75.8854 130.24L75.6454
      131H76.4854L78.0254 126.26H77.1354L76.8254 127.19C76.6754 126.59 76.1354 126.16 75.3454
      126.16C73.7154 126.14 72.3754 127.44 72.4054 129.15ZM76.5054 128.13C76.5354 129.26 75.5054
      130.36 74.5154 130.33C73.7854 130.33 73.3154 129.88 73.3154 129.05C73.3154 127.83 74.1854 126.94
      75.2654 126.94C76.0154 126.94 76.5054 127.44 76.5054 128.13ZM78.0962 129.6C78.2062 130.52 78.8962
      131.1 80.0262 131.1C81.3762 131.1 82.2062 130.46 82.2062 129.47C82.2062 128.8 81.8062 128.41
      80.9362 128.22C80.1762 128.05 79.8462 127.85 79.8462 127.5C79.8462 127.11 80.2262 126.86 80.8162
      126.86C81.3262 126.86 81.6662 127.13 81.7462 127.56L82.5262 127.41C82.4362 126.64 81.8362 126.16
      80.9162 126.16C79.7462 126.16 79.0262 126.7 79.0262 127.54C79.0262 128.19 79.4362 128.61 80.2662
      128.81C81.0662 129.01 81.3162 129.16 81.3162 129.55C81.3162 130.03 80.8262 130.34 80.1162
      130.34C79.3862 130.34 78.9862 129.98 78.9162 129.37L78.0962 129.6ZM84.6199 126.26L83.0799
      131H83.9699L85.5099 126.26H84.6199ZM84.8599 125.38H85.8499L86.1799 124.37H85.1899L84.8599
      125.38ZM85.456 131.51C85.566 132.48 86.266 133.08 87.346 133.08C88.816 133.08 89.696 132.34
      90.186 130.86L91.676 126.26H90.786L90.526 127.07C90.336 126.56 89.796 126.16 88.996 126.16C87.306
      126.13 86.026 127.51 86.056 129.09C86.056 130.27 86.856 131 87.836 131C88.556 131 89.106 130.75
      89.496 130.25L89.336 130.71C88.976 131.78 88.356 132.32 87.486 132.32C86.796 132.32 86.366 131.93
      86.326 131.29L85.456 131.51ZM90.176 128.12C90.176 129.26 89.226 130.24 88.156 130.24C87.446 130.24
      86.966 129.77 86.966 128.98C86.966 127.75 87.786 126.92 88.936 126.92C89.696 126.92 90.176 127.41
      90.176 128.12ZM91.744 131H92.634L93.494 128.37C93.804 127.46 94.464 126.92 95.184 126.92C95.674
      126.92 95.964 127.24 95.964 127.71C95.964 127.85 95.944 127.99 95.894 128.12L94.954
      131H95.854L96.794 128.09C96.874 127.85 96.914 127.64 96.914 127.46C96.914 126.69 96.404 126.16
      95.594 126.16C94.924 126.16 94.374 126.44 93.934 127.01L94.174 126.26H93.284L91.744 131ZM97.8741
      129.15C97.8741 130.36 98.6641 131.1 99.6241 131.1C100.414 131.1 101.004 130.76 101.354
      130.24L101.114 131H101.954L103.494 126.26H102.604L102.294 127.19C102.144 126.59 101.604 126.16
      100.814 126.16C99.1841 126.14 97.8441 127.44 97.8741 129.15ZM101.974 128.13C102.004 129.26 100.974
      130.36 99.9841 130.33C99.2541 130.33 98.7841 129.88 98.7841 129.05C98.7841 127.83 99.6541 126.94
      100.734 126.94C101.484 126.94 101.974 127.44 101.974 128.13ZM103.993 129.17C103.993 130.35 104.763
      131.1 105.723 131.1C106.533 131.1 107.133 130.76 107.483 130.2L107.233 131H108.073L110.343
      124H109.443L108.403 127.19C108.273 126.59 107.713 126.16 106.923 126.16C105.273 126.16 103.993
      127.42 103.993 129.17ZM108.083 128.13C108.113 129.26 107.093 130.36 106.103 130.33C105.383 130.33
      104.903 129.87 104.903 129.08C104.903 127.84 105.763 126.94 106.843 126.94C107.603 126.94
      108.083 127.44 108.083 128.13ZM110.142 129.1C110.142 130.24 110.962 131.1 112.182 131.1C114.002
      131.13 115.282 129.75 115.252 128.16C115.252 127.02 114.472 126.16 113.222 126.16C111.402 126.13
      110.112 127.51 110.142 129.1ZM114.332 128.23C114.332 129.36 113.492 130.32 112.322 130.32C111.592
      130.32 111.062 129.76 111.062 129.03C111.062 127.9 111.902 126.94 113.082 126.94C113.812 126.94
      114.332 127.5 114.332 128.23ZM118.982 129.8C118.982 130.57 119.492 131.1 120.302 131.1C121.012
      131.1 121.522 130.82 121.962 130.25L121.712 131H122.612L124.152 126.26H123.252L122.392
      128.89C122.102 129.8 121.432 130.34 120.712 130.34C120.222 130.34 119.932 130.02 119.932
      129.55C119.932 129.42 119.952 129.28 119.992 129.14L120.932 126.26H120.042L119.112 129.12C119.022
      129.39 118.982 129.61 118.982 129.8ZM124.221 131H125.111L125.971 128.37C126.281 127.46
      126.941
      126.92
      127.661
      126.92C128.151
      126.92
      128.441
      127.24
      128.441
      127.71C128.441
      127.85
      128.421
      127.99
      128.371
      128.12L127.431
      131H128.331L129.271
      128.09C129.351
      127.85
      129.391
      127.64
      129.391
      127.46C129.391
      126.69
      128.881
      126.16
      128.071
      126.16C127.401
      126.16
      126.851
      126.44
      126.411
      127.01L126.651
      126.26H125.761L124.221
      131ZM132.64
      131H133.53L134.52
      127.99C134.73
      127.32
      135.19
      126.92
      135.68
      126.92C136.05
      126.92
      136.26
      127.1
      136.26
      127.39C136.26
      127.53
      136.21
      127.71
      136.1
      127.92H136.96C137.11
      127.6
      137.19
      127.31
      137.19
      127.05C137.19
      126.53
      136.84
      126.16
      136.24
      126.16C135.67
      126.16
      135.23
      126.4
      134.86
      126.91L135.07
      126.26H134.18L132.64
      131ZM142.565
      127.77C142.565
      126.84
      141.885
      126.16
      140.755
      126.16C139.045
      126.16
      137.795
      127.43
      137.795
      129.01C137.795
      130.26
      138.665
      131.1
      139.785
      131.1C141.015
      131.1
      141.805
      130.61
      142.255
      129.62L141.495
      129.33C141.195
      130
      140.675
      130.34
      139.945
      130.34C139.215
      130.34
      138.695
      129.82
      138.695
      128.98C138.695
      128.88
      138.695
      128.81
      138.705
      128.76H142.385L142.435
      128.59C142.525
      128.3
      142.565
      128.02
      142.565
      127.77ZM141.715
      127.83C141.715
      127.94
      141.705
      128.04
      141.685
      128.14H138.845C139.135
      127.41
      139.775
      126.92
      140.645
      126.92C141.285
      126.92
      141.715
      127.24
      141.715
      127.83ZM142.413
      132.98H143.303L144.253
      130.06C144.403
      130.63
      144.973
      131.1
      145.733
      131.1C147.383
      131.1
      148.663
      129.84
      148.663
      128.09C148.663
      126.9
      147.953
      126.16
      146.913
      126.16C146.123
      126.16
      145.543
      126.49
      145.193
      127L145.433
      126.26H144.593L142.413
      132.98ZM147.763
      128.18C147.763
      129.42
      146.893
      130.32
      145.813
      130.32C145.063
      130.32
      144.573
      129.82
      144.573
      129.13C144.543
      128
      145.573
      126.9
      146.563
      126.93C147.293
      126.93
      147.763
      127.38
      147.763
      128.18ZM149.671
      129.15C149.671
      130.36
      150.461
      131.1
      151.421
      131.1C152.211
      131.1
      152.801
      130.76
      153.151
      130.24L152.911
      131H153.751L155.291
      126.26H154.401L154.091
      127.19C153.941
      126.59
      153.401
      126.16
      152.611
      126.16C150.981
      126.14
      149.641
      127.44
      149.671
      129.15ZM153.771
      128.13C153.801
      129.26
      152.771
      130.36
      151.781
      130.33C151.051
      130.33
      150.581
      129.88
      150.581
      129.05C150.581
      127.83
      151.451
      126.94
      152.531
      126.94C153.281
      126.94
      153.771
      127.44
      153.771
      128.13ZM155.359
      131H156.249L157.239
      127.99C157.449
      127.32
      157.909
      126.92
      158.399
      126.92C158.769
      126.92
      158.979
      127.1
      158.979
      127.39C158.979
      127.53
      158.929
      127.71
      158.819
      127.92H159.679C159.829
      127.6
      159.909
      127.31
      159.909
      127.05C159.909
      126.53
      159.559
      126.16
      158.959
      126.16C158.389
      126.16
      157.949
      126.4
      157.579
      126.91L157.789
      126.26H156.899L155.359
      131ZM160.504
      131H161.394L162.704
      126.99H164.134L164.374
      126.26H162.934L163.414
      124.79H162.524L162.044
      126.26H161.114L160.874
      126.99H161.804L160.504
      131ZM165.514
      126.26L163.974
      131H164.864L166.404
      126.26H165.514ZM165.754
      125.38H166.744L167.074
      124.37H166.084L165.754
      125.38ZM166.941
      129.17C166.941
      130.35
      167.711
      131.1
      168.671
      131.1C169.481
      131.1
      170.081
      130.76
      170.431
      130.2L170.181
      131H171.021L173.291
      124H172.391L171.351
      127.19C171.221
      126.59
      170.661
      126.16
      169.871
      126.16C168.221
      126.16
      166.941
      127.42
      166.941
      129.17ZM171.031
      128.13C171.061
      129.26
      170.041
      130.36
      169.051
      130.33C168.331
      130.33
      167.851
      129.87
      167.851
      129.08C167.851
      127.84
      168.711
      126.94
      169.791
      126.94C170.551
      126.94
      171.031
      127.44
      171.031
      128.13ZM173.089
      129.1C173.089
      130.24
      173.909
      131.1
      175.129
      131.1C176.949
      131.13
      178.229
      129.75
      178.199
      128.16C178.199
      127.02
      177.419
      126.16
      176.169
      126.16C174.349
      126.13
      173.059
      127.51
      173.089
      129.1ZM177.279
      128.23C177.279
      129.36
      176.439
      130.32
      175.269
      130.32C174.539
      130.32
      174.009
      129.76
      174.009
      129.03C174.009
      127.9
      174.849
      126.94
      176.029
      126.94C176.759
      126.94
      177.279
      127.5
      177.279
      128.23ZM178.748
      131H179.638L180.628
      127.99C180.838
      127.32
      181.298
      126.92
      181.788
      126.92C182.158
      126.92
      182.368
      127.1
      182.368
      127.39C182.368
      127.53
      182.318
      127.71
      182.208
      127.92H183.068C183.218
      127.6
      183.298
      127.31
      183.298
      127.05C183.298
      126.53
      182.948
      126.16
      182.348
      126.16C181.778
      126.16
      181.338
      126.4
      180.968
      126.91L181.178
      126.26H180.288L178.748
      131ZM186.573
      129.17C186.573
      130.35
      187.343
      131.1
      188.303
      131.1C189.113
      131.1
      189.713
      130.76
      190.063
      130.2L189.813
      131H190.653L192.923
      124H192.023L190.983
      127.19C190.853
      126.59
      190.293
      126.16
      189.503
      126.16C187.853
      126.16
      186.573
      127.42
      186.573
      129.17ZM190.663
      128.13C190.693
      129.26
      189.673
      130.36
      188.683
      130.33C187.963
      130.33
      187.483
      129.87
      187.483
      129.08C187.483
      127.84
      188.343
      126.94
      189.423
      126.94C190.183
      126.94
      190.663
      127.44
      190.663
      128.13ZM197.522
      127.77C197.522
      126.84
      196.842
      126.16
      195.712
      126.16C194.002
      126.16
      192.752
      127.43
      192.752
      129.01C192.752
      130.26
      193.622
      131.1
      194.742
      131.1C195.972
      131.1
      196.762
      130.61
      197.212
      129.62L196.452
      129.33C196.152
      130
      195.632
      130.34
      194.902
      130.34C194.172
      130.34
      193.652
      129.82
      193.652
      128.98C193.652
      128.88
      193.652
      128.81
      193.662
      128.76H197.342L197.392
      128.59C197.482
      128.3
      197.522
      128.02
      197.522
      127.77ZM196.672
      127.83C196.672
      127.94
      196.662
      128.04
      196.642
      128.14H193.802C194.092
      127.41
      194.732
      126.92
      195.602
      126.92C196.242
      126.92
      196.672
      127.24
      196.672
      127.83ZM200.791
      131H201.741L202.701
      128.04H204.581C205.271
      128.04
      205.611
      128.28
      205.611
      128.84C205.611
      129.01
      205.571
      129.23
      205.481
      129.48C205.351
      129.87
      205.281
      130.23
      205.281
      130.55C205.281
      130.68
      205.301
      130.83
      205.341
      131H206.331L206.381
      130.86C206.301
      130.72
      206.261
      130.57
      206.261
      130.42C206.261
      130.23
      206.321
      129.9
      206.451
      129.45C206.531
      129.15
      206.571
      128.91
      206.571
      128.73C206.571
      128.2
      206.371
      127.91
      205.921
      127.75C207.041
      127.58
      207.841
      126.84
      207.841
      125.72C207.841
      124.69
      207.101
      124
      205.891
      124H203.071L200.791
      131ZM202.981
      127.17L203.731
      124.87H205.721C206.431
      124.87
      206.841
      125.22
      206.841
      125.85C206.841
      126.68
      206.211
      127.17
      205.131
      127.17H202.981ZM208.11
      129.15C208.11
      130.36
      208.9
      131.1
      209.86
      131.1C210.65
      131.1
      211.24
      130.76
      211.59
      130.24L211.35
      131H212.19L213.73
      126.26H212.84L212.53
      127.19C212.38
      126.59
      211.84
      126.16
      211.05
      126.16C209.42
      126.14
      208.08
      127.44
      208.11
      129.15ZM212.21
      128.13C212.24
      129.26
      211.21
      130.36
      210.22
      130.33C209.49
      130.33
      209.02
      129.88
      209.02
      129.05C209.02
      127.83
      209.89
      126.94
      210.97
      126.94C211.72
      126.94
      212.21
      127.44
      212.21
      128.13ZM213.159
      132.98H214.049L214.999
      130.06C215.149
      130.63
      215.719
      131.1
      216.479
      131.1C218.129
      131.1
      219.409
      129.84
      219.409
      128.09C219.409
      126.9
      218.699
      126.16
      217.659
      126.16C216.869
      126.16
      216.289
      126.49
      215.939
      127L216.179
      126.26H215.339L213.159
      132.98ZM218.509
      128.18C218.509
      129.42
      217.639
      130.32
      216.559
      130.32C215.809
      130.32
      215.319
      129.82
      215.319
      129.13C215.289
      128
      216.319
      126.9
      217.309
      126.93C218.039
      126.93
      218.509
      127.38
      218.509
      128.18ZM219.307
      132.98H220.197L221.147
      130.06C221.297
      130.63
      221.867
      131.1
      222.627
      131.1C224.277
      131.1
      225.557
      129.84
      225.557
      128.09C225.557
      126.9
      224.847
      126.16
      223.807
      126.16C223.017
      126.16
      222.437
      126.49
      222.087
      127L222.327
      126.26H221.487L219.307
      132.98ZM224.657
      128.18C224.657
      129.42
      223.787
      130.32
      222.707
      130.32C221.957
      130.32
      221.467
      129.82
      221.467
      129.13C221.437
      128
      222.467
      126.9
      223.457
      126.93C224.187
      126.93
      224.657
      127.38
      224.657
      128.18ZM227.636
      126.26L226.096
      131H226.986L228.526
      126.26H227.636ZM227.876
      125.38H228.866L229.196
      124.37H228.206L227.876
      125.38Z"
        fill="#404040"
      />
    </svg>
  );
}

DeliveryInProgress.propTypes = {
  className: PropTypes.string,
};

DeliveryInProgress.defaultProps = {
  className: '',
};

export default DeliveryInProgress;
