import PropTypes from 'prop-types';
import clsx from 'clsx';

function Skeleton(props) {
  const {
    type,
    className,
  } = props;
  const classes = clsx(
    'animate-pulse bg-gray-300',
    className,
    {
      'rounded-full': type === 'circle',
      'rounded-lg': type === 'rect',
    },
  );
  return <div className={classes} />;
}

Skeleton.propTypes = {
  type: PropTypes.oneOf(['rect', 'circle']),
  className: PropTypes.string,
};
Skeleton.defaultProps = {
  type: 'rect',
  className: '',
};

export default Skeleton;
