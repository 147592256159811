import React, { useState } from 'react';
import {
  GoogleMap, MarkerF, useJsApiLoader,
} from '@react-google-maps/api';
import MapPin from 'src/components/icons/MapPin.svg';
import { useNavigate } from 'react-router-dom';
import useDelivery from 'src/hooks/actions/useDelivery';
import { motion, MotionConfig } from 'framer-motion';
import clsx from 'clsx';
import { LogoIcon, ChevronLeftIcon } from '../../components/icons';
import Button from '../../components/Button';

function AddressMap() {
  const { deliveryData, updateDelivery } = useDelivery();
  const currentPosition = {
    lat: deliveryData.lat, lng: deliveryData.lng,
  };
  const [newPosition, setNewPosition] = useState(null);
  const navigate = useNavigate();
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isMarkerLoading, setIsMarkerLoading] = useState(true);

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setNewPosition({ lat, lng });
  };

  const mapStyles = {
    height: '40vh',
    width: '100%',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const handleConfirm = () => {
    if (newPosition) updateDelivery({ lat: newPosition.lat, lng: newPosition.lng });
    navigate(`/store/${deliveryData.storeId}`);
    updateDelivery({ isNewAddress: true });
  };

  return (
    <MotionConfig transition={{ duration: 0.5 }}>
      <motion.div
        className={
        clsx(
          'fixed w-screen h-full overflow-auto z-10',
        )
      }
        initial={{ top: '100%', opacity: 1 }}
        animate={{ top: 0, opacity: 1 }}
        exit={{ top: '100%', opacity: 1 }}
      >
        <div className="flex flex-col justify-between items-center h-full fill-baco-primary">
          <div className="flex flex-col items-center justify-start">
            <nav className="flex justify-between py-6 px-4 w-screen">
              <button type="button" onClick={() => navigate('/AddressInput')}>
                <ChevronLeftIcon
                  className="fill-baco-primary scale-150"
                />
              </button>
              <button type="button" onClick={() => navigate('./')}>
                <LogoIcon
                  className="fill-baco-primary w-5 h-8 scale-110"
                />
              </button>
            </nav>
            <h1 className="text-2xl text-center text-baco-primary mb-6">
              <b>Datos de envío</b>
            </h1>
            <div className="mb-1 font-bold text-baco-carbon">
              Indica dónde debemos dejar tu pedido:
            </div>
            <div className="px-4 w-screen max-w-md -mt-8">
              <div className="text-white bg-[#5C5C5C] bg-opacity-70 text-center py-2
            text-base font-bold relative top-10 z-10 rounded-b-md"
              >
                {deliveryData.address}
              </div>
              {isLoaded
                && (
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={17}
                  center={currentPosition}
                  clickableIcons={false}
                  options={
                    { disableDefaultUI: true, draggable: false, zoomControl: false }
                  }
                  onClick={(position) => {
                    setNewPosition({
                      lat: position.latLng.lat(),
                      lng: position.latLng.lng(),
                    });
                  }}
                  onIdle={() => setIsMapLoading(false)}
                >
                  {
                      currentPosition.lat
                        ? (
                          <MarkerF
                            position={newPosition || currentPosition}
                            icon={MapPin}
                            onDragEnd={(e) => onMarkerDragEnd(e)}
                            draggable
                            visible
                            onLoad={() => {
                              setIsMarkerLoading(false);
                            }}
                          />
                        )
                        : null
                    }
                </GoogleMap>
                )}
            </div>
          </div>
          <div className="w-full max-w-md px-4">
            <Button
              type="submit"
              size="lg"
              fullWidth
              className="!text-base mx-auto lg:mb-12 mb-6 font-bold"
              onClick={handleConfirm}
              isLoading={isMapLoading || isMarkerLoading}
            >
              <span className="mr-2">Confirmar ubicación</span>
            </Button>
          </div>
        </div>
      </motion.div>
    </MotionConfig>
  );
}

export default AddressMap;
