import PropTypes from 'prop-types';

function DeliveryProcessing(props) {
  const { className } = props;

  return (
    <svg
      width="263"
      height="150"
      viewBox="0 0 263 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.868042" y="0.214844" width="261.264" height="149.069" fill="white" />
      <path
        d="M130.317 16.6755C112.616 -0.415315 92.7033 11.3921 84.6337 16.939C71.63 25.8779 71.3689 39.9629
      56.6008 45.7616C43.0913 51.0679 36.7769 41.8476 25.8724 48.2712C15.2262 54.5425 7.57055 71.3904 9.99005
      85.4191C14.3906 110.925 50.9879 120.722 69.5244 122.168C81.2819 123.086 107.084 125.101 124.188 102.975C140.268
      82.1741 155.34 40.8386 130.315 16.6757L130.317 16.6755Z"
        fill="#FFF9F3"
      />
      <path
        d="M189.053 15.7498C195.268 20.5152 194.008 26.4973 192.6 28.8926C188.7 33.1115 178.777 39.5848 170.288
      31.7266C161.799 23.8684 168.678 16.6351 173.178 14.0007C175.88 12.5982 182.838 10.9844 189.053 15.7498Z"
        fill="#FFEDDD"
      />
      <path
        d="M215.66 105.673C234.414 88.1422 227.394 72.5092 211.89 71.0802C201.955 70.1645 198.497 58.3255 177.315
      67.7409C156.133
      77.1563 166.345 97.5097 173.616 103.481C179.816 111.516 196.906 123.204 215.66 105.673Z"
        fill="#FFF9F3"
      />
      <rect x="96.4042" y="27.4043" width="66.5319" height="89.0851" rx="4" fill="#8C684C" />
      <rect x="94.1488" y="25.1494" width="66.5319" height="89.0851" rx="4" fill="#D19161" />
      <rect x="113.319" y="36.4258" width="30.4468" height="30.4468" rx="15.2234" fill="#DDDDDD" />
      <path
        d="M128.041 52.5153C130.758 52.5153 133.475 52.5308 136.191 52.505C136.802 52.4998 136.941 52.7375 136.978
      53.2906C137.091 54.8721 136.776 56.3347 135.86 57.6268C135.311 58.4021 134.649 59.0843 133.868 59.6477C132.326
      60.7537 130.991 61.2705 128.636 61.2498C126.732 61.2498 126.256 61.255 125.03 60.9914C123.328 60.6245 121.93
      59.7355
      120.766 58.4589C119.912 57.5235 119.292 56.4639 119.043 55.208C118.898 54.4689 118.976 53.7195 118.997
      52.9753C119.007
      52.5463 119.338 52.5153 119.69 52.5153C122.474 52.5256 125.252 52.5153 128.036 52.5153H128.041ZM127.276
      54.9548C129.583
      54.9548 131.896 54.9548 134.204 54.9548C134.478 54.9548 134.882 55.0685 134.877 54.5982C134.877 54.2002
      134.546 54.1847
      134.22 54.1847C129.599 54.1847 124.983 54.1847 120.363 54.1847C120.078 54.1847 119.737 54.0968 119.747
      54.593C119.762
      55.0478 120.083 54.9548 120.342 54.9548C122.65 54.9548 124.963 54.9548 127.27 54.9548H127.276Z"
        fill="#0F5945"
      />
      <path
        d="M130.346 51.9256C131.95 50.0805 133.285 48.2509 134.822 46.5867C135.345 46.0233 136.033 45.9923 136.674
       46.535C137.063 46.8606 137.083 47.7754 136.695 48.2199C135.557 49.5172 134.165 50.5405 132.954 51.7654C132.845
        51.8791 132.711 51.9308 132.55 51.9308C131.873 51.9308 131.195 51.9308 130.351 51.9308L130.346 51.9256Z"
        fill="#5C5C5C"
      />
      <path
        d="M127.292 55.1244C125.037 55.1244 122.777 55.1244 120.522 55.1244C120.269 55.1244 119.761 55.2408
      119.746 54.5496C119.731 53.8041 120.259 53.9673 120.543 53.9673C125.058 53.9673 129.568 53.9673 134.083
      53.9673C134.396 53.9673 135.109 53.9516 135.114 54.5496C135.114 55.2485 134.335 55.1244 134.067 55.1244C131.812
       55.1322 129.552 55.1244 127.297 55.1244H127.292Z"
        fill="#0F5945"
      />
      <path
        d="M135.899 53.6504C135.845 55.2774 134.725 58.4412 131.207 59.7516"
        stroke="#147053"
        strokeWidth="0.587173"
        strokeLinecap="round"
      />
      <path
        d="M131.041 51.5816L134.931 47.0295C135.152 46.7812 135.742 46.3673 136.338 46.6984"
        stroke="#AEAEAE"
        strokeOpacity="0.6"
        strokeWidth="0.293586"
        strokeLinecap="round"
      />
      <path
        d="M123.205 42.0635C123.993 42.3275 124.662 42.7023 125.01 43.476C125.229 43.9644 125.241 44.4764 125.149
       44.9942C125.015 45.7552 124.676 46.4288 124.264 47.0754C124.025 47.449 123.796 47.8325 123.606 48.2316C123.121
       49.2466 123.267 50.1918 123.986 51.053C124.107 51.1983 124.239 51.3353 124.386 51.4988C124.231 51.4729 124.098
        51.4581 123.969 51.4278C123.036 51.2093 122.243 50.7534 121.632 50.0178C120.877 49.1078 120.754 48.0963 121.208
         47.0123C121.425 46.4954 121.762 46.0538 122.097 45.61C122.458 45.13 122.822 44.652 123.172 44.1638C123.295
         43.9922 123.384 43.7961 123.477 43.6054C123.639 43.2735 123.63 42.9379 123.472 42.6062C123.389 42.4319 123.301
         42.2596 123.205 42.0635Z"
        fill="white"
      />
      <path
        d="M125.903 44.4023C126.231 44.5017 126.521 44.6377 126.776 44.8423C127.232 45.2093 127.425 45.6941 127.397
       46.2678C127.363 46.9645 127.075 47.5698 126.707 48.1482C126.534 48.4205 126.366 48.698 126.223 48.9864C125.821
        49.7975 125.943 50.5445 126.536 51.2221C126.613 51.3093 126.693 51.3931 126.788 51.4959C126.119 51.4123 125.557
         51.1507 125.065 50.7363C124.708 50.4361 124.434 50.0765 124.293 49.628C124.102 49.0183 124.188 48.4285 124.513
          47.896C124.81 47.4078 125.168 46.9553 125.505 46.4915C125.726 46.1871 125.961 45.8928 126.111 45.5428C126.214
           45.3009 126.217 45.0644 126.108 44.8268C126.046 44.6899 125.978 44.5554 125.904 44.4023H125.903Z"
        fill="white"
      />
      <path
        d="M126.202 51.1024C125.851 50.6275 125.421 50.0609 125.529 49.425C125.556 49.265 125.62 49.1141 125.697
       48.9734C125.769 48.8378 125.848 48.7055 125.932 48.5775C126.137 48.2588 126.377 47.9772 126.59 47.6704C126.666
        47.552 126.732 47.4215 126.787 47.2922C126.895 47.027 126.964 46.7407 126.982 46.4541C126.989 46.369 126.988
         46.268 126.986 46.1829C126.985 46.1406 126.977 46.0622 126.975 46.0203C126.958 45.8787 126.932 45.7305 126.889
          45.5945C126.786 45.2389 126.586 44.9258 126.325 44.6599C126.663 44.8935 126.928 45.2543 127.064
          45.6534C127.076 45.6968 127.104 45.7799 127.114 45.8214C127.126 45.8781 127.14 45.9369 127.151
           45.9932C127.157 46.0371 127.17 46.1259 127.175 46.1678C127.178 46.2255 127.184 46.2865 127.187
           46.3439C127.187 46.3879 127.187 46.4786 127.186 46.5206C127.169 46.8936 127.079 47.2675 126.91 47.5998C126.86
            47.7017 126.793 47.8133 126.726 47.9047C126.546 48.1532 126.295 48.4612 126.122 48.712C125.999 48.8911
            125.878 49.0735 125.784 49.2697C125.499 49.9049 125.928 50.5433 126.202 51.1024Z"
        fill="#E3E3E3"
      />
      <path
        d="M123.641 51.151C123.201 50.5 122.663 49.7236 122.807 48.8461C122.842 48.6252 122.925 48.4166
       123.024 48.2218C123.116 48.0343 123.218 47.8511 123.325 47.6738C123.589 47.2324 123.896 46.8418 124.167
        46.4167C124.265 46.2527 124.35 46.0721 124.421 45.8933C124.56 45.5267 124.651 45.1313 124.676 44.7362C124.686
         44.6188 124.686 44.4796 124.685 44.3624C124.683 44.3041 124.675 44.196 124.672 44.1384C124.652 43.9433 124.62
          43.7393 124.567 43.5523C124.441 43.0634 124.192 42.634 123.864 42.2702C124.289 42.5886 124.621 43.0832
          124.789 43.6317C124.804 43.6915 124.838 43.8057 124.85 43.8628C124.864 43.9408 124.883 44.0217 124.895
          44.0992C124.903 44.1596 124.918 44.2819 124.924 44.3395C124.928 44.4191 124.934 44.503 124.937
          44.5822C124.937 44.6428 124.936 44.7678 124.935 44.8257C124.909 45.3401 124.791 45.8563 124.573
           46.3161C124.509
           46.4569 124.423 46.6115 124.338 46.7382C124.108 47.0826 123.786 47.5097 123.565 47.8571C123.408 48.1053
            123.252 48.358 123.13 48.6293C122.763 49.5078 123.3 50.3832 123.641 51.151Z"
        fill="#E3E3E3"
      />
      <rect x="103.17" y="77.0215" width="50.7447" height="5.6383" rx="2.81915" fill="#FCD1A9" />
      <rect x="103.17" y="86.043" width="50.7447" height="5.6383" rx="2.81915" fill="#FCD1A9" />
      <rect x="103.17" y="95.0635" width="50.7447" height="5.6383" rx="2.81915" fill="#FCD1A9" />
    </svg>
  );
}

DeliveryProcessing.propTypes = {
  className: PropTypes.string,
};

DeliveryProcessing.defaultProps = {
  className: '',
};

export default DeliveryProcessing;
