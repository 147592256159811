import TagManager from 'react-gtm-module';
import config from '../config';

const TRACKING_ID = config.trackingID;

const tagManagerArgs = {
  gtmId: TRACKING_ID,
};

function init() {
  // Enable debug mode on the local development environment
  TagManager.initialize(tagManagerArgs);
}

function sendEvent(event, payload) {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...payload,
    },
  });
}

function sendPageview(location, title) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: location,
      pageTitle: title,
    },
  });
}

export default {
  init,
  sendEvent,
  sendPageview,
};
