import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputText from '../InputText';
import CustomSelect from '../CustomSelect';

function InputPhoneNumber(props) {
  const {
    value,
    size,
    onChange,
    fullWidth,
    fieldState,
    placeholder,
    options,
    isLoading,
    ...args
  } = props;

  const renderOption = (areaCode) => (
    <div className="w-full flex items-center justify-center">
      <div className="flex items-center gap-x-4 w-full max-w-xs">
        <img
          crossOrigin="anonymous"
          className="min-w-[24px] h-3 bg-gray-400 object-center object-cover drop-shadow-md"
          src={areaCode?.flag}
          alt={areaCode?.name}
        />
        <span className="w-full text-left truncate">
          {areaCode?.name}
        </span>
      </div>
    </div>
  );

  const renderValue = (val) => {
    let param = options.find((option) => option.country_calling_code === val);

    if (!param) {
      const defaultOption = {
        name: 'Colombia',
        flag: 'https://baco-assets-dev.s3.amazonaws.com/images/original/banderas/Colombia.png',
        country_calling_code: '57',
      };
      param = defaultOption;
    }

    return (
      <div className="w-full flex gap-x-2 items-center">
        <img
          crossOrigin="anonymous"
          className="min-w-[24px] h-3 bg-gray-400 object-center object-cover drop-shadow-md"
          src={`${param?.flag}`}
          alt={param?.name}
        />
        <span>
          {`+${param?.country_calling_code}`}
        </span>
      </div>
    );
  };

  const handleChangeArea = (val) => {
    onChange({ ...value, area: val });
  };

  const handleChangePhone = (event) => {
    onChange({ ...value, number: event.target.value });
  };

  return (
    <div
      className={
        clsx('relative flex gap-x-4', {
          'w-full': fullWidth,
        })
      }
    >
      <div className="w-[140px]">
        <CustomSelect
          size={size}
          value={value.area}
          valueKey="country_calling_code"
          options={options}
          onChange={handleChangeArea}
          renderOption={renderOption}
          renderValue={renderValue}
          fieldState={fieldState}
          fullWidth
          fluid
          isLoading={isLoading}
        />
      </div>

      <InputText
        size={size}
        value={value.number}
        onChange={handleChangePhone}
        fieldState={fieldState}
        placeholder={placeholder}
        fullWidth
        type="tel"
        {...args}
      />
    </div>
  );
}

InputPhoneNumber.propTypes = {
  value: PropTypes.shape({
    area: PropTypes.string,
    number: PropTypes.string,
  }),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  fieldState: PropTypes.any,
  options: PropTypes.any,
  isLoading: PropTypes.bool,
};

InputPhoneNumber.defaultProps = {
  value: {
    area: '57',
    phone: '',
  },
  size: 'md',
  placeholder: 'Ingresa tu número',
  onChange: () => null,
  fullWidth: false,
  fieldState: {},
  options: [
  ],
  isLoading: true,
};

export default InputPhoneNumber;
