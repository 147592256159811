import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import analytics from '../services/analytics';

export default function useGoogleAnalytics() {
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  const restrictionEvent = 'search';

  useEffect(() => {
    analytics.init();
  }, []);

  const sendPageEvent = () => {
    useEffect(() => {
      analytics.sendPageview(currentPath, location.pathname);
    }, [location]);
  };

  const sendEvent = (eventName, payload) => {
    if (eventName !== restrictionEvent || (eventName === restrictionEvent && payload.searchValue !== '')) {
      analytics.sendEvent(eventName, payload);
    }
  };

  return {
    sendPageEvent,
    sendEvent,
  };
}
