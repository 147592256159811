import PropTypes from 'prop-types';

function LogoBacu(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="80"
      height="30"
      viewBox="0 0 80 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M76.8332 29.6673C78.3059 29.6673 79.4998 28.4734 79.4998 27.0006C79.4998 25.5279 78.3059 24.334 76.8332
       24.334C75.3604 24.334 74.1665 25.5279 74.1665 27.0006C74.1665 28.4734 75.3604 29.6673 76.8332 29.6673Z"
        fill="#0F5946"
      />
      <path
        d="M5.73411 8.5791C10.1599 8.5791 12.8926 10.6884 12.8926 15.0842C12.8926 16.5392 12.5064 17.6979
       12.0311 18.6785C11.972 18.7975 11.9121 18.9457 11.9121 19.0647C11.9121 19.1838 11.9711 19.3319 12.0311
        19.421C12.6255 20.3416 12.8926 21.4403 12.8926 22.8371C12.8926 27.1738 10.6052 29.3713 6.50658
         29.3713H1.48643C1.01113 29.3713 0.833008 29.1932 0.833008 28.7179V9.23343C0.833008 8.75813 1.01113
          8.58001 1.48643 8.58001H5.73411V8.5791ZM6.4466 16.7473C7.21907 16.7473 7.81251 16.272 7.81251
          15.3514C7.81251 14.4599 7.21816 13.9555 6.4466 13.9555H6.29847C5.82317 13.9555 5.64505 14.1636 5.64505
          14.6389V16.0648C5.64505 16.5401 5.82317 16.7482 6.29847 16.7482H6.4466V16.7473ZM6.47659 23.994C7.24906
          23.994 7.8425 23.5187 7.8425 22.5981C7.8425 21.7066 7.24815 21.2022 6.47659 21.2022H6.29847C5.82317
          21.2022 5.64505 21.4103 5.64505 21.8856V23.1624C5.64505 23.6377 5.82317 23.994 6.29847 23.994H6.47659Z"
        fill="#0F5946"
      />
      <path
        d="M20.7036 26.3998C20.0202 26.3998 19.783 26.5479 19.4268 27.2023L18.6843 28.5682C18.328 29.2216
      18.0899 29.3706 17.4074 29.3706H13.3679C12.8335 29.3706 12.6554 29.0144 12.8926 28.5391L23.3173
      8.75656C23.5254 8.37032 23.9707 8.31125 24.2088 8.75656L34.6344 28.5382C34.8716 29.0135 34.6935 29.3697
      34.1591 29.3697H30.0905C29.4071 29.3697 29.1699 29.2216 28.8137 28.5673L28.0712 27.2013C27.7149 26.5479
      27.4768 26.3989 26.7943 26.3989H20.7036V26.3998ZM24.1788 19.5084C23.9416 19.0631 23.5845 19.0631 23.3473
      19.5084L22.3667 21.2315C22.0695 21.7368 22.2186 22.004 22.7829 22.004H24.7132C25.2776 22.004 25.4257
      21.7368 25.1294 21.2315L24.1788 19.5084Z"
        fill="#0F5946"
      />
      <path
        d="M49.6871 24.0243C51.6174 24.0243 52.9842 23.2227 54.0539 21.7369C54.3801 21.2915 54.6182
      21.2316 55.1226 21.4697L58.5088 23.0737C59.1031 23.3709 59.0141 23.8162 58.776 24.3214C57.1419 27.7376
      53.6077 29.6679 49.6572 29.6679C43.6573 29.6679 39.2915 25.183 39.2915 18.975C39.2915 12.7671 43.6582
      8.28223 49.6572 8.28223C53.6077 8.28223 57.1419 10.2125 58.776 13.6286C59.0141 14.1339 59.1031 14.5792
      58.5088 14.8764L55.1226 16.4804C54.6173 16.7176 54.3801 16.6585 54.0539 16.2132C52.9842 14.7283 51.6183
      13.9258 49.6871 13.9258C46.8363 13.9258 44.727 16.1533 44.727 18.975C44.727 21.7968 46.8363 24.0243 49.6871
      24.0243Z"
        fill="#0F5946"
      />
      <path
        d="M60.499 21.6464V9.26056C60.499 8.78526 60.6771 8.57715 61.1524 8.57715H64.9539C65.4292 8.57715
      65.6073 8.78526 65.6073 9.26056V21.2902C65.6073 23.2805 65.9936 23.6958 66.617 23.6958C67.2404 23.6958
      67.6267 23.2795 67.6267 21.2902V9.26056C67.6267 8.78526 67.7748 8.57715 68.2801 8.57715H72.0815C72.5568
      8.57715 72.735 8.78526 72.735 9.26056V21.6464C72.735 27.7353 70.5075 29.6665 66.6161 29.6665C62.7265
      29.6665 60.499 27.7353 60.499 21.6464Z"
        fill="#0F5946"
      />
      <path
        d="M72.5083 5.53499H1.06021C0.934792 5.53499 0.833008 5.4332 0.833008 5.30779V0.561182C0.833008
      0.435769 0.934792 0.333984 1.06021 0.333984H72.5083C72.6337 0.333984 72.7355 0.435769 72.7355
      0.561182V5.30779C72.7355 5.4332 72.6337 5.53499 72.5083 5.53499Z"
        fill="#0F5946"
      />
    </svg>

  );
}

LogoBacu.propTypes = {
  className: PropTypes.string,
};

LogoBacu.defaultProps = {
  className: '',
};

export default LogoBacu;
