import PropTypes from 'prop-types';
import clsx from 'clsx';

function RadioButton(props) {
  const {
    name,
    id,
    color,
    margin,
    children,
    fullWidth,
    className,
    isChecked,
    checked,
    onClick,
    ...args
  } = props;
  const classes = clsx(
    'relative block w-full border-2 rounded-lg border border-gray-300 p-3 m-[1px]',
    'disabled:opacity-50 disabled:cursor-not-allowed peer-checked:border-2 peer-checked:m-0',
    className,
    {
      'peer-checked:border-baco-primary': color === 'primary',
      'px-1 py-1': margin === 'densed',
      'w-fit': !fullWidth,
    },
  );

  return (
    <div
      role="button"
      tabIndex={-1}
      onKeyPress={onClick}
      onClick={onClick}
    >
      <input
        className="hidden peer"
        name={`${name}-${id}`}
        id={`${name}-${id}`}
        type="radio"
        checked={checked}
        {...args}
      />
      <label
        htmlFor={`${name}-${id}`}
        className={classes}
      >
        {children}
        {checked && isChecked}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  margin: PropTypes.oneOf(['none', 'densed']),
  children: PropTypes.node,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  isChecked: PropTypes.node,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};
RadioButton.defaultProps = {
  color: 'primary',
  margin: 'none',
  children: null,
  className: '',
  fullWidth: false,
  isChecked: null,
  checked: false,
  onClick: () => null,
};

export default RadioButton;
