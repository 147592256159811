import PropTypes from 'prop-types';

function Done(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m9.55 18-5.7-5.7 1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4Z" />
    </svg>
  );
}

Done.propTypes = {
  className: PropTypes.string,
};

Done.defaultProps = {
  className: '',
};

export default Done;
