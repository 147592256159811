import { useQuery } from '@tanstack/react-query';
import { createdInPOS } from '../../services/api';

export function useCreatedInPOS({
  orderID,
}) {
  const {
    data: orderInPOS,
    isLoading: isLoadingOrderInPOS,
  } = useQuery(['createdInPOS', orderID], () => createdInPOS(orderID), {
    refetchInterval: (response) => {
      if (!response?.is_created_in_pos) {
        // console.log('im retrying', response?.is_created_in_pos);
        return 1000;
      }
      return false;
    },
  });

  return { isLoadingOrderInPOS, orderInPOS };
}

export default useCreatedInPOS;
