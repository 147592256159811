import PropTypes from 'prop-types';

function Info(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33337 10.3333H7.66671V6.33334H6.33337V10.3333ZM7.00004 5C7.18893 5 7.34737 4.936 7.47537 4.808C7.60293
        4.68045 7.66671 4.52222 7.66671 4.33334C7.66671 4.14445 7.60293 3.986 7.47537 3.858C7.34737 3.73045 7.18893
        3.66667 7.00004 3.66667C6.81115 3.66667 6.65293 3.73045 6.52537 3.858C6.39737 3.986 6.33337 4.14445 6.33337
        4.33334C6.33337 4.52222 6.39737 4.68045 6.52537 4.808C6.65293 4.936 6.81115 5 7.00004 5ZM7.00004 13.6667C6.07782
        13.6667 5.21115 13.4916 4.40004 13.1413C3.58893 12.7916 2.88337 12.3167 2.28337 11.7167C1.68337 11.1167 1.20849
        10.4111 0.858707 9.6C0.508485 8.78889 0.333374 7.92222 0.333374 7C0.333374 6.07778 0.508485 5.21111 0.858707
        4.4C1.20849 3.58889 1.68337 2.88334 2.28337 2.28334C2.88337 1.68334 3.58893 1.20822 4.40004 0.858002C5.21115
        0.508225 6.07782 0.333336 7.00004 0.333336C7.92226 0.333336 8.78893 0.508225 9.60004 0.858002C10.4112 1.20822
        11.1167 1.68334 11.7167 2.28334C12.3167 2.88334 12.7916 3.58889 13.1414 4.4C13.4916 5.21111 13.6667 6.07778
        13.6667 7C13.6667 7.92222 13.4916 8.78889 13.1414 9.6C12.7916 10.4111 12.3167 11.1167 11.7167 11.7167C11.1167
        12.3167 10.4112 12.7916 9.60004 13.1413C8.78893 13.4916 7.92226 13.6667 7.00004 13.6667ZM7.00004 12.3333C8.48893
        12.3333 9.75004 11.8167 10.7834 10.7833C11.8167 9.75 12.3334 8.48889 12.3334 7C12.3334 5.51111 11.8167 4.25
        10.7834 3.21667C9.75004 2.18334 8.48893 1.66667 7.00004 1.66667C5.51115 1.66667 4.25004 2.18334 3.21671
        3.21667C2.18337 4.25 1.66671 5.51111 1.66671 7C1.66671 8.48889 2.18337 9.75 3.21671 10.7833C4.25004 11.8167
        5.51115 12.3333 7.00004 12.3333Z"
      />
    </svg>
  );
}

Info.propTypes = {
  className: PropTypes.string,
};

Info.defaultProps = {
  className: '',
};

export default Info;
