import PropTypes from 'prop-types';

function ChevronUp(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m7.4 15.375-1.4-1.4 6-6 6 6-1.4 1.4-4.6-4.6Z" />
    </svg>
  );
}

ChevronUp.propTypes = {
  className: PropTypes.string,
};

ChevronUp.defaultProps = {
  className: '',
};

export default ChevronUp;
