import clsx from 'clsx';
import PropTypes from 'prop-types';

function IllustratedMessage(props) {
  const {
    className,
    classes,
    illustration,
    title,
    description,
  } = props;

  return (
    <div
      className={
        clsx(
          'flex flex-col justify-center items-center gap-y-5 w-full px-4',
          className,
        )
      }
    >
      {illustration}
      <div className="flex flex-col items-center">
        <h2
          className={
            clsx(
              'm-0 text-gray-700 text-xl leading-5 mb-4 font-bold text-center',
              classes.title,
            )
          }
        >
          {title}
        </h2>
        <p
          className={
            clsx(
              'm-0 text-gray-500 text-lg text-center',
              classes.description,
            )
          }
        >
          {description}
        </p>
      </div>
    </div>
  );
}

IllustratedMessage.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  illustration: PropTypes.node,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
};
IllustratedMessage.defaultProps = {
  className: '',
  classes: {},
  illustration: null,
};

export default IllustratedMessage;
