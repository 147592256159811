import PropTypes from 'prop-types';

function DeliveryFindingCourier(props) {
  const { className } = props;

  return (

    <svg
      width="263"
      height="151"
      viewBox="0 0 263 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M81.3388 133.103C116.453 124.564 118.3 99.919 100.024 87.0057C88.3124 78.7309 92.0171
      61.3996 59.3709 58.0175C26.7247 54.6353 25.6508 87.4912 30.6412 100.196C32.9094 114.723 46.2245
       141.642 81.3388 133.103Z"
        fill="#FFF9F3"
      />
      <path
        d="M129.592 13.7498C146.547 -2.60083 165.622 8.69524 173.351 14.002C185.807 22.5538 186.057
       36.0288 200.203 41.5764C213.144 46.6529 219.192 37.8319 229.637 43.9773C239.835 49.9771 247.168
       66.0953 244.851 79.5165C240.636 103.918 205.58 113.29 187.824 114.674C176.562 115.552 151.846
       117.48 135.463 96.3119C120.06 76.4121 105.624 36.8666 129.594 13.7501L129.592 13.7498Z"
        fill="#FCE0A9"
        fillOpacity="0.48"
      />
      <path
        d="M41.2178 59.4851C38.1871 50.9653 43.1562 45.9594 46.0195 44.5214C52.4396 42.8815 66.103
      42.8376 69.3966 55.782C72.6903 68.7264 61.4982 71.4181 55.4905 71.1459C51.9957 70.8089 44.2485
      68.005 41.2178 59.4851Z"
        fill="#F6F5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.418 45.6709C95.1295 45.6709 90.0315 50.7689
      90.0315 57.0575V96.9108C90.0315 103.199 95.1295 108.297 101.418 108.297H158.351C164.64 108.297
      169.738 103.199 169.738 96.9108V57.0575C169.738 50.7689 164.64 45.6709 158.351 45.6709H101.418ZM117.075
      57.0575C112.358 57.0575 108.535 60.881 108.535 65.5975C108.535 70.314 112.358 74.1375 117.075
      74.1375C121.791 74.1375 125.615 70.314 125.615 65.5975C125.615 60.881 121.791 57.0575 117.075
      57.0575ZM102.374 90.0893C104.572 84.1064 110.32 79.8308 117.075 79.8308C123.829 79.8308 129.577 84.1064
      131.775 90.0893C132.265 91.4236 131.696 92.9149 130.441 93.5832C126.453 95.7079 121.9 96.9108 117.075
      96.9108C112.249 96.9108 107.696 95.7079 103.708 93.5832C102.453 92.9149 101.884 91.4236 102.374
      90.0893ZM141.271 62.7509C139.699 62.7509 138.425 64.0253 138.425 65.5975C138.425 67.1697 139.699 68.4442
       141.271 68.4442H155.505C157.077 68.4442 158.351 67.1697 158.351 65.5975C158.351 64.0253 157.077 62.7509
        155.505 62.7509H141.271ZM138.425 76.9842C138.425 75.412 139.699 74.1375 141.271 74.1375H155.505C157.077
         74.1375 158.351 75.412 158.351 76.9842C158.351 78.5563 157.077 79.8308 155.505 79.8308H141.271C139.699
         79.8308 138.425 78.5563 138.425 76.9842ZM141.271 85.5241C139.699 85.5241 138.425 86.7986 138.425
          88.3708C138.425 89.943 139.699 91.2175 141.271 91.2175H155.505C157.077 91.2175 158.351 89.943 158.351
           88.3708C158.351 86.7986 157.077 85.5241 155.505 85.5241H141.271Z"
        fill="#A05F33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.418 45.6709C95.1295 45.6709 90.0315 50.7689 90.0315
        57.0575V96.9108C90.0315 103.199 95.1295 108.297 101.418 108.297H158.351C164.64 108.297 169.738 103.199
        169.738 96.9108V57.0575C169.738 50.7689 164.64 45.6709 158.351 45.6709H101.418ZM117.075 57.0575C112.358
        57.0575 108.535 60.881 108.535 65.5975C108.535 70.314 112.358 74.1375 117.075 74.1375C121.791 74.1375
        125.615 70.314 125.615 65.5975C125.615 60.881 121.791 57.0575 117.075 57.0575ZM102.374 90.0893C104.572
        84.1064 110.32 79.8308 117.075 79.8308C123.829 79.8308 129.577 84.1064 131.775 90.0893C132.265 91.4236
        131.696 92.9149 130.441 93.5832C126.453 95.7079 121.9 96.9108 117.075 96.9108C112.249 96.9108 107.696
        95.7079 103.708 93.5832C102.453 92.9149 101.884 91.4236 102.374 90.0893ZM141.271 62.7509C139.699 62.7509
        138.425 64.0253 138.425 65.5975C138.425 67.1697 139.699 68.4442 141.271 68.4442H155.505C157.077 68.4442
        158.351 67.1697 158.351 65.5975C158.351 64.0253 157.077 62.7509 155.505 62.7509H141.271ZM138.425
        76.9842C138.425 75.412 139.699 74.1375 141.271 74.1375H155.505C157.077 74.1375 158.351 75.412 158.351
        76.9842C158.351 78.5563 157.077 79.8308 155.505 79.8308H141.271C139.699 79.8308 138.425 78.5563 138.425
        76.9842ZM141.271 85.5241C139.699 85.5241 138.425 86.7986 138.425 88.3708C138.425 89.943 139.699 91.2175
        141.271 91.2175H155.505C157.077 91.2175 158.351 89.943 158.351 88.3708C158.351 86.7986 157.077 85.5241
        155.505 85.5241H141.271Z"
        fill="#A05F33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.5207 42.8252C93.232 42.8252 88.134 47.9232 88.134
      54.2118V94.0651C88.134 100.354 93.232 105.452 99.5207 105.452H156.454C162.743 105.452 167.841 100.354
      167.841 94.0651V54.2118C167.841 47.9232 162.743 42.8252 156.454 42.8252H99.5207ZM115.177 54.2118C110.461
      54.2118 106.637 58.0353 106.637 62.7518C106.637 67.4683 110.461 71.2918 115.177 71.2918C119.894 71.2918
      123.717 67.4683 123.717 62.7518C123.717 58.0353 119.894 54.2118 115.177 54.2118ZM100.477 87.2436C102.674
      81.2607 108.423 76.9851 115.177 76.9851C121.931 76.9851 127.68 81.2607 129.877 87.2436C130.368 88.5779
      129.798 90.0692 128.544 90.7375C124.555 92.8622 120.002 94.0651 115.177 94.0651C110.352 94.0651 105.799
      92.8622 101.811 90.7375C100.556 90.0692 99.9867 88.5779 100.477 87.2436ZM139.374 59.9052C137.802 59.9052
      136.527 61.1796 136.527 62.7518C136.527 64.324 137.802 65.5985 139.374 65.5985H153.607C155.179 65.5985
      156.454 64.324 156.454 62.7518C156.454 61.1796 155.179 59.9052 153.607 59.9052H139.374ZM136.527
      74.1385C136.527 72.5663 137.802 71.2918 139.374 71.2918H146.491H153.607C155.179 71.2918 156.454 72.5663
      156.454 74.1385C156.454 75.7106 155.179 76.9851 153.607 76.9851H139.374C137.802 76.9851 136.527 75.7106
      136.527 74.1385ZM139.374 82.6784C137.802 82.6784 136.527 83.9529 136.527 85.5251C136.527 87.0973 137.802
      88.3717 139.374 88.3717H153.607C155.179 88.3717 156.454 87.0973 156.454 85.5251C156.454 83.9529 155.179
      82.6784 153.607 82.6784H139.374Z"
        fill="#FFB869"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.5207 42.8252C93.232 42.8252 88.134 47.9232 88.134
      54.2118V94.0651C88.134 100.354 93.232 105.452 99.5207 105.452H156.454C162.743 105.452 167.841 100.354
      167.841 94.0651V54.2118C167.841 47.9232 162.743 42.8252 156.454 42.8252H99.5207ZM115.177 54.2118C110.461
      54.2118 106.637 58.0353 106.637 62.7518C106.637 67.4683 110.461 71.2918 115.177 71.2918C119.894 71.2918
      123.717 67.4683 123.717 62.7518C123.717 58.0353 119.894 54.2118 115.177 54.2118ZM100.477 87.2436C102.674
      81.2607 108.423 76.9851 115.177 76.9851C121.931 76.9851 127.68 81.2607 129.877 87.2436C130.368 88.5779
      129.798 90.0692 128.544 90.7375C124.555 92.8622 120.002 94.0651 115.177 94.0651C110.352 94.0651 105.799
      92.8622 101.811 90.7375C100.556 90.0692 99.9867 88.5779 100.477 87.2436ZM139.374 59.9052C137.802 59.9052
      136.527 61.1796 136.527 62.7518C136.527 64.324 137.802 65.5985 139.374 65.5985H153.607C155.179 65.5985
      156.454 64.324 156.454 62.7518C156.454 61.1796 155.179 59.9052 153.607 59.9052H139.374ZM136.527 74.1385C136.527
      72.5663 137.802 71.2918 139.374 71.2918H146.491H153.607C155.179 71.2918 156.454 72.5663 156.454 74.1385C156.454
      75.7106 155.179 76.9851 153.607 76.9851H139.374C137.802 76.9851 136.527 75.7106 136.527
      74.1385ZM139.374
      82.6784C137.802 82.6784 136.527 83.9529 136.527 85.5251C136.527 87.0973 137.802 88.3717 139.374
      88.3717H153.607C155.179 88.3717 156.454 87.0973 156.454 85.5251C156.454 83.9529 155.179 82.6784 153.607
      82.6784H139.374Z"
        fill="#D19161"
      />
      <path
        d="M106.637 62.7518C106.637 58.0353 110.461 54.2118 115.177 54.2118C119.894 54.2118 123.717 58.0353
      123.717 62.7518C123.717 67.4683 119.894 71.2918 115.177 71.2918C110.461 71.2918 106.637 67.4683 106.637 62.7518Z"
        fill="#FFB869"
      />
      <path
        d="M106.637 62.7518C106.637 58.0353 110.461 54.2118 115.177 54.2118C119.894 54.2118 123.717 58.0353 123.717
      62.7518C123.717 67.4683 119.894 71.2918 115.177 71.2918C110.461 71.2918 106.637 67.4683 106.637 62.7518Z"
        fill="#FCF2E8"
      />
      <path
        d="M115.177 76.9851C108.423 76.9851 102.674 81.2607 100.477 87.2436C99.9867 88.5779 100.556 90.0692
      101.811 90.7375C105.799 92.8622 110.352 94.0651 115.177 94.0651C120.002 94.0651 124.555 92.8622 128.544
      90.7375C129.798 90.0692 130.368 88.5779 129.877 87.2436C127.68 81.2607 121.931 76.9851 115.177 76.9851Z"
        fill="#FFB869"
      />
      <path
        d="M115.177 76.9851C108.423 76.9851 102.674 81.2607 100.477 87.2436C99.9867 88.5779 100.556 90.0692
      101.811 90.7375C105.799 92.8622 110.352 94.0651 115.177 94.0651C120.002 94.0651 124.555 92.8622 128.544
      90.7375C129.798 90.0692 130.368 88.5779 129.877 87.2436C127.68 81.2607 121.931 76.9851 115.177 76.9851Z"
        fill="#FCF2E8"
      />
      <path
        d="M136.527 62.7518C136.527 61.1796 137.802 59.9052 139.374 59.9052H153.607C155.179 59.9052 156.454
      61.1796 156.454 62.7518C156.454 64.324 155.179 65.5985 153.607 65.5985H139.374C137.802 65.5985 136.527
      64.324 136.527 62.7518Z"
        fill="#FFB869"
      />
      <path
        d="M136.527 62.7518C136.527 61.1796 137.802 59.9052 139.374 59.9052H153.607C155.179 59.9052 156.454
      61.1796 156.454 62.7518C156.454 64.324 155.179 65.5985 153.607 65.5985H139.374C137.802 65.5985 136.527
      64.324 136.527 62.7518Z"
        fill="#FCD1A9"
      />
      <path
        d="M139.374 71.2918C137.802 71.2918 136.527 72.5663 136.527 74.1385C136.527 75.7106 137.802 76.9851
       139.374 76.9851H153.607C155.179 76.9851 156.454 75.7106 156.454 74.1385C156.454 72.5663 155.179 71.2918
       153.607 71.2918H146.491H139.374Z"
        fill="#FFB869"
      />
      <path
        d="M139.374 71.2918C137.802 71.2918 136.527 72.5663 136.527 74.1385C136.527 75.7106 137.802 76.9851
       139.374 76.9851H153.607C155.179 76.9851 156.454 75.7106 156.454 74.1385C156.454 72.5663 155.179 71.2918
       153.607 71.2918H146.491H139.374Z"
        fill="#FCD1A9"
      />
      <path
        d="M136.527 85.5251C136.527 83.9529 137.802 82.6784 139.374 82.6784H153.607C155.179 82.6784 156.454
       83.9529 156.454 85.5251C156.454 87.0973 155.179 88.3717 153.607 88.3717H139.374C137.802 88.3717 136.527
        87.0973 136.527 85.5251Z"
        fill="#FFB869"
      />
      <path
        d="M136.527 85.5251C136.527 83.9529 137.802 82.6784 139.374 82.6784H153.607C155.179 82.6784 156.454
       83.9529 156.454 85.5251C156.454 87.0973 155.179 88.3717 153.607 88.3717H139.374C137.802 88.3717 136.527
        87.0973 136.527 85.5251Z"
        fill="#D19161"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.171 34.3319C161.671 34.3319 156.403 39.6007
       156.403 46.1C156.403 52.5994 161.671 57.8681 168.171 57.8681C171.421 57.8681 174.361 56.5524 176.492
        54.4213C178.623 52.2903 179.939 49.3501 179.939 46.1C179.939 39.6007 174.67 34.3319 168.171
        34.3319ZM153.788 46.1C153.788 38.1564 160.227 31.7168 168.171 31.7168C176.114 31.7168 182.554 38.1564
         182.554 46.1C182.554 49.6005 181.302 52.8105 179.224 55.304L187.401 63.4814C187.912 63.992 187.912
          64.8199 187.401 65.3305C186.891 65.8412 186.063 65.8412 185.552 65.3305L177.375 57.1532C174.881
          59.2314 171.671 60.4832 168.171 60.4832C160.227 60.4832 153.788 54.0437 153.788 46.1Z"
        fill="#404040"
      />
      <path
        d="M156.403 46.1C156.403 39.6007 161.671 34.3319 168.171 34.3319C174.67 34.3319 179.939 39.6007
      179.939 46.1C179.939 49.3501 178.623 52.2903 176.492 54.4213C174.361 56.5524 171.421 57.8681 168.171
      57.8681C161.671 57.8681 156.403 52.5994 156.403 46.1Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>

  );
}

DeliveryFindingCourier.propTypes = {
  className: PropTypes.string,
};

DeliveryFindingCourier.defaultProps = {
  className: '',
};

export default DeliveryFindingCourier;
