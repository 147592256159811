import PropTypes from 'prop-types';

function DeliveryStepper3(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="343"
      height="73"
      viewBox="0 0 343 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.214844" width="343" height="72" rx="12" fill="white" />
      <circle cx="48.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M46.775 20.7336L42.5 16.4586L43.5688 15.3898L46.775 18.5961L53.6562
      11.7148L54.725 12.7836L46.775 20.7336Z"
        fill="white"
      />
      <rect x="68.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="130.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M128.775 20.7336L124.5 16.4586L125.569 15.3898L128.775 18.5961L135.656
      11.7148L136.725 12.7836L128.775 20.7336Z"
        fill="white"
      />
      <rect x="150.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="212.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M208.479 18.4648C208.794 20.3248 210.234 21.6148 212.409 21.6148C214.614 21.6148 216.219 20.0998
      216.219 17.9398C216.219 15.9298 214.629 14.7448 213.174 14.7448C212.994 14.7448 212.829 14.7598 212.679
      14.7898L215.754 12.2398V10.9648H208.929V12.4648H213.429L210.129 15.2398L211.029 16.3648C211.344 16.0498
      211.869 15.8398 212.454 15.8398C213.729 15.8398 214.644 16.6948 214.644 17.9698C214.644 19.2448 213.684
      20.1148 212.409 20.1148C211.029 20.1148 210.054 19.1848 209.979 17.7898L208.479 18.4648Z"
        fill="#AEAEAE"
      />
      <rect x="232.5" y="15.2148" width="42" height="2" rx="1" fill="#E0D8D1" />
      <circle cx="294.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M294.848 21.4648H296.423V18.9148H298.148V17.4898H296.423V14.2048H294.848V17.4898H291.773C292.628
      16.6348 293.198 15.7648 293.498 14.8798C293.798 13.9798 293.948 12.8998 293.948
      11.6398V10.9648H292.373V11.6398C292.328 14.3248 292.088 15.2248 290.123 17.2648V18.9148H294.848V21.4648Z"
        fill="#AEAEAE"
      />
      <path
        d="M25.5266
46.2148V53.2148H30.5566V52.3448H26.4466V50.0248H30.1566V49.1548H26.4466V47.0848H30.4566V46.2148H25.5266ZM32.8747
53.2148V50.6148C32.8747
49.7048
33.4347
49.1348
34.1547
49.1348C34.8347
49.1348
35.2347
49.5648
35.2347
50.3948V53.2148H36.1047V50.1348C36.1047
49.0248
35.4447
48.3748
34.4347
48.3748C33.7647
48.3748
33.2047
48.6948
32.8747
49.2448V48.4748H32.0147V53.2148H32.8747ZM42.5756
52.5548C41.7356
52.5548
40.9456
51.8748
40.9456
50.8148C40.9456
49.7248
41.7456
49.1348
42.4756
49.1348C43.2456
49.1348
43.7456
49.5748
43.8956
50.3248L44.6856
50.0848C44.4556
49.0048
43.6356
48.3748
42.4856
48.3748C41.1956
48.3748
40.1056
49.3948
40.1056
50.8148C40.1056
52.3148
41.1056
53.3148
42.5956
53.3148C43.7856
53.3148
44.6456
52.6248
44.9156
51.4248L44.1456
51.1348C43.9556
52.0248
43.4956
52.5548
42.5756
52.5548ZM49.3936
48.4748V50.9948C49.3936
51.9448
48.8236
52.5548
48.0736
52.5548C47.3836
52.5548
47.0036
52.1448
47.0036
51.3148V48.4748H46.1436V51.5148C46.1436
52.6848
46.8036
53.3148
47.8436
53.3148C48.5336
53.3148
49.0736
52.9648
49.3936
52.4148V53.2148H50.2636V48.4748H49.3936ZM51.8429
48.4748V53.2148H52.7029V50.1948C52.7029
49.5548
53.0529
49.1348
53.5829
49.1348C54.0529
49.1348
54.3429
49.4048
54.3429
49.8448C54.3429
49.9348
54.3329
50.0548
54.3129
50.1948H55.1529C55.1929
50.0348
55.2129
49.8548
55.2129
49.6648C55.2129
48.9448
54.6829
48.3748
53.9129
48.3748C53.3429
48.3748
52.9129
48.6548
52.7029
49.1648V48.4748H51.8429ZM56.079
51.9748C56.359
52.7848
57.089
53.3148
58.159
53.3148C59.229
53.3148
60.059
52.6948
60.059
51.8148C60.059
51.0248
59.509
50.5548
58.399
50.4048C57.469
50.2748
57.129
50.0948
57.129
49.6848C57.129
49.3148
57.529
49.0748
58.049
49.0748C58.559
49.0748
58.929
49.3148
59.089
49.7948L59.759
49.4248C59.469
48.7548
58.939
48.3748
58.049
48.3748C57.559
48.3748
57.149
48.4948
56.819
48.7448C56.499
48.9848
56.339
49.2948
56.339
49.6748C56.339
50.4248
56.879
50.8748
57.969
51.0348C58.929
51.1648
59.219
51.4148
59.219
51.8648C59.219
52.2748
58.829
52.5548
58.199
52.5548C57.479
52.5548
57.059
52.2748
56.789
51.6048L56.079
51.9748ZM61.0568
50.8448C61.0568
52.2448
62.0268
53.3148
63.4568
53.3148C64.8768
53.3148
65.8668
52.2448
65.8668
50.8448C65.8668
49.4448
64.8968
48.3748
63.4568
48.3748C62.0268
48.3748
61.0568
49.4448
61.0568
50.8448ZM64.9768
50.8448C64.9768
51.8448
64.3468
52.5348
63.4568
52.5348C62.5768
52.5348
61.9368
51.8348
61.9368
50.8448C61.9368
49.8548
62.5768
49.1548
63.4568
49.1548C63.9068
49.1548
64.2668
49.3148
64.5468
49.6348C64.8368
49.9448
64.9768
50.3548
64.9768
50.8448Z"
        fill="#0F5945"
      />
      <path
        d="M109.14
53.2148V53.0548C108.87
52.7248
108.82
52.3548
108.78
51.5948C108.74
50.7648
108.33
50.2448
107.69
50.0348C108.53
49.7248
109.07
49.0248
109.07
48.1848C109.07
47.0648
108.21
46.2148
106.77
46.2148H103.34V53.2148H104.69V50.4348H106.19C106.92
50.4348
107.33
50.7748
107.33
51.6148C107.33
52.0648
107.35
52.3948
107.38
52.6148C107.41
52.8348
107.47
53.0348
107.56
53.2148H109.14ZM104.69
49.1848V47.4648H106.69C107.36
47.4648
107.72
47.7848
107.72
48.3148C107.72
48.8448
107.36
49.1848
106.69
49.1848H104.69ZM113.619
51.4048C113.469
51.9448
113.099
52.2148
112.519
52.2148C111.839
52.2148
111.279
51.8048
111.269
51.0848H114.489C114.529
50.9048
114.549
50.7148
114.549
50.5048C114.549
49.2048
113.659
48.3748
112.349
48.3748C110.949
48.3748
109.989
49.4648
109.989
50.8148C109.989
52.3148
111.029
53.3148
112.499
53.3148C113.609
53.3148
114.379
52.7548
114.709
51.8348L113.619
51.4048ZM111.269
50.2348C111.389
49.7148
111.829
49.4048
112.349
49.4048C112.919
49.4048
113.309
49.7148
113.309
50.1948L113.299
50.2348H111.269ZM115.85
48.4748V55.1948H117.13V52.6048C117.38
53.0448
117.93
53.3148
118.58
53.3148C119.91
53.3148
120.88
52.3548
120.88
50.8648C120.88
49.3748
119.93
48.3748
118.65
48.3748C118.02
48.3748
117.43
48.6948
117.08
49.1848V48.4748H115.85ZM118.34
52.2248C117.65
52.2248
117.08
51.7048
117.08
50.8648C117.08
50.0148
117.65
49.4748
118.34
49.4748C119.05
49.4748
119.6
50.0148
119.6
50.8648C119.6
51.6948
119.05
52.2248
118.34
52.2248ZM121.877
51.8448C121.877
52.7548
122.617
53.3148
123.437
53.3148C124.207
53.3148
124.697
53.0348
125.007
52.4848V53.2148H126.177V50.2948C126.177
49.0548
125.437
48.3748
123.997
48.3748C122.847
48.3748
122.097
48.9448
121.847
49.8648L122.957
50.1648C123.067
49.6848
123.417
49.3748
123.987
49.3748C124.577
49.3748
124.957
49.6948
124.957
50.2248V50.5248L124.537
50.4248C124.277
50.3548
124.007
50.3248
123.727
50.3248C122.607
50.3248
121.877
50.8348
121.877
51.8448ZM124.957
51.4648C124.957
52.0148
124.537
52.3348
123.947
52.3348C123.437
52.3348
123.177
52.1148
123.177
51.7648C123.177
51.3948
123.457
51.1948
123.927
51.1948C124.117
51.1948
124.367
51.2448
124.667
51.3348L124.957
51.4248V51.4648ZM127.649
48.4748V53.2148H128.929V50.1648C128.929
49.6848
129.189
49.4148
129.589
49.4148C129.949
49.4148
130.189
49.6548
130.189
50.0048C130.189
50.1348
130.179
50.2548
130.149
50.3648H131.359C131.409
50.1748
131.429
49.9848
131.429
49.7748C131.429
48.9648
130.919
48.3748
130.069
48.3748C129.519
48.3748
129.129
48.6448
128.929
49.0848V48.4748H127.649ZM133.146
51.6148C133.146
52.6548
133.776
53.3148
134.926
53.3148C135.926
53.3148
136.566
52.7348
136.696
51.7448L135.516
51.5248C135.476
51.9648
135.286
52.1848
134.956
52.1848C134.636
52.1848
134.416
51.9748
134.416
51.6148V49.5248H136.196V48.4748H134.416V46.9148H133.146V48.4748H132.166V49.5248H133.146V51.6148ZM137.788
48.4748V53.2148H139.068V48.4748H137.788ZM137.748
47.7648H139.118V46.4148H137.748V47.7648ZM140.268
50.8648C140.268
52.3348
141.208
53.3148
142.548
53.3148C143.218
53.3148
143.758
53.0248
144.068
52.5348V53.2148H145.298V46.2148H144.018V49.1848C143.728
48.6948
143.188
48.3748
142.518
48.3748C141.218
48.3748
140.268
49.3748
140.268
50.8648ZM144.068
50.8648C144.068
51.7048
143.498
52.2248
142.808
52.2248C142.098
52.2248
141.548
51.7048
141.548
50.8648C141.548
50.0148
142.098
49.4748
142.808
49.4748C143.498
49.4748
144.068
50.0148
144.068
50.8648ZM146.495
50.8448C146.495
52.2548
147.545
53.3148
148.975
53.3148C150.405
53.3148
151.455
52.2548
151.455
50.8448C151.455
49.4248
150.415
48.3748
148.975
48.3748C147.535
48.3748
146.495
49.4248
146.495
50.8448ZM150.205
50.8448C150.205
51.6348
149.685
52.1648
148.975
52.1648C148.265
52.1648
147.745
51.6348
147.745
50.8448C147.745
50.0548
148.265
49.5248
148.975
49.5248C149.685
49.5248
150.205
50.0548
150.205
50.8448ZM152.659
48.4748V53.2148H153.939V50.1648C153.939
49.6848
154.199
49.4148
154.599
49.4148C154.959
49.4148
155.199
49.6548
155.199
50.0048C155.199
50.1348
155.189
50.2548
155.159
50.3648H156.369C156.419
50.1748
156.439
49.9848
156.439
49.7748C156.439
48.9648
155.929
48.3748
155.079
48.3748C154.529
48.3748
154.139
48.6448
153.939
49.0848V48.4748H152.659ZM108.88
63.8448C108.88
64.7548
109.62
65.3148
110.44
65.3148C111.21
65.3148
111.7
65.0348
112.01
64.4848V65.2148H113.18V62.2948C113.18
61.0548
112.44
60.3748
111
60.3748C109.85
60.3748
109.1
60.9448
108.85
61.8648L109.96
62.1648C110.07
61.6848
110.42
61.3748
110.99
61.3748C111.58
61.3748
111.96
61.6948
111.96
62.2248V62.5248L111.54
62.4248C111.28
62.3548
111.01
62.3248
110.73
62.3248C109.61
62.3248
108.88
62.8348
108.88
63.8448ZM111.96
63.4648C111.96
64.0148
111.54
64.3348
110.95
64.3348C110.44
64.3348
110.18
64.1148
110.18
63.7648C110.18
63.3948
110.46
63.1948
110.93
63.1948C111.12
63.1948
111.37
63.2448
111.67
63.3348L111.96
63.4248V63.4648ZM114.253
64.0448C114.583
64.8548
115.313
65.3148
116.403
65.3148C117.543
65.3148
118.403
64.6748
118.403
63.7848C118.403
62.9448
117.923
62.4848
116.713
62.2948C115.913
62.1748
115.663
62.0548
115.663
61.7748C115.663
61.5348
115.943
61.3648
116.333
61.3648C116.723
61.3648
117.013
61.5448
117.133
61.9048L118.103
61.3748C117.813
60.7448
117.293
60.3748
116.303
60.3748C115.343
60.3748
114.493
60.9448
114.493
61.7648C114.493
62.5648
115.083
63.0248
116.113
63.1848C116.963
63.3048
117.213
63.4748
117.213
63.8048C117.213
64.0748
116.933
64.2648
116.443
64.2648C115.873
64.2648
115.473
64.0148
115.253
63.5148L114.253
64.0448ZM119.609
60.4748V65.2148H120.889V60.4748H119.609ZM119.569
59.7648H120.939V58.4148H119.569V59.7648ZM122.339
65.8648C122.669
66.8148
123.429
67.2948
124.589
67.2948C126.189
67.2948
127.039
66.3148
127.039
64.7848V60.4748H125.769V61.0348C125.459
60.5948
124.989
60.3748
124.339
60.3748C123.109
60.3748
122.089
61.3348
122.089
62.8348C122.089
64.2248
123.029
65.1848
124.249
65.1848C124.979
65.1848
125.479
64.9348
125.769
64.4348V64.8848C125.769
65.7248
125.389
66.2248
124.619
66.2248C124.029
66.2248
123.619
65.9148
123.469
65.3648L122.339
65.8648ZM125.809
62.8048C125.809
63.5648
125.289
64.1148
124.599
64.1148C123.889
64.1148
123.369
63.6248
123.369
62.8348C123.369
61.9948
123.889
61.4748
124.579
61.4748C125.289
61.4748
125.809
62.0048
125.809
62.8048ZM129.788
65.2148V62.5948C129.788
61.8448
130.198
61.4148
130.768
61.4148C131.318
61.4148
131.658
61.8148
131.658
62.4648V65.2148H132.938V62.1148C132.938
61.0248
132.258
60.3748
131.228
60.3748C130.628
60.3748
130.098
60.6148
129.788
61.0948V60.4748H128.508V65.2148H129.788ZM134.163
63.8448C134.163
64.7548
134.903
65.3148
135.723
65.3148C136.493
65.3148
136.983
65.0348
137.293
64.4848V65.2148H138.463V62.2948C138.463
61.0548
137.723
60.3748
136.283
60.3748C135.133
60.3748
134.383
60.9448
134.133
61.8648L135.243
62.1648C135.353
61.6848
135.703
61.3748
136.273
61.3748C136.863
61.3748
137.243
61.6948
137.243
62.2248V62.5248L136.823
62.4248C136.563
62.3548
136.293
62.3248
136.013
62.3248C134.893
62.3248
134.163
62.8348
134.163
63.8448ZM137.243
63.4648C137.243
64.0148
136.823
64.3348
136.233
64.3348C135.723
64.3348
135.463
64.1148
135.463
63.7648C135.463
63.3948
135.743
63.1948
136.213
63.1948C136.403
63.1948
136.653
63.2448
136.953
63.3348L137.243
63.4248V63.4648ZM139.665
62.8648C139.665
64.3348
140.605
65.3148
141.945
65.3148C142.615
65.3148
143.155
65.0248
143.465
64.5348V65.2148H144.695V58.2148H143.415V61.1848C143.125
60.6948
142.585
60.3748
141.915
60.3748C140.615
60.3748
139.665
61.3748
139.665
62.8648ZM143.465
62.8648C143.465
63.7048
142.895
64.2248
142.205
64.2248C141.495
64.2248
140.945
63.7048
140.945
62.8648C140.945
62.0148
141.495
61.4748
142.205
61.4748C142.895
61.4748
143.465
62.0148
143.465
62.8648ZM145.892
62.8448C145.892
64.2548
146.942
65.3148
148.372
65.3148C149.802
65.3148
150.852
64.2548
150.852
62.8448C150.852
61.4248
149.812
60.3748
148.372
60.3748C146.932
60.3748
145.892
61.4248
145.892
62.8448ZM149.602
62.8448C149.602
63.6348
149.082
64.1648
148.372
64.1648C147.662
64.1648
147.142
63.6348
147.142
62.8448C147.142
62.0548
147.662
61.5248
148.372
61.5248C149.082
61.5248
149.602
62.0548
149.602
62.8448Z"
        fill="#0F5945"
      />
      <path
        d="M192.21
53.2148V53.0748C191.93
52.7648
191.89
52.4248
191.86
51.6248C191.83
50.6948
191.46
50.1848
190.75
49.9148C191.66
49.6048
192.15
48.9848
192.15
48.1148C192.15
47.0648
191.31
46.2148
189.92
46.2148H186.72V53.2148H187.64V50.2548H189.47C190.36
50.2548
190.83
50.6148
190.84
51.6148C190.85
52.4948
190.92
52.8948
191.08
53.2148H192.21ZM187.64
49.3848V47.0848H189.84C190.68
47.0848
191.18
47.5048
191.18
48.2348C191.18
48.9548
190.68
49.3848
189.84
49.3848H187.64ZM196.932
51.4948C196.742
52.1748
196.292
52.5548
195.562
52.5548C194.722
52.5548
194.062
52.0048
194.052
50.9748H197.482C197.522
50.7948
197.542
50.6048
197.542
50.3948C197.542
49.7748
197.352
49.2848
196.962
48.9248C196.572
48.5548
196.062
48.3748
195.432
48.3748C194.082
48.3748
193.172
49.4548
193.172
50.8148C193.172
52.3448
194.172
53.3148
195.562
53.3148C196.652
53.3148
197.362
52.7448
197.682
51.7848L196.932
51.4948ZM194.052
50.3548C194.142
49.6048
194.692
49.1348
195.422
49.1348C196.142
49.1348
196.692
49.5348
196.692
50.2148L196.682
50.3548H194.052ZM198.936
48.4748V55.1948H199.796V52.4748C200.116
53.0048
200.716
53.3148
201.396
53.3148C202.686
53.3148
203.616
52.3148
203.616
50.8448C203.616
49.3748
202.736
48.3748
201.456
48.3748C200.746
48.3748
200.066
48.7948
199.756
49.3648V48.4748H198.936ZM201.256
52.5448C200.516
52.5448
199.756
51.9048
199.756
50.8548C199.756
49.8048
200.516
49.1548
201.256
49.1548C202.036
49.1548
202.756
49.7648
202.756
50.8548C202.756
51.9548
202.036
52.5448
201.256
52.5448ZM204.699
51.8648C204.699
52.3048
204.849
52.6548
205.149
52.9248C205.459
53.1848
205.829
53.3148
206.269
53.3148C207.029
53.3148
207.659
52.9748
207.899
52.4648V53.2148H208.729V50.2448C208.729
49.0748
207.999
48.3748
206.709
48.3748C205.599
48.3748
204.859
48.9548
204.669
49.8648L205.449
50.0848C205.569
49.4748
206.029
49.1048
206.709
49.1048C207.419
49.1048
207.899
49.4848
207.899
50.1748V50.6648L207.469
50.5448C207.149
50.4448
206.809
50.3948
206.459
50.3948C205.429
50.3948
204.699
50.8948
204.699
51.8648ZM207.899
51.4448C207.899
52.1348
207.349
52.5548
206.559
52.5548C205.929
52.5548
205.589
52.2548
205.589
51.8148C205.589
51.3248
205.969
51.0648
206.579
51.0648C206.829
51.0648
207.129
51.1148
207.459
51.2148L207.899
51.3448V51.4448ZM210.315
48.4748V53.2148H211.175V50.1948C211.175
49.5548
211.525
49.1348
212.055
49.1348C212.525
49.1348
212.815
49.4048
212.815
49.8448C212.815
49.9348
212.805
50.0548
212.785
50.1948H213.625C213.665
50.0348
213.685
49.8548
213.685
49.6648C213.685
48.9448
213.155
48.3748
212.385
48.3748C211.815
48.3748
211.385
48.6548
211.175
49.1648V48.4748H210.315ZM215.47
51.8048C215.47
52.7548
216.02
53.3148
217.02
53.3148C217.83
53.3148
218.37
52.8448
218.5
51.9948L217.7
51.8148C217.62
52.3348
217.39
52.5548
216.99
52.5548C216.62
52.5548
216.34
52.2948
216.34
51.8448V49.2048H218.04V48.4748H216.34V47.0048H215.47V48.4748H214.53V49.2048H215.47V51.8048ZM219.702
48.4748V53.2148H220.562V48.4748H219.702ZM219.652
47.5948H220.612V46.5848H219.652V47.5948ZM221.859
50.8748C221.859
52.3248
222.769
53.3148
224.079
53.3148C224.799
53.3148
225.399
52.9348
225.719
52.3648V53.2148H226.549V46.2148H225.679V49.3348C225.369
48.7448
224.789
48.3748
224.059
48.3748C222.769
48.3748
221.859
49.4048
221.859
50.8748ZM225.719
50.8648C225.719
51.9248
224.959
52.5448
224.219
52.5448C223.439
52.5448
222.719
51.9348
222.719
50.8648C222.719
49.8048
223.449
49.1548
224.219
49.1548C224.959
49.1548
225.719
49.8148
225.719
50.8648ZM227.842
50.8448C227.842
52.2448
228.812
53.3148
230.242
53.3148C231.662
53.3148
232.652
52.2448
232.652
50.8448C232.652
49.4448
231.682
48.3748
230.242
48.3748C228.812
48.3748
227.842
49.4448
227.842
50.8448ZM231.762
50.8448C231.762
51.8448
231.132
52.5348
230.242
52.5348C229.362
52.5348
228.722
51.8348
228.722
50.8448C228.722
49.8548
229.362
49.1548
230.242
49.1548C230.692
49.1548
231.052
49.3148
231.332
49.6348C231.622
49.9448
231.762
50.3548
231.762
50.8448ZM233.938
48.4748V53.2148H234.798V50.1948C234.798
49.5548
235.148
49.1348
235.678
49.1348C236.148
49.1348
236.438
49.4048
236.438
49.8448C236.438
49.9348
236.428
50.0548
236.408
50.1948H237.248C237.288
50.0348
237.308
49.8548
237.308
49.6648C237.308
48.9448
236.778
48.3748
236.008
48.3748C235.438
48.3748
235.008
48.6548
234.798
49.1648V48.4748H233.938ZM194.08
63.4948C193.89
64.1748
193.44
64.5548
192.71
64.5548C191.87
64.5548
191.21
64.0048
191.2
62.9748H194.63C194.67
62.7948
194.69
62.6048
194.69
62.3948C194.69
61.7748
194.5
61.2848
194.11
60.9248C193.72
60.5548
193.21
60.3748
192.58
60.3748C191.23
60.3748
190.32
61.4548
190.32
62.8148C190.32
64.3448
191.32
65.3148
192.71
65.3148C193.8
65.3148
194.51
64.7448
194.83
63.7848L194.08
63.4948ZM191.2
62.3548C191.29
61.6048
191.84
61.1348
192.57
61.1348C193.29
61.1348
193.84
61.5348
193.84
62.2148L193.83
62.3548H191.2ZM196.945
65.2148V62.6148C196.945
61.7048
197.505
61.1348
198.225
61.1348C198.905
61.1348
199.305
61.5648
199.305
62.3948V65.2148H200.175V62.1348C200.175
61.0248
199.515
60.3748
198.505
60.3748C197.835
60.3748
197.275
60.6948
196.945
61.2448V60.4748H196.085V65.2148H196.945ZM206.646
64.5548C205.806
64.5548
205.016
63.8748
205.016
62.8148C205.016
61.7248
205.816
61.1348
206.546
61.1348C207.316
61.1348
207.816
61.5748
207.966
62.3248L208.756
62.0848C208.526
61.0048
207.706
60.3748
206.556
60.3748C205.266
60.3748
204.176
61.3948
204.176
62.8148C204.176
64.3148
205.176
65.3148
206.666
65.3148C207.856
65.3148
208.716
64.6248
208.986
63.4248L208.216
63.1348C208.026
64.0248
207.566
64.5548
206.646
64.5548ZM209.994
63.8648C209.994
64.3048
210.144
64.6548
210.444
64.9248C210.754
65.1848
211.124
65.3148
211.564
65.3148C212.324
65.3148
212.954
64.9748
213.194
64.4648V65.2148H214.024V62.2448C214.024
61.0748
213.294
60.3748
212.004
60.3748C210.894
60.3748
210.154
60.9548
209.964
61.8648L210.744
62.0848C210.864
61.4748
211.324
61.1048
212.004
61.1048C212.714
61.1048
213.194
61.4848
213.194
62.1748V62.6648L212.764
62.5448C212.444
62.4448
212.104
62.3948
211.754
62.3948C210.724
62.3948
209.994
62.8948
209.994
63.8648ZM213.194
63.4448C213.194
64.1348
212.644
64.5548
211.854
64.5548C211.224
64.5548
210.884
64.2548
210.884
63.8148C210.884
63.3248
211.264
63.0648
211.874
63.0648C212.124
63.0648
212.424
63.1148
212.754
63.2148L213.194
63.3448V63.4448ZM215.61
60.4748V65.2148H216.47V62.3148C216.47
61.5448
216.87
61.1548
217.39
61.1548C217.91
61.1548
218.26
61.4948
218.26
62.1448V65.2148H219.13V62.2648C219.13
61.5348
219.54
61.1548
220.06
61.1548C220.57
61.1548
220.93
61.5148
220.93
62.1048V65.2148H221.79V61.9248C221.79
60.9248
221.23
60.3748
220.35
60.3748C219.75
60.3748
219.21
60.7148
218.96
61.3148C218.8
60.7048
218.32
60.3748
217.75
60.3748C217.12
60.3748
216.7
60.6748
216.47
61.1448V60.4748H215.61ZM223.38
60.4748V65.2148H224.24V60.4748H223.38ZM223.33
59.5948H224.29V58.5848H223.33V59.5948ZM226.687
65.2148V62.6148C226.687
61.7048
227.247
61.1348
227.967
61.1348C228.647
61.1348
229.047
61.5648
229.047
62.3948V65.2148H229.917V62.1348C229.917
61.0248
229.257
60.3748
228.247
60.3748C227.577
60.3748
227.017
60.6948
226.687
61.2448V60.4748H225.827V65.2148H226.687ZM231.217
62.8448C231.217
64.2448
232.187
65.3148
233.617
65.3148C235.037
65.3148
236.027
64.2448
236.027
62.8448C236.027
61.4448
235.057
60.3748
233.617
60.3748C232.187
60.3748
231.217
61.4448
231.217
62.8448ZM235.137
62.8448C235.137
63.8448
234.507
64.5348
233.617
64.5348C232.737
64.5348
232.097
63.8348
232.097
62.8448C232.097
61.8548
232.737
61.1548
233.617
61.1548C234.067
61.1548
234.427
61.3148
234.707
61.6348C234.997
61.9448
235.137
62.3548
235.137
62.8448Z"
        fill="#AEAEAE"
      />
      <path
        d="M263.916
48.1948V49.2448H264.956V48.1948H263.916ZM264.006
49.9448V55.1948H264.866V49.9448H264.006ZM266.629
46.2148V53.2148H271.659V52.3448H267.549V50.0248H271.259V49.1548H267.549V47.0848H271.559V46.2148H266.629ZM273.117
53.2148H273.977V46.2148H273.117V53.2148ZM278.266
48.4748V53.2148H279.126V50.1948C279.126
49.5548
279.476
49.1348
280.006
49.1348C280.476
49.1348
280.766
49.4048
280.766
49.8448C280.766
49.9348
280.756
50.0548
280.736
50.1948H281.576C281.616
50.0348
281.636
49.8548
281.636
49.6648C281.636
48.9448
281.106
48.3748
280.336
48.3748C279.766
48.3748
279.336
48.6548
279.126
49.1648V48.4748H278.266ZM286.302
51.4948C286.112
52.1748
285.662
52.5548
284.932
52.5548C284.092
52.5548
283.432
52.0048
283.422
50.9748H286.852C286.892
50.7948
286.912
50.6048
286.912
50.3948C286.912
49.7748
286.722
49.2848
286.332
48.9248C285.942
48.5548
285.432
48.3748
284.802
48.3748C283.452
48.3748
282.542
49.4548
282.542
50.8148C282.542
52.3448
283.542
53.3148
284.932
53.3148C286.022
53.3148
286.732
52.7448
287.052
51.7848L286.302
51.4948ZM283.422
50.3548C283.512
49.6048
284.062
49.1348
284.792
49.1348C285.512
49.1348
286.062
49.5348
286.062
50.2148L286.052
50.3548H283.422ZM288.307
48.4748V55.1948H289.167V52.4748C289.487
53.0048
290.087
53.3148
290.767
53.3148C292.057
53.3148
292.987
52.3148
292.987
50.8448C292.987
49.3748
292.107
48.3748
290.827
48.3748C290.117
48.3748
289.437
48.7948
289.127
49.3648V48.4748H288.307ZM290.627
52.5448C289.887
52.5448
289.127
51.9048
289.127
50.8548C289.127
49.8048
289.887
49.1548
290.627
49.1548C291.407
49.1548
292.127
49.7648
292.127
50.8548C292.127
51.9548
291.407
52.5448
290.627
52.5448ZM294.069
51.8648C294.069
52.3048
294.219
52.6548
294.519
52.9248C294.829
53.1848
295.199
53.3148
295.639
53.3148C296.399
53.3148
297.029
52.9748
297.269
52.4648V53.2148H298.099V50.2448C298.099
49.0748
297.369
48.3748
296.079
48.3748C294.969
48.3748
294.229
48.9548
294.039
49.8648L294.819
50.0848C294.939
49.4748
295.399
49.1048
296.079
49.1048C296.789
49.1048
297.269
49.4848
297.269
50.1748V50.6648L296.839
50.5448C296.519
50.4448
296.179
50.3948
295.829
50.3948C294.799
50.3948
294.069
50.8948
294.069
51.8648ZM297.269
51.4448C297.269
52.1348
296.719
52.5548
295.929
52.5548C295.299
52.5548
294.959
52.2548
294.959
51.8148C294.959
51.3248
295.339
51.0648
295.949
51.0648C296.199
51.0648
296.499
51.1148
296.829
51.2148L297.269
51.3448V51.4448ZM299.686
48.4748V53.2148H300.546V50.1948C300.546
49.5548
300.896
49.1348
301.426
49.1348C301.896
49.1348
302.186
49.4048
302.186
49.8448C302.186
49.9348
302.176
50.0548
302.156
50.1948H302.996C303.036
50.0348
303.056
49.8548
303.056
49.6648C303.056
48.9448
302.526
48.3748
301.756
48.3748C301.186
48.3748
300.756
48.6548
300.546
49.1648V48.4748H299.686ZM304.84
51.8048C304.84
52.7548
305.39
53.3148
306.39
53.3148C307.2
53.3148
307.74
52.8448
307.87
51.9948L307.07
51.8148C306.99
52.3348
306.76
52.5548
306.36
52.5548C305.99
52.5548
305.71
52.2948
305.71
51.8448V49.2048H307.41V48.4748H305.71V47.0048H304.84V48.4748H303.9V49.2048H304.84V51.8048ZM309.072
48.4748V53.2148H309.932V48.4748H309.072ZM309.022
47.5948H309.982V46.5848H309.022V47.5948ZM311.23
50.8748C311.23
52.3248
312.14
53.3148
313.45
53.3148C314.17
53.3148
314.77
52.9348
315.09
52.3648V53.2148H315.92V46.2148H315.05V49.3348C314.74
48.7448
314.16
48.3748
313.43
48.3748C312.14
48.3748
311.23
49.4048
311.23
50.8748ZM315.09
50.8648C315.09
51.9248
314.33
52.5448
313.59
52.5448C312.81
52.5448
312.09
51.9348
312.09
50.8648C312.09
49.8048
312.82
49.1548
313.59
49.1548C314.33
49.1548
315.09
49.8148
315.09
50.8648ZM317.212
50.8448C317.212
52.2448
318.182
53.3148
319.612
53.3148C321.032
53.3148
322.022
52.2448
322.022
50.8448C322.022
49.4448
321.052
48.3748
319.612
48.3748C318.182
48.3748
317.212
49.4448
317.212
50.8448ZM321.132
50.8448C321.132
51.8448
320.502
52.5348
319.612
52.5348C318.732
52.5348
318.092
51.8348
318.092
50.8448C318.092
49.8548
318.732
49.1548
319.612
49.1548C320.062
49.1548
320.422
49.3148
320.702
49.6348C320.992
49.9448
321.132
50.3548
321.132
50.8448ZM323.309
48.4748V53.2148H324.169V50.1948C324.169
49.5548
324.519
49.1348
325.049
49.1348C325.519
49.1348
325.809
49.4048
325.809
49.8448C325.809
49.9348
325.799
50.0548
325.779
50.1948H326.619C326.659
50.0348
326.679
49.8548
326.679
49.6648C326.679
48.9448
326.149
48.3748
325.379
48.3748C324.809
48.3748
324.379
48.6548
324.169
49.1648V48.4748H323.309ZM284.895
65.2148H285.755V58.2148H284.895V65.2148ZM287.342
65.2148H288.202V58.2148H287.342V65.2148ZM293.259
63.4948C293.069
64.1748
292.619
64.5548
291.889
64.5548C291.049
64.5548
290.389
64.0048
290.379
62.9748H293.809C293.849
62.7948
293.869
62.6048
293.869
62.3948C293.869
61.7748
293.679
61.2848
293.289
60.9248C292.899
60.5548
292.389
60.3748
291.759
60.3748C290.409
60.3748
289.499
61.4548
289.499
62.8148C289.499
64.3448
290.499
65.3148
291.889
65.3148C292.979
65.3148
293.689
64.7448
294.009
63.7848L293.259
63.4948ZM290.379
62.3548C290.469
61.6048
291.019
61.1348
291.749
61.1348C292.469
61.1348
293.019
61.5348
293.019
62.2148L293.009
62.3548H290.379ZM295.244
65.8348C295.554
66.8048
296.274
67.2948
297.424
67.2948C298.144
67.2948
298.704
67.0648
299.094
66.6048C299.484
66.1448
299.684
65.5448
299.684
64.7948V60.4748H298.814V61.1848C298.464
60.6448
297.934
60.3748
297.214
60.3748C295.964
60.3748
294.974
61.3848
294.974
62.8648C294.974
64.2848
295.964
65.2148
297.164
65.2148C297.954
65.2148
298.534
64.8548
298.814
64.3248V64.8548C298.814
65.9248
298.344
66.5348
297.424
66.5348C296.684
66.5348
296.214
66.1948
296.024
65.5048L295.244
65.8348ZM298.854
62.8048C298.854
63.7748
298.214
64.4548
297.354
64.4548C296.474
64.4548
295.834
63.8448
295.834
62.8748C295.834
61.7948
296.474
61.1348
297.354
61.1348C298.214
61.1348
298.854
61.8448
298.854
62.8048ZM300.985
62.8448C300.985
64.2448
301.955
65.3148
303.385
65.3148C304.805
65.3148
305.795
64.2448
305.795
62.8448C305.795
61.4448
304.825
60.3748
303.385
60.3748C301.955
60.3748
300.985
61.4448
300.985
62.8448ZM304.905
62.8448C304.905
63.8448
304.275
64.5348
303.385
64.5348C302.505
64.5348
301.865
63.8348
301.865
62.8448C301.865
61.8548
302.505
61.1548
303.385
61.1548C303.835
61.1548
304.195
61.3148
304.475
61.6348C304.765
61.9448
304.905
62.3548
304.905
62.8448ZM302.975
59.6648H303.805L305.015
58.4748V58.3348H303.845L302.975
59.6648ZM307.162
63.4648H308.022V58.2148H307.162V63.4648ZM307.072
65.2148H308.112V64.1648H307.072V65.2148Z"
        fill="#AEAEAE"
      />
    </svg>
  );
}

DeliveryStepper3.propTypes = {
  className: PropTypes.string,
};

DeliveryStepper3.defaultProps = {
  className: '',
};

export default DeliveryStepper3;
