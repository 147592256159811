const initialState = {
  redirectUrl: null,
  token: null,
  refreshToken: null,
};

const storeReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case 'REDIRECT_URL':
      return {
        ...state,
        redirectUrl: data,
      };
    case 'USER_TOKEN':
      return {
        ...state,
        token: data,
      };
    case 'REFRESH_TOKEN':
      return {
        ...state,
        refreshToken: data,
      };
    default:
      return state;
  }
};

export default storeReducer;
