import PropTypes from 'prop-types';

function ItemsGroup(props) {
  const {
    id,
    title,
    offsetTop,
    children,
  } = props;

  return (
    <div className="relative flex flex-col px-4 mt-6 z-0">
      <div className="absolute" style={{ top: -offsetTop }} id={id} />
      <h2 className="text-xl font-bold capitalize text-baco-primary m-0 mb-4">
        {title}
      </h2>
      <div className="flex flex-wrap -mx-3">
        {children}
      </div>
    </div>
  );
}

ItemsGroup.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node.isRequired,
  offsetTop: PropTypes.number,
  children: PropTypes.node.isRequired,
};
ItemsGroup.defaultProps = {
  id: null,
  offsetTop: 0,
};

export default ItemsGroup;
