import PropTypes from 'prop-types';

function IcoStore(props) {
  const { className } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      width="40"
      height="40"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5125 15.5V15.25H2.2625H2C1.5988 15.25 1.28224 15.1012 1.03021 14.7962L1.02999
      14.796C0.78045 14.4949 0.697253 14.1547 0.78215 13.7515L0.782645 13.749L2.2823 6.25077C2.34569
      5.95651 2.49047 5.71945 2.72081 5.53099C2.95188 5.34193 3.20866 5.25 3.5 5.25H25.025C25.3163
      5.25 25.5731 5.34193 25.8042 5.53099C26.0345 5.71943 26.1793 5.95647 26.2427 6.25068C26.2428
      6.25125 26.2429 6.25182 26.2431 6.25238L27.7423 13.749L27.7429 13.7515C27.8278 14.1547 27.7446
      14.4949 27.495 14.796L27.4948 14.7962C27.2428 15.1012 26.9262 15.25 26.525
      15.25H26.2625H26.0125V15.5V23C26.0125 23.3619 25.8926 23.6531 25.654 23.8909L25.6534
      23.8915C25.4156 24.1301 25.1244 24.25 24.7625 24.25C24.4006 24.25 24.1094 24.1301 23.8716
      23.8915L23.871 23.8909C23.6324 23.6531 23.5125 23.3619
      23.5125 23V15.5V15.25H23.2625H17.2625H17.0125V15.5V23C17.0125
      23.3622 16.8929 23.6535 16.6552 23.8912C16.4163 24.1301 16.1244 24.25 15.7625 24.25H3.7625C3.40059 24.25 3.10867
      24.1301 2.86978 23.8912C2.63207 23.6535 2.5125 23.3622 2.5125 23V15.5ZM5.0125
      21.5V21.75H5.2625H14.2625H14.5125V21.5V15.5V15.25H14.2625H5.2625H5.0125V15.5V21.5ZM3.7625 3.25C3.40047
      3.25 3.10855 3.13052 2.86978 2.89272C2.63198 2.65395 2.5125 2.36203 2.5125 2C2.5125 1.63797
      2.63198 1.34605 2.86978 1.10728C3.10855 0.869481 3.40047 0.75 3.7625 0.75H24.7625C25.1247 0.75 25.416
      0.869565 25.6537 1.10728C25.8926 1.34617 26.0125 1.63809 26.0125 2C26.0125 2.36191 25.8926 2.65383
      25.6537 2.89272C25.416 3.13044 25.1247 3.25 24.7625 3.25H3.7625Z"
      />
    </svg>
  );
}

IcoStore.propTypes = {
  className: PropTypes.string,
};

IcoStore.defaultProps = {
  className: '',
};

export default IcoStore;
