import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import InputText from '.';

function InputTextControlled(props) {
  const { control } = useFormContext();
  const {
    type,
    label,
    name,
    placeholder,
    helper,
    fullWidth,
    size,
    classes,
    readOnly,
    disabled,
    direction,
    multiValueInput,
    ...args
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        fieldState,
        field: {
          onChange,
          onBlur,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          direction={direction}
          className={clsx(classes)}
          fieldState={fieldState}
          multiValueInput={multiValueInput}
          name={name}
        >
          <InputText
            {...args}
            type={type}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            size={size}
            readOnly={readOnly}
            disabled={disabled}
            fullWidth={fullWidth}
            fieldState={fieldState}
            multiValueInput={multiValueInput}
          />
        </InputContainer>
      )}
    />
  );
}

InputTextControlled.propTypes = {
  label: PropTypes.node,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'tel']),
  name: PropTypes.string.isRequired,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  placeholder: PropTypes.string,
  helper: PropTypes.node,
  size: PropTypes.string,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  multiValueInput: PropTypes.bool,
};

InputTextControlled.defaultProps = {
  label: null,
  type: 'text',
  startAdornment: null,
  endAdornment: null,
  placeholder: '',
  helper: null,
  size: 'md',
  classes: [],
  readOnly: false,
  disabled: false,
  fullWidth: false,
  direction: 'column',
  multiValueInput: false,
};

export default InputTextControlled;
