import PropTypes from 'prop-types';

function DeliveryErrorBg(props) {
  const { className } = props;

  return (
    <svg
      className={className}
      width="207"
      height="101"
      viewBox="0 0 207 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4557 90.8564C27.4557 90.8564 17.4768 91.6479 17.597 94.5034C17.7173 97.3588 38.667
          96.4471 38.6971 97.6994C38.7271 98.9518 32.5554 98.501 32.7257 99.7533C32.8961 101.006
          54.3668 99.5229 54.1364 96.7877C53.9059 94.0525 37.3946 91.5778 37.3946 91.5778"
        fill="#414141"
      />
      <path
        d="M80.3862 45.3809C66.3796 49.138 18.3184 46.6132 18.3184 51.9133C18.3184 57.2133 68.5337
          50.7911 69.5356 57.3736C70.5375 63.9561 0.825152 62.9843 0.624771 70.7691C0.424391 78.5539
          116.284 69.166 116.284 82.3411C116.284 88.5328 95.1843 86.7995 95.1843 91.9894C95.1843 97.1793
          170.257 98.0509 170.257 90.737C170.257 85.1865 145.64 87.7413 145.64 81.75C145.64 75.7586 206.375
          71.5606 206.375 57.7844C206.375 49.3985 170.257 45.3809 170.257 45.3809"
        fill="#414141"
      />
      <path
        d="M125.322 1.47754C100.504 1.47754 80.3862 21.1349 80.3862 45.391C80.3862 51.4224 100.504 56.3117
          125.322 56.3117C150.139 56.3117 170.257 51.4224 170.257 45.391C170.257 21.1449 150.139 1.47754
          125.322 1.47754Z"
        fill="white"
        stroke="#414141"
        strokeWidth="1.02875"
        strokeMiterlimit="10"
      />
      <path
        d="M125.321 9.95337C135.807 9.95337 144.308 7.88548 144.308 5.3346C144.308 2.78372 135.807 0.71582
          125.321 0.71582C114.836 0.71582 106.335 2.78372 106.335 5.3346C106.335 7.88548 114.836 9.95337
          125.321 9.95337Z"
        fill="white"
        stroke="#414141"
        strokeWidth="1.02875"
        strokeMiterlimit="10"
      />
      <path
        d="M12.9782 51.6327C12.7979 49.5989 1.38621 49.699 1.57657 51.9132C1.76694
          54.1275 13.2087 54.2978 12.9782 51.6327Z"
        fill="#414141"
      />
      <path
        d="M189.984 89.5242C189.764 86.9593 175.387 87.0795 175.627 89.8748C175.867
          92.6701 190.275 92.8705 189.984 89.5242Z"
        fill="#414141"
      />
      <path
        d="M50.7097 44.6091C50.7097 45.05 49.818 45.4107 48.716 45.4107C47.6139
          45.4107 46.7222 44.6091 46.7222 44.6091C46.7222 44.6091 47.6139 43.8076
          48.716 43.8076C49.818 43.8076 50.7097 44.1683 50.7097 44.6091Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M161.6 81.199C161.6 81.6398 160.709 82.0005 159.607 82.0005C158.504
          82.0005 157.613 81.199 157.613 81.199C157.613 81.199 158.504 80.3975
          159.607 80.3975C160.709 80.3975 161.6 80.7581 161.6 81.199Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M166.57 82.7918C166.57 83.2326 165.678 83.5933 164.576 83.5933C163.474
          83.5933 162.582 82.7918 162.582 82.7918C162.582 82.7918 163.474 81.9902
          164.576 81.9902C165.678 81.9902 166.57 82.3509 166.57 82.7918Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M176.408 95.2654C176.408 95.7062 175.517 96.0669 174.415 96.0669C173.313 96.0669 172.421
          95.2654 172.421 95.2654C172.421 95.2654 173.313 94.4639 174.415 94.4639C175.517 94.4639 176.408
          94.8246 176.408 95.2654Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M51.2208 58.0252C51.2208 58.466 50.3292 58.8267 49.2271 58.8267C48.125 58.8267 47.2333
          58.0252 47.2333 58.0252C47.2333 58.0252 48.125 57.2236 49.2271 57.2236C50.3292 57.2236
          51.2208 57.5843 51.2208 58.0252Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M158.655 83.1317C158.575 82.7008 159.396 82.1899 160.478 81.9895C161.56 81.7891 162.582
          82.4203 162.582 82.4203C162.582 82.4203 161.841 83.3621 160.759 83.5625C159.677 83.7629
          158.735 83.5625 158.655 83.1317Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M62.1715 42.0343C62.2617 41.6035 63.2135 41.4432 64.2956 41.6736C65.3776 41.904 66.079
          42.8759 66.079 42.8759C66.079 42.8759 65.037 43.467 63.9549 43.2366C62.8729 43.0061 62.0814
          42.4651 62.1715 42.0343Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M69.4353 44.6997C69.4153 45.1406 68.5036 45.4612 67.4015 45.4111C66.2994 45.361 65.4377
          44.5194 65.4377 44.5194C65.4377 44.5194 66.3695 43.7579 67.4716 43.808C68.5737 43.8581 69.4453
          44.2589 69.4353 44.6997Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M38.6971 58.4855C38.7872 58.0546 39.739 57.8943 40.8211 58.1248C41.9031 58.3552 42.6045
          59.3271 42.6045 59.3271C42.6045 59.3271 41.5625 59.9182 40.4804 59.6877C39.3984 59.4573
          38.6069 58.9163 38.6971 58.4855Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M31.9542 93.4521C34.8697 93.8027 37.5648 92.2097 38.7471 89.6849L63.4039 92.6606C63.995
          92.7307 64.536 92.3099 64.6061 91.7188C64.6763 91.1277 64.2555 90.5866 63.6644 90.5165L39.3382
          87.5809C39.4484 85.9077 36.7032 84.2145 33.1264 83.7837C29.5496 83.3529 26.3736 84.3648 26.1732
          86.0681C25.7324 89.705 28.3273 93.0112 31.9642 93.4521H31.9542Z"
        fill="white"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
      />
      <path
        d="M27.4657 86.4287C27.3755 87.7713 29.5697 89.1138 32.4351 89.4645C35.3006 89.8151 37.8354
          88.9936 37.9957 87.641"
        stroke="#414141"
        strokeWidth="0.514377"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_3449_27767">
          <rect
            width="205.751"
            height="100"
            fill="white"
            transform="translate(0.624756 0.214844)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

DeliveryErrorBg.propTypes = {
  className: PropTypes.string,
};

DeliveryErrorBg.defaultProps = {
  className: '',
};

export default DeliveryErrorBg;
