import clsx from 'clsx';
import PropTypes from 'prop-types';

function ViewWithAd(props) {
  const {
    advertisement,
    children,
    color,
    classes,
  } = props;

  return (
    <div className="absolute flex flex-col lg:flex-row top-0 left-0 w-full h-full overflow-auto">
      <div
        className={
          clsx(
            'relative z-0 w-full h-full',
            classes.advertisement || '',
            {
              'bg-baco-primary': color === 'primary',
              'bg-baco-secondary': color === 'secondary',
            },
          )
        }
      >
        {advertisement}
      </div>
      <div
        className={
          clsx(
            'relative z-10 box-content bg-white w-full h-auto lg:w-auto lg:h-full',
            'rounded-t-3xl lg:rounded-l-3xl lg:rounded-tr-none',
            'pt-6 -mt-6 lg:pl-6 lg:pt-0 lg:-ml-6 lg:-mt-0',
            '[box-shadow:0px_0px_20px_-5px_rgba(0,0,0,0.5)]',
            classes.content || '',
          )
        }
      >
        {children}
      </div>
    </div>
  );
}

ViewWithAd.propTypes = {
  advertisement: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']),
  classes: PropTypes.shape({
    advertisement: PropTypes.string,
    content: PropTypes.string,
  }),
};

ViewWithAd.defaultProps = {
  color: 'secondary',
  classes: {},
};

export default ViewWithAd;
