import PropTypes from 'prop-types';
import clsx from 'clsx';

function TextArea(props) {
  const {
    className,
    size,
    value,
    placeholder,
    onChange,
    fullWidth,
    rows,
    fieldState,
    ...args
  } = props;

  const {
    invalid,
    isDirty,
  } = fieldState;

  return (
    <textarea
      className={
        clsx(
          'disabled:opacity:60 rounded-sm outline outline-0 placeholder:text-gray-400 border transition-colors',
          {
            'px-4 py-2 text-sm': size === 'sm',
            'p-4 text-base': size === 'md',
            'p-6 text-lg': size === 'lg',
            'w-full': fullWidth,
            'bg-gray-100 border-gray-100 text-black': !(invalid && isDirty),
            'bg-baco-error-light border-baco-error-normal text-baco-error-normal': invalid && isDirty,
          },
        )
      }
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      rows={rows}
      {...args}
    />
  );
}

TextArea.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  rows: PropTypes.number,
  fieldState: PropTypes.any,
};
TextArea.defaultProps = {
  className: '',
  size: 'md',
  value: '',
  placeholder: '',
  onChange: () => null,
  fullWidth: false,
  rows: 3,
  fieldState: {},
};

export default TextArea;
