import PropTypes from 'prop-types';

function IcoBike(props) {
  const { className } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      width="40"
      height="40"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1667 3.35714V3.11905H21.9286H19.0714C18.4172 3.11905 17.881 2.58279 17.881 1.92857C17.881 1.27435 18.4172
        0.738095 19.0714 0.738095H21.9286C23.3685 0.738095 24.5476 1.91721 24.5476 3.35714V7.14286C24.5476
        7.73245 24.3513
        8.30807 23.9717 8.77921L23.9712 8.77983L19.0006 14.9932C19.0005 14.9933 19.0004 14.9934 19.0003 14.9935C18.5015
        15.6102 17.7548 15.9762 16.9571 15.9762H11.9286H11.6905V16.2143C11.6905 18.4542 9.88279 20.2619 7.64286
        20.2619C5.40292 20.2619 3.59524 18.4542 3.59524 16.2143V15.9762H3.35714H1.92857C1.27435 15.9762 0.738095
        15.4399 0.738095 14.7857V11.9286C0.738095 8.90292 3.18864 6.45238 6.21429 6.45238H10.5C11.1542 6.45238
        11.6905 6.98864 11.6905 7.64286V13.3571V13.5952H11.9286H16.9571H17.0716L17.1431 13.5059L22.1145
        7.29159L22.1667 7.22638V7.14286V3.35714ZM6.21429 15.9762H5.97619V16.2143C5.97619 17.1315 6.72565
        17.881 7.64286 17.881C8.56007 17.881 9.30952 17.1315 9.30952 16.2143V15.9762H9.07143H6.21429Z"
        fill="white"
        stroke="#0F5945"
        strokeWidth="0.47619"
      />
      <path
        d="M6.21434 2.16681H10.5001C11.1543 2.16681 11.6905 2.70306 11.6905 3.35728C11.6905 4.0115 11.1543 4.54776
        10.5001 4.54776H6.21434C5.56012 4.54776 5.02386 4.0115 5.02386 3.35728C5.02386 2.70306 5.56012 2.16681
        6.21434 2.16681ZM20.7381 16.2144C20.7381 13.9745 22.5458 12.1668 24.7858 12.1668C27.0257 12.1668
        28.8334 13.9745 28.8334 16.2144C28.8334 18.4544 27.0257 20.262 24.7858 20.262C22.5458 20.262 20.7381
        18.4544 20.7381 16.2144ZM23.1191 16.2144C23.1191 17.1316 23.8686 17.8811 24.7858 17.8811C25.703
        17.8811 26.4524 17.1316 26.4524 16.2144C26.4524 15.2972 25.703 14.5478 24.7858 14.5478C23.8686
        14.5478 23.1191 15.2972 23.1191 16.2144Z"
        fill="white"
        stroke="#0F5945"
        strokeWidth="0.47619"
      />
    </svg>
  );
}

IcoBike.propTypes = {
  className: PropTypes.string,
};

IcoBike.defaultProps = {
  className: '',
};

export default IcoBike;
