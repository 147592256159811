/* eslint-disable no-promise-executor-return */
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MotionConfig, motion, useAnimation } from 'framer-motion';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import useDelivery from 'src/hooks/actions/useDelivery';
import DeliveryDetails from 'src/screens/Delivery/DeliveryDetails';
import HeaderWithButton from '../../../components/HeaderWithButton';
import PaymentStatusPopUp from '../../../components/PaymentStatusPopUp';
import Portal from '../../../components/Portal';
import { getEnrollmentPaymentSession, getPaymentSession } from '../../../services/api';
import PaymentMethod from './PaymentMethod';
import UserInformation from './UserInformation';
import Overview from './Overview';
import { processEnrollmentCheckout, processYunoCheckout } from '../../../services/yuno';
import PickUp from './PickUp';
import AnimatedStepper from '../../../templates/AnimatedStepper';
import config from '../../../config';
import analytics from '../../../services/analytics';
import { useStore } from '../../../hooks/actions/useStore';
import { useShoppingCart } from '../../../hooks/actions/useShoppingCart';
import { useFcm } from '../../../hooks/actions/useFcm';
import { useScheduler } from '../../../contexts/Scheduler.context';
import { useLogin } from '../../../contexts/Login.context';

function Cart() {
  const { selectedStore } = useStore();
  const { token } = useLogin();
  const {
    items, scheduler, updateSession, clearSession, markToCleanCart,
    setTotalAndDiscount, updateQuantityProduct, totalCart, totalWithDiscountCart,
    preorder, schedule, discount,
  } = useShoppingCart();

  const { fcm } = useFcm();
  const opacityAnimation = useAnimation();
  const drawerAnimation = useAnimation();
  const { timers } = useScheduler();
  const { deliveryData } = useDelivery();

  const { minSchedule, maxSchedule } = timers;
  const [searchParams] = useSearchParams({ step: 0 });
  const navigate = useNavigate();
  const [openDrag, setOpenDrag] = useState(true);
  const [step, setStep] = useState(0);
  const [preStep, setPreStep] = useState();
  const [data, setData] = useState({
    items,
    discount,
    method_payments: [],
    enrollment_payment_methods: [],
    isEnrollment: false,
    payment_method: '',
    type: deliveryData.isDelivery ? config.deliveryType.delivery : config.deliveryType.pickup,
    schedule: minSchedule,
    pick_method: config.pickupMethods.superFast,
    customer: {
      first_name: '',
      last_name: '',
      email: '',
      phone: {
        country_code: '+57',
        number: '',
      },
      fcm_token: null,
    },
    session: {},
    payment: {},
    total: totalCart,
    totalWithDiscount: totalWithDiscountCart,
  });
  const [headerTitle, setHeaderTitle] = useState('');
  // updateDelivery({ orderType: data.type });
  // console.log('order_type: ', deliveryData.orderType);

  useEffect(() => {
    let title = '';
    if (step === 0) {
      title = 'Carrito';
    }
    if (step === 1) {
      if (deliveryData.isDelivery) title = 'Detalle de entrega';
      else title = 'Detalle de recogida';
    }
    if (step === 3) {
      title = 'Método de pago';
    }

    setHeaderTitle(title);
  }, [step]);

  useEffect(() => setData({ ...data, items }), [items.length]);

  useEffect(() => {
    opacityAnimation.start({
      opacity: openDrag ? [0, 1] : [1, 0],
      transition: {
        times: [0, 0.5],
      },
    });

    drawerAnimation.start({
      right: openDrag ? [-576, 0] : [0, -576],
      transition: {
        times: [0, 0.5],
      },
    });

    if (!openDrag) {
      setTimeout(() => {
        if (selectedStore.storeId) {
          navigate(`/store/${selectedStore.storeId}`);
        } else {
          navigate(-1);
        }
      }, 500);
    }
  }, [openDrag]);

  useEffect(() => {
    if (data.session?.order_id) {
      updateSession(data);
    }
  }, [data.session]);

  useEffect(() => {
    try {
      if (token) {
        const stepNumber = Number(searchParams.get('step'));

        if (stepNumber > 0) {
          setStep(stepNumber);
        } else {
          setStep(0);
          clearSession();
        }
      }
    } catch (error) {
      setStep(0);
    }
  }, []);

  const {
    mutate: processData,
    isLoading,
  } = useMutation(([service, request]) => {
    switch (service) {
      // Paso 1: Resumen de la orden
      case 'overview':
        setTotalAndDiscount(request.total, request.totalWithDiscount);
        updateQuantityProduct(request.items);
        return request;

      // Paso 2: Elegir la hora de envio
      case 'pickUp':
        return request;

      // Paso 3: Procesar informacion personal del cliente
      case 'customerInformation':
        const customer = {
          ...request,
          phone: {
            country_code: request.phone.country_code,
            number: request.phone.number,
          },
          fcm_token: fcm.token,
          country: 'CO',
        };

        setData((prevData) => ({ ...prevData, customer }));
        setPreStep(step);
        return getPaymentSession({
          items: data.items.map(((item) => ({
            quantity: item.count,
            product: {
              productId: item.id,
              unitPrice: item.price, // agregar descuento
              name: item.name,
              id: item.id,
              discountedPrice: item.discountedPrice,
            },
            notes: '',
            itemGroups: item.modifierGroups.map((modifierGroup) => ({
              name: '-',
              id: modifierGroup.id,
              modifiers: modifierGroup.modifiers.filter(
                (modifier) => !!modifier.value,
              ).map((modifier) => ({
                name: modifier.name,
                productId: item.id,
                unitPrice: modifier.price,
                quantity: typeof modifier.value === 'number' ? modifier.value : 1,
              })),
            })),
            observations: item.observations,
          }))),
          store_id: selectedStore.storeId,
          type: data.type,
          pick_update: (
            !preorder
              ? new Date(+new Date() + config.superFast.minTime)
              : new Date(schedule)
          ).toISOString(),
          preorder,
          address: {
            label: deliveryData.isDelivery ? deliveryData.address : selectedStore.address,
            lat: deliveryData.lat,
            lng: deliveryData.lng,
            detail: deliveryData.addressDetail,
          },
          pay: {
            amount: {
              value: totalCart,
              currency: 'COP',
            },
            payment_description: 'test',
          },
          discounts: data.discount ? [{
            type: data.discount.type,
            name: data.discount.name,
            discountType: 'coupon',
            value: data.discount.value,
          }] : undefined,
        }, data.type);

      // Paso 4: Procesar pago con el metodo de pago elegido
      case 'paymentMethod':
        analytics.sendEvent('checkout init', {
          email: data.customer?.email,
          total: data.total,
          totalWithDiscount: totalWithDiscountCart,
          pickupType: data.pick_method,
        });
        analytics.sendEvent('order', {
          email: data.customer?.email,
          order_id: data.session?.order_id,
        });

        const [paymentMethod, vaultedToken] = request.payment_method.split(':');
        if (!request.isEnrollment) {
          processYunoCheckout({
            onLoading: () => setData((prevData) => ({ ...prevData, payment: { status: 'LOADING' } })),
            paymentMethod,
            vaultedToken,
            methodPayments: data.method_payments,
            storeID: selectedStore.storeId,
            data,
          }).then(() => {
            markToCleanCart();
            navigate(`/order/${data.session.order_id}/status`);
          });

          return new Promise((resolve) => setTimeout(() => resolve(request), 2500));
        }

        const enrollmentMethod = data.enrollment_payment_methods.find((enrollmentPayment) => (
          enrollmentPayment.type === paymentMethod
        ));

        getEnrollmentPaymentSession({
          type: enrollmentMethod.type,
          order_id: data.session.order_id,
          customerSession: enrollmentMethod?.enrollment?.session,
        }).then(() => processEnrollmentCheckout({
          customerSession: enrollmentMethod?.enrollment?.session,
        })).then(() => {
          markToCleanCart();
          navigate(`/order/${data.session.order_id}/status`);
        });

        return new Promise((resolve) => setTimeout(() => resolve(request), 2500));
      default:
        return null;
    }
  }, {
    onSuccess: (response) => {
      setData((prevData) => ({ ...prevData, ...response }));
      setPreStep(step);
      setStep((prevStep) => Math.min(prevStep + 1, 3));
    },
  });

  const previousStep = () => {
    if (step > 0) {
      setPreStep(step);
      setStep((prevStep) => Math.max(prevStep - (prevStep === 3 ? 2 : 1), 0));
    } else {
      setOpenDrag(false);
    }
  };
  const handleOverview = async (cart) => processData(['overview', cart]);
  const handlePickUp = async (pickup) => processData(['pickUp', pickup]);
  const handleUserInfo = async (customer) => processData(['customerInformation', customer]);
  const handlePaymentMethod = async (paymentMethod) => processData(['paymentMethod', paymentMethod]);

  const detailsComponent = !deliveryData.isDelivery ? (
    <PickUp
      store={selectedStore}
      scheduler={scheduler}
      schedule={data.schedule}
      type={data.type}
      minSchedule={minSchedule}
      maxSchedule={maxSchedule}
      onSubmit={handlePickUp}
      total={data.total}
      totalWithDiscount={data.totalWithDiscount}
    />
  ) : (
    <DeliveryDetails
      onSubmit={handlePickUp}
      total={data.total}
      totalWithDiscount={data.totalWithDiscount}
    />
  );

  const forms = (currStep) => ([
    <Overview
      store={selectedStore}
      onSubmit={handleOverview}
    />,
    detailsComponent,
    <UserInformation
      store={selectedStore}
      onSubmit={handleUserInfo}
      step={step}
    />,
    <PaymentMethod
      payments={data.method_payments}
      enrollPayments={data.enrollment_payment_methods}
      paymentMethod={data.payment_method}
      isLoading={isLoading}
      onSubmit={handlePaymentMethod}
      total={data.total}
      totalWithDiscount={data.totalWithDiscount}
    />,
  ][currStep]);

  return (
    <MotionConfig transition={{ duration: 0.5 }}>
      <motion.div
        className="fixed w-screen bg-gray-600 bg-opacity-50 z-30 top-0 right-0 h-full"
        animate={opacityAnimation}
      >
        <motion.div
          className={
            clsx(
              'flex flex-col absolute top-0 right-0 max-w-xl w-full h-full bg-white overflow-y-auto overflow-x-hidden',
            )
          }
          animate={drawerAnimation}
        >
          <HeaderWithButton
            title={headerTitle}
            onClick={previousStep}
            isTextColorPrimary
            titleCenter
          />
          <div className="relative w-full h-full overflow-y-auto">
            <AnimatedStepper
              current={forms(step)}
              previous={step !== preStep && forms(preStep)}
              direction={
                step - preStep >= 0 ? 'left' : 'right'
              }
            />
          </div>

          <Portal
            className="yuno-content-wrapper"
          >
            <div id="yuno-content" />
            <div id="form-element" />
          </Portal>
          <PaymentStatusPopUp
            status={data.payment?.status}
          />
        </motion.div>
      </motion.div>
    </MotionConfig>
  );
}

export default Cart;
