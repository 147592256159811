import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { activateCoupon } from '../../services/api';

export function useCoupon({
  storeID,
  type,
}) {
  const [coupon, setCoupon] = useState();

  const {
    mutate: validateCoupon,
    isLoading: isLoadingCoupon,
  } = useMutation((code) => activateCoupon(code, storeID, type), {
    onSuccess: (data) => {
      setCoupon(data);
    },
  });

  return { isLoadingCoupon, coupon, validateCoupon };
}

export default useCoupon;
