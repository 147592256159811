import PropTypes from 'prop-types';
import * as icons from '../icons';

function IconRender(props) {
  const {
    className,
    icon,
  } = props;

  const Icon = icons[icon];

  if (!Icon) {
    return <span />;
  }

  return <Icon className={className} />;
}

IconRender.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
};

IconRender.defaultProps = {
  className: '',
  icon: undefined,
};

export default IconRender;
