import clsx from 'clsx';
import HeaderWithButton from '../../components/HeaderWithButton';
import { LogoIcon } from '../../components/icons';
import { LoginScreens, useLogin } from '../../contexts/Login.context';

function SuccesRestore() {
  const { handleChangeScreen, emailSendedRecoverPass } = useLogin();

  const handleBack = () => handleChangeScreen(LoginScreens.LOGIN, 'right');
  const handleGoRegister = () => handleChangeScreen(LoginScreens.REGISTER);

  return (
    <div className="w-full max-w-6xl mx-auto h-full flex flex-col overflow-hidden">
      <HeaderWithButton
        buttonVariant="normal"
        onClick={handleBack}
        endAdornment={(
          <LogoIcon className={clsx(
            'h-16 w-8 sm:h-14 sm:w-7 md:h-10 md:w-10',
            'fill-baco-primary  mr-5 md:mr-24',
          )}

          />
        )}
      />

      <div className="w-full flex flex-col justify-start items-center flex-1">
        <div className="flex flex-col py-10 h-full px-3 w-full max-w-lg justify-between md:justify-start">
          <div className="w-full flex flex-col justify-center mb-10">
            <h2 className="text-baco-primary font-bold text-3xl mb-2 text-center">Olvidaste tu contraseña</h2>
            <span className="text-baco-primary text-center">
              Acabamos de enviar un correo electrónico a
              {' '}
              {emailSendedRecoverPass}
              .
              Cuando recibas el correo electrónico, haz clic en el enlace para restaablecer tu contraseña.
            </span>
          </div>

          <span className="inline-block w-full text-base text-center text-gray-700">
            ¿Aún no tienes cuenta?
            <button
              className="underline text-baco-primary ml-1 font-semibold"
              type="button"
              onClick={handleGoRegister}
            >
              Regístrate
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default SuccesRestore;
