import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { onMessage } from 'firebase/messaging';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Toast(props) {
  const { showToast } = props;
  const { fcm: { messaging } } = useSelector((state) => state);

  useEffect(() => {
    if (messaging) {
      onMessage(messaging, (message) => {
        const { notification: { title }, messageId } = message;
        if (showToast) {
          toast(title, {
            toastId: messageId,
          });
        }
      });
    }
  });

  return (
    <ToastContainer />
  );
}

Toast.propTypes = {
  showToast: PropTypes.bool,
};
Toast.defaultProps = {
  showToast: false,
};

export default Toast;
