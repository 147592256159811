import { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useDelivery from 'src/hooks/actions/useDelivery';
import { useNavigate } from 'react-router-dom';
import { useLogin } from 'src/contexts/Login.context';
import {
  LogoIcon, ChevronDownIcon, CartIcon, CloseIcon,
} from '../icons';
import Skeleton from '../Skeleton';
import Drawer from '../Drawer';
import Button from '../Button';

function CommonHeader(props) {
  const {
    currentLocation,
    onGoToStores,
    onGoToCart,
    endComponent,
  } = props;

  const { items = [] } = useSelector((state) => state.cart);
  const total = items.reduce((sum, item) => sum + item.count, 0);
  const { deliveryData } = useDelivery();
  const [openAddressDrawer, setOpenAddressDrawer] = useState(false);
  const navigate = useNavigate();
  const { token } = useLogin();

  return (
    <nav className="flex justify-between py-4">
      <div className="flex justify-start items-center">
        <div className="mr-4">
          <a href="/">
            <LogoIcon
              className="w-5 h-8 fill-baco-primary"
              alt="logo-bacu"
            />
          </a>
        </div>
        <div className="flex flex-col min-w-[130px] items-start">
          {
            deliveryData.isDelivery
              ? (
                <>
                  <p className="text-xs">Dirección de entrega</p>
                  <button
                    type="button"
                    className="text-sm underline"
                    onClick={() => setOpenAddressDrawer(true)}
                  >
                    {deliveryData.address}
                  </button>
                  <Drawer
                    open={openAddressDrawer}
                    direction="bottom"
                    keyboard="true"
                    classes={{
                      drawer: 'bg-gray-50 max-w-md !inset-x-0 !m-auto',
                      backdrop: 'z-20',
                      content: 'h-full w-full max-w-4xl mx-auto px-2',
                    }}
                  >
                    <div className="flex flex-col w-full checked:bg-baco-primary">
                      <nav className="flex justify-end py-0">
                        <button
                          type="button"
                          onClick={() => setOpenAddressDrawer(false)}
                        >
                          <CloseIcon
                            className="fill-baco-primary w-7 scale-110"
                          />
                        </button>
                      </nav>
                      <div className="font-bold text-xl py-4">
                        ¿Quieres cambiar la dirección?
                      </div>
                      <div className="text-md text-left pb-10">
                        Al cambiar la dirección, tendrás que seleccionar tus productos nuevamente.
                      </div>
                      <Button
                        type="submit"
                        size="lg"
                        fullWidth
                        className="!text-base font-bold"
                        onClick={() => setOpenAddressDrawer(false)}
                      >
                        <span className="mr-2">Mantener dirección actual</span>
                      </Button>
                      <button
                        type="button"
                        className="text-xs underline my-6"
                        onClick={() => {
                          if (token) {
                            navigate('/MyAddress');
                          } else {
                            navigate('/AddressInput');
                          }
                        }}
                      >
                        Cambiar dirección de entrega
                      </button>
                    </div>
                  </Drawer>
                </>
              )
              : (
                <>
                  <p className="m-0 leading-none">Recoge en</p>
                  <button className="flex m-0 items-center" onClick={onGoToStores} type="button">
                    {!currentLocation?.name ? (
                      <Skeleton className="w-[80px] h-4" />
                    ) : (
                      <b className="mr-1 max-w-[160px] truncate">{currentLocation?.name}</b>
                    )}
                    <ChevronDownIcon
                      className="img-fluid bacu-arrow-down"
                      alt="bacu-arrow-down"
                    />
                  </button>
                </>
              )
          }
        </div>
      </div>
      <div className="flex justify-end items-center gap-x-10 w-full">
        {endComponent}
        <button
          className={
            clsx(
              'relative p-2 border rounded-full',
              {
                'bg-baco-secondary border-baco-primary': total > 0,
                'bg-white border-gray-400': total === 0,
              },
            )
          }
          onClick={onGoToCart}
          type="button"
        >
          {total > 0 && (
            <div
              className="absolute top-0 -left-3 min-w-fit px-2 h-5 text-[8px] inline-flex
              justify-center items-center text-white bg-baco-primary rounded-full"
            >
              {total}
            </div>
          )}
          <CartIcon
            className={
              clsx(
                'w-4 h-4',
                {
                  'fill-baco-primary': total > 0,
                  'fill-gray-800': total === 0,
                },
              )
            }
            alt="bacu-arrow-down"
          />
        </button>
      </div>
    </nav>
  );
}

CommonHeader.propTypes = {
  currentLocation: PropTypes.any,
  onGoToStores: PropTypes.func,
  onGoToCart: PropTypes.func,
  endComponent: PropTypes.node,
};

CommonHeader.defaultProps = {
  currentLocation: {},
  onGoToStores: () => null,
  onGoToCart: () => null,
  endComponent: null,
};

export default CommonHeader;
