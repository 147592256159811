import { useDispatch, useSelector } from 'react-redux';
import { setDeliveryData } from '../../redux/actions/delivery';

export function useDelivery() {
  const dispatch = useDispatch();
  const deliveryData = useSelector((state) => state.delivery);

  const updateDelivery = (data) => {
    dispatch(setDeliveryData(data));
  };

  const clearDeliveryData = () => {
    dispatch(setDeliveryData({}));
  };

  return {
    deliveryData,
    updateDelivery,
    clearDeliveryData,
  };
}

export default useDelivery;
