import { useSelector } from 'react-redux';

export function useFcm() {
  const fcm = useSelector((state) => state.fcm);

  return {
    fcm,
  };
}

export default useFcm;
