import PropTypes from 'prop-types';

function Star(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m5.825 22 1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625 7.2.625-5.45 4.725L18.175 22 12 18.275Z" />
    </svg>
  );
}

Star.propTypes = {
  className: PropTypes.string,
};

Star.defaultProps = {
  className: '',
};

export default Star;
