import clsx from 'clsx';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CloseIcon, InfoIcon, WarningIcon } from '../icons';

function Alert(props) {
  const {
    title,
    description,
    type,
    onClose,
    customProps,
    className,
    classes,
    showCloseIcon,
  } = props;

  const [show, setShow] = useState(false);
  const top = customProps.top || 16;

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const getIcon = () => {
    switch (type) {
      case 'custom':
        return customProps.icon;
      case 'info':
        return <InfoIcon className={classes.icon} />;
      case 'success':
        return <div />;
      default:
        return <WarningIcon className={classes.icon} />;
    }
  };

  return (
    <motion.div
      className={
        clsx(
          'absolute right-4 left-4 mx-auto max-w-sm flex items-start p-6 gap-x-4 text-sm rounded-lg',
          '-top-28 opacity-0 z-30',
          className,
          {
            'text-red-700 bg-red-100': type === 'error',
            'text-yellow-700 bg-yellow-100': type === 'warning',
            'text-blue-700 bg-blue-100': type === 'info',
            'text-green-700 bg-green-100': type === 'success',
          },
        )
      }
      initial={{ top: -100, opacity: 0 }}
      animate={{
        top: show ? top : -100,
        opacity: show ? 1 : 0,
        transition: {
          duration: 0.25,
        },
      }}
      role="alert"
    >
      {getIcon()}
      <div className="w-full">
        <div
          className="flex flex-col"
        >
          <h3
            className={
              clsx(
                'text-base font-bold m-0',
                classes.title || '',
                {
                  'mb-2': !!description,
                },
              )
            }
          >
            {title}
          </h3>
          {description && (
            <p className={clsx('text-xs m-0', classes.description || '')}>
              {description}
            </p>
          )}
        </div>
      </div>
      {showCloseIcon
        && (
        <button
          onClick={handleClose}
          type="button"
        >
          <CloseIcon className={classes.close} />
        </button>
        )}

    </motion.div>
  );
}

Alert.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  type: PropTypes.oneOf(['warning', 'error', 'info', 'success', 'custom']),
  className: PropTypes.string,
  customProps: PropTypes.shape({
    icon: PropTypes.node,
    top: PropTypes.number,
  }),
  classes: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    close: PropTypes.string,
    icon: PropTypes.string,
  }),
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

Alert.defaultProps = {
  title: undefined,
  description: undefined,
  onClose: () => null,
  className: '',
  customProps: {},
  classes: {},
  type: 'warning',
  showCloseIcon: true,
};

export default Alert;
