import PropTypes from 'prop-types';

function DeliveryStepper1(props) {
  const { className } = props;

  return (
    <svg
      width="343"
      height="73"
      viewBox="0 0 343 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="48.5" cy="16.2148" r="11" fill="#0F5945" stroke="#0F5945" strokeWidth="2" />
      <path
        d="M48.288 21.2139H49.863V10.7139H48.438C48.153 12.2139 46.968 13.0239 45.363 13.1889L45.663 14.9139C46.728
       14.8539 47.988 13.9689 48.288 13.0389V21.2139Z"
        fill="white"
      />
      <rect x="68.5" y="15.2148" width="42" height="2" rx="1" fill="#E0D8D1" />
      <circle cx="130.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M129.578 17.2648C128.753 17.7298 128.093 18.2698 127.598 18.9148C127.118 19.5598 126.878 20.3998
       126.878 21.4648H134.228V19.9648H128.918C129.008 19.5898 129.233 19.2598 129.578 19.0048C129.938 18.7498
       130.463 18.4348 131.168 18.0598L131.978 17.6248C133.358 16.8298 134.228 15.8548 134.228 14.2198C134.228
       12.2398 132.863 10.8148 130.478 10.8148C128.213 10.8148 126.653 12.3748 126.653 14.2648C126.653 15.1198
       126.923 15.8998 127.478 16.5898L128.828 15.6898C128.453 15.2698 128.228 14.7298 128.228 14.2348C128.228
       13.1548 129.128 12.2398 130.403 12.2398C131.783 12.2398 132.653 13.1098 132.653 14.3098C132.653 15.1948
       132.233 15.7498 131.633 16.1548C131.348 16.3498 130.928 16.5748 130.388 16.8448C130.028 17.0248 129.758
       17.1598 129.578 17.2648Z"
        fill="#AEAEAE"
      />
      <rect x="150.5" y="15.2148" width="42" height="2" rx="1" fill="#E0D8D1" />
      <circle cx="212.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M208.479 18.4648C208.794 20.3248 210.234 21.6148 212.409 21.6148C214.614 21.6148 216.219 20.0998
      216.219 17.9398C216.219 15.9298 214.629 14.7448 213.174 14.7448C212.994 14.7448 212.829 14.7598 212.679
      14.7898L215.754 12.2398V10.9648H208.929V12.4648H213.429L210.129 15.2398L211.029 16.3648C211.344 16.0498
      211.869 15.8398 212.454 15.8398C213.729 15.8398 214.644 16.6948 214.644 17.9698C214.644 19.2448 213.684 20.1148
       212.409 20.1148C211.029 20.1148 210.054 19.1848 209.979 17.7898L208.479 18.4648Z"
        fill="#AEAEAE"
      />
      <rect x="232.5" y="15.2148" width="42" height="2" rx="1" fill="#E0D8D1" />
      <circle cx="294.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M294.848 21.4648H296.423V18.9148H298.148V17.4898H296.423V14.2048H294.848V17.4898H291.773C292.628
      16.6348 293.198 15.7648 293.498 14.8798C293.798 13.9798 293.948 12.8998 293.948
      11.6398V10.9648H292.373V11.6398C292.328
      14.3248 292.088 15.2248 290.123 17.2648V18.9148H294.848V21.4648Z"
        fill="#AEAEAE"
      />
      <path
        d="M24.9095
      46.2148V53.2148H30.2695V51.9648H26.2595V50.2348H29.8695V48.9848H26.2595V47.4648H30.1695V46.2148H24.9095ZM32.8539
      53.2148V50.5948C32.8539 49.8448 33.2639 49.4148 33.8339 49.4148C34.3839 49.4148 34.7239 49.8148 34.7239
      50.4648V53.2148H36.0039V50.1148C36.0039 49.0248 35.3239 48.3748 34.2939 48.3748C33.6939 48.3748 33.1639 48.6148
      32.8539 49.0948V48.4748H31.5739V53.2148H32.8539ZM42.3617 52.1848C41.6917 52.1848 41.0617 51.6648
      41.0617 50.8148C41.0617
       49.9648 41.6917 49.4948 42.2917 49.4948C42.8917 49.4948 43.3417 49.8648 43.4317 50.4648L44.5617
       50.0848C44.3517 49.0048
       43.4617 48.3748 42.2617 48.3748C40.9417 48.3748 39.8117 49.3848 39.8117 50.8148C39.8117 52.3148
       40.8417 53.3148 42.3917
       53.3148C43.6317 53.3148 44.5217 52.6748 44.8117 51.4248L43.6317 51.0148C43.5417 51.6948 43.1317
       52.1848 42.3617 52.1848ZM49.1574 48.4748V50.9148C49.1574 51.7148 48.7574 52.2148 48.0774 52.2148C47.5174
       52.2148 47.2074 51.8448 47.2074 51.1648V48.4748H45.9274V51.4848C45.9274 52.6848 46.6374 53.3148 47.7174
       53.3148C48.3874 53.3148 48.9074 53.0048 49.1574 52.5148V53.2148H50.4274V48.4748H49.1574ZM51.9
       48.4748V53.2148H53.18V50.1648C53.18 49.6848 53.44 49.4148 53.84 49.4148C54.2 49.4148 54.44 49.6548 54.44
       50.0048C54.44 50.1348 54.43 50.2548 54.4 50.3648H55.61C55.66 50.1748 55.68 49.9848 55.68 49.7748C55.68 48.9648
       55.17 48.3748 54.32 48.3748C53.77 48.3748 53.38 48.6448 53.18 49.0848V48.4748H51.9ZM56.4387 52.0448C56.7687
       52.8548 57.4987 53.3148 58.5887 53.3148C59.7287 53.3148 60.5887 52.6748 60.5887 51.7848C60.5887 50.9448 60.1087
        50.4848 58.8987 50.2948C58.0987 50.1748 57.8487 50.0548 57.8487 49.7748C57.8487 49.5348 58.1287 49.3648 58.5187
        49.3648C58.9087 49.3648 59.1987 49.5448 59.3187 49.9048L60.2887 49.3748C59.9987 48.7448 59.4787 48.3748 58.4887
        48.3748C57.5287 48.3748 56.6787 48.9448 56.6787 49.7648C56.6787 50.5648 57.2687 51.0248 58.2987 51.1848C59.1487
        51.3048 59.3987 51.4748 59.3987 51.8048C59.3987 52.0748 59.1187 52.2648 58.6287 52.2648C58.0587 52.2648 57.6587
        52.0148 57.4387 51.5148L56.4387 52.0448ZM61.466 50.8448C61.466 52.2548 62.516 53.3148 63.946
        53.3148C65.376 53.3148
        66.426 52.2548 66.426 50.8448C66.426 49.4248 65.386 48.3748 63.946 48.3748C62.506 48.3748 61.466 49.4248 61.466
        50.8448ZM65.176 50.8448C65.176 51.6348 64.656 52.1648 63.946 52.1648C63.236 52.1648 62.716 51.6348 62.716
        50.8448C62.716 50.0548 63.236 49.5248 63.946 49.5248C64.656 49.5248 65.176 50.0548 65.176 50.8448Z"
        fill="#0F5945"
      />
      <path
        d="M107.43 53.2148H110.81C112.09 53.2148 113.07 52.4048 113.07 51.3248C113.07 50.4448 112.58 49.8748 111.77
      49.6048C112.41 49.3148 112.82 48.7248 112.82 48.0348C112.82 47.0148 111.93 46.2148 110.6
      46.2148H107.43V53.2148ZM108.35 49.2048V47.0848H110.64C111.35 47.0848 111.91 47.4748 111.91 48.1248C111.91 48.8048
      111.43 49.2048 110.64 49.2048H108.35ZM108.35 52.3448V50.0248H110.78C111.57 50.0248 112.11 50.5248 112.11
      51.2048C112.11
      51.8948 111.55 52.3448 110.78 52.3448H108.35ZM117.676 48.4748V50.9948C117.676 51.9448 117.106 52.5548 116.356
      52.5548C115.666 52.5548 115.286 52.1448 115.286 51.3148V48.4748H114.426V51.5148C114.426 52.6848 115.086 53.3148
      116.126 53.3148C116.816 53.3148 117.356 52.9648 117.676 52.4148V53.2148H118.546V48.4748H117.676ZM119.785
      51.9748C120.065 52.7848 120.795 53.3148 121.865 53.3148C122.935 53.3148 123.765 52.6948 123.765 51.8148C123.765
      51.0248 123.215 50.5548 122.105 50.4048C121.175 50.2748 120.835 50.0948 120.835 49.6848C120.835 49.3148 121.235
      49.0748 121.755 49.0748C122.265 49.0748 122.635 49.3148 122.795 49.7948L123.465 49.4248C123.175 48.7548 122.645
      48.3748 121.755 48.3748C121.265 48.3748 120.855 48.4948 120.525 48.7448C120.205 48.9848 120.045 49.2948 120.045
      49.6748C120.045 50.4248 120.585 50.8748 121.675 51.0348C122.635 51.1648 122.925 51.4148 122.925 51.8648C122.925
      52.2748 122.535 52.5548 121.905 52.5548C121.185 52.5548 120.765 52.2748 120.495 51.6048L119.785 51.9748ZM127.233
      52.5548C126.393 52.5548 125.603 51.8748 125.603 50.8148C125.603 49.7248 126.403 49.1348 127.133 49.1348C127.903
      49.1348 128.403 49.5748 128.553 50.3248L129.343 50.0848C129.113 49.0048 128.293 48.3748 127.143 48.3748C125.853
      48.3748 124.763 49.3948 124.763 50.8148C124.763 52.3148 125.763 53.3148 127.253 53.3148C128.443 53.3148 129.303
      52.6248 129.573 51.4248L128.803 51.1348C128.613 52.0248 128.153 52.5548 127.233 52.5548ZM130.581 51.8648C130.581
      52.3048 130.731 52.6548 131.031 52.9248C131.341 53.1848 131.711 53.3148 132.151 53.3148C132.911 53.3148 133.541
      52.9748 133.781 52.4648V53.2148H134.611V50.2448C134.611 49.0748 133.881 48.3748 132.591 48.3748C131.481 48.3748
      130.741 48.9548 130.551 49.8648L131.331 50.0848C131.451 49.4748 131.911 49.1048 132.591 49.1048C133.301 49.1048
      133.781 49.4848 133.781 50.1748V50.6648L133.351 50.5448C133.031 50.4448 132.691 50.3948 132.341 50.3948C131.311
      50.3948 130.581 50.8948 130.581 51.8648ZM133.781 51.4448C133.781 52.1348 133.231 52.5548 132.441 52.5548C131.811
      52.5548 131.471 52.2548 131.471 51.8148C131.471 51.3248 131.851 51.0648 132.461 51.0648C132.711 51.0648 133.011
      51.1148 133.341 51.2148L133.781 51.3448V51.4448ZM137.058 53.2148V50.6148C137.058 49.7048 137.618 49.1348 138.338
      49.1348C139.018 49.1348 139.418 49.5648 139.418 50.3948V53.2148H140.288V50.1348C140.288 49.0248 139.628 48.3748
      138.618 48.3748C137.948 48.3748 137.388 48.6948 137.058 49.2448V48.4748H136.198V53.2148H137.058ZM141.587
      50.8748C141.587 52.3248 142.497 53.3148 143.807 53.3148C144.527 53.3148 145.127 52.9348 145.447
      52.3648V53.2148H146.277V46.2148H145.407V49.3348C145.097 48.7448 144.517 48.3748 143.787 48.3748C142.497
      48.3748 141.587 49.4048 141.587 50.8748ZM145.447 50.8648C145.447 51.9248 144.687 52.5448 143.947 52.5448C143.167
      52.5448 142.447 51.9348 142.447 50.8648C142.447 49.8048 143.177 49.1548 143.947 49.1548C144.687 49.1548 145.447
      49.8148 145.447 50.8648ZM147.57 50.8448C147.57 52.2448 148.54 53.3148 149.97 53.3148C151.39 53.3148 152.38 52.2448
      152.38 50.8448C152.38 49.4448 151.41 48.3748 149.97 48.3748C148.54 48.3748 147.57 49.4448 147.57 50.8448ZM151.49
      50.8448C151.49 51.8448 150.86 52.5348 149.97 52.5348C149.09 52.5348 148.45 51.8348 148.45 50.8448C148.45 49.8548
      149.09 49.1548 149.97 49.1548C150.42 49.1548 150.78 49.3148 151.06 49.6348C151.35 49.9448 151.49 50.3548 151.49
      50.8448ZM105.78 60.4748V65.2148H106.64V62.1948C106.64 61.5548 106.99 61.1348 107.52 61.1348C107.99 61.1348 108.28
      61.4048 108.28 61.8448C108.28 61.9348 108.27 62.0548 108.25 62.1948H109.09C109.13 62.0348 109.15 61.8548 109.15
      61.6648C109.15 60.9448 108.62 60.3748 107.85 60.3748C107.28 60.3748 106.85 60.6548 106.64
      61.1648V60.4748H105.78ZM113.816
      63.4948C113.626 64.1748 113.176 64.5548 112.446 64.5548C111.606 64.5548 110.946 64.0048 110.936
      62.9748H114.366C114.406
      62.7948 114.426 62.6048 114.426 62.3948C114.426 61.7748 114.236 61.2848 113.846 60.9248C113.456 60.5548 112.946
      60.3748 112.316 60.3748C110.966 60.3748 110.056 61.4548 110.056 62.8148C110.056 64.3448 111.056 65.3148 112.446
      65.3148C113.536 65.3148 114.246 64.7448 114.566 63.7848L113.816 63.4948ZM110.936 62.3548C111.026 61.6048 111.576
      61.1348 112.306 61.1348C113.026 61.1348 113.576 61.5348 113.576 62.2148L113.566 62.3548H110.936ZM115.821
      60.4748V67.1948H116.681V64.4748C117.001 65.0048 117.601 65.3148 118.281 65.3148C119.571 65.3148 120.501 64.3148
      120.501 62.8448C120.501 61.3748 119.621 60.3748 118.341 60.3748C117.631 60.3748 116.951 60.7948 116.641
      61.3648V60.4748H115.821ZM118.141 64.5448C117.401 64.5448 116.641 63.9048 116.641 62.8548C116.641 61.8048 117.401
      61.1548 118.141 61.1548C118.921 61.1548 119.641 61.7648 119.641 62.8548C119.641 63.9548 118.921 64.5448 118.141
      64.5448ZM121.583 63.8648C121.583 64.3048 121.733 64.6548 122.033 64.9248C122.343 65.1848 122.713 65.3148 123.153
      65.3148C123.913 65.3148 124.543 64.9748 124.783 64.4648V65.2148H125.613V62.2448C125.613 61.0748 124.883 60.3748
      123.593 60.3748C122.483 60.3748 121.743 60.9548 121.553 61.8648L122.333 62.0848C122.453 61.4748 122.913 61.1048
      123.593 61.1048C124.303 61.1048 124.783 61.4848 124.783 62.1748V62.6648L124.353 62.5448C124.033 62.4448 123.693
      62.3948 123.343 62.3948C122.313 62.3948 121.583 62.8948 121.583 63.8648ZM124.783 63.4448C124.783 64.1348 124.233
      64.5548 123.443 64.5548C122.813 64.5548 122.473 64.2548 122.473 63.8148C122.473 63.3248 122.853 63.0648 123.463
      63.0648C123.713 63.0648 124.013 63.1148 124.343 63.2148L124.783 63.3448V63.4448ZM127.2
      60.4748V65.2148H128.06V62.1948C128.06 61.5548 128.41 61.1348 128.94 61.1348C129.41 61.1348 129.7 61.4048 129.7
      61.8448C129.7 61.9348 129.69 62.0548 129.67 62.1948H130.51C130.55 62.0348 130.57 61.8548 130.57 61.6648C130.57
      60.9448 130.04 60.3748 129.27 60.3748C128.7 60.3748 128.27 60.6548 128.06 61.1648V60.4748H127.2ZM132.354
      63.8048C132.354 64.7548 132.904 65.3148 133.904 65.3148C134.714 65.3148 135.254 64.8448 135.384 63.9948L134.584
      63.8148C134.504 64.3348 134.274 64.5548 133.874 64.5548C133.504 64.5548 133.224 64.2948 133.224
      63.8448V61.2048H134.924V60.4748H133.224V59.0048H132.354V60.4748H131.414V61.2048H132.354V63.8048ZM136.586
      60.4748V65.2148H137.446V60.4748H136.586ZM136.536 59.5948H137.496V58.5848H136.536V59.5948ZM138.744 62.8748C138.744
      64.3248 139.654 65.3148 140.964 65.3148C141.684 65.3148 142.284 64.9348 142.604
      64.3648V65.2148H143.434V58.2148H142.564V61.3348C142.254 60.7448 141.674 60.3748 140.944 60.3748C139.654 60.3748
      138.744 61.4048 138.744 62.8748ZM142.604 62.8648C142.604 63.9248 141.844 64.5448 141.104 64.5448C140.324 64.5448
      139.604 63.9348 139.604 62.8648C139.604 61.8048 140.334 61.1548 141.104 61.1548C141.844 61.1548 142.604 61.8148
      142.604 62.8648ZM144.726 62.8448C144.726 64.2448 145.696 65.3148 147.126 65.3148C148.546 65.3148 149.536 64.2448
      149.536 62.8448C149.536 61.4448 148.566 60.3748 147.126 60.3748C145.696 60.3748 144.726 61.4448 144.726
      62.8448ZM148.646
      62.8448C148.646 63.8448 148.016 64.5348 147.126 64.5348C146.246 64.5348 145.606 63.8348 145.606 62.8448C145.606
      61.8548 146.246 61.1548 147.126 61.1548C147.576 61.1548 147.936 61.3148 148.216 61.6348C148.506 61.9448 148.646
      62.3548 148.646 62.8448ZM150.823 60.4748V65.2148H151.683V62.1948C151.683 61.5548 152.033 61.1348 152.563
      61.1348C153.033 61.1348 153.323 61.4048 153.323 61.8448C153.323 61.9348 153.313 62.0548 153.293
      62.1948H154.133C154.173 62.0348 154.193 61.8548 154.193 61.6648C154.193 60.9448 153.663 60.3748 152.893
      60.3748C152.323 60.3748 151.893 60.6548 151.683 61.1648V60.4748H150.823Z"
        fill="#AEAEAE"
      />
      <path
        d="M192.21 53.2148V53.0748C191.93 52.7648 191.89 52.4248 191.86 51.6248C191.83 50.6948 191.46 50.1848
      190.75 49.9148C191.66 49.6048 192.15 48.9848 192.15 48.1148C192.15 47.0648 191.31 46.2148 189.92
      46.2148H186.72V53.2148H187.64V50.2548H189.47C190.36 50.2548 190.83 50.6148 190.84 51.6148C190.85 52.4948
      190.92 52.8948 191.08 53.2148H192.21ZM187.64 49.3848V47.0848H189.84C190.68 47.0848 191.18 47.5048 191.18
      48.2348C191.18 48.9548 190.68 49.3848 189.84 49.3848H187.64ZM196.932 51.4948C196.742 52.1748 196.292 52.5548
      195.562 52.5548C194.722 52.5548 194.062 52.0048 194.052 50.9748H197.482C197.522 50.7948 197.542 50.6048
      197.542 50.3948C197.542 49.7748 197.352 49.2848 196.962 48.9248C196.572 48.5548 196.062 48.3748 195.432
      48.3748C194.082 48.3748 193.172 49.4548 193.172 50.8148C193.172 52.3448 194.172 53.3148 195.562 53.3148C196.652
      53.3148 197.362 52.7448 197.682 51.7848L196.932 51.4948ZM194.052 50.3548C194.142 49.6048 194.692 49.1348 195.422
      49.1348C196.142 49.1348 196.692 49.5348 196.692 50.2148L196.682 50.3548H194.052ZM198.936
      48.4748V55.1948H199.796V52.4748C200.116 53.0048 200.716 53.3148 201.396 53.3148C202.686 53.3148 203.616 52.3148
       203.616 50.8448C203.616 49.3748 202.736 48.3748 201.456 48.3748C200.746 48.3748 200.066 48.7948 199.756
       49.3648V48.4748H198.936ZM201.256 52.5448C200.516 52.5448 199.756 51.9048 199.756 50.8548C199.756 49.8048
       200.516 49.1548 201.256 49.1548C202.036 49.1548 202.756 49.7648 202.756 50.8548C202.756 51.9548 202.036 52.5448
        201.256 52.5448ZM204.699 51.8648C204.699 52.3048 204.849 52.6548 205.149 52.9248C205.459 53.1848 205.829 53.3148
        206.269
53.3148C207.029
53.3148
207.659
52.9748
207.899
52.4648V53.2148H208.729V50.2448C208.729
49.0748
207.999
48.3748
206.709
48.3748C205.599
48.3748
204.859
48.9548
204.669
49.8648L205.449
50.0848C205.569
49.4748
206.029
49.1048
206.709
49.1048C207.419
49.1048
207.899
49.4848
207.899
50.1748V50.6648L207.469
50.5448C207.149
50.4448
206.809
50.3948
206.459
50.3948C205.429
50.3948
204.699
50.8948
204.699
51.8648ZM207.899
51.4448C207.899
52.1348
207.349
52.5548
206.559
52.5548C205.929
52.5548
205.589
52.2548
205.589
51.8148C205.589
51.3248
205.969
51.0648
206.579
51.0648C206.829
51.0648
207.129
51.1148
207.459
51.2148L207.899
51.3448V51.4448ZM210.315
48.4748V53.2148H211.175V50.1948C211.175
49.5548
211.525
49.1348
212.055
49.1348C212.525
49.1348
212.815
49.4048
212.815
49.8448C212.815
49.9348
212.805
50.0548
212.785
50.1948H213.625C213.665
50.0348
213.685
49.8548
213.685
49.6648C213.685
48.9448
213.155
48.3748
212.385
48.3748C211.815
48.3748
211.385
48.6548
211.175
49.1648V48.4748H210.315ZM215.469
51.8048C215.469
52.7548
216.019
53.3148
217.019
53.3148C217.829
53.3148
218.369
52.8448
218.499
51.9948L217.699
51.8148C217.619
52.3348
217.389
52.5548
216.989
52.5548C216.619
52.5548
216.339
52.2948
216.339
51.8448V49.2048H218.039V48.4748H216.339V47.0048H215.469V48.4748H214.529V49.2048H215.469V51.8048ZM219.702
48.4748V53.2148H220.562V48.4748H219.702ZM219.652
47.5948H220.612V46.5848H219.652V47.5948ZM221.859
50.8748C221.859
52.3248
222.769
53.3148
224.079
53.3148C224.799
53.3148
225.399
52.9348
225.719
52.3648V53.2148H226.549V46.2148H225.679V49.3348C225.369
48.7448
224.789
48.3748
224.059
48.3748C222.769
48.3748
221.859
49.4048
221.859
50.8748ZM225.719
50.8648C225.719
51.9248
224.959
52.5448
224.219
52.5448C223.439
52.5448
222.719
51.9348
222.719
50.8648C222.719
49.8048
223.449
49.1548
224.219
49.1548C224.959
49.1548
225.719
49.8148
225.719
50.8648ZM227.842
50.8448C227.842
52.2448
228.812
53.3148
230.242
53.3148C231.662
53.3148
232.652
52.2448
232.652
50.8448C232.652
49.4448
231.682
48.3748
230.242
48.3748C228.812
48.3748
227.842
49.4448
227.842
50.8448ZM231.762
50.8448C231.762
51.8448
231.132
52.5348
230.242
52.5348C229.362
52.5348
228.722
51.8348
228.722
50.8448C228.722
49.8548
229.362
49.1548
230.242
49.1548C230.692
49.1548
231.052
49.3148
231.332
49.6348C231.622
49.9448
231.762
50.3548
231.762
50.8448ZM233.938
48.4748V53.2148H234.798V50.1948C234.798
49.5548
235.148
49.1348
235.678
49.1348C236.148
49.1348
236.438
49.4048
236.438
49.8448C236.438
49.9348
236.428
50.0548
236.408
50.1948H237.248C237.288
50.0348
237.308
49.8548
237.308
49.6648C237.308
48.9448
236.778
48.3748
236.008
48.3748C235.438
48.3748
235.008
48.6548
234.798
49.1648V48.4748H233.938ZM194.08
63.4948C193.89
64.1748
193.44
64.5548
192.71
64.5548C191.87
64.5548
191.21
64.0048
191.2
62.9748H194.63C194.67
62.7948
194.69
62.6048
194.69
62.3948C194.69
61.7748
194.5
61.2848
194.11
60.9248C193.72
60.5548
193.21
60.3748
192.58
60.3748C191.23
60.3748
190.32
61.4548
190.32
62.8148C190.32
64.3448
191.32
65.3148
192.71
65.3148C193.8
65.3148
194.51
64.7448
194.83
63.7848L194.08
63.4948ZM191.2
62.3548C191.29
61.6048
191.84
61.1348
192.57
61.1348C193.29
61.1348
193.84
61.5348
193.84
62.2148L193.83
62.3548H191.2ZM196.945
65.2148V62.6148C196.945
61.7048
197.505
61.1348
198.225
61.1348C198.905
61.1348
199.305
61.5648
199.305
62.3948V65.2148H200.175V62.1348C200.175
61.0248
199.515
60.3748
198.505
60.3748C197.835
60.3748
197.275
60.6948
196.945
61.2448V60.4748H196.085V65.2148H196.945ZM206.646
64.5548C205.806
64.5548
205.016
63.8748
205.016
62.8148C205.016
61.7248
205.816
61.1348
206.546
61.1348C207.316
61.1348
207.816
61.5748
207.966
62.3248L208.756
62.0848C208.526
61.0048
207.706
60.3748
206.556
60.3748C205.266
60.3748
204.176
61.3948
204.176
62.8148C204.176
64.3148
205.176
65.3148
206.666
65.3148C207.856
65.3148
208.716
64.6248
208.986
63.4248L208.216
63.1348C208.026
64.0248
207.566
64.5548
206.646
64.5548ZM209.994
63.8648C209.994
64.3048
210.144
64.6548
210.444
64.9248C210.754
65.1848
211.124
65.3148
211.564
65.3148C212.324
65.3148
212.954
64.9748
213.194
64.4648V65.2148H214.024V62.2448C214.024
61.0748
213.294
60.3748
212.004
60.3748C210.894
60.3748
210.154
60.9548
209.964
61.8648L210.744
62.0848C210.864
61.4748
211.324
61.1048
212.004
61.1048C212.714
61.1048
213.194
61.4848
213.194
62.1748V62.6648L212.764
62.5448C212.444
62.4448
212.104
62.3948
211.754
62.3948C210.724
62.3948
209.994
62.8948
209.994
63.8648ZM213.194
63.4448C213.194
64.1348
212.644
64.5548
211.854
64.5548C211.224
64.5548
210.884
64.2548
210.884
63.8148C210.884
63.3248
211.264
63.0648
211.874
63.0648C212.124
63.0648
212.424
63.1148
212.754
63.2148L213.194
63.3448V63.4448ZM215.61
60.4748V65.2148H216.47V62.3148C216.47
61.5448
216.87
61.1548
217.39
61.1548C217.91
61.1548
218.26
61.4948
218.26
62.1448V65.2148H219.13V62.2648C219.13
61.5348
219.54
61.1548
220.06
61.1548C220.57
61.1548
220.93
61.5148
220.93
62.1048V65.2148H221.79V61.9248C221.79
60.9248
221.23
60.3748
220.35
60.3748C219.75
60.3748
219.21
60.7148
218.96
61.3148C218.8
60.7048
218.32
60.3748
217.75
60.3748C217.12
60.3748
216.7
60.6748
216.47
61.1448V60.4748H215.61ZM223.38
60.4748V65.2148H224.24V60.4748H223.38ZM223.33
59.5948H224.29V58.5848H223.33V59.5948ZM226.687
65.2148V62.6148C226.687
61.7048
227.247
61.1348
227.967
61.1348C228.647
61.1348
229.047
61.5648
229.047
62.3948V65.2148H229.917V62.1348C229.917
61.0248
229.257
60.3748
228.247
60.3748C227.577
60.3748
227.017
60.6948
226.687
61.2448V60.4748H225.827V65.2148H226.687ZM231.217
62.8448C231.217
64.2448
232.187
65.3148
233.617
65.3148C235.037
65.3148
236.027
64.2448
236.027
62.8448C236.027
61.4448
235.057
60.3748
233.617
60.3748C232.187
60.3748
231.217
61.4448
231.217
62.8448ZM235.137
62.8448C235.137
63.8448
234.507
64.5348
233.617
64.5348C232.737
64.5348
232.097
63.8348
232.097
62.8448C232.097
61.8548
232.737
61.1548
233.617
61.1548C234.067
61.1548
234.427
61.3148
234.707
61.6348C234.997
61.9448
235.137
62.3548
235.137
62.8448Z"
        fill="#AEAEAE"
      />
      <path
        d="
      M263.916
      48.1948V49.2448H264.956V48.1948H263.916ZM264.006
      49.9448V55.1948H264.866V49.9448H264.006ZM266.629
      46.2148V53.2148H271.659V52.3448H267.549V50.0248H271.259V49.1548H267.549V47.0848H271.559V46.2148H266.629ZM273.117
      53.2148H273.977V46.2148H273.117V53.2148ZM278.266
      48.4748V53.2148H279.126V50.1948C279.126
      49.5548
      279.476
      49.1348
      280.006
      49.1348C280.476
      49.1348
      280.766
      49.4048
      280.766
      49.8448C280.766
      49.9348
      280.756
      50.0548
      280.736
      50.1948H281.576C281.616
      50.0348
      281.636
      49.8548
      281.636
      49.6648C281.636
      48.9448
      281.106
      48.3748
      280.336
      48.3748C279.766
      48.3748
      279.336
      48.6548
      279.126
      49.1648V48.4748H278.266ZM286.302
      51.4948C286.112
      52.1748
      285.662
      52.5548
      284.932
      52.5548C284.092
      52.5548
      283.432
      52.0048
      283.422
      50.9748H286.852C286.892
      50.7948
      286.912
      50.6048
      286.912
      50.3948C286.912
      49.7748
      286.722
      49.2848
      286.332
      48.9248C285.942
      48.5548
      285.432
      48.3748
      284.802
      48.3748C283.452
      48.3748
      282.542
      49.4548
      282.542
      50.8148C282.542
      52.3448
      283.542
      53.3148
      284.932
      53.3148C286.022
      53.3148
      286.732
      52.7448
      287.052
      51.7848L286.302
      51.4948ZM283.422
      50.3548C283.512
      49.6048
      284.062
      49.1348
      284.792
      49.1348C285.512
      49.1348
      286.062
      49.5348
      286.062
      50.2148L286.052
      50.3548H283.422ZM288.307
      48.4748V55.1948H289.167V52.4748C289.487
      53.0048
      290.087
      53.3148
      290.767
      53.3148C292.057
      53.3148
      292.987
      52.3148
      292.987
      50.8448C292.987
      49.3748
      292.107
      48.3748
      290.827
      48.3748C290.117
      48.3748
      289.437
      48.7948
      289.127
      49.3648V48.4748H288.307ZM290.627
      52.5448C289.887
      52.5448
      289.127
      51.9048
      289.127
      50.8548C289.127
      49.8048
      289.887
      49.1548
      290.627
      49.1548C291.407
      49.1548
      292.127
      49.7648
      292.127
      50.8548C292.127
      51.9548
      291.407
      52.5448
      290.627
      52.5448ZM294.069
      51.8648C294.069
      52.3048
      294.219
      52.6548
      294.519
      52.9248C294.829
      53.1848
      295.199
      53.3148
      295.639
      53.3148C296.399
      53.3148
      297.029
      52.9748
      297.269
      52.4648V53.2148H298.099V50.2448C298.099
      49.0748
      297.369
      48.3748
      296.079
      48.3748C294.969
      48.3748
      294.229
      48.9548
      294.039
      49.8648L294.819
      50.0848C294.939
      49.4748
      295.399
      49.1048
      296.079
      49.1048C296.789
      49.1048
      297.269
      49.4848
      297.269
      50.1748V50.6648L296.839
      50.5448C296.519
      50.4448
      296.179
      50.3948
      295.829
      50.3948C294.799
      50.3948
      294.069
      50.8948
      294.069
      51.8648ZM297.269
      51.4448C297.269
      52.1348
      296.719
      52.5548
      295.929
      52.5548C295.299
      52.5548
      294.959
      52.2548
      294.959
      51.8148C294.959
      51.3248
      295.339
      51.0648
      295.949
      51.0648C296.199
      51.0648
      296.499
      51.1148
      296.829
      51.2148L297.269
      51.3448V51.4448ZM299.686
      48.4748V53.2148H300.546V50.1948C300.546
      49.5548
      300.896
      49.1348
      301.426
      49.1348C301.896
      49.1348
      302.186
      49.4048
      302.186
      49.8448C302.186
      49.9348
      302.176
      50.0548
      302.156
      50.1948H302.996C303.036
      50.0348
      303.056
      49.8548
      303.056
      49.6648C303.056
      48.9448
      302.526
      48.3748
      301.756
      48.3748C301.186
      48.3748
      300.756
      48.6548
      300.546
      49.1648V48.4748H299.686ZM304.84
      51.8048C304.84
      52.7548
      305.39
      53.3148
      306.39
      53.3148C307.2
      53.3148
      307.74
      52.8448
      307.87
      51.9948L307.07
      51.8148C306.99
      52.3348
      306.76
      52.5548
      306.36
      52.5548C305.99
      52.5548
      305.71
      52.2948
      305.71
      51.8448V49.2048H307.41V48.4748H305.71V47.0048H304.84V48.4748H303.9V49.2048H304.84V51.8048ZM309.072
      48.4748V53.2148H309.932V48.4748H309.072ZM309.022
      47.5948H309.982V46.5848H309.022V47.5948ZM311.23
      50.8748C311.23
      52.3248
      312.14
      53.3148
      313.45
      53.3148C314.17
      53.3148
      314.77
      52.9348
      315.09
      52.3648V53.2148H315.92V46.2148H315.05V49.3348C314.74
      48.7448
      314.16
      48.3748
      313.43
      48.3748C312.14
      48.3748
      311.23
      49.4048
      311.23
      50.8748ZM315.09
      50.8648C315.09
      51.9248
      314.33
      52.5448
      313.59
      52.5448C312.81
      52.5448
      312.09
      51.9348
      312.09
      50.8648C312.09
      49.8048
      312.82
      49.1548
      313.59
      49.1548C314.33
      49.1548
      315.09
      49.8148
      315.09
      50.8648ZM317.212
      50.8448C317.212
      52.2448
      318.182
      53.3148
      319.612
      53.3148C321.032
      53.3148
      322.022
      52.2448
      322.022
      50.8448C322.022
      49.4448
      321.052
      48.3748
      319.612
      48.3748C318.182
      48.3748
      317.212
      49.4448
      317.212
      50.8448ZM321.132
      50.8448C321.132
      51.8448
      320.502
      52.5348
      319.612
      52.5348C318.732
      52.5348
      318.092
      51.8348
      318.092
      50.8448C318.092
      49.8548
      318.732
      49.1548
      319.612
      49.1548C320.062
      49.1548
      320.422
      49.3148
      320.702
      49.6348C320.992
      49.9448
      321.132
      50.3548
      321.132
      50.8448ZM323.309
      48.4748V53.2148H324.169V50.1948C324.169
      49.5548
      324.519
      49.1348
      325.049
      49.1348C325.519
      49.1348
      325.809
      49.4048
      325.809
      49.8448C325.809
      49.9348
      325.799
      50.0548
      325.779
      50.1948H326.619C326.659
      50.0348
      326.679
      49.8548
      326.679
      49.6648C326.679
      48.9448
      326.149
      48.3748
      325.379
      48.3748C324.809
      48.3748
      324.379
      48.6548
      324.169
      49.1648V48.4748H323.309ZM284.895
      65.2148H285.755V58.2148H284.895V65.2148ZM287.342
      65.2148H288.202V58.2148H287.342V65.2148ZM293.259
      63.4948C293.069
      64.1748
      292.619
      64.5548
      291.889
      64.5548C291.049
      64.5548
      290.389
      64.0048
      290.379
      62.9748H293.809C293.849
      62.7948
      293.869
      62.6048
      293.869
      62.3948C293.869
      61.7748
      293.679
      61.2848
      293.289
      60.9248C292.899
      60.5548
      292.389
      60.3748
      291.759
      60.3748C290.409
      60.3748
      289.499
      61.4548
      289.499
      62.8148C289.499
      64.3448
      290.499
      65.3148
      291.889
      65.3148C292.979
      65.3148
      293.689
      64.7448
      294.009
      63.7848L293.259
      63.4948ZM290.379
      62.3548C290.469
      61.6048
      291.019
      61.1348
      291.749
      61.1348C292.469
      61.1348
      293.019
      61.5348
      293.019
      62.2148L293.009
      62.3548H290.379ZM295.244
      65.8348C295.554
      66.8048
      296.274
      67.2948
      297.424
      67.2948C298.144
      67.2948
      298.704
      67.0648
      299.094
      66.6048C299.484
      66.1448
      299.684
      65.5448
      299.684
      64.7948V60.4748H298.814V61.1848C298.464
      60.6448
      297.934
      60.3748
      297.214
      60.3748C295.964
      60.3748
      294.974
      61.3848
      294.974
      62.8648C294.974
      64.2848
      295.964
      65.2148
      297.164
      65.2148C297.954
      65.2148
      298.534
      64.8548
      298.814
      64.3248V64.8548C298.814
      65.9248
      298.344
      66.5348
      297.424
      66.5348C296.684
      66.5348
      296.214
      66.1948
      296.024
      65.5048L295.244
      65.8348ZM298.854
      62.8048C298.854
      63.7748
      298.214
      64.4548
      297.354
      64.4548C296.474
      64.4548
      295.834
      63.8448
      295.834
      62.8748C295.834
      61.7948
      296.474
      61.1348
      297.354
      61.1348C298.214
      61.1348
      298.854
      61.8448
      298.854
      62.8048ZM300.985
      62.8448C300.985
      64.2448
      301.955
      65.3148
      303.385
      65.3148C304.805
      65.3148
      305.795
      64.2448
      305.795
      62.8448C305.795
      61.4448
      304.825
      60.3748
      303.385
      60.3748C301.955
      60.3748
      300.985
      61.4448
      300.985
      62.8448ZM304.905
      62.8448C304.905
      63.8448
      304.275
      64.5348
      303.385
      64.5348C302.505
      64.5348
      301.865
      63.8348
      301.865
      62.8448C301.865
      61.8548
      302.505
      61.1548
      303.385
      61.1548C303.835
      61.1548
      304.195
      61.3148
      304.475
      61.6348C304.765
      61.9448
      304.905
      62.3548
      304.905
      62.8448ZM302.975
      59.6648H303.805L305.015
      58.4748V58.3348H303.845L302.975
      59.6648ZM307.162
      63.4648H308.022V58.2148H307.162V63.4648ZM307.072
      65.2148H308.112V64.1648H307.072V65.2148Z
      "
        fill="#AEAEAE"
      />
    </svg>

  );
}

DeliveryStepper1.propTypes = {
  className: PropTypes.string,
};

DeliveryStepper1.defaultProps = {
  className: '',
};

export default DeliveryStepper1;
