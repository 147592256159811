import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { motion, MotionConfig } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ChevronUpIcon, CloseIcon } from '../components/icons';
import StoreItem from '../components/StoreItem';
import { useShoppingCart } from '../hooks/actions/useShoppingCart';
import { useStore } from '../hooks/actions/useStore';
import { getLocations } from '../services/api';
import IntermidiateScreen from './components/IntermidiateScreen';
import StoreNotAvailable from './components/StoreNotAvailable';

function Stores() {
  const { selectedStore, clearStore } = useStore();
  const { clearCart } = useShoppingCart();
  const { data: locations = [], isLoading } = useQuery(['locations'], getLocations, {
    refetchOnWindowFocus: false,
  });
  const [latestStore, setLatestStore] = useState();
  const navigate = useNavigate();
  const isAvailable = locations.some((location) => location.is_available);
  const isOpenStore = isLoading || locations.some((location) => location.currentSchedule?.open);
  const minSchedule = locations.reduce((min, location) => {
    if (min.openHour > location.currentSchedule?.openHour) {
      return location.currentSchedule;
    }

    if (min.openHour === location.currentSchedule?.openHour) {
      if (min.openMinute > location.currentSchedule?.openMinute) {
        return location.currentSchedule;
      }
    }

    return min;
  }, { openHour: 25, openMinute: 60 });

  if (!latestStore && locations.length > 0 && selectedStore?.storeId) {
    const tmpStore = locations.find((location) => selectedStore.storeId === location.storeId);
    if (tmpStore && tmpStore.isActive && tmpStore.isOpen && tmpStore.is_available) {
      setLatestStore(tmpStore || {});
    }
  }

  const handleHideStores = () => navigate(-1);
  const handleStore = (storeId) => {
    if (selectedStore?.storeId && storeId !== selectedStore?.storeId) {
      clearCart();
    }
    clearStore();
    navigate(`/store/${storeId}`);
  };

  if (isLoading) {
    const msg = (
      <p className="text-base font-medium text-baco-primary text-center w-full max-w-2xl mx-auto">
        Consultando las tiendas disponibles...
      </p>
    );
    return <IntermidiateScreen content={msg} />;
  }

  if (!isOpenStore || !isAvailable) {
    return <StoreNotAvailable {...minSchedule} />;
  }

  return (
    <MotionConfig transition={{ duration: 0.5 }}>
      <motion.div
        className={
          clsx(
            'fixed w-screen h-full overflow-auto z-10 bg-transparent',
            {
              'bg-baco-secondary': !selectedStore?.storeId,
              'bg-white': selectedStore?.storeId,
            },
          )
        }
        initial={{ top: '100%', opacity: 0 }}
        animate={{ top: 0, opacity: 1 }}
        exit={{ top: '100%', opacity: 0 }}
      >
        <div className="flex w-screen h-full">
          <div className="hidden lg:block w-1/2 h-full bg-gray-400">
            <img
              className="w-full h-full object-left-bottom object-cover"
              src="/images/photos/main2.jpg"
              alt="Home"
            />
          </div>
          <div className="block h-full overflow-y-auto py-4 w-full lg:w-1/2">
            <div className="flex flex-col w-full max-w-md mx-auto">
              <div className="px-4 mt-10 sticky -top-4 z-10">
                <button
                  className={
                    clsx(
                      'flex w-full h-14 justify-between items-center',
                      {
                        'bg-baco-secondary': !selectedStore?.storeId,
                        'bg-white': selectedStore?.storeId,
                      },
                    )
                  }
                  type="button"
                  onClick={handleHideStores}
                >
                  {selectedStore?.storeId ? (
                    <CloseIcon className="w-10 h-10 border border-gray-400 fill-gray-700 rounded p-2" />
                  ) : (
                    <ChevronUpIcon className="w-5 h-5" />
                  )}
                  <div className="w-full h-6 leading-6 text-center font-bold text-xl">
                    Escoge el Bacu
                  </div>
                </button>
              </div>
              <div className="mt-8 px-4">
                <div className="flex flex-wrap items-stretch z-0 -m-3 gap-y-6 pb-4">
                  {isLoading
                    ? Array.apply(0, new Array(3)).map(() => uuid()).map((key) => (
                      <div className="w-full px-3" key={key}>
                        <StoreItem isLoading />
                      </div>
                    ))
                    : (
                      <>
                        {latestStore?.storeId && (
                          <>
                            <div key={latestStore.storeId} className="w-full px-3">
                              <StoreItem
                                {...latestStore}
                                isCurrent
                                isAvailable={latestStore.is_available}
                                onClick={handleStore}
                              />
                            </div>
                            <div className="w-full px-4">
                              <hr className="w-full h-[1px] border-none bg-gray-300" />
                            </div>
                          </>
                        )}
                        {locations.filter((store) => store.storeId !== latestStore?.storeId).map((store) => (
                          <div key={store.storeId} className="w-full px-3">
                            <StoreItem {...store} isAvailable={store.is_available} onClick={handleStore} />
                          </div>
                        ))}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </MotionConfig>
  );
}

export default Stores;
