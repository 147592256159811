import PropTypes from 'prop-types';

import Button from '../../components/Button';
import { SupportAgentIcon } from '../../components/icons';
import config from '../../config';
import { useAmplitude } from '../../hooks/useAmplitude';

function HelpButton(props) {
  const { color, className } = props;
  const { trackSupportRedirectPickUp } = useAmplitude().useCheckoutPageEvents();
  const handleHelp = () => {
    trackSupportRedirectPickUp();
    window.open(config.whatsAppSupport, '_blank');
  };

  return (
    <Button
      className={className}
      onClick={handleHelp}
      color={color}
      fullWidth
      size="lg"
    >
      <span className="flex justify-center items-center gap-2">
        <SupportAgentIcon className="h-6 w-6" />
        ¿Necesitas ayuda?
      </span>
    </Button>
  );
}

HelpButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
};

HelpButton.defaultProps = {
  color: 'secondary',
  className: '',
};

export default HelpButton;
