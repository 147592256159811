/**
 * Método para mezclar los datos de formulario con el producto
 * @param {ProductDetail} productDetail el detalle del producto cargado desde servicio
 * @param {FormData} formData los datos de formulario seleccionados por el usuario
 * @returns {ModifierGroup[]} los modificadores de grupo con los valores mezclados
 */
function mixProductWithForm(productDetail, formData) {
  return (productDetail.modifierGroups || []).map((modifierGroup, i) => {
    if (modifierGroup.selectType === 'radioBtn') {
      const modifier = modifierGroup.modifiers?.find((mod) => (
        formData.modifierGroups[i]?.modifiers[0]?.value === mod.name
      ));
      return {
        ...modifierGroup,
        modifiers: [{
          ...modifier,
          value: formData.modifierGroups[i]?.modifiers[0]?.value,
        }],
      };
    }

    const modifiers = modifierGroup.modifiers?.map((mod, j) => (
      { ...mod, value: formData.modifierGroups[i]?.modifiers[j]?.value }
    ));

    return {
      ...modifierGroup,
      modifiers,
    };
  });
}

/**
 * Método para obtener el subtotal de un modificador especifico
 * @param {String} selectType tipo de dato del modificador de grupo (counters, radioBtn, etc..)
 * @param {Modifier} modifier El modificador al que se calculará el subtotal
 * @returns {Number} el subtotal del modificador
 */
function getModifiersSubtotal(selectType, modifier) {
  if (selectType === 'counters') {
    return (modifier.price || 0) * (modifier.value || 0);
  }

  return modifier.value ? (modifier.price || 0) : 0;
}

/**
 * Método para obtener el precio total de un producto con sus modificadores
 * @param {ModifierGroup[]} mixModifierGroups los grupos de modificadores con el valor del formulario mezclado
 * @param {ProductDetail} productDetail El detalle del producto desde el servicio
 * @returns {Number} El precio total con la suma de todos los modificadores
 */
function getProductPrice(mixModifierGroups, productDetail) {
  return mixModifierGroups.reduce((total, modifierGroup) => {
    const subtotal = modifierGroup.modifiers.reduce((sum, modifier) => (
      sum + getModifiersSubtotal(modifierGroup.selectType, modifier)
    ), 0);

    return total + subtotal;
  }, productDetail.discountedPrice && productDetail.discountedPrice < productDetail.price
    ? productDetail.discountedPrice : productDetail.price);
}

/**
 * Método para validar si se tienen los minimos o maximos modificadores permitidos seleccionados
 * @param {Modifier[]} modifiers Los modificadores a verificar
 * @param {String} selectType El tipo del grupo modificador (counters, etc...)
 * @param {Number} minAllowed El minimo de elecciones permitidas
 * @param {Number} maxAllowed El maximo de elecciones permitidas
 * @returns {{ min: Boolean, max: Boolean }} Minimos y maximos modificadores permitidos
 */
function modifiersAllowed(modifiers, selectType, minAllowed, maxAllowed) {
  switch (selectType) {
    case 'counters':
      const totalCounters = modifiers.reduce((sum, modifier) => sum + (modifier.value || 0), 0);
      return { min: totalCounters < minAllowed, max: totalCounters >= maxAllowed };
    case 'checkBoxes':
      const totalChecks = modifiers.reduce((sum, modifier) => sum + (modifier.value ? 1 : 0), 0);
      return { min: totalChecks < minAllowed, max: totalChecks >= maxAllowed };
    case 'radioBtn':
      const hasChecked = modifiers.some((modifier) => modifier.value);
      return { min: minAllowed > 0 && !hasChecked, max: false };
    default:
      return { min: false, max: false };
  }
}

/**
 * Método para agregar el disables a cada modifierGroup
 * @param {ProductDetail} productDetail El detalle del producto desde el servicio
 * @param {FormData} modifierGroupsForm La data agregada del formulario
 * @returns {ModifierGroup[]} Los modifierGroups con el disables incluido
 */
function addDisablesToModifiers(productDetail, modifierGroupsForm) {
  return (productDetail.modifierGroups || []).map((modifierGroup, index) => ({
    ...modifierGroup,
    disables: modifiersAllowed(
      modifierGroupsForm[index]?.modifiers || [],
      modifierGroup.selectType,
      modifierGroup.minAllowed,
      modifierGroup.maxAllowed,
    ),
  }));
}

/**
 * Método para saber si se cumplen los requerimientos minimos de opciones
 * @param {ProductDetail} productDetail El detalle del producto desde el servicio
 * @returns {Boolean} Si se cumple o no los requerimientos minimos
 */
function hasMinOptionsRequired(productDetail) {
  return productDetail.modifierGroups?.every((modifierGroup) => !modifierGroup.disables.min);
}

export {
  mixProductWithForm,
  getModifiersSubtotal,
  getProductPrice,
  modifiersAllowed,
  addDisablesToModifiers,
  hasMinOptionsRequired,
};
