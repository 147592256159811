import { useEffect } from 'react';
import { useLogin } from '../contexts/Login.context';
import {
  setUserInfoProperties,
} from '../services/amplitude';

import {
  useLandingPageEvents,
  useStorePageEvents,
  useCartPageEvents,
  useCheckoutPageEvents,
  useLoginPageEvents,
} from '../analytics';

export function useAmplitude() {
  const { userInfo } = useLogin();

  useEffect(() => {
    setUserInfoProperties(userInfo);
  }, [userInfo]);

  return {
    useLandingPageEvents,
    useStorePageEvents,
    useCartPageEvents,
    useCheckoutPageEvents,
    useLoginPageEvents,
  };
}

export default useAmplitude;
