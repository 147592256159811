import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '../Button';
import Drawer from '../Drawer';
import TimePicker from '.';

function TimePickerControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    fullWidth,
    classes,
    readOnly,
    disabled,
    open,
    onClose,
    ...args
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange,
          value,
        },
      }) => (
        <Drawer
          open={open}
          classes={{
            content: 'w-full max-w-7xl mx-auto',
          }}
        >
          <div className="flex flex-col space-y-8">
            <div>
              {label}
            </div>
            <TimePicker
              onChange={onChange}
              value={value}
              className={clsx(classes)}
              {...args}
            />
            <Button
              type="button"
              onClick={onClose}
              size="lg"
              fullWidth
            >
              Confirmar Hora
            </Button>
          </div>
        </Drawer>
      )}
    />
  );
}

TimePickerControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

TimePickerControlled.defaultProps = {
  label: null,
  classes: [],
  readOnly: false,
  disabled: false,
  fullWidth: false,
  open: false,
  onClose: () => null,
};

export default TimePickerControlled;
