import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../redux/actions/user';

export function useUser() {
  const dispatch = useDispatch();

  const updateUser = (user) => {
    dispatch(setUserInfo(user));
  };

  return {
    updateUser,
  };
}

export default useUser;
