import PropTypes from 'prop-types';

function ElectricBolt(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.225 21.775q-.2-.2-.225-.5-.025-.3.15-.525L13 14l-8.1-.775q-.65-.05-.863-.675-.212-.625.288-1.05L14.7 2.2q
        .225-.225.538-.213.312.013.537.238.2.2.225.5.025.3-.15.525L11 10l8.1.775q.65.05.862.675.213.625-.287 1.05L
        9.3 21.8q-.225.225-.537.212-.313-.012-.538-.237Z"
      />
    </svg>
  );
}

ElectricBolt.propTypes = {
  className: PropTypes.string,
};

ElectricBolt.defaultProps = {
  className: '',
};

export default ElectricBolt;
