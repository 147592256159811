import PropTypes from 'prop-types';
import { LogoBacuIcon } from '../../components/icons';
import HelpButton from './HelpButton';

function IntermidiateScreen({ footer, content }) {
  return (
    <div className="fixed flex bg-baco-secondary top-0 left-0 w-full h-full">
      <div className="hidden lg:block w-1/2 h-full bg-gray-400">
        <img
          className="w-full h-full object-left-bottom object-cover"
          src="/images/photos/main2.jpg"
          alt="Home"
        />
      </div>
      <div className="py-12 px-4 w-full lg:w-1/2">
        <div className="flex flex-col items-center w-full h-full">
          <div className="flex justify-end w-full">
            <HelpButton className="font-semibold w-auto" color="secondary" />
          </div>
          <div className="flex flex-col gap-y-4 items-center justify-center h-full">
            <LogoBacuIcon className="w-full max-w-[302px] h-[80px] fill-baco-primary" />
            {content}
          </div>
          <div className="w-full flex flex-col items-center gap-y-6">
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
}

IntermidiateScreen.propTypes = {
  footer: PropTypes.node,
  content: PropTypes.node,
};

IntermidiateScreen.defaultProps = {
  footer: <div />,
  content: <div />,
};

export default IntermidiateScreen;
