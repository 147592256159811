/* eslint-disable consistent-return */
import axios from 'axios';
import config from '../config';

const onRequest = (axiosConfig) => {
  const tmpConfig = { ...axiosConfig };
  const token = localStorage.getItem(config.bacuAccessToken);

  if (token) {
    tmpConfig.headers.Authorization = token;
  }

  return tmpConfig;
};

const onRequestError = (error) => Promise.reject(error);

const onResponse = (response) => response.data;

const onResponseError = async (error) => {
  if (error.response?.status === 401) {
    const storage = JSON.parse(localStorage.getItem(config.localStorageKey));
    const session = JSON.parse(storage.session);
    const { refreshToken } = session;

    if (refreshToken) {
      try {
        const baseUrl = process.env.NODE_ENV === 'development' ? '' : config.baseURL;
        const rs = await axios.post(`${baseUrl}${config.apiBase}OAuth/v1/secure/user/refreshToken`, {}, {
          headers: {
            Authorization: refreshToken,
          },
        });

        const { token } = rs.data.data;

        if (token) {
          localStorage.setItem(config.bacuAccessToken, token);
        }
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }

  return Promise.reject(error);
};

const setupInterceptorsTo = (
  axiosInstance,
) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export default setupInterceptorsTo;
