export function getDescriptionFromModifiers(modifierGroups) {
  return modifierGroups.map((modifierGroup) => {
    const modifiers = modifierGroup.modifiers
      .filter((modifier) => !!modifier.value)
      .map((modifier) => {
        if (modifierGroup.selectType === 'counters') {
          return `${modifier.value} ${modifier.name}`;
        }

        if (modifierGroup.selectType === 'radioBtn') {
          return `${modifierGroup.name}: ${modifier.value}`;
        }

        return modifier.name;
      });

    if (modifiers.length > 0) {
      return modifiers.join(' + ');
    }

    return null;
  }).filter((mg) => !!mg).join(' + ');
}

export function b64Encode(str) {
  return btoa(encodeURIComponent(str));
}

export function b64Decode(str) {
  return decodeURIComponent(atob(str));
}

export function getPaymentTypeName(paymentType) {
  return {
    card: 'Tarjeta de crédito / débito',
    bancolombia_transfer: 'Botón Bancolombia',
    pse: 'PSE',
    click_to_pay: 'Click To Pay',
  }[(paymentType || '').toLowerCase()] || paymentType;
}

export default getDescriptionFromModifiers;
