import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import { setRedirectUrl, setRefreshToken, setSessionToken } from '../../redux/actions/session';

export function useSession() {
  const { token, refreshToken, redirectUrl } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  const changeRedirectUrl = (uri) => {
    dispatch(setRedirectUrl(uri));
  };

  const changeSessionToken = (_token) => {
    localStorage.setItem(config.bacuAccessToken, _token);
    dispatch(setSessionToken(_token));
  };

  const changeRefreshToken = (_token) => {
    dispatch(setRefreshToken(_token));
  };

  return {
    redirectUrl,
    token,
    refreshToken,
    changeSessionToken,
    changeRefreshToken,
    changeRedirectUrl,
  };
}

export default useSession;
