import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  DeliverySuccessIcon,
  CloseIcon,
} from 'src/components/icons';
import { useOrderRating } from 'src/hooks/services/useOrder';
import Button from 'src/components/Button';
import { motion } from 'framer-motion';
import HeaderWithHelp from 'src/components/HeaderWithHelp';
import InputRating from '../../components/InputRating';
import { useDelivery } from '../../hooks/actions/useDelivery';
import Drawer from '../../components/Drawer';

function DeliveryError() {
  const navigate = useNavigate();
  const { deliveryData } = useDelivery();
  const clientName = deliveryData.Name;
  const { orderID } = useParams();
  const [openRating, setopenRating] = useState(false);
  const [drawerConfirmed, setDrawerConfirmed] = useState(false);
  const { isLoading, rate, setRating } = useOrderRating({ orderID });

  const hideRating = () => { setopenRating(false); };
  const handleSubmit = (rating) => setRating(rating);

  return (
    <motion.div
      className="fixed w-screen h-full overflow-auto"
      transition={{ duration: 0.5 }}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
    >
      <div className="flex flex-col w-screen h-screen items-center bg-white text-baco-primary">
        <div className="flex flex-col w-full h-screen items-center max-w-[430px] justify-between lg:justify-start">
          <div className="w-screen flex flex-col items-center">
            <div className="w-full max-w-[430px]">
              <HeaderWithHelp />
            </div>
            <div className="font-bold pt-4 px-4">{`${clientName}, tu pedido fue entregado.`}</div>
            <div className="pb-6 px-4 text-center font-bold text-2xl font-sans">
              <span>¡Disfrútalo!</span>
            </div>
            <div className="pt-6 px-4 scale-110">
              <DeliverySuccessIcon />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-y-2 w-full mt-12 px-4">
              <Button className="rounded-2xl px-6 py-2" size="lg" fullWidth onClick={() => setopenRating(true)}>
                <span className="inline-block w-full text-lg text-center">
                  Califica tu experiencia
                </span>
              </Button>
              <Button size="lg" color="secondary" fullWidth className="mb-4 !bg-transparent">
                <span className="inline-block w-full text-base text-center font-bold underline text-baco-carbon">
                  Volver al inicio
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={openRating}
        direction="bottom"
        keyboard="true"
        classes={{
          drawer: 'bg-gray-50',
          backdrop: 'z-20',
          content: 'h-full w-full max-w-4xl mx-auto',
        }}
      >
        <div className="flex flex-col w-full checked:bg-baco-primary">
          <nav className="flex justify-end px-2 items-end">
            <button
              className="flex w-5 h-5 justify-between items-center"
              type="button"
              onClick={hideRating}
            >
              <CloseIcon className="w-5 h-5 scale-110" />
            </button>
          </nav>
          {!drawerConfirmed ? (
            <>
              <div className="font-bold text-xl py-2 px-3">¡Gracias por pedir en Bacu!</div>
              <div className="text-md text-left px-4">
                Califica tu experiencia y ayúdanos a mejorar.
              </div>
              <div className="flex flex-col items-center py-8">
                <InputRating size="lg" value={rate} onChange={handleSubmit} disabled={isLoading} />
              </div>
              <Button
                type="submit"
                size="lg"
                fullWidth
                className="!text-base font-bold"
                onClick={() => setDrawerConfirmed(true)}
              >
                Enviar
              </Button>
              <button
                type="button"
                className="underline text-center text-sm mt-4 mb-6 hover:cursor-pointer"
                onClick={() => navigate('/')}
              >
                Ir al inicio
              </button>
            </>
          ) : (
            <>
              <div className="font-bold text-xl py-24 px-3 text-center">
                ¡Gracias por calificarnos!
              </div>
              <Button
                size="lg"
                fullWidth
                className="!text-base font-bold"
                onClick={() => navigate('/')}
              >
                Volver al inicio
              </Button>
            </>
          )}
        </div>
      </Drawer>
      {/* </ViewWithAd> */}
    </motion.div>
  );
}

export default DeliveryError;
