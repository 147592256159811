import { useNavigate, useParams } from 'react-router-dom';
import { motion, MotionConfig } from 'framer-motion';
import clsx from 'clsx';
import Button from '../components/Button';
import InputRating from '../components/InputRating';
import {
  BagIcon,
  LogoBacuIcon,
} from '../components/icons';
import { useOrderRating } from '../hooks/services/useOrder';

function SearchOrder() {
  const navigate = useNavigate();
  const { orderID } = useParams();
  const { isLoading, rate, setRating } = useOrderRating({ orderID });

  const handleSubmit = (rating) => setRating(rating);
  const handleGoToStore = () => navigate('/');

  return (
    <MotionConfig transition={{ duration: 0.5 }}>
      <motion.div
        className={
          clsx(
            'fixed bg-baco-secondary top-0 bottom-0 w-screen px-4 pt-10',
            'h-full overflow-y-auto overflow-x-hidden z-10',
          )
        }
        initial={{ left: '100%' }}
        animate={{ left: 0 }}
        exit={{ left: '100%' }}
      >
        <div className="flex flex-col justify-between items-center w-full h-full max-w-4xl mx-auto">
          <div className="flex flex-col items-center">
            <LogoBacuIcon className="h-8 w-20 fill-baco-primary mb-10" />
            <h2 className="text-baco-primary text-2xl font-bold text-center max-w-sm mx-auto">
              Tu pedido ha sido recogido, Esperamos que lo disfrutes.
            </h2>
          </div>

          <div className="flex flex-col gap-y-4 items-center">
            <InputRating
              size="lg"
              value={rate}
              onChange={handleSubmit}
              disabled={isLoading}
            />
          </div>

          <div className="flex flex-col gap-y-4 w-full">
            <Button
              className="font-semibold"
              onClick={handleGoToStore}
              color="primary"
              fullWidth
              size="lg"
            >
              <span className="flex justify-center items-center gap-2">
                <BagIcon className="h-6 w-6" />
                Ir a inicio
              </span>
            </Button>
          </div>
        </div>
      </motion.div>
    </MotionConfig>
  );
}

export default SearchOrder;
