import PropTypes from 'prop-types';

function Numeral(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.52653 7.088L1.95253 10H3.10053L3.67453 7.088H5.70453L5.13053 10H6.27853L6.85253
          7.088H9.49853V5.996H7.07653L7.42653 4.218H9.91853V3.126H7.65053L8.23853 0.199999H7.09053L6.50253
          3.126H4.47253L5.06053 0.199999H3.91253L3.32453 3.126H0.832532V4.218H3.10053L2.75053
          5.996H0.412532V7.088H2.52653ZM3.89853
          5.996L4.24853 4.218H6.27853L5.92853 5.996H3.89853Z"
        fill="#5C5C5C"
      />
    </svg>
  );
}

Numeral.propTypes = {
  className: PropTypes.string,
};

Numeral.defaultProps = {
  className: '',
};

export default Numeral;
