import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Button from '../Button';
import { CloseIcon } from '../icons';

function DiscountInput(props) {
  const {
    disabled,
    fullWidth,
    isLoading,
    onChange,
    placeholder,
    size,
    value,
  } = props;

  const {
    name: label,
    type,
    value: discount,
  } = value || {};

  const [code, setCode] = useState('');
  const handleClearPromo = () => onChange(undefined);
  const handleChangeCode = (event) => setCode((event.target.value || '').toUpperCase());
  const handleChange = () => onChange(code);

  if (type && discount) {
    return (
      <div className={clsx('flex', { 'justify-center w-full': fullWidth })}>
        <div className="flex items-center gap-x-2 rounded-md bg-gray-100 text-baco-primary px-2 py-1">
          <span className="text-sm font-semibold">
            {`${label} ${discount}${type === 'percentage' ? '%' : 'COP'} off`}
          </span>
          <div className="w-0 h-full border-l border-l-gray-300" />
          <button
            className="flex justify-center items-center w-4 h-4 border border-baco-primary rounded-full"
            type="button"
            onClick={handleClearPromo}
          >
            <CloseIcon className="w-3 h-3 fill-baco-primary font-semibold" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        clsx(
          'flex flex-nowrap overflow-hidden w-[280px]',
          { '!w-full': fullWidth },
        )
      }
    >
      <input
        className="w-full rounded-l-md border bg-gray-50 border-gray-300 px-4"
        type="text"
        placeholder={placeholder}
        value={code}
        onChange={handleChangeCode}
        disabled={disabled}
      />
      <Button
        className="!rounded-l-none font-bold"
        size={size}
        color="primary-gray"
        onClick={handleChange}
        disabled={disabled}
        isLoading={isLoading}
      >
        Ingresar
      </Button>
    </div>
  );
}

DiscountInput.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['percentage', 'price']),
    discount: PropTypes.number,
  }),
};

DiscountInput.defaultProps = {
  disabled: false,
  fullWidth: false,
  isLoading: false,
  onChange: () => null,
  placeholder: 'Ingresa el código',
  size: 'md',
  value: undefined,
};

export default DiscountInput;
