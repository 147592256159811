import PropTypes from 'prop-types';
import clsx from 'clsx';
import RadioBox from '../RadioBox';

function RadioBoxWithIcon(props) {
  const {
    className,
    icon,
    label,
    name,
    id,
    ...args
  } = props;

  return (
    <label
      className={clsx('flex items-center px-7 py-3 rounded-lg bg-gray-50 cursor-pointer', className)}
      htmlFor={`${name}-${id}`}
    >
      <span className="mr-4">
        {icon && (<span>{icon}</span>)}
      </span>
      <span className="w-full">
        {label && (<p className="m-0">{label}</p>)}
      </span>
      <span>
        <RadioBox id={`${name}-${id}`} name={name} size="lg" {...args} />
      </span>
    </label>
  );
}

RadioBoxWithIcon.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  icon: PropTypes.node,
  label: PropTypes.node,
  className: PropTypes.string,
};
RadioBoxWithIcon.defaultProps = {
  icon: null,
  label: null,
  className: '',
};

export default RadioBoxWithIcon;
