/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query';
import { register } from '../../services/api';
import { useSession } from '../actions/useSession';
import { useAmplitude } from '../useAmplitude';

export function useSignUp() {
  const { changeSessionToken, changeRefreshToken, token } = useSession();
  const { trackSignUpCreateAccountSuccess } = useAmplitude().useLoginPageEvents();
  const {
    mutate: signUp,
    isLoading,
    error,
  } = useMutation(({
    email,
    password,
    last_name,
    first_name,
    phone,
  }) => register({
    email, password, last_name, first_name, phone,
  }), {
    onSuccess: (res) => {
      trackSignUpCreateAccountSuccess();
      changeRefreshToken(res.data.refresh_token);
      changeSessionToken(res.data.token);
    },
  });

  return {
    isLoading, token, signUp, error,
  };
}

export default useSignUp;
