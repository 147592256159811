import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import config from '../config';

const { firebaseConfig } = config;

let fcmToken;
let fcmInstance;

export default {
  getFCMToken: async () => {
    if (fcmToken) {
      return {
        token: fcmToken,
        messaging: fcmInstance,
      };
    }

    const perm = await Notification.requestPermission();
    if (perm !== 'granted') {
      return {
        notifications: false,
      };
    }

    const firebaseApp = initializeApp(firebaseConfig.app);
    const messaging = getMessaging(firebaseApp);

    const token = await getToken(messaging, { validKey: firebaseConfig.vapidKey });

    if (!token) {
      return {};
    }

    fcmToken = token;
    fcmInstance = messaging;

    return { token, messaging, notifications: true };
  },
};
