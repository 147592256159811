import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import config from '../../config';
import { getOrderDetail, rateOrder } from '../../services/api';

const TIMES = [1, 2, 5, 10, 30];
let retries = -1;

export function useOrderStatus({
  orderID,
  status,
  onStatusChange,
  onCloseModal,
}) {
  const { data: order = {}, isLoading } = useQuery(
    ['productDetail', orderID],
    () => getOrderDetail(orderID),
    {
      enabled: !!orderID,
      refetchOnWindowFocus: () => [config.paymentStatus.PENDING, 'LOADING'].includes(status),
      refetchInterval: (data) => {
        if (status === config.paymentStatus.ERROR) {
          return false;
        }

        if (!data?.transaction_status) {
          return TIMES[retries] * 1000;
        }

        if ([config.paymentStatus.PENDING, config.paymentStatus.AWAITING_WEBHOOK].includes(data?.transaction_status)) {
          retries = Math.min(TIMES.length - 1, retries + 1);

          if (retries > config.maxRetriesBeforeError) {
            onStatusChange(
              !data?.transaction_status
                ? config.paymentStatus.ERROR
                : config.paymentStatus.PENDING,
            );
          }

          return TIMES[retries] * 1000;
        }

        onStatusChange(data?.transaction_status);
        if (data?.transaction_status === config.paymentStatus.SUCCEEDED) {
          setTimeout(() => {
            onCloseModal();
          }, retries < 2 ? 0 : 2000);
        }

        return false;
      },
      retry: (errorRetries) => {
        if (errorRetries > config.maxRetriesBeforeError) {
          onStatusChange(config.paymentStatus.ERROR);
          return false;
        }

        return true;
      },
    },
  );

  return { isLoading, order };
}

export function useOrderRating({
  orderID,
}) {
  const [rate, setRate] = useState(0);
  const {
    mutate: setRating,
    isLoading,
  } = useMutation((rating) => rateOrder(orderID, rating), {
    onSuccess: (data) => {
      setRate(data.rating);
    },
  });

  return { isLoading, rate, setRating };
}

export default useOrderStatus;
