import PropTypes from 'prop-types';

function AssistantNavigation(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      width="24px"
      height="24px"
      viewBox="0 0 8 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5835 6.0997L4 5.4542L5.4165 6.0997L5.469 6.0477L4 2.4747L2.531
        6.0477L2.5835 6.0997ZM4 8.0167C3.507 8.0167 3.0435 7.92287 2.6095 7.7352C2.1755
        7.54787 1.79534 7.29087 1.469 6.9642C1.14234 6.63787 0.885337 6.2577 0.698004
        5.8237C0.510337 5.3897 0.416504 4.9262 0.416504 4.4332C0.416504 3.9332 0.510337
        3.46787 0.698004 3.0372C0.885337 2.60687 1.14234 2.22853 1.469 1.9022C1.79534 1.57553
        2.1755 1.31853 2.6095 1.1312C3.0435 0.943534 3.507 0.849701 4 0.849701C4.5 0.849701
        4.96534 0.943534 5.396 1.1312C5.82634 1.31853 6.20467 1.57553 6.531 1.9022C6.85767 2.22853
        7.11467 2.60687 7.302 3.0372C7.48967 3.46787 7.5835 3.9332 7.5835 4.4332C7.5835 4.9262
        7.48967 5.3897 7.302 5.8237C7.11467 6.2577 6.85767 6.63787 6.531 6.9642C6.20467 7.29087
        5.82634 7.54787 5.396 7.7352C4.96534 7.92287 4.5 8.0167 4 8.0167ZM4 7.6832C4.90267 7.6832
        5.67 7.3672 6.302 6.7352C6.934 6.1032 7.25 5.33587 7.25 4.4332C7.25 3.53053 6.934 2.7632 6.302
        2.1312C5.67 1.4992 4.90267 1.1832 4 1.1832C3.09734 1.1832 2.33 1.4992 1.698 2.1312C1.066 2.7632 0.750004
        3.53053 0.750004 4.4332C0.750004 5.33587 1.066 6.1032 1.698 6.7352C2.33 7.3672 3.09734 7.6832 4 7.6832Z"
      />
    </svg>
  );
}

AssistantNavigation.propTypes = {
  className: PropTypes.string,
};

AssistantNavigation.defaultProps = {
  className: '',
};

export default AssistantNavigation;
