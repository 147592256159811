import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import SelectBox from '.';

function SelectBoxControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    placeholder,
    options,
    classes,
    readOnly,
    disabled,
    fullWidth,
    direction,
    multiValueInput,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        fieldState,
        field: {
          onChange,
          onBlur,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          direction={direction}
          className={clsx(classes)}
          fieldState={fieldState}
          multiValueInput={multiValueInput}
        >
          <SelectBox
            className={clsx(classes)}
            name={name}
            placeholder={placeholder}
            value={value}
            options={options}
            onBlur={onBlur}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
            fullWidth={fullWidth}
            fieldState={fieldState}
          />
        </InputContainer>
      )}
    />
  );
}

SelectBoxControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  helper: PropTypes.node,
  placeholder: PropTypes.string,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  fullWidth: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  multiValueInput: PropTypes.bool,
};

SelectBoxControlled.defaultProps = {
  label: null,
  helper: null,
  placeholder: '',
  classes: [],
  readOnly: false,
  disabled: false,
  fullWidth: false,
  options: [],
  direction: 'column',
  multiValueInput: false,
};

export default SelectBoxControlled;
