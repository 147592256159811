import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import clsx from 'clsx';
import InputContainer from '../InputContainer';
import InputPhoneNumber from '.';
import { useCountryCode } from '../../hooks/services/useCountryCode';

function InputPhoneNumberControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    readOnly,
    disabled,
    fullWidth,
    direction,
    classes,
    ...args
  } = props;

  const { isLoadingCountryCode, CountryCode } = useCountryCode();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        fieldState,
        field: {
          onChange,
          onBlur,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          direction={direction}
          fieldState={fieldState}
          className={clsx(classes)}
        >
          <InputPhoneNumber
            {...args}
            options={CountryCode?.countries}
            name={name}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
            fullWidth={fullWidth}
            fieldState={fieldState}
            isLoading={isLoadingCountryCode}
          />
        </InputContainer>
      )}
    />
  );
}

InputPhoneNumberControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  helper: PropTypes.node,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  classes: PropTypes.array,
};

InputPhoneNumberControlled.defaultProps = {
  label: null,
  helper: null,
  readOnly: false,
  disabled: false,
  fullWidth: false,
  direction: 'column',
  classes: [],
};

export default InputPhoneNumberControlled;
