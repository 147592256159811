import PropTypes from 'prop-types';
import { useState } from 'react';
import clsx from 'clsx';
import useShoppingCart from 'src/hooks/actions/useShoppingCart';
import Button from '../../components/Button';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '../../components/icons';
import { useDelivery } from '../../hooks/actions/useDelivery';
import { useStore } from '../../hooks/actions/useStore';
import { formatCurrency } from '../../utils/currency';

function DeliveryDetails(props) {
  const [showDetails, setShowDetails] = useState(true);
  const { onSubmit, total, totalWithDiscount } = props;
  const handleToggleDetails = () => setShowDetails((prev) => !prev);
  const { selectedStore } = useStore();

  const { deliveryData } = useDelivery();
  const { items } = useShoppingCart();

  return (
    <div className="block h-full overflow-y-auto py-4 w-full bg-white">
      <div className="flex flex-col w-full max-w-md mx-auto">
        <div className="flex flex-col items-center h-full fill-baco-primary">
          <div className="w-full max-w-md mx-auto content-center">
            <div className="bg-baco-secondary-100 rounded-lg pb-6 px-4 mb-10">
              <hr className="w-full h-[1px] border-none mt-2 mb-8 bg-gray-300" />
              <div className="text-gray-500 text-sm lg:text-base text-center font-normal leading-6 mb-7 italic">
                {/* {`Tiempo estimado de entrega ${deliveryETA} minutos`} */}
              </div>
              <div
                className="w-full bg-[#FCF2E8] h-auto align-bottom pl-4 rounded-xl"
              >
                <div className="inline-block py-6 flex-col w-[62%] ">
                  <p className="text-baco-primary font-bold text-base leading-6 tracking-wide mb-2">
                    Dirección de entrega
                  </p>
                  <hr className="border-none h-[1px] w-[134px] bg-baco-primary my-2" />
                  <p className="text-baco-primary text-sm font-normal leading-6 tracking-wide">
                    {deliveryData.address}
                  </p>
                  <p className="text-baco-primary text-sm font-normal leading-6 tracking-wide mb-1">
                    {deliveryData.addressDetail}
                  </p>
                </div>
                <div
                  className="inline-block h-36 align-top bg-right
                  rounded-br-xl rounded-tr-xl w-[38%]"
                  style={{
                    backgroundImage:
                    'url(/images/banners/BacuRunner.jpg)',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </div>
            </div>
            <hr className="border-none h-[1px] max-w-[90%] w-full bg-gray-200 mb-10 mt-2 mx-auto" />
            <div
              className={clsx('transition-all overflow-hidden px-5', {
                'max-h-[35px]': !showDetails,
                'max-h-full': showDetails,
              })}
            >
              <h2
                className={clsx('flex justify-between items-center w-full', {
                  'mb-4': showDetails,
                  'mb-2': !showDetails,
                })}
              >
                <span className="text-base text-gray-700 font-bold">
                  Detalles del Pedido
                </span>
                <button className="text-slate-800" onClick={handleToggleDetails} type="button">
                  {showDetails ? (
                    <span className="flex justify-start text-gray-700 items-center gap-2">
                      <ChevronDownIcon className="h-9 w-9" />
                    </span>
                  ) : (
                    <span className="flex justify-start items-center gap-2">
                      <ChevronUpIcon className="h-9 w-9" />
                    </span>
                  )}
                </button>
              </h2>
              <ul className="text-gray-800 text-xs mb-6">
                {items?.map((item) => (
                  <li key={item.id} className="flex flex-col justify-between">
                    <span className="flex justify-between mb-1">
                      <span className="w-7">{`${item.count} x`}</span>
                      <span className="w-full">{item.name}</span>
                    </span>
                  </li>
                ))}
                <hr className="border-none h-[1px] w-full bg-gray-800 mb-2 mt-2 mx-auto" />
                <li className="flex justify-end pt-2 text-gray-800">
                  <span>Subtotal</span>
                  <span className="pl-2">
                    {formatCurrency(totalWithDiscount > 0
                      ? totalWithDiscount
                      : total)}
                  </span>
                </li>
                <li className="flex justify-end pt-1 text-gray-800">
                  <span>Costo de envío </span>
                  <span className="pl-1">
                    {formatCurrency(Number(selectedStore.shipping_cost))}
                  </span>
                </li>
                <li className="flex justify-end pt-1 text-sm font-bold border-t-2 border-t-white">
                  <span>Total</span>
                  <span className="pl-2">
                    {formatCurrency(totalWithDiscount > 0
                      ? totalWithDiscount + Number(selectedStore.shipping_cost)
                      : total + Number(selectedStore.shipping_cost))}
                  </span>
                </li>
              </ul>
            </div>
            <div className="w-full max-w-md mx-auto px-4 py-6 bottom-0
              shadow-baco-primary-100 shadow-outline absolute
              shadow-[0_-1px_22px_-5px]"
            >
              <Button
                type="submit"
                style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}
                size="lg"
                fullWidth
                className="!text-base"
                onClick={() => onSubmit()}
              >
                <span className="mr-2">Continuar |</span>
                {totalWithDiscount > 0
                  ? (
                    <>
                      <span className="line-through text-gray-200 mr-2">
                        {formatCurrency(total + Number(selectedStore.shipping_cost))}
                      </span>
                      <span className="font-bold">
                        {formatCurrency(totalWithDiscount + Number(selectedStore.shipping_cost))}
                      </span>
                    </>
                  ) : formatCurrency(total + Number(selectedStore.shipping_cost))}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryDetails;

DeliveryDetails.propTypes = {
  onSubmit: PropTypes.func,
  total: PropTypes.number,
  totalWithDiscount: PropTypes.number,
};

DeliveryDetails.defaultProps = {
  onSubmit: () => null,
  total: 0,
  totalWithDiscount: 0,
};
