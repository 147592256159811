import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import clsx from 'clsx';
import { LogoIcon } from '../../components/icons';
import { useYupValidationResolver } from '../../hooks/useYupValidatorResolver';
import Button from '../../components/Button';
import FormInputPanel from '../../components/FormInputPanel';
import HeaderWithButton from '../../components/HeaderWithButton';
import { LoginScreens, useLogin } from '../../contexts/Login.context';
import { useForgotRequest } from '../../hooks/services/useForgotRequest';

const userEmailSchema = yup.object({
  email: yup.string().email('Ingresa un correo electrónico válido')
    .required('El correo electrónico es obligatorio'),
});

function FormForgotPassword() {
  const { handleChangeScreen, handleChangeEmailRecoverPass } = useLogin();
  const resolver = useYupValidationResolver(userEmailSchema);

  const methods = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    resolver,
  });
  const { forgotRequest, isLoadingForgotRequest } = useForgotRequest(methods.setError);

  const onSubmit = (data) => {
    handleChangeEmailRecoverPass(data.email);
    forgotRequest(data);
  };

  const handleBack = () => handleChangeScreen(LoginScreens.LOGIN, 'right');
  const handleGoRegister = () => handleChangeScreen(LoginScreens.REGISTER);

  return (
    <div className="w-full max-w-6xl mx-auto h-full flex flex-col overflow-hidden">
      <HeaderWithButton
        buttonVariant="normal"
        onClick={handleBack}
        endAdornment={(
          <LogoIcon className={clsx(
            'h-16 w-8 sm:h-14 sm:w-7 md:h-10 md:w-10',
            'fill-baco-primary  mr-5 md:mr-24',
          )}

          />
        )}
      />

      <div className="w-full flex flex-col justify-start items-center flex-1 overflow-auto">
        <div className="flex flex-col py-10 h-full px-3 w-full max-w-lg overflow-auto">
          <h2 className="text-baco-primary font-bold text-3xl mb-2">Olvidaste tu contraseña</h2>
          <span className="text-gray-700">
            Te enviaremos un email con instrucciones sobre cómo
            restablecer tu contraseña
          </span>

          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="flex flex-col h-full justify-between md:justify-start"
              noValidate
            >
              <div>
                <FormInputPanel
                  id="login"
                  panelElements={[{
                    type: 'email',
                    name: 'email',
                    placeholder: 'Correo electrónico',
                    fullWidth: true,
                    classes: ['my-4'],
                    autoComplete: 'username',
                  },
                  ]}
                />
              </div>
              <div className="md:mt-20">
                <Button
                  type="submit"
                  size="lg"
                  fullWidth
                  className="mb-4"
                  isLoading={isLoadingForgotRequest}
                  disabled={!methods.formState.isValid}
                >
                  Continuar
                </Button>

                <span className="inline-block w-full text-base text-center text-gray-700">
                  ¿Aún no tienes cuenta?
                  <button
                    className="underline text-baco-primary ml-1 font-semibold"
                    type="button"
                    onClick={handleGoRegister}
                  >
                    Regístrate
                  </button>
                </span>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>

  );
}

export default FormForgotPassword;
