import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '../Button';

function InputCounterNumber(props) {
  const {
    value,
    min,
    max,
    step,
    onChange,
    onConfirmOptions,
    fullWidth,
    readOnly,
    disabled,
    maxDisabled,
  } = props;

  const handleSubtract = () => {
    if (readOnly) {
      return;
    }

    const newVal = Math.max(value - step, min);
    if (newVal === min) {
      const changeValue = onConfirmOptions(true);

      if (changeValue) {
        onChange(newVal);
      }

      return;
    }

    onChange(newVal);
  };

  const handleAdd = () => {
    if (!readOnly) {
      onChange(Math.min(value + step, max));
      onConfirmOptions(false);
    }
  };

  const wrapperClasses = clsx(
    'flex justify-start items-center',
    {
      'justify-between w-full': fullWidth,
    },
  );

  const textClasses = clsx(
    'px-4',
    {
      'opacity-60': disabled,
    },
  );

  return (
    <div className={wrapperClasses}>
      <Button
        onClick={handleSubtract}
        disabled={disabled || value === min}
        className="w-[50px]"
        color="secondary"
        size="sm"
      >
        -
      </Button>
      <span className={textClasses}>
        {value}
      </span>
      <Button
        onClick={handleAdd}
        disabled={disabled || value === max || maxDisabled}
        className="w-[50px]"
        color="secondary"
        size="sm"
      >
        +
      </Button>
    </div>
  );
}

InputCounterNumber.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onConfirmOptions: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  maxDisabled: PropTypes.bool,
};
InputCounterNumber.defaultProps = {
  value: 0,
  min: 0,
  max: 999,
  step: 1,
  onChange: () => null,
  onConfirmOptions: () => true,
  fullWidth: false,
  readOnly: false,
  disabled: false,
  maxDisabled: false,
};

export default InputCounterNumber;
