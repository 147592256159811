import { combineReducers } from 'redux';
import shoppingCartReducer from './shoppingCart';
import storeReducer from './locations';
import fcmReducer from './fcm';
import userReducer from './user';
import deliveryReducer from './delivery';
import sessionReducer from './session';

const rootReducer = combineReducers({
  location: storeReducer,
  cart: shoppingCartReducer,
  fcm: fcmReducer,
  user: userReducer,
  delivery: deliveryReducer,
  session: sessionReducer,
});

export default rootReducer;
