import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import config from '../../config';
import { getConfigCategories } from '../../services/api';
import { getCurrentDayMinutes, getMinutesOfDay } from '../../utils/date';

const MIN_MS = 60000;

export function useConfigCategories({
  storeID,
}) {
  const {
    data: configCategories = [],
    isLoading: isLoadingConfig,
  } = useQuery(['getConfigCategories'], () => getConfigCategories(), {
    enabled: !!storeID,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
  const availableCategories = useMemo(() => configCategories.filter((categoryConfig) => {
    const { fastSchedule: { to } } = categoryConfig;
    const minTime = config.superFast.minTime / MIN_MS;
    return getCurrentDayMinutes() + minTime < getMinutesOfDay(to);
  }), [configCategories, isLoadingConfig]);

  return { isLoadingConfig, availableCategories };
}

export default useConfigCategories;
