import PropTypes from 'prop-types';

function DeliveryCreated(props) {
  const { className } = props;

  return (
    <svg
      width="263"
      height="151"
      viewBox="0 0 263 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M214.719 92.1258C214.786 96.7019 215.129 120.305 200.869 129.457C190.12 136.351
      182.087 127.976 159.319 127.65C128.548 127.217 124.134 142.252 94.8861 141.499C85.1128
      141.246 61.7423 140.644 48.5185 124.64C28.1408 99.9773 48.3559 58.7085 49.7228 55.999C62.3264
      31.0836 88.8945 10.9008 120.78 9.03428C167.948 6.27058 195.383 45.4139 200.267 52.3864C205.109
      59.3046 214.442 72.9967 214.719 92.1258Z"
        fill="#FFB869"
        fillOpacity="0.08"
      />
      <path
        d="M133.018 55.6838C128.867 35.8805 109.861 34.6964 101.807 34.7345C88.828 34.7962 82.144
       44.2491 69.415 41.3603C57.77 38.7189 57.7319 29.5314 47.3436 28.8543C37.2014 28.1931 24.2089 36.104
        19.3752 46.7585C10.5881 66.1303 30.969 89.7154 42.9158 99.2719C50.4934 105.334 67.1226 118.636
         88.9831 111.508C109.535 104.806 138.886 83.6807 133.017 55.6832L133.018 55.6838Z"
        fill="#F7F5F5"
      />
      <path
        d="M210.671 21.3691C216.925 26.1643 215.657 32.1837 214.241 34.594C210.316 38.8392 200.331 45.3529
       191.789 37.4457C183.247 29.5384 190.169 22.2599 194.697 19.6091C197.416 18.1978 204.418 16.574 210.671 21.3691Z"
        fill="#FFEDDD"
      />
      <path
        d="M183.897 124.947H126.582C126.148 125.139 127.489 126.462 128.878 126.921C130.615 127.495 200.326 128.105
      199.528 126.096C199.221 125.321 195.984 124.947 183.897 124.947Z"
        fill="#8E8A8A"
      />
      <path
        d="M132.325 85.3075C145.281 85.3075 158.237 85.3815 171.193 85.2582C174.105 85.2336 174.771 86.3674 174.944
       89.0049C175.487 96.5477 173.982 103.523 169.614 109.686C166.998 113.383 163.839 116.637 160.113 119.324C152.758
       124.599 146.391 127.064 135.163 126.965C126.081 126.965 123.811 126.99 117.962 125.733C109.843 123.983 103.18
       119.743 97.6275 113.654C93.5556 109.193 90.5942 104.14 89.4097 98.1499C88.7187 94.625 89.0889 91.0508 89.1876
       87.5013C89.2369 85.4554 90.8163 85.3075 92.4944 85.3075C105.771 85.3568 119.023 85.3075 132.3
       85.3075H132.325ZM128.673
       96.942C139.679 96.942 150.71 96.942 161.717 96.942C163.025 96.942 164.949 97.4843 164.925 95.2412C164.925 93.3432
       163.345 93.2693 161.791 93.2693C139.753 93.2693 117.74 93.2693 95.7026 93.2693C94.3453 93.2693 92.7165 92.8502
       92.7659 95.2166C92.8399 97.3857 94.37 96.942 95.6039 96.942C106.61 96.942 117.641 96.942 128.648 96.942H128.673Z"
        fill="#D19161"
      />
      <path
        d="M143.317 82.4967C150.967 73.6969 157.334 64.9709 164.663 57.0338C167.156 54.347 170.438 54.1991 173.498
      56.7873C175.349 58.3402 175.448 62.7032 173.597 64.823C168.168 71.0101 161.529 75.8907 155.754 81.7326C155.236
      82.2749 154.595 82.5214 153.83 82.5214C150.597 82.5214 147.364 82.5214 143.341 82.5214L143.317 82.4967Z"
        fill="#5C5C5C"
      />
      <path
        d="M128.752 97.7501C117.997 97.7501 107.218 97.7501 96.4636 97.7501C95.2579 97.7501 92.8327 98.305 92.7603
       95.0086C92.688 91.4529 95.2097 92.2314 96.56 92.2314C118.094 92.2314 139.603 92.2314 161.137 92.2314C162.632
       92.2314
       166.03 92.1566 166.055 95.0086C166.055 98.342 162.342 97.7501 161.064 97.7501C150.309 97.7871 139.531 97.7501
       128.776
       97.7501H128.752Z"
        fill="#D19161"
      />
      <path
        d="M169.799 90.7217C169.542 98.4813 164.202 113.571 147.421 119.82"
        stroke="#E2B491"
        strokeWidth="3.15789"
        strokeLinecap="round"
      />
      <path
        d="M146.632 80.8537L165.184 59.1431C166.237 57.9589 169.053 55.9852 171.895 57.5642"
        stroke="#AEAEAE"
        strokeOpacity="0.6"
        strokeWidth="1.57895"
        strokeLinecap="round"
      />
      <path
        d="M109.256 35.459C113.018 36.7183 116.209 38.5057 117.867 42.1957C118.912 44.5253 118.968 46.9668 118.532
       49.4365C117.891 53.0656 116.275 56.2786 114.308 59.3623C113.171 61.1442 112.078 62.9729 111.17 64.8766C108.859
       69.7172 109.556 74.2254 112.983 78.3326C113.562 79.0258 114.188 79.6788 114.89 80.459C114.152 80.3351 113.518
       80.2645 112.901 80.12C108.455 79.078 104.668 76.9038 101.757 73.3952C98.1564 69.0555 97.5711 64.2312 99.7362
       59.0613C100.768 56.5959 102.378 54.4901 103.972 52.3735C105.695 50.0841 107.433 47.8045 109.1 45.476C109.685
       44.6578 110.114 43.7223 110.557 42.8128C111.329 41.2297 111.284 39.6292 110.53 38.0472C110.134 37.216 109.718
       36.3945 109.256 35.459Z"
        fill="white"
      />
      <path
        d="M122.127 46.6182C123.69 47.0919 125.074 47.7406 126.289 48.7163C128.466 50.4668 129.382 52.779 129.25
      55.5149C129.089 58.8376 127.713 61.7246 125.96 64.4834C125.135 65.7819 124.335 67.1053 123.652 68.4809C121.732
      72.3491 122.314 75.9119 125.145 79.1433C125.51 79.5595 125.894 79.9593 126.344 80.4494C123.153 80.0506 120.475
      78.8032 118.126 76.8268C116.424 75.3947 115.117 73.6801 114.447 71.5407C113.534 68.633 113.948 65.8199 115.495
      63.2806C116.914 60.9521 118.618 58.7942 120.226 56.5819C121.282 55.1303 122.4 53.7265 123.116 52.0575C123.61
      50.9036 123.621 49.7757 123.105 48.6424C122.807 47.9894 122.481 47.3483 122.129 46.6182H122.127Z"
        fill="white"
      />
      <path
        d="M123.553 78.5641C121.877 76.299 119.826 73.5966 120.343 70.5639C120.47 69.8006 120.777 69.0812 121.145
      68.4099C121.487 67.7636 121.864 67.1324 122.264 66.522C123.244 65.0021 124.389 63.6591 125.401 62.1957C125.764
      61.6309 126.081 61.0085 126.344 60.3919C126.857 59.1271 127.187 57.7615 127.27 56.395C127.304 55.9888 127.302
      55.5072 127.293 55.1015C127.285 54.8999 127.25 54.5256 127.239 54.3259C127.158 53.6504 127.032 52.9436 126.827
      52.2949C126.336 50.5994 125.385 49.1061 124.14 47.8379C125.75 48.952 127.016 50.6729 127.663 52.5759C127.722
      52.7833 127.853 53.1795 127.902 53.3772C127.957 53.6475 128.028 53.9281 128.077 54.1967C128.109 54.4059 128.17
       54.8296 128.194 55.0293C128.209 55.3047 128.236 55.5954 128.249 55.8693C128.25 56.079 128.252 56.5115 128.247
        56.7118C128.166 58.4912 127.735 60.2743 126.929 61.8592C126.69 62.3448 126.369 62.8774 126.053 63.3133C125.195
         64.4983 123.994 65.9673 123.173 67.1633C122.587 68.0175 122.008 68.8878 121.557 69.8231C120.197 72.8525 122.246
         75.8974 123.553 78.5641Z"
        fill="#E3E3E3"
      />
      <path
        d="M111.339 78.8018C109.241 75.6974 106.673 71.9943 107.359 67.809C107.527 66.7557 107.924 65.7609 108.396
       64.8319C108.836 63.9375 109.32 63.0637 109.833 62.2182C111.088 60.1131 112.552 58.25 113.848 56.2226C114.313
        55.4403 114.721 54.5792 115.06 53.7266C115.723 51.9781 116.155 50.0924 116.274 48.2081C116.322 47.6479 116.324
        46.9842 116.317 46.4251C116.309 46.1473 116.269 45.6318 116.256 45.3566C116.161 44.4266 116.009 43.4536 115.756
        42.5618C115.152 40.23 113.965 38.1818 112.402 36.4468C114.428 37.9655 116.012 40.3242 116.811 42.9403C116.884
        43.2255 117.045 43.7703 117.105 44.0422C117.172 44.4142 117.26 44.8001 117.319 45.1698C117.357 45.4579 117.43
        46.0412 117.457 46.3162C117.474 46.6955 117.506 47.0959 117.518 47.4734C117.517 47.7624 117.516 48.3584 117.507
         48.6346C117.387 51.0879 116.822 53.5499 115.785 55.7428C115.478 56.4146 115.066 57.152 114.661 57.7561C113.563
          59.3984 112.028 61.4355 110.976 63.0926C110.225 64.276 109.484 65.4815 108.903 66.7753C107.151 70.9648 109.712
          75.14 111.339 78.8018Z"
        fill="#E3E3E3"
      />
    </svg>

  );
}

DeliveryCreated.propTypes = {
  className: PropTypes.string,
};

DeliveryCreated.defaultProps = {
  className: '',
};

export default DeliveryCreated;
