import axios from 'axios';
import {
  defaultHeaderInterpreter,
  defaultKeyGenerator,
  setupCache,
} from 'axios-cache-interceptor';
import axiosRetry from 'axios-retry';
import config from '../config';
import setupInterceptorsTo from './setupInterceptorsTo';

const axiosInstance = setupCache(
  axios.create({
    baseURL: config.apiBase,
  }),
  {
    generateKey: defaultKeyGenerator,
    headerInterpreter: defaultHeaderInterpreter,
    debug: undefined,
  },
);

axiosInstance.interceptors.request.use((configuration) => configuration, (error) => Promise.reject(error));
axiosInstance.interceptors.response.use((response) => response, (error) => Promise.reject(error));

const HelperAxios = setupInterceptorsTo(axiosInstance);

axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: (error) => {
    const status = error.response?.status;
    return status === 401 || status >= 500;
  },
});

export default HelperAxios;
