/* eslint-disable camelcase */
import axiosInstance from '../helpers/helperAxios';

export const getUserAddresses = async () => {
  const response = await axiosInstance.get('ecom/v1/user/addresses', {
    cache: false,
  });
  return response;
};

export const addUserAddress = (address) => axiosInstance.post('ecom/v1/user/address', address);

export const deleteUserAddress = (addressId) => axiosInstance.delete(`ecom/v1/user/address/${addressId}`);

export const getLocations = async () => {
  const response = await axiosInstance.get('ecom/v1/locations');
  return response
    .filter((store) => !store.is_order_in_table_location)
    .sort((store) => ((store.isActive && store.isOpen && store.is_available) ? -1 : 1));
};
export const getMenu = (storeID) => axiosInstance.get(`ecom/v1/store/${storeID}/menu`);
export const getFilterMenu = async (storeID, value, categoryType) => {
  const options = {
    cache: {
      ttl: 1000 * 60 * 2,
      methods: ['get'],
      cachePredicate: {
        statusCheck: (status) => status >= 200 && status < 400,
      },
    },
  };

  if (!value || value === '') {
    return axiosInstance.get(`ecom/v1/store/${storeID}/menu?categoryType=${categoryType}`, options);
  }

  const response = await axiosInstance.get(`ecom/v1/store/${storeID}/menu?categoryType=${categoryType}`, options);
  const filterProducts = [];
  response.menuCategories?.forEach((category) => {
    const filteProduct = category.products.filter((product) => {
      const productLower = product.name.toLowerCase();
      const categoryLower = (product.categoryGroup?.name || '').toLowerCase();
      const valueLower = value.toLowerCase().trim();

      return productLower.includes(valueLower) || categoryLower.includes(valueLower);
    });

    if (Array.isArray(filteProduct) && filteProduct.length) {
      filterProducts.push({ ...category, products: filteProduct });
    }
  });

  return { menuCategories: filterProducts };
};

export const getProductDetail = (storeID, productID) => axiosInstance
  .get(`ecom/v1/store/${storeID}/product/${productID}`, {
    cache: false,
  });
export const getPaymentSession = (paymentInformation, orderType) => axiosInstance
  .post(`ecom/v1/secure/paymentSessionV2?type=${orderType}`, paymentInformation);
export const getEnrollmentPaymentSession = ({ customerSession, type, order_id }) => axiosInstance
  .post(`/ecom/v1/secure/register/${customerSession}/paymentMethod`, null, {
    params: { type, order_id },
  });
export const generateCheckout = (payment) => axiosInstance.post('ecom/v1/secure/checkout', payment);
export const getOrderDetail = (orderID) => axiosInstance
  .get(`ecom/v1/order/${orderID}`, {
    cache: false,
    headers: {
      'Internal-Order-ID': true,
    },
  });
export const getOrdersByPhone = (phone) => axiosInstance.get(`ecom/v1/order/phone/${phone}`);
export const rateOrder = (orderId, rating) => axiosInstance.post(`ecom/v1/order/${orderId}/rating`, { rating });
export const getConfigCategories = () => axiosInstance.get('ecom/v1/schedule/config');
export const activateCoupon = (code, storeID, type) => axiosInstance
  .get(`ecom/v1/discounts/coupon/${code}/store/${storeID}?orderType=${type}`);
export const requestForgot = ({ email }) => axiosInstance.post('OAuth/v1/public/user/forgot_password', { email });
export const restoreForgot = ({ password, hash }) => axiosInstance
  .post('OAuth/v1/email_secure/user/restorePassword', {
    password,
  }, {
    headers: {
      Authorization: hash,
    },
  });
export const getUserInfo = () => axiosInstance.get('ecom/v1/secure/customer', {
  cache: false,
});

export const createdInPOS = (orderID) => axiosInstance.get(`ecom/v1/secure/order/${orderID}/created-in-pos`, {
  cache: false,
});
export const getStorebyAddress = (addresInformation) => axiosInstance.post('nicoendpoint', addresInformation);
export const validateAddress = (address) => axiosInstance.get(`ecom/v1/geolocation/validate/${address}`);
export const getNearestStore = (lat, lng) => axiosInstance.get(`ecom/v1/geolocation/nearest-store/${lat}/${lng}`);

export const login = ({ email, password }) => axiosInstance.post('/ecom/v1/user/signin', { email, password });
export const register = ({
  email,
  password,
  last_name,
  first_name,
  phone,
}) => axiosInstance.post('ecom/v1/user/signup', {
  email,
  password,
  last_name,
  first_name,
  phone,
});

export const getInfoEmail = (email) => axiosInstance.post('OAuth/v1/public/user/taken', { email });

export const getCountryCode = () => axiosInstance.get('ecom/v1/country');
export const getCustomerSession = () => axiosInstance.post('/ecom/v1/secure/customer/session');
