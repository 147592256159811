import PropTypes from 'prop-types';

function DeliverySuccess(props) {
  const { className } = props;

  return (
    <svg
      width="263"
      height="159"
      viewBox="0 0 263 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M202.462 92.539C202.519 96.4723 202.815 116.759 190.529 124.626C181.268 130.552 174.347
        123.353 154.731 123.073C128.22 122.701 124.417 135.623 99.218 134.976C90.7977 134.759 70.6626
        134.242 59.2695 120.486C41.7128 99.2876 59.1294 63.8161 60.3071 61.4872C71.1658 40.0718 94.0558
        22.7242 121.527 21.1199C162.166 18.7444 185.803 52.3891 190.01 58.3821C194.182 64.3285 202.223
        76.0971 202.462 92.539Z"
        fill="#FFB869"
        fillOpacity="0.08"
      />
      <path
        d="M208.405 138.598C227.159 121.067 220.139 105.434 204.634 104.005C194.699 103.089 191.241
        91.2503 170.06 100.666C148.878 110.081 159.089 130.434 166.36 136.405C172.561 144.441 189.651
        156.128 208.405 138.598Z"
        fill="#FEF0D6"
      />
      <path
        d="M115.409 75.3662C115.092 59.472 100.592 55.7855 94.3685 54.64C84.3396 52.7945
        77.8006 59.1174 68.3949 55.0304C59.7899 51.2925 61.1007 44.194 53.1795 42.1559C45.4459
        40.166 34.2614 44.3782 28.9755 51.8986C19.366 65.5723 31.6601 86.7536 39.4893 95.874C44.4552
        101.659 55.3529 114.355 73.2695 112.04C90.1135 109.864 115.855 97.8363 115.408 75.3655L115.409 75.3662Z"
        fill="#F7F5F5"
      />
      <path
        d="M210.672 21.3691C216.926 26.1643 215.657 32.1837 214.241 34.594C210.317 38.8392 200.331 45.3529
        191.79 37.4457C183.248 29.5384 190.169 22.2599 194.698 19.6091C197.417 18.1978 204.418 16.574 210.672 21.3691Z"
        fill="#FFEDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93 80.499C93 61.9975 107.998 46.999 126.5 46.999C145.002 46.999 160 61.9975 160 80.499C160
        99.0006 145.002 113.999 126.5 113.999C107.998 113.999 93 99.0006 93 80.499ZM138.905 74.2661C139.732
        73.108 139.464 71.4985 138.306 70.6713C137.147 69.8441 135.538 70.1123 134.711 71.2705L123.594
        86.8336L118.014 81.2538C117.008 80.2474 115.376 80.2474 114.37 81.2538C113.364 82.2601 113.364
        83.8918 114.37 84.8981L122.101 92.6289C122.637 93.1645 123.381 93.4373 124.136 93.3749C124.891
        93.3124 125.58 92.9209 126.02 92.3045L138.905 74.2661Z"
        fill="#FFB869"
      />
    </svg>
  );
}

DeliverySuccess.propTypes = {
  className: PropTypes.string,
};

DeliverySuccess.defaultProps = {
  className: '',
};

export default DeliverySuccess;
