import PropTypes from 'prop-types';
import clsx from 'clsx';

function RadioBox(props) {
  const {
    color,
    size,
    margin,
    ...args
  } = props;

  return (
    <input
      className={
        clsx(
          'appearance-none relative block border-2 rounded-full cursor-pointer before:transition-opacity',
          'before:duration-300 before:content-[""] before:block before:absolute before:opacity-0',
          'before:top-0 before:left-0 hover:before:opacity-20 before:w-full before:h-full',
          'after:content-[""] after:block overflow-hidden',
          'disabled:opacity-50 disabled:cursor-not-allowed disabled:border-gray-500 disabled:checked:after:bg-gray-500',
          {
            'after:w-3 after:h-3': size === 'sm',
            'after:w-4 after:h-4': size === 'md',
            'after:w-5 after:h-5': size === 'lg',
          },
          {
            'border-baco-primary checked:after:bg-baco-primary before:bg-baco-primary': color === 'primary',
          },
          {
            'px-1 py-1': margin === 'densed',
          },
        )
      }
      type="radio"
      {...args}
    />
  );
}

RadioBox.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  margin: PropTypes.oneOf(['none', 'densed']),
};
RadioBox.defaultProps = {
  color: 'primary',
  margin: 'none',
  size: 'sm',
};

export default RadioBox;
