import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Visibility, VisibilityOff } from '../icons';
import InputText from '../InputText';

function InputPassword(props) {
  const {
    value, fieldState, type, ...args
  } = props;

  const { error } = fieldState;

  const iconClasses = clsx({ 'fill-baco-error-normal': !!error, 'fill-neutral-700': !error });

  const [_type, setType] = useState(type);

  const changeInputType = () => {
    setType(_type === 'text' ? 'password' : 'text');
  };

  return (
    <InputText
      fieldState={fieldState}
      type={_type}
      value={value}
      {...(value ? {
        endAdornment: (
          <button type="button" onClick={changeInputType}>
            {_type === 'text'
              ? <Visibility className={iconClasses} />
              : <VisibilityOff className={iconClasses} />}
          </button>),
      } : null)}
      {...args}
    />
  );
}

InputPassword.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  startAdornment: PropTypes.node,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onConfirmOptions: PropTypes.func,
  fullWidth: PropTypes.bool,
  fieldState: PropTypes.any,
  autoComplete: PropTypes.string,
};
InputPassword.defaultProps = {
  type: 'password',
  className: '',
  size: 'md',
  startAdornment: undefined,
  value: '',
  placeholder: '',
  onChange: () => null,
  onConfirmOptions: () => false,
  fullWidth: false,
  fieldState: {},
  autoComplete: '',
};

export default InputPassword;
