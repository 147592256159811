import { useQuery } from '@tanstack/react-query';
import config from '../../config';
import { getFilterMenu } from '../../services/api';

export function useMenu({
  storeID,
  searchValue,
  selectedCategoryType,
}) {
  const {
    data: menu = { menuCategories: [] },
    isLoading,
    isError,
  } = useQuery(['getMenu', storeID, searchValue, selectedCategoryType], () => {
    const calculatedCategory = selectedCategoryType || config.defaultCategory;
    return getFilterMenu(storeID, searchValue, calculatedCategory);
  }, {
    enabled: !!storeID,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  return { menu, isLoading, isError };
}

export default useMenu;
