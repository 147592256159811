import PropTypes from 'prop-types';

function Basket(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 11.458984,3.9589844 4.4589844,10.958984 a 1.0001,1.0001 0 0 0 -0.28125,0.859375 l 1.6015625,10.5 a
        1.0001,1.0001 0 0 0 0.9882812,0.84961 H 22.232422 a 1.0001,1.0001 0 0 0 0.988281,-0.84961 l 1.601563,-10.5 a
        1.0001,1.0001 0 0 0 -0.28125,-0.859375 l -7,-6.9999996 a 1,1 0 0 0 -1.414063,0 1,1 0 0 0 0,1.4160156 l
        6.640625,6.640625 -1.394531,9.152344 H 7.6269531 L 6.2324219,12.015625 12.873047,5.375 a 1,1 0 0 0 0,-1.4160156
        1,1 0 0 0 -1.414063,0 z"
      />
      <path d="m 5.1660156,10.667969 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 H 23.833984 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" />
    </svg>
  );
}

Basket.propTypes = {
  className: PropTypes.string,
};

Basket.defaultProps = {
  className: '',
};

export default Basket;
