import {
  trackEvent,
} from '../services/amplitude';

export function useLandingPageEvents() {
  const trackSelectChannel = (channel) => {
    trackEvent('start_buying_flow', { channel });
  };

  return {
    trackSelectChannel,
  };
}

export default useLandingPageEvents;
