import PropTypes from 'prop-types';

function LogoBacu(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 128 48"
      width="64px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M123.696 48.0001C125.848 48.0001 127.593 46.255 127.593 44.1023C127.593 41.9497 125.848 40.2046 123.696
        40.2046C121.543 40.2046 119.798 41.9497 119.798 44.1023C119.798 46.255 121.543 48.0001 123.696 48.0001Z"
      />
      <path
        d="M8.42681 13.4912C15.6691 13.4912 20.1409 16.9428 20.1409 24.1361C20.1409 26.5169 19.5089 28.413 18.7311
        30.0176C18.6344 30.2125 18.5363 30.4549 18.5363 30.6497C18.5363 30.8445 18.633 31.0869 18.7311 31.2326C
        19.7037 32.7391 20.1409 34.537 20.1409 36.8227C20.1409 43.9193 16.3978 47.5152 9.69087 47.5152H1.47598C
        0.698215 47.5152 0.406738 47.2237 0.406738 46.4459V14.5619C0.406738 13.7842 0.698215 13.4927 1.47598 13.4927H
        8.42681V13.4912ZM9.59272 26.8575C10.8568 26.8575 11.8279 26.0797 11.8279 24.5733C11.8279 23.1144 10.8553 22.289
        9.59272 22.289H9.35032C8.57255 22.289 8.28107 22.6296 8.28107 23.4074V25.7407C8.28107 26.5184 8.57255 26.859
        9.35032 26.859H9.59272V26.8575ZM9.64179 38.7158C10.9058 38.7158 11.8769 37.9381 11.8769 36.4316C11.8769 34.9727
        10.9044 34.1474 9.64179 34.1474H9.35032C8.57255 34.1474 8.28107 34.4879 8.28107 35.2657V37.3551C8.28107 38.1329
        8.57255 38.7158 9.35032 38.7158H9.64179Z"
      />
      <path
        d="M32.9231 42.6535C31.8048 42.6535 31.4166 42.8959 30.8337 43.9666L29.6187 46.2017C29.0357 47.271 28.6461
        47.5149 27.5293 47.5149H20.919C20.0446 47.5149 19.7531 46.9319 20.1412 46.1542L37.2001 13.7824C37.5406
        13.1504 38.2693 13.0537 38.6589 13.7824L55.7192 46.1527C56.1074 46.9304 55.8159 47.5134 54.9415 47.5134H
        48.2836C47.1653 47.5134 46.7772 47.271 46.1942 46.2003L44.9792 43.9651C44.3963 42.8959 44.0066 42.652 42.8898
        42.652H32.9231V42.6535ZM38.6099 31.3766C38.2217 30.6479 37.6373 30.6479 37.2491 31.3766L35.6445 34.1962C
        35.1582 35.023 35.4021 35.4602 36.3256 35.4602H39.4843C40.4078 35.4602 40.6502 35.023 40.1654 34.1962L
        38.6099 31.3766Z"
      />
      <path
        d="M80.3501 38.7649C83.5088 38.7649 85.7454 37.4532 87.4957 35.0218C88.0296 34.2931 88.4193 34.1949 89.2446
        34.5846L94.7856 37.2093C95.7582 37.6956 95.6125 38.4243 95.2229 39.2512C92.549 44.8413 86.7656 47.9999 80.301
        47.9999C70.483 47.9999 63.3389 40.661 63.3389 30.5024C63.3389 20.3439 70.4845 13.0049 80.301 13.0049C86.7656
        13.0049 92.549 16.1635 95.2229 21.7536C95.6125 22.5805 95.7582 23.3092 94.7856 23.7955L89.2446 26.4202C88.4178
        26.8084 88.0296 26.7117 87.4957 25.983C85.7454 23.5531 83.5103 22.2399 80.3501 22.2399C75.685 22.2399 72.2334
        25.8849 72.2334 30.5024C72.2334 35.1199 75.685 38.7649 80.3501 38.7649Z"
      />
      <path
        d="M98.0425 34.8761V14.6081C98.0425 13.8303 98.334 13.4897 99.1117 13.4897H105.332C106.11 13.4897 106.402
        13.8303 106.402 14.6081V34.2932C106.402 37.55 107.034 38.2296 108.054 38.2296C109.074 38.2296 109.706 37.5485
        109.706 34.2932V14.6081C109.706 13.8303 109.948 13.4897 110.775 13.4897H116.996C117.774 13.4897 118.065 13.8303
        118.065 14.6081V34.8761C118.065 44.8399 114.42 48 108.052 48C101.687 48 98.0425 44.8399 98.0425 34.8761Z"
      />
      <path
        d="M117.695 8.51082H0.77852C0.573296 8.51082 0.406738 8.34426 0.406738 8.13904V0.371782C0.406738 0.166558
        0.573296 0 0.77852 0H117.695C117.9 0 118.067 0.166558 118.067 0.371782V8.13904C118.067 8.34426 117.9 8.51082
        117.695 8.51082Z"
      />
    </svg>
  );
}

LogoBacu.propTypes = {
  className: PropTypes.string,
};

LogoBacu.defaultProps = {
  className: '',
};

export default LogoBacu;
