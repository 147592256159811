import PropTypes from 'prop-types';

function ArrowBack(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12 20-8-8 8-8 1.425 1.4-5.6 5.6H20v2H7.825l5.6 5.6Z" />
    </svg>
  );
}

ArrowBack.propTypes = {
  className: PropTypes.string,
};

ArrowBack.defaultProps = {
  className: '',
};

export default ArrowBack;
