import { motion, MotionConfig } from 'framer-motion';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Header from './components/Header';
import ModifierGroup from '../components/ModifierGroup';
import FormInputPanel from '../components/FormInputPanel';
import Skeleton from '../components/Skeleton';
import { formatCurrency } from '../utils/currency';
import {
  addDisablesToModifiers,
  getProductPrice,
  hasMinOptionsRequired,
  mixProductWithForm,
} from '../utils/product';
import { isScreenMin, MEDIA_SCREEN, useWindow } from '../hooks/useWindow';
import { useProductDetail } from '../hooks/services/useProductDetail';
import { useStore } from '../hooks/actions/useStore';
import { useShoppingCart } from '../hooks/actions/useShoppingCart';
import AddToCartButton from './components/AddToCartButton';
import BackButtonProduct from './components/BackButtonProduct';
import { useAmplitude } from '../hooks/useAmplitude';

function ProductDetail() {
  const { screen } = useWindow();
  const isMD = isScreenMin(MEDIA_SCREEN.MD, screen);

  const { selectedStore } = useStore();
  const { addProduct } = useShoppingCart();
  const [addedProduct, setAddedProduct] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      modifierGroups: [],
    },
  });
  const modifierGroupsForm = useWatch({
    control: methods.control,
    name: 'modifierGroups',
  });
  const { storeID, productID } = useParams();
  const { isLoading, productDetail } = useProductDetail({ storeID, productID });
  const modifierGroupsData = JSON.stringify(modifierGroupsForm);
  useEffect(() => {
    if (addedProduct) {
      setAddedProduct(false);
    }
  }, [modifierGroupsData]);

  const { trackAddItemToCart } = useAmplitude().useCartPageEvents();

  const handleBack = () => navigate(`/store/${storeID}`);

  const handleAddProduct = () => {
    trackAddItemToCart(productID);
    handleBack();
  };

  // Obtener el precio total del producto
  const calculatedModifierGroups = mixProductWithForm(productDetail, { modifierGroups: modifierGroupsForm });
  productDetail.total = getProductPrice(calculatedModifierGroups, productDetail);

  // Obtener los requisitos minimos para pedir
  productDetail.modifierGroups = addDisablesToModifiers(productDetail, modifierGroupsForm);
  const minOptionsRequired = hasMinOptionsRequired(productDetail);

  const onSubmit = (data) => {
    const modifierGroups = mixProductWithForm(productDetail, data);

    if (!addedProduct) {
      setAddedProduct(true);
      addProduct({
        ...productDetail,
        imageUrl: productDetail.shortcutImage,
        modifierGroups,
        observations: data.observations,
      });
    }
  };

  return (
    <MotionConfig transition={{ duration: 0.25 }}>
      <motion.div
        className="fixed h-full w-screen overflow-y-auto overflow-x-hidden bg-white z-10"
        initial={{ left: '100%' }}
        animate={{ left: 0 }}
        exit={{ left: '100%' }}
      >
        { isMD && (
          <Header>
            <BackButtonProduct
              onBack={handleBack}
            >
              {selectedStore?.name}
            </BackButtonProduct>
          </Header>
        ) }
        {isLoading ? (
          <div className="flex flex-col md:flex-row-reverse gap-x-10 overflow-auto h-full w-full max-w-7xl mx-auto">
            <div className="w-full md:w-1/2 flex flex-col">
              <div
                className="w-full min-h-[320px] max-h-[320px] bg-gray-400 bg-cover bg-center"
              >
                <Skeleton className="w-full h-full" />
              </div>

              <div className="w-full px-4 pt-8 pb-4">
                <div className="flex flex-col">
                  <h2 className="text-3xl font-bold text-black m-0 mb-6">
                    <Skeleton className="w-7/12 h-9" />
                  </h2>
                  <div className="text-base text-gray-600 m-0 mb-6">
                    <Skeleton className="w-9/12 h-4 mb-1" />
                    <Skeleton className="w-4/12 h-4" />
                  </div>
                  <div className="flex justify-start items-center space-x-2 mb-10">
                    <Skeleton className="w-3/12 h-9" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className={
                clsx('flex flex-col justify-between', {
                  'h-full': !isMD,
                  'mt-6': isMD,
                })
              }
            >
              <div
                className={
                  clsx('flex flex-col md:flex-row-reverse z-0 w-full max-w-7xl mx-auto', {
                    'overflow-auto h-[100%-92px]': !isMD,
                    'gap-x-20': isMD,
                  })
                }
              >
                <div className="w-full md:w-1/2 flex flex-col">
                  <div
                    className="relative w-full min-h-[320px] max-h-[320px] bg-gray-400 bg-cover bg-center z-10"
                    style={{ backgroundImage: `url(${productDetail.DetailImage})` }}
                  >
                    {!productDetail.isAvailable && (
                      <div
                        className={
                          clsx(
                            'flex items-center justify-center absolute top-0 left-0 w-full h-full',
                            'text-gray-100 text-center bg-gray-600 bg-opacity-80 p-4',
                          )
                        }
                      >
                        Producto No Disponible
                      </div>
                    )}
                    {!isMD && (
                      <BackButtonProduct
                        onBack={handleBack}
                        animated
                      >
                        {selectedStore?.name}
                      </BackButtonProduct>
                    )}
                  </div>

                  <div className="w-full px-4 pt-8 pb-4">
                    <h2 className="text-3xl font-bold text-black m-0 mb-6">
                      {productDetail.name}
                      {productDetail.tags?.length > 0 && (
                        <div className="flex flex-start space-x-1">
                          {productDetail.tags.map((tag) => (
                            <span
                              className="px-2 py-1 rounded-md text-xs"
                              style={{ backgroundColor: tag.bgColor, color: tag.fontColor }}
                              key={tag.label}
                            >
                              {tag.label}
                            </span>
                          ))}
                        </div>
                      )}
                    </h2>
                    <p className="text-base text-gray-600 m-0 mb-6">
                      {productDetail.description}
                    </p>
                    <div className="flex justify-start items-center space-x-2 mb-10">
                      {productDetail.discountedPrice && productDetail.discountedPrice < productDetail.price && (
                        <h5
                          className="text-lg font-bold text-gray-500 line-through"
                        >
                          {formatCurrency(productDetail.price)}
                        </h5>
                      )}
                      <h4
                        className="text-2xl font-bold"
                      >
                        {formatCurrency(
                          productDetail.discountedPrice && productDetail.discountedPrice < productDetail.price
                            ? productDetail.discountedPrice : productDetail.price,
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-4 pt-8 md:pt-4 pl-4 md:pl-8 pb-4 z-0">
                  <div className="flex flex-col">
                    <div>
                      {productDetail.modifierGroups?.map((modifierGroup, index) => (
                        <ModifierGroup
                          className="mb-4"
                          index={index}
                          key={modifierGroup.id}
                          name={modifierGroup.name}
                          modifiers={modifierGroup.modifiers}
                          selectType={modifierGroup.selectType}
                          minAllowed={modifierGroup.minAllowed}
                          maxAllowed={modifierGroup.maxAllowed}
                          disables={modifierGroup.disables}
                          disabled={!productDetail.isAvailable}
                        />
                      ))}
                    </div>
                    <FormInputPanel
                      id="observations"
                      panelElements={[{
                        type: 'textarea',
                        name: 'observations',
                        placeholder: 'Deja tus comentarios',
                        rows: 3,
                        resizable: false,
                        fullWidth: true,
                      }]}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full max-w-7xl mx-auto">
                <div className="w-full md:w-1/2 px-4 py-4 bg-white box-shadow-up md:shadow-none z-10">
                  <AddToCartButton
                    product={productDetail}
                    minRequirements={minOptionsRequired}
                    startAnimation={addedProduct}
                    onAnimationEnd={handleAddProduct}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        )}
      </motion.div>
    </MotionConfig>
  );
}

ProductDetail.propTypes = {};

export default ProductDetail;
