import PropTypes from 'prop-types';

function Add(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
    </svg>
  );
}

Add.propTypes = {
  className: PropTypes.string,
};

Add.defaultProps = {
  className: '',
};

export default Add;
