/* eslint-disable prefer-spread */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { StarIcon } from '../icons';

function InputRating(props) {
  const {
    classes,
    size,
    value,
    onChange,
    rate,
    readOnly,
    disabled,
  } = props;

  const [overValue, setOverValue] = useState(-1);

  const handleClickStar = (rating) => !readOnly && onChange(rating + 1);
  const handleMouseEnter = (rating) => !readOnly && setOverValue(rating);
  const handleMouseLeave = () => !readOnly && setOverValue(-1);

  return (
    <div
      className={
        clsx(
          'flex flex-row',
          classes.wrapper,
          {
            'gap-x-1': size === 'sm',
            'gap-x-3': size === 'md',
            'gap-x-5': size === 'lg',
          },
        )
      }
    >
      {Array.apply(null, new Array(rate)).map((_, index) => index).map((rating) => (
        <button
          disabled={disabled}
          key={rating}
          className={
            clsx(
              classes.button,
              'relative m-0 p-0',
              {
                'cursor-not-allowed': disabled,
                'w-5 h-5': size === 'sm',
                'w-7 h-7': size === 'md',
                'w-9 h-9': size === 'lg',
              },
            )
          }
          onClick={() => handleClickStar(rating)}
          onMouseEnter={() => handleMouseEnter(rating)}
          onMouseLeave={handleMouseLeave}
          type="button"
        >
          <StarIcon
            className={
              clsx(
                'absolute top-0',
                classes.back,
                {
                  'fill-yellow-400': rating <= overValue && !disabled,
                  'fill-yellow-200': rating <= overValue && disabled,
                  'fill-gray-400': rating > overValue && !disabled,
                  'fill-gray-200': rating > overValue && disabled,
                  'w-5 h-5': size === 'sm',
                  'w-7 h-7': size === 'md',
                  'w-9 h-9': size === 'lg',
                },
              )
            }
          />
          <span
            className={
              clsx(
                'absolute top-0 block overflow-x-hidden',
                {
                  'w-5 h-5': size === 'sm',
                  'w-7 h-7': size === 'md',
                  'w-9 h-9': size === 'lg',
                },
              )
            }
            style={{
              width: `${Math.min(Math.max(value - rating, 0), 1) * 100}%`,
            }}
          >
            <StarIcon
              className={
                clsx(
                  'absolute top-0',
                  classes.front,
                  {
                    'fill-yellow-500': !disabled,
                    'fill-yellow-200': disabled,
                    'w-5 h-5': size === 'sm',
                    'w-7 h-7': size === 'md',
                    'w-9 h-9': size === 'lg',
                  },
                )
              }
            />
          </span>
        </button>
      ))}
    </div>
  );
}

InputRating.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    button: PropTypes.string,
    back: PropTypes.string,
    front: PropTypes.string,
  }),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  rate: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

InputRating.defaultProps = {
  classes: {
    wrapper: '',
    button: '',
    back: '',
    front: '',
  },
  size: 'md',
  value: 0,
  onChange: () => null,
  rate: 5,
  readOnly: false,
  disabled: false,
};

export default InputRating;
