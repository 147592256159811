import { useLogin } from '../../contexts/Login.context';
import AnimatedStepper from '../../templates/AnimatedStepper';

function Login() {
  const {
    screens, screen, prevScreen, direction,
  } = useLogin();

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-white">
      <AnimatedStepper
        current={screens[screen]}
        previous={screens[prevScreen]}
        direction={direction}
        duration={0.5}
      />
    </div>
  );
}

export default Login;
