import clsx from 'clsx';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

let timmer;
function Drawer(props) {
  const {
    open,
    fullWidth,
    square,
    decorator,
    classes,
    direction,
    children,
    scrollBar,
  } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    clearTimeout(timmer);

    if (!open) {
      timmer = setTimeout(() => setShow(false), 500);
    } else {
      setShow(true);
    }
  }, [open]);

  if (!show) {
    return <div />;
  }

  return (
    <motion.div
      className={
        clsx(
          'fixed top-0 left-0 w-full h-full z-50 opacity-0 bg-gray-500/50 overflow-hidden',
          classes.backdrop,
        )
      }
      animate={{
        opacity: open ? 1 : 0,
        transition: {
          duration: 0.25,
        },
      }}
    >
      <motion.div
        className={
          clsx('absolute p-4 bg-white overflow-y-auto max-h-full', {
            'top-[-100%] right-[unset] bottom-[unset] left-[unset] w-full h-auto': direction === 'top',
            'right-[-100%] bottom-[unset] left-[unset] top-[unset] h-full w-auto': direction === 'right',
            'bottom-[-100%] left-[unset] top-[unset] right-[unset] w-full h-auto': direction === 'bottom',
            'left-[-100%] top-[unset] right-[unset] bottom-[unset] h-full w-auto': direction === 'left',
            'rounded-b-2xl': direction === 'top',
            'rounded-l-2xl': direction === 'right',
            'rounded-t-2xl': direction === 'bottom',
            'rounded-r-2xl': direction === 'left',
            'pb-12': direction === 'top' && decorator,
            'pl-12': direction === 'right' && decorator,
            'pt-12': direction === 'bottom' && decorator,
            'pr-12': direction === 'left' && decorator,
            '!w-full !h-full !rounded-none': fullWidth,
            '!rounded-none': square,
            'scrollbar-hide': !scrollBar,
          }, classes.drawer)
        }
        animate={{
          [direction]: open ? 0 : '-100%',
          transition: {
            duration: 0.5,
          },
        }}
      >
        <hr
          className={
            clsx('absolute border-none rounded-lg bg-gray-200', {
              'bottom-4 left-0 right-0 mx-auto h-1 w-[114px]': direction === 'top' && decorator,
              'left-4 top-0 bottom-0 my-auto w-1 h-[114px]': direction === 'right' && decorator,
              'top-4 left-0 right-0 mx-auto h-1 w-[114px]': direction === 'bottom' && decorator,
              'right-4 top-0 bottom-0 my-auto w-1 h-[114px]': direction === 'left' && decorator,
            }, classes.decorator)
          }
        />
        <div className={classes.content}>
          {children}
        </div>
      </motion.div>
    </motion.div>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  square: PropTypes.bool,
  decorator: PropTypes.bool,
  classes: PropTypes.shape({
    backdrop: PropTypes.string,
    drawer: PropTypes.string,
    content: PropTypes.string,
    decorator: PropTypes.string,
  }),
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  children: PropTypes.node.isRequired,
  scrollBar: PropTypes.bool,
};
Drawer.defaultProps = {
  open: false,
  fullWidth: false,
  square: false,
  decorator: true,
  classes: {
    backdrop: '',
    drawer: '',
    content: '',
    decorator: '',
  },
  direction: 'bottom',
  scrollBar: true,
};

export default Drawer;
