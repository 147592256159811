import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import { BasketIcon, OpenBasketIcon } from '../../components/icons';
import { formatCurrency } from '../../utils/currency';

function AddToCartButton(props) {
  const {
    product,
    minRequirements,
    startAnimation,
    onAnimationEnd,
  } = props;

  const handleAddProductComplete = () => setTimeout(onAnimationEnd, 250);

  const renderSubmitButton = () => {
    if (!product.isAvailable) {
      return (
        <span>Producto No Disponible</span>
      );
    }

    return (
      <span className="flex justify-center items-center w-full">
        {!startAnimation ? (
          <BasketIcon className="mr-3" />
        ) : (
          <OpenBasketIcon className="mr-3" />
        )}
        <span className="flex items-center justify-center">
          <span className="mr-2">
            {!startAnimation ? 'Agregar' : 'Agregado'}
          </span>
          <motion.span
            className="block overflow-hidden whitespace-nowrap"
            onAnimationComplete={handleAddProductComplete}
            initial={{
              opacity: 1,
              maxWidth: 100,
            }}
            animate={
              startAnimation
                ? {
                  opacity: 0,
                  maxWidth: 0,
                  transition: {
                    duration: 0.5,
                  },
                }
                : {}
            }
          >
            {`| ${formatCurrency(product.total)}`}
          </motion.span>
        </span>
      </span>
    );
  };

  return (
    <Button
      type="submit"
      disabled={!minRequirements || !product.isAvailable}
      size="lg"
      fullWidth
    >
      {renderSubmitButton()}
    </Button>
  );
}

AddToCartButton.propTypes = {
  product: PropTypes.any.isRequired,
  minRequirements: PropTypes.bool.isRequired,
  startAnimation: PropTypes.bool.isRequired,
  onAnimationEnd: PropTypes.func.isRequired,
};

export default AddToCartButton;
