/**
 * Method to get the number of minutes on the day based on a string date hh:mm
 * @param {String} date date in format hh:mm
 * @returns {Number} the number of minutes
 */
export function getMinutesOfDay(date) {
  const [hours, minutes] = date.split(':');

  return Number(hours) * 60 + Number(minutes);
}

/**
 * Method to get the number of minutes based on the current date
 * @returns {Number} the number of minutes
 */
export function getCurrentDayMinutes() {
  const now = new Date();

  return now.getHours() * 60 + now.getMinutes();
}

/**
 * Method to convert hours and minutes on a time format
 * @param {Number} hour the hours in 24-hours format
 * @param {Number} minute the minutes
 * @returns {String} hh:mma
 */
export function convertToTime(hour, minute) {
  const formattedHour = `${hour % 12 || 12}`.padStart(2, '0');
  const formattedMinute = `${minute || 0}`.padStart(2, '0');
  return `${formattedHour}:${formattedMinute}${hour >= 12 ? 'pm' : 'am'}`;
}

export default getMinutesOfDay;
