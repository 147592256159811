import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import CustomSelect from '.';

function CustomSelectControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    options,
    classes,
    readOnly,
    disabled,
    fullWidth,
    direction,
    renderValue,
    renderOption,
    valueKey,
    fluid,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        fieldState,
        field: {
          onChange,
          onBlur,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          direction={direction}
          className={clsx(classes)}
          fieldState={fieldState}
        >
          <CustomSelect
            className={clsx(classes)}
            name={name}
            value={value}
            valueKey={valueKey}
            options={options}
            onBlur={onBlur}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
            fullWidth={fullWidth}
            fieldState={fieldState}
            renderOption={renderOption}
            renderValue={renderValue}
            fluid={fluid}
          />
        </InputContainer>
      )}
    />
  );
}

CustomSelectControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  helper: PropTypes.node,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  fullWidth: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  renderValue: PropTypes.func,
  renderOption: PropTypes.func,
  valueKey: PropTypes.string,
  fluid: PropTypes.bool,
};

CustomSelectControlled.defaultProps = {
  label: null,
  helper: null,
  classes: [],
  readOnly: false,
  disabled: false,
  fullWidth: false,
  options: [],
  direction: 'column',
  renderValue: (value) => value,
  renderOption: (option) => option.label,
  valueKey: 'value',
  fluid: false,
};

export default CustomSelectControlled;
