import PropTypes from 'prop-types';
import {
  createContext, useContext, useEffect, useMemo, useState, useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSession } from '../hooks/actions/useSession';
import { useUserInfo } from '../hooks/services/useUserInfo';

export const LoginScreens = {
  ONBOARDING: 'onBoarding',
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT: 'forgot',
  FORGOT_RESTORE: 'forgot_restore',
  SUCCES_RESTORE: 'succes_restore',
};

const Context = createContext({});

function LoginContext(props) {
  const { children, screens } = props;
  const {
    token,
    redirectUrl,
    changeRedirectUrl,
    changeSessionToken,
    changeRefreshToken,
  } = useSession();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const resolveFn = useRef(null);
  const { userInfo, isLoadingUserInfo, isErrorOnUserInfo } = useUserInfo({ token });
  const [direction, setDirection] = useState('left');
  const [screen, setScreen] = useState(LoginScreens.LOGIN);
  const [prevScreen, setPrevScreen] = useState();
  const [emailSendedRecoverPass, setEmailSendedRecoverPass] = useState();
  const handleChangeScreen = (newScreen, directionRoute = 'left', isNewProcess = false) => {
    setPrevScreen(isNewProcess ? undefined : screen);
    setDirection(directionRoute);
    setScreen(newScreen);
  };

  const handleChangeEmailRecoverPass = (email) => setEmailSendedRecoverPass(email);

  const onRequestToken = (route) => new Promise((resolve) => {
    if (token && userInfo) {
      resolve({ token, userInfo });
      return;
    }

    resolveFn.current = resolve;
    handleChangeScreen(LoginScreens.LOGIN, 'left', true);
    changeRedirectUrl(route || `${pathname}${search && `?${search}`}`);
    navigate('/login');
  });

  const closePopup = () => {
    resolveFn.current = null;

    if (window.location.pathname.includes('/login')) {
      navigate(redirectUrl || '/');
      changeRedirectUrl(null);
    }
  };

  useEffect(() => {
    if (isErrorOnUserInfo) {
      changeRefreshToken(null);
      changeSessionToken(null);
      window.location.reload();
    }
  }, [isErrorOnUserInfo]);

  useEffect(() => {
    if (token && userInfo) {
      if (resolveFn.current) {
        resolveFn.current({ token, userInfo });
      }

      closePopup();
    }
  }, [token, userInfo, resolveFn]);

  const valueProps = useMemo(() => ({
    token,
    userInfo,
    isLoadingUserInfo,
    screens,
    screen,
    prevScreen,
    direction,
    onRequestToken,
    changeSessionToken,
    handleChangeEmailRecoverPass,
    emailSendedRecoverPass,
    handleChangeScreen,
    closePopup,
  }), [screens, screen, prevScreen, direction]);

  return (
    <Context.Provider
      value={valueProps}
    >
      {children}
    </Context.Provider>
  );
}

LoginContext.propTypes = {
  children: PropTypes.node.isRequired,
  screens: PropTypes.object,
};

LoginContext.defaultProps = {
  screens: {},
};

export const useLogin = () => useContext(Context);

export default LoginContext;
