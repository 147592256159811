import config from '../config';
import { generateCheckout } from './api';

export function processYunoCheckout({
  data,
  storeID,
  paymentMethod,
  vaultedToken,
  onLoading,
}) {
  return new Promise((resolve) => {
    if (vaultedToken) {
      generateCheckout({
        amount: data.session.amount,
        checkout: {
          session: data.session.checkout_session,
        },
        country: data.session.country,
        customer_payer: { nationality: 'CO', ...data.session.customer, document: { document_number: '1111111111' } },
        description: 'SUCCESSFUL', // data.session.payment_description,
        merchant_order_id: data.session.merchant_order_id,
        payment_method: {
          vaulted_token: vaultedToken,
        },
        discounts: data.discount ? [
          {
            type: data.discount.type,
            name: data.discount.name,
            discountType: 'coupon',
            value: data.discount.value,
          },
        ] : [],
        storeID,
      }).then((response) => {
        resolve(response);
      });
    } else {
      const yuno = window.Yuno.initialize(config.yunoPublicAPIKey);

      yuno.startCheckout({
        checkoutSession: data.session.checkout_session,
        elementSelector: '#yuno-content',
        countryCode: 'CO',
        language: 'es',
        async yunoCreatePayment(oneTimeToken) {
          onLoading();
          const response = await generateCheckout({
            amount: data.session.amount,
            checkout: {
              session: data.session.checkout_session,
            },
            country: data.session.country,
            customer_payer: { nationality: 'CO', ...data.session.customer },
            description: 'SUCCESSFUL', // data.session.payment_description,
            merchant_order_id: data.session.merchant_order_id,
            payment_method: {
              token: oneTimeToken,
            },
            discounts: data.discount ? [
              {
                type: data.discount.type,
                name: data.discount.name,
                discountType: 'coupon',
                value: data.discount.value,
              },
            ] : [],
            storeID,
          });

          if (response.payment.checkout.sdk_action_required) {
            yuno.continuePayment();
          }

          resolve(response);
        },
      });

      yuno.mountCheckoutLite({
        paymentMethodType: paymentMethod,
        vaultedToken: vaultedToken || undefined,
      });
    }
  });
}

export function processEnrollmentCheckout({ customerSession }) {
  return new Promise((resolve, reject) => {
    const yuno = window.Yuno.initialize(config.yunoPublicAPIKey);

    yuno.mountEnrollmentLite({
      customerSession,
      language: 'es',
      countryCode: 'CO',
      renderMode: {
        type: 'modal',
        elementSelector: '#form-element',
      },
      yunoEnrollmentStatus: () => {},
      onLoading: ({ isLoading, type }) => {
        if (!isLoading && type === 'ONE_TIME_TOKEN') {
          resolve();
        }
      },
      yunoError: (error) => {
        reject(error);
      },
    });
  });
}

export default processYunoCheckout;
