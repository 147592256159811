import PropTypes from 'prop-types';

function DeliveryArrive(props) {
  const { className } = props;

  return (
    <svg
      width="263"
      height="151"
      viewBox="0 0 263 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.868164" y="0.214844" width="261.264" height="149.069" fill="white" />
      <path
        d="M130.317 16.6755C112.616 -0.415316 92.7033 11.3921 84.6337 16.939C71.63 25.8779
      71.3689 39.9629 56.6008 45.7616C43.0913 51.0679 36.7769 41.8476 25.8724 48.2712C15.2262
      54.5425 7.57055 71.3904 9.99005 85.4191C14.3906 110.925 50.9879 120.722 69.5244 122.168C81.2819
      123.086 107.084 125.101 124.188 102.975C140.268 82.1741 155.34 40.8386 130.315 16.6757L130.317
      16.6755Z"
        fill="#FFF9F3"
      />
      <path
        d="M189.053 15.7498C195.268 20.5152 194.008 26.4973 192.601 28.8926C188.7 33.1115 178.777
      39.5848 170.288 31.7266C161.799 23.8684 168.678 16.6351 173.178 14.0007C175.88 12.5982 182.838
      10.9844 189.053 15.7498Z"
        fill="#FFEDDD"
      />
      <path
        d="M215.661 105.673C234.415 88.1422 227.395 72.5092 211.89 71.0802C201.955 70.1645 198.497
      58.3255 177.315 67.7409C156.134 77.1563 166.345 97.5097 173.616 103.481C179.817 111.516 196.907
      123.204 215.661 105.673Z"
        fill="#FFF9F3"
      />
      <path
        d="M95.6312 44.1504H86.1392C83.7564 44.1504 81.8247 46.0821 81.8247 48.4649V73.9206C81.8247
      76.3034 83.7564 78.2351 86.1392 78.2351H95.6312C98.014 78.2351 99.9457 76.3034 99.9457
      73.9206V48.4649C99.9457 46.0821 98.014 44.1504 95.6312 44.1504Z"
        fill="#5C5C5C"
      />
      <path
        d="M87.1719 46.5049H94.9771C95.5966 46.5049 96.9098 46.7324 97.2072 47.6425C97.5045 48.5526
      97.3311 62.4318 97.2072 69.2576"
        stroke="#868282"
        strokeWidth="1.56915"
        strokeLinecap="round"
      />
      <path
        d="M182.788 89.8246C182.745 98.3803 182.762 106.936 182.762 115.487C182.762 124.004 182.762
      132.525 182.762 141.042C182.762 141.983 182.749 142.436 182.486 142.656H192.112C191.836 142.427
      191.831 141.953 191.831 140.947C191.831 125.143 191.831 109.339 191.831 93.5394C191.831 93.2504
      191.84 92.9656 191.84 92.6765C191.84 92.5126 191.862 92.3745 191.901 92.2623C188.863 90.8558
      185.614 89.816 182.456 88.4785C182.689 88.8107 182.788 89.2422 182.784 89.8246H182.788Z"
        fill="#A87C4F"
      />
      <path
        d="M200.305 86.1745C199.581 84.8974 198.368 84.3106 197.061 83.836C189.903 81.2387 182.75
      78.6327 175.596 76.0354C174.177 75.5177 173.819 75.3925 173.728 74.4951V85.8768C173.81 85.1218
      174.103 85.1477 175.204 85.5532C177.227 86.2953 179.238 87.0892 181.291 87.7407C181.848 87.9176
      182.228 88.1462 182.465 88.4828C185.623 89.8203 188.868 90.8601 191.909 92.2666C192.026 91.9171
      192.324 91.8093 192.815 91.9775C193.527 92.2235 194.231 92.5039 194.951 92.7196C196.828 93.2805
      198.769 92.6894 199.909 91.2484C201.048 89.8073 201.203 87.7666 200.31 86.1788L200.305 86.1745Z"
        fill="#D19161"
      />
      <path
        d="M174.081 142.677C173.801 142.492 173.671 142.164 173.697 141.659C173.715 141.301 173.697
      140.939 173.697 140.58C173.697 122.787 173.697 104.994 173.697 87.2012C173.697 86.9855 173.697
      86.7698 173.697 86.5541C173.697 86.2779 173.71 86.0536 173.728 85.8724V74.4907C173.697 74.1887
      173.697 73.8047 173.693 73.2869C173.676 67.1474 173.658 61.0035 173.641 54.8639C173.572 54.8596
      173.503 54.851 173.438 54.8467C172.99 54.9675 172.528 55.0581 172.092 55.2177C168.813 56.3999
      165.539 57.595 162.264 58.7858C136.433 68.1828 110.606 77.5755 84.7751 86.9683C82.9242 87.6413
      82.9199 87.6284 82.9199 89.6476C82.9199 106.608 82.9199 123.568 82.9199 140.529C82.9199 140.96
      82.9069 141.392 82.9113 141.823C82.9199 142.267 82.7861 142.556 82.5229 142.716C102.137 143.424
      163.049 143.104 174.086 142.677H174.081ZM114.653 137.793C114.64 129.065 114.64 120.337 114.653
      111.613C114.657 108.45 116.526 106.617 119.697 106.612C125.444 106.604 131.191 106.599 136.938
      106.612C140.074 106.617 141.83 108.347 141.848 111.462C141.869 115.88 141.852 120.298 141.852
      124.716C141.852 129.061 141.865 133.41 141.852 137.754C141.839 141.163 140.143 142.867 136.756
      142.876C131.083 142.884 125.405 142.884 119.731 142.876C116.478 142.867 114.662 141.059 114.653
      137.802V137.793Z"
        fill="#B6D4DB"
      />
      <path
        d="M178.668 41.017C178.388 41.3061 178.103 41.3061 177.827 41.017C177.398 40.9695 176.123
      41.4657 175.294 41.7677C151.107 50.5564 126.92 59.3537 102.737 68.1424L81.501 75.8826C79.8917
      76.396 78.3212 77.0216 76.7378 77.6127C76.0173 77.8802 75.4003 78.3031 74.9084 78.9028C73.5882
      80.5164 73.4846 82.7082 74.6539 84.4167C75.8361 86.1339 77.9717 86.7768 80.129 86.0045C83.7144
      84.7187 87.2911 83.4028 90.8721 82.0998C107.996 75.8697 125.121 69.6438 142.245 63.4137C154.067
      59.1164 165.889 54.8105 177.715 50.5305C178.789 50.1422 179.941 49.9524 180.83 49.1542C180.933
      48.6666 181.235 48.3862 181.723 48.3042C183.923 45.3574 182.396 41.716 178.664 41.0127L178.668
      41.017Z"
        fill="#AEAEAE"
      />
      <path
        d="M180.829 49.1546C180.933 48.6671 181.235 48.3867 181.722 48.3047C181.481 48.6455 181.2
      48.9476 180.829 49.1546Z"
        fill="#AEAEAE"
      />
      <path
        d="M178.668 41.0176C178.388 41.3067 178.103 41.3067 177.827 41.0176C178.107 41.0176 178.388
      41.0176 178.668 41.0176Z"
        fill="#AEAEAE"
      />
      <path
        d="M173.637 54.8639C173.568 54.8596 173.499 54.851 173.434 54.8467C173.503 54.851 173.572
      54.8596 173.637 54.8639Z"
        fill="#0F192B"
      />
      <path
        d="M116.201 106.524H140.523C141.173 106.524 141.7 107.051 141.7
      107.701V142.615H115.024V107.701C115.024 107.051 115.551 106.524 116.201 106.524Z"
        fill="#94C4D9"
        stroke="#94C4D9"
        strokeWidth="0.784576"
      />
      <rect x="69.9111" y="141.438" width="127.101" height="2.35373" rx="1.17686" fill="#5C5C5C" />
    </svg>
  );
}

DeliveryArrive.propTypes = {
  className: PropTypes.string,
};

DeliveryArrive.defaultProps = {
  className: '',
};

export default DeliveryArrive;
