import PropTypes from 'prop-types';
import clsx from 'clsx';
import { DoneIcon } from '../icons';

function Stepper(props) {
  const {
    step,
    stepActive,
  } = props;

  const stepJSX = Array.apply(0, new Array(step)).map((_, i) => {
    const index = i + 1;
    const key = `key-${index}`;
    return (
      <div key={key} className="flex flex-row items-center">
        <div
          className={clsx({
            'rounded-full h-6 w-6 flex text-baco-primary-500 items-center justify-center': true,
            'bg-baco-primary-500 flex items-center': (index !== stepActive),
            'border border-baco-primary-500': (index === stepActive),
            'bg-baco-secondary-700': (index > stepActive),
          })}
        >
          <span>
            {index === stepActive && (
              <p>
                {`${index}`}
              </p>
            )}
            {index < stepActive && (
              <DoneIcon className="text-baco-secondary-50" />
            )}
            {index > stepActive && (
              <p className="text-baco-secondary-900">
                {`${index}`}
              </p>
            )}
          </span>

        </div>
        {index < step && (
          <div className="mx-px h-0.5 w-12 bg-baco-primary-500" />
        )}
      </div>
    );
  });

  return (
    <div className="flex flex-row items-center">
      {stepJSX}
    </div>
  );
}

Stepper.propTypes = {
  step: PropTypes.number.isRequired,
  stepActive: PropTypes.number.isRequired,
};

Stepper.defaultProps = {};

export default Stepper;
