import PropTypes from 'prop-types';

function ArrowForward(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12 20-1.425-1.4 5.6-5.6H4v-2h12.175l-5.6-5.6L12 4l8 8Z" />
    </svg>
  );
}

ArrowForward.propTypes = {
  className: PropTypes.string,
};

ArrowForward.defaultProps = {
  className: '',
};

export default ArrowForward;
