import { useQuery } from '@tanstack/react-query';
import { getProductDetail } from '../../services/api';

export function useProductDetail({
  storeID,
  productID,
}) {
  const { data: productDetail = {}, isLoading } = useQuery(
    ['productDetail', storeID, productID],
    () => getProductDetail(storeID, productID),
    {
      enabled: !!storeID && !!productID,
      refetchOnWindowFocus: false,
    },
  );

  return { isLoading, productDetail };
}

export default useProductDetail;
