const initialState = {
  stores: [],
  selectedStore: {
    selecting: false,
  },
  loading: false,
};

const storeReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case 'SELECTED_STORE':
      return {
        ...state,
        selectedStore: data,
      };
    default:
      return state;
  }
};

export default storeReducer;
