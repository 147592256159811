import PropTypes from 'prop-types';

function OpenBasket(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m 2.1894531,2.5488281 a 1,1 0 0 0 -0.640625,1.2617188 l 2.6425781,8.0878911 1.5878907,10.417968 a
        1.0001,1.0001 0 0 0 0.9882812,0.84961 H 22.232422 a 1.0001,1.0001 0 0 0 0.988281,-0.84961 l 1.587891,-10.417968
        2.642578,-8.0878911 a 1,1 0 0 0 -0.640625,-1.2617188 1,1 0 0 0 -1.261719,0.640625 l -2.666016,8.1660159 a
        1.0001,1.0001 0 0 0 -0.03906,0.160156 l -1.470703,9.650391 H 7.6269531 L 6.1542969,11.515625 A 1.0001,1.0001
        0 0 0 6.1171875,11.355469 L 3.4511719,3.1894531 A 1,1 0 0 0 2.1894531,2.5488281 Z"
      />
      <path
        d="m 5.1660156,10.666016 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 H 23.833984 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z"
      />
    </svg>
  );
}

OpenBasket.propTypes = {
  className: PropTypes.string,
};

OpenBasket.defaultProps = {
  className: '',
};

export default OpenBasket;
