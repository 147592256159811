import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '../Button';

function OrderItem(props) {
  const {
    orderId,
    status,
    name,
    pickDate,
    totalProducts,
    onGoToOrder,
    className,
    finished,
  } = props;

  return (
    <div
      className={
        clsx(
          'flex justify-between items-end gap-x-1 bg-baco-primary text-white rounded-xl p-4',
          'gap-y-3 [@media(max-width:350px)]:flex-col [@media(max-width:350px)]:items-start',
          className,
          {
            '!bg-[#F4F3F3] !text-[#5C5C5C]': finished === 'SUCCESS',
          },
        )
      }
    >
      <div
        className="flex flex-col"
      >
        <p className="text-[10px] truncate max-w-[190px] mb-3 sm:max-w-fit">
          {`Orden #${orderId}`}
        </p>
        <h3 className="text-xl font-bold truncate max-w-[190px] sm:max-w-fit">
          {status}
        </h3>
        <hr className={clsx('border-b border-b-white mt-2 mb-3', {
          'border-b-[#E3E3E3]': finished === 'SUCCESS',
        })}
        />
        <h4 className="text-sm mb-1 truncate max-w-[190px] sm:max-w-fit">
          {name}
        </h4>
        <p className="text-[10px]">
          {`${pickDate} - ${totalProducts} producto${totalProducts !== 1 ? 's' : ''}`}
        </p>
      </div>
      {
        finished === 'NOT' ? (
          <Button
            className="!rounded-2xl !text-[14px] font-bold [@media(max-width:350px)]:w-full
            !inline-block !whitespace-nowrap text-[#5C5C5C] bg-[#E3E3E3] !py-1.5"
            onClick={onGoToOrder}
          >
            <span>
              Ver Orden
            </span>
          </Button>
        ) : (<span className="text-[14px] mb-2 mr-2">Finalizado</span>)
      }
    </div>
  );
}

OrderItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pickDate: PropTypes.string.isRequired,
  totalProducts: PropTypes.number.isRequired,
  onGoToOrder: PropTypes.func,
  className: PropTypes.string,
  finished: PropTypes.string,
};

OrderItem.defaultProps = {
  onGoToOrder: () => null,
  className: '',
  finished: 'NOT',
};

export default OrderItem;
