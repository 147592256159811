import {
  trackEvent,
} from '../services/amplitude';

export function useCheckoutPageEvents() {
  const trackBeginCheckout = (typeSchedule) => {
    trackEvent('begin_checkout_pick_up', { type_schedule: typeSchedule });
  };

  const trackSelectPaymentMethod = (paymentMethod) => {
    trackEvent('select_payment_method', { payment_method: paymentMethod });
  };

  const trackPaymentFlowResult = (orderId, transactionStatus) => {
    trackEvent('payment_flow_result', { order_id: orderId, transaction_status: transactionStatus });
  };

  const trackSupportRedirectPickUp = () => {
    trackEvent('support_redirect_pick_up');
  };

  return {
    trackBeginCheckout,
    trackSelectPaymentMethod,
    trackPaymentFlowResult,
    trackSupportRedirectPickUp,
  };
}

export default useCheckoutPageEvents;
