import PropTypes from 'prop-types';

function DeliveryStepper2(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="343"
      height="73"
      viewBox="0 0 343 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.214844" width="343" height="72" rx="12" fill="white" />
      <circle cx="48.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M46.775 20.7336L42.5 16.4586L43.5688 15.3898L46.775 18.5961L53.6562 11.7148L54.725 12.7836L46.775 20.7336Z"
        fill="white"
      />
      <rect x="68.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="130.5" cy="16.2148" r="11" stroke="#0F5945" strokeWidth="2" />
      <path
        d="M129.578 17.2648C128.753 17.7298 128.093 18.2698 127.598 18.9148C127.118 19.5598 126.878 20.3998
      126.878 21.4648H134.228V19.9648H128.918C129.008 19.5898 129.233 19.2598 129.578 19.0048C129.938 18.7498 130.463
      18.4348 131.168 18.0598L131.978 17.6248C133.358 16.8298 134.228 15.8548 134.228 14.2198C134.228 12.2398 132.863
      10.8148 130.478 10.8148C128.213 10.8148 126.653 12.3748 126.653 14.2648C126.653 15.1198 126.923 15.8998 127.478
      16.5898L128.828 15.6898C128.453 15.2698 128.228 14.7298 128.228 14.2348C128.228 13.1548 129.128 12.2398 130.403
      12.2398C131.783 12.2398 132.653 13.1098 132.653 14.3098C132.653 15.1948 132.233 15.7498 131.633 16.1548C131.348
      16.3498 130.928 16.5748 130.388 16.8448C130.028 17.0248 129.758 17.1598 129.578 17.2648Z"
        fill="#0F5945"
      />
      <rect x="150.5" y="15.2148" width="42" height="2" rx="1" fill="#E0D8D1" />
      <circle cx="212.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M208.479 18.4648C208.794 20.3248 210.234 21.6148 212.409 21.6148C214.614 21.6148 216.219 20.0998
      216.219 17.9398C216.219 15.9298 214.629 14.7448 213.174 14.7448C212.994 14.7448 212.829 14.7598 212.679
      14.7898L215.754 12.2398V10.9648H208.929V12.4648H213.429L210.129 15.2398L211.029 16.3648C211.344 16.0498 211.869
       15.8398 212.454 15.8398C213.729 15.8398 214.644 16.6948 214.644 17.9698C214.644 19.2448 213.684 20.1148 212.409
        20.1148C211.029 20.1148 210.054 19.1848 209.979 17.7898L208.479 18.4648Z"
        fill="#AEAEAE"
      />
      <rect x="232.5" y="15.2148" width="42" height="2" rx="1" fill="#E0D8D1" />
      <circle cx="294.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M294.848 21.4648H296.423V18.9148H298.148V17.4898H296.423V14.2048H294.848V17.4898H291.773C292.628 16.6348
      293.198 15.7648 293.498 14.8798C293.798 13.9798 293.948 12.8998 293.948 11.6398V10.9648H292.373V11.6398C292.328
      14.3248 292.088 15.2248 290.123 17.2648V18.9148H294.848V21.4648Z"
        fill="#AEAEAE"
      />
      <path
        d="M25.5266
      46.2148V53.2148H30.5566V52.3448H26.4466V50.0248H30.1566V49.1548H26.4466V47.0848H30.4566V46.2148H25.5266ZM32.8747
      53.2148V50.6148C32.8747 49.7048 33.4347 49.1348 34.1547 49.1348C34.8347 49.1348 35.2347 49.5648 35.2347
      50.3948V53.2148H36.1047V50.1348C36.1047 49.0248 35.4447 48.3748 34.4347 48.3748C33.7647 48.3748 33.2047 48.6948
      32.8747 49.2448V48.4748H32.0147V53.2148H32.8747ZM42.5756 52.5548C41.7356 52.5548 40.9456 51.8748 40.9456
      50.8148C40.9456 49.7248 41.7456 49.1348 42.4756 49.1348C43.2456 49.1348 43.7456 49.5748 43.8956 50.3248L44.6856
      50.0848C44.4556 49.0048 43.6356 48.3748 42.4856 48.3748C41.1956 48.3748 40.1056 49.3948 40.1056 50.8148C40.1056
      52.3148 41.1056 53.3148 42.5956 53.3148C43.7856 53.3148 44.6456 52.6248 44.9156 51.4248L44.1456 51.1348C43.9556
      52.0248 43.4956 52.5548 42.5756 52.5548ZM49.3936 48.4748V50.9948C49.3936 51.9448 48.8236 52.5548 48.0736
      52.5548C47.3836 52.5548 47.0036 52.1448 47.0036 51.3148V48.4748H46.1436V51.5148C46.1436 52.6848 46.8036 53.3148
      47.8436 53.3148C48.5336 53.3148 49.0736 52.9648 49.3936 52.4148V53.2148H50.2636V48.4748H49.3936ZM51.8429
      48.4748V53.2148H52.7029V50.1948C52.7029 49.5548 53.0529 49.1348 53.5829 49.1348C54.0529 49.1348 54.3429 49.4048
      54.3429 49.8448C54.3429 49.9348 54.3329 50.0548 54.3129 50.1948H55.1529C55.1929 50.0348 55.2129 49.8548 55.2129
      49.6648C55.2129 48.9448 54.6829 48.3748 53.9129 48.3748C53.3429 48.3748 52.9129 48.6548 52.7029
      49.1648V48.4748H51.8429ZM56.079 51.9748C56.359 52.7848 57.089 53.3148 58.159 53.3148C59.229 53.3148 60.059
      52.6948 60.059 51.8148C60.059 51.0248 59.509 50.5548 58.399 50.4048C57.469 50.2748 57.129 50.0948 57.129
      49.6848C57.129 49.3148 57.529 49.0748 58.049 49.0748C58.559 49.0748 58.929 49.3148 59.089 49.7948L59.759
      49.4248C59.469 48.7548 58.939 48.3748 58.049 48.3748C57.559 48.3748 57.149 48.4948 56.819 48.7448C56.499 48.9848
      56.339 49.2948 56.339 49.6748C56.339 50.4248 56.879 50.8748 57.969 51.0348C58.929 51.1648 59.219 51.4148 59.219
      51.8648C59.219 52.2748 58.829 52.5548 58.199 52.5548C57.479 52.5548 57.059 52.2748 56.789 51.6048L56.079
      51.9748ZM61.0568 50.8448C61.0568 52.2448 62.0268 53.3148 63.4568 53.3148C64.8768 53.3148 65.8668 52.2448 65.8668
      50.8448C65.8668 49.4448 64.8968 48.3748 63.4568 48.3748C62.0268 48.3748 61.0568 49.4448 61.0568 50.8448ZM64.9768
      50.8448C64.9768 51.8448 64.3468 52.5348 63.4568 52.5348C62.5768 52.5348 61.9368 51.8348 61.9368 50.8448C61.9368
      49.8548 62.5768 49.1548 63.4568 49.1548C63.9068 49.1548 64.2668 49.3148 64.5468 49.6348C64.8368 49.9448 64.9768
      50.3548 64.9768 50.8448Z"
        fill="#0F5945"
      />
      <path
        d="M106.711
53.2148H110.291C111.691
53.2148
112.661
52.4548
112.661
51.2848C112.661
50.4348
112.131
49.8448
111.291
49.6148C112.011
49.3048
112.411
48.7548
112.411
48.0348C112.411
46.9648
111.481
46.2148
110.161
46.2148H106.711V53.2148ZM108.061
49.0148V47.4648H110.111C110.701
47.4648
111.091
47.7448
111.091
48.2148C111.091
48.7248
110.751
49.0148
110.111
49.0148H108.061ZM108.061
51.9648V50.2348H110.311C110.921
50.2348
111.311
50.6048
111.311
51.1148C111.311
51.6248
110.911
51.9648
110.311
51.9648H108.061ZM117.113
48.4748V50.9148C117.113
51.7148
116.713
52.2148
116.033
52.2148C115.473
52.2148
115.163
51.8448
115.163
51.1648V48.4748H113.883V51.4848C113.883
52.6848
114.593
53.3148
115.673
53.3148C116.343
53.3148
116.863
53.0048
117.113
52.5148V53.2148H118.383V48.4748H117.113ZM119.505
52.0448C119.835
52.8548
120.565
53.3148
121.655
53.3148C122.795
53.3148
123.655
52.6748
123.655
51.7848C123.655
50.9448
123.175
50.4848
121.965
50.2948C121.165
50.1748
120.915
50.0548
120.915
49.7748C120.915
49.5348
121.195
49.3648
121.585
49.3648C121.975
49.3648
122.265
49.5448
122.385
49.9048L123.355
49.3748C123.065
48.7448
122.545
48.3748
121.555
48.3748C120.595
48.3748
119.745
48.9448
119.745
49.7648C119.745
50.5648
120.335
51.0248
121.365
51.1848C122.215
51.3048
122.465
51.4748
122.465
51.8048C122.465
52.0748
122.185
52.2648
121.695
52.2648C121.125
52.2648
120.725
52.0148
120.505
51.5148L119.505
52.0448ZM127.083
52.1848C126.413
52.1848
125.783
51.6648
125.783
50.8148C125.783
49.9648
126.413
49.4948
127.013
49.4948C127.613
49.4948
128.063
49.8648
128.153
50.4648L129.283
50.0848C129.073
49.0048
128.183
48.3748
126.983
48.3748C125.663
48.3748
124.533
49.3848
124.533
50.8148C124.533
52.3148
125.563
53.3148
127.113
53.3148C128.353
53.3148
129.243
52.6748
129.533
51.4248L128.353
51.0148C128.263
51.6948
127.853
52.1848
127.083
52.1848ZM130.448
51.8448C130.448
52.7548
131.188
53.3148
132.008
53.3148C132.778
53.3148
133.268
53.0348
133.578
52.4848V53.2148H134.748V50.2948C134.748
49.0548
134.008
48.3748
132.568
48.3748C131.418
48.3748
130.668
48.9448
130.418
49.8648L131.528
50.1648C131.638
49.6848
131.988
49.3748
132.558
49.3748C133.148
49.3748
133.528
49.6948
133.528
50.2248V50.5248L133.108
50.4248C132.848
50.3548
132.578
50.3248
132.298
50.3248C131.178
50.3248
130.448
50.8348
130.448
51.8448ZM133.528
51.4648C133.528
52.0148
133.108
52.3348
132.518
52.3348C132.008
52.3348
131.748
52.1148
131.748
51.7648C131.748
51.3948
132.028
51.1948
132.498
51.1948C132.688
51.1948
132.938
51.2448
133.238
51.3348L133.528
51.4248V51.4648ZM137.501
53.2148V50.5948C137.501
49.8448
137.911
49.4148
138.481
49.4148C139.031
49.4148
139.371
49.8148
139.371
50.4648V53.2148H140.651V50.1148C140.651
49.0248
139.971
48.3748
138.941
48.3748C138.341
48.3748
137.811
48.6148
137.501
49.0948V48.4748H136.221V53.2148H137.501ZM141.855
50.8648C141.855
52.3348
142.795
53.3148
144.135
53.3148C144.805
53.3148
145.345
53.0248
145.655
52.5348V53.2148H146.885V46.2148H145.605V49.1848C145.315
48.6948
144.775
48.3748
144.105
48.3748C142.805
48.3748
141.855
49.3748
141.855
50.8648ZM145.655
50.8648C145.655
51.7048
145.085
52.2248
144.395
52.2248C143.685
52.2248
143.135
51.7048
143.135
50.8648C143.135
50.0148
143.685
49.4748
144.395
49.4748C145.085
49.4748
145.655
50.0148
145.655
50.8648ZM148.081
50.8448C148.081
52.2548
149.131
53.3148
150.561
53.3148C151.991
53.3148
153.041
52.2548
153.041
50.8448C153.041
49.4248
152.001
48.3748
150.561
48.3748C149.121
48.3748
148.081
49.4248
148.081
50.8448ZM151.791
50.8448C151.791
51.6348
151.271
52.1648
150.561
52.1648C149.851
52.1648
149.331
51.6348
149.331
50.8448C149.331
50.0548
149.851
49.5248
150.561
49.5248C151.271
49.5248
151.791
50.0548
151.791
50.8448ZM104.435
60.4748V65.2148H105.715V62.1648C105.715
61.6848
105.975
61.4148
106.375
61.4148C106.735
61.4148
106.975
61.6548
106.975
62.0048C106.975
62.1348
106.965
62.2548
106.935
62.3648H108.145C108.195
62.1748
108.215
61.9848
108.215
61.7748C108.215
60.9648
107.705
60.3748
106.855
60.3748C106.305
60.3748
105.915
60.6448
105.715
61.0848V60.4748H104.435ZM112.704
63.4048C112.554
63.9448
112.184
64.2148
111.604
64.2148C110.924
64.2148
110.364
63.8048
110.354
63.0848H113.574C113.614
62.9048
113.634
62.7148
113.634
62.5048C113.634
61.2048
112.744
60.3748
111.434
60.3748C110.034
60.3748
109.074
61.4648
109.074
62.8148C109.074
64.3148
110.114
65.3148
111.584
65.3148C112.694
65.3148
113.464
64.7548
113.794
63.8348L112.704
63.4048ZM110.354
62.2348C110.474
61.7148
110.914
61.4048
111.434
61.4048C112.004
61.4048
112.394
61.7148
112.394
62.1948L112.384
62.2348H110.354ZM114.935
60.4748V67.1948H116.215V64.6048C116.465
65.0448
117.015
65.3148
117.665
65.3148C118.995
65.3148
119.965
64.3548
119.965
62.8648C119.965
61.3748
119.015
60.3748
117.735
60.3748C117.105
60.3748
116.515
60.6948
116.165
61.1848V60.4748H114.935ZM117.425
64.2248C116.735
64.2248
116.165
63.7048
116.165
62.8648C116.165
62.0148
116.735
61.4748
117.425
61.4748C118.135
61.4748
118.685
62.0148
118.685
62.8648C118.685
63.6948
118.135
64.2248
117.425
64.2248ZM120.962
63.8448C120.962
64.7548
121.702
65.3148
122.522
65.3148C123.292
65.3148
123.782
65.0348
124.092
64.4848V65.2148H125.262V62.2948C125.262
61.0548
124.522
60.3748
123.082
60.3748C121.932
60.3748
121.182
60.9448
120.932
61.8648L122.042
62.1648C122.152
61.6848
122.502
61.3748
123.072
61.3748C123.662
61.3748
124.042
61.6948
124.042
62.2248V62.5248L123.622
62.4248C123.362
62.3548
123.092
62.3248
122.812
62.3248C121.692
62.3248
120.962
62.8348
120.962
63.8448ZM124.042
63.4648C124.042
64.0148
123.622
64.3348
123.032
64.3348C122.522
64.3348
122.262
64.1148
122.262
63.7648C122.262
63.3948
122.542
63.1948
123.012
63.1948C123.202
63.1948
123.452
63.2448
123.752
63.3348L124.042
63.4248V63.4648ZM126.734
60.4748V65.2148H128.014V62.1648C128.014
61.6848
128.274
61.4148
128.674
61.4148C129.034
61.4148
129.274
61.6548
129.274
62.0048C129.274
62.1348
129.264
62.2548
129.234
62.3648H130.444C130.494
62.1748
130.514
61.9848
130.514
61.7748C130.514
60.9648
130.004
60.3748
129.154
60.3748C128.604
60.3748
128.214
60.6448
128.014
61.0848V60.4748H126.734ZM132.231
63.6148C132.231
64.6548
132.861
65.3148
134.011
65.3148C135.011
65.3148
135.651
64.7348
135.781
63.7448L134.601
63.5248C134.561
63.9648
134.371
64.1848
134.041
64.1848C133.721
64.1848
133.501
63.9748
133.501
63.6148V61.5248H135.281V60.4748H133.501V58.9148H132.231V60.4748H131.251V61.5248H132.231V63.6148ZM136.873
60.4748V65.2148H138.153V60.4748H136.873ZM136.833
59.7648H138.203V58.4148H136.833V59.7648ZM139.353
62.8648C139.353
64.3348
140.293
65.3148
141.633
65.3148C142.303
65.3148
142.843
65.0248
143.153
64.5348V65.2148H144.383V58.2148H143.103V61.1848C142.813
60.6948
142.273
60.3748
141.603
60.3748C140.303
60.3748
139.353
61.3748
139.353
62.8648ZM143.153
62.8648C143.153
63.7048
142.583
64.2248
141.893
64.2248C141.183
64.2248
140.633
63.7048
140.633
62.8648C140.633
62.0148
141.183
61.4748
141.893
61.4748C142.583
61.4748
143.153
62.0148
143.153
62.8648ZM145.58
62.8448C145.58
64.2548
146.63
65.3148
148.06
65.3148C149.49
65.3148
150.54
64.2548
150.54
62.8448C150.54
61.4248
149.5
60.3748
148.06
60.3748C146.62
60.3748
145.58
61.4248
145.58
62.8448ZM149.29
62.8448C149.29
63.6348
148.77
64.1648
148.06
64.1648C147.35
64.1648
146.83
63.6348
146.83
62.8448C146.83
62.0548
147.35
61.5248
148.06
61.5248C148.77
61.5248
149.29
62.0548
149.29
62.8448ZM151.744
60.4748V65.2148H153.024V62.1648C153.024
61.6848
153.284
61.4148
153.684
61.4148C154.044
61.4148
154.284
61.6548
154.284
62.0048C154.284
62.1348
154.274
62.2548
154.244
62.3648H155.454C155.504
62.1748
155.524
61.9848
155.524
61.7748C155.524
60.9648
155.014
60.3748
154.164
60.3748C153.614
60.3748
153.224
60.6448
153.024
61.0848V60.4748H151.744Z"
        fill="#0F5945"
      />
      <path
        d="M192.21
53.2148V53.0748C191.93
52.7648
191.89
52.4248
191.86
51.6248C191.83
50.6948
191.46
50.1848
190.75
49.9148C191.66
49.6048
192.15
48.9848
192.15
48.1148C192.15
47.0648
191.31
46.2148
189.92
46.2148H186.72V53.2148H187.64V50.2548H189.47C190.36
50.2548
190.83
50.6148
190.84
51.6148C190.85
52.4948
190.92
52.8948
191.08
53.2148H192.21ZM187.64
49.3848V47.0848H189.84C190.68
47.0848
191.18
47.5048
191.18
48.2348C191.18
48.9548
190.68
49.3848
189.84
49.3848H187.64ZM196.932
51.4948C196.742
52.1748
196.292
52.5548
195.562
52.5548C194.722
52.5548
194.062
52.0048
194.052
50.9748H197.482C197.522
50.7948
197.542
50.6048
197.542
50.3948C197.542
49.7748
197.352
49.2848
196.962
48.9248C196.572
48.5548
196.062
48.3748
195.432
48.3748C194.082
48.3748
193.172
49.4548
193.172
50.8148C193.172
52.3448
194.172
53.3148
195.562
53.3148C196.652
53.3148
197.362
52.7448
197.682
51.7848L196.932
51.4948ZM194.052
50.3548C194.142
49.6048
194.692
49.1348
195.422
49.1348C196.142
49.1348
196.692
49.5348
196.692
50.2148L196.682
50.3548H194.052ZM198.936
48.4748V55.1948H199.796V52.4748C200.116
53.0048
200.716
53.3148
201.396
53.3148C202.686
53.3148
203.616
52.3148
203.616
50.8448C203.616
49.3748
202.736
48.3748
201.456
48.3748C200.746
48.3748
200.066
48.7948
199.756
49.3648V48.4748H198.936ZM201.256
52.5448C200.516
52.5448
199.756
51.9048
199.756
50.8548C199.756
49.8048
200.516
49.1548
201.256
49.1548C202.036
49.1548
202.756
49.7648
202.756
50.8548C202.756
51.9548
202.036
52.5448
201.256
52.5448ZM204.699
51.8648C204.699
52.3048
204.849
52.6548
205.149
52.9248C205.459
53.1848
205.829
53.3148
206.269
53.3148C207.029
53.3148
207.659
52.9748
207.899
52.4648V53.2148H208.729V50.2448C208.729
49.0748
207.999
48.3748
206.709
48.3748C205.599
48.3748
204.859
48.9548
204.669
49.8648L205.449
50.0848C205.569
49.4748
206.029
49.1048
206.709
49.1048C207.419
49.1048
207.899
49.4848
207.899
50.1748V50.6648L207.469
50.5448C207.149
50.4448
206.809
50.3948
206.459
50.3948C205.429
50.3948
204.699
50.8948
204.699
51.8648ZM207.899
51.4448C207.899
52.1348
207.349
52.5548
206.559
52.5548C205.929
52.5548
205.589
52.2548
205.589
51.8148C205.589
51.3248
205.969
51.0648
206.579
51.0648C206.829
51.0648
207.129
51.1148
207.459
51.2148L207.899
51.3448V51.4448ZM210.315
48.4748V53.2148H211.175V50.1948C211.175
49.5548
211.525
49.1348
212.055
49.1348C212.525
49.1348
212.815
49.4048
212.815
49.8448C212.815
49.9348
212.805
50.0548
212.785
50.1948H213.625C213.665
50.0348
213.685
49.8548
213.685
49.6648C213.685
48.9448
213.155
48.3748
212.385
48.3748C211.815
48.3748
211.385
48.6548
211.175
49.1648V48.4748H210.315ZM215.47
51.8048C215.47
52.7548
216.02
53.3148
217.02
53.3148C217.83
53.3148
218.37
52.8448
218.5
51.9948L217.7
51.8148C217.62
52.3348
217.39
52.5548
216.99
52.5548C216.62
52.5548
216.34
52.2948
216.34
51.8448V49.2048H218.04V48.4748H216.34V47.0048H215.47V48.4748H214.53V49.2048H215.47V51.8048ZM219.702
48.4748V53.2148H220.562V48.4748H219.702ZM219.652
47.5948H220.612V46.5848H219.652V47.5948ZM221.859
50.8748C221.859
52.3248
222.769
53.3148
224.079
53.3148C224.799
53.3148
225.399
52.9348
225.719
52.3648V53.2148H226.549V46.2148H225.679V49.3348C225.369
48.7448
224.789
48.3748
224.059
48.3748C222.769
48.3748
221.859
49.4048
221.859
50.8748ZM225.719
50.8648C225.719
51.9248
224.959
52.5448
224.219
52.5448C223.439
52.5448
222.719
51.9348
222.719
50.8648C222.719
49.8048
223.449
49.1548
224.219
49.1548C224.959
49.1548
225.719
49.8148
225.719
50.8648ZM227.842
50.8448C227.842
52.2448
228.812
53.3148
230.242
53.3148C231.662
53.3148
232.652
52.2448
232.652
50.8448C232.652
49.4448
231.682
48.3748
230.242
48.3748C228.812
48.3748
227.842
49.4448
227.842
50.8448ZM231.762
50.8448C231.762
51.8448
231.132
52.5348
230.242
52.5348C229.362
52.5348
228.722
51.8348
228.722
50.8448C228.722
49.8548
229.362
49.1548
230.242
49.1548C230.692
49.1548
231.052
49.3148
231.332
49.6348C231.622
49.9448
231.762
50.3548
231.762
50.8448ZM233.938
48.4748V53.2148H234.798V50.1948C234.798
49.5548
235.148
49.1348
235.678
49.1348C236.148
49.1348
236.438
49.4048
236.438
49.8448C236.438
49.9348
236.428
50.0548
236.408
50.1948H237.248C237.288
50.0348
237.308
49.8548
237.308
49.6648C237.308
48.9448
236.778
48.3748
236.008
48.3748C235.438
48.3748
235.008
48.6548
234.798
49.1648V48.4748H233.938ZM194.08
63.4948C193.89
64.1748
193.44
64.5548
192.71
64.5548C191.87
64.5548
191.21
64.0048
191.2
62.9748H194.63C194.67
62.7948
194.69
62.6048
194.69
62.3948C194.69
61.7748
194.5
61.2848
194.11
60.9248C193.72
60.5548
193.21
60.3748
192.58
60.3748C191.23
60.3748
190.32
61.4548
190.32
62.8148C190.32
64.3448
191.32
65.3148
192.71
65.3148C193.8
65.3148
194.51
64.7448
194.83
63.7848L194.08
63.4948ZM191.2
62.3548C191.29
61.6048
191.84
61.1348
192.57
61.1348C193.29
61.1348
193.84
61.5348
193.84
62.2148L193.83
62.3548H191.2ZM196.945
65.2148V62.6148C196.945
61.7048
197.505
61.1348
198.225
61.1348C198.905
61.1348
199.305
61.5648
199.305
62.3948V65.2148H200.175V62.1348C200.175
61.0248
199.515
60.3748
198.505
60.3748C197.835
60.3748
197.275
60.6948
196.945
61.2448V60.4748H196.085V65.2148H196.945ZM206.646
64.5548C205.806
64.5548
205.016
63.8748
205.016
62.8148C205.016
61.7248
205.816
61.1348
206.546
61.1348C207.316
61.1348
207.816
61.5748
207.966
62.3248L208.756
62.0848C208.526
61.0048
207.706
60.3748
206.556
60.3748C205.266
60.3748
204.176
61.3948
204.176
62.8148C204.176
64.3148
205.176
65.3148
206.666
65.3148C207.856
65.3148
208.716
64.6248
208.986
63.4248L208.216
63.1348C208.026
64.0248
207.566
64.5548
206.646
64.5548ZM209.994
63.8648C209.994
64.3048
210.144
64.6548
210.444
64.9248C210.754
65.1848
211.124
65.3148
211.564
65.3148C212.324
65.3148
212.954
64.9748
213.194
64.4648V65.2148H214.024V62.2448C214.024
61.0748
213.294
60.3748
212.004
60.3748C210.894
60.3748
210.154
60.9548
209.964
61.8648L210.744
62.0848C210.864
61.4748
211.324
61.1048
212.004
61.1048C212.714
61.1048
213.194
61.4848
213.194
62.1748V62.6648L212.764
62.5448C212.444
62.4448
212.104
62.3948
211.754
62.3948C210.724
62.3948
209.994
62.8948
209.994
63.8648ZM213.194
63.4448C213.194
64.1348
212.644
64.5548
211.854
64.5548C211.224
64.5548
210.884
64.2548
210.884
63.8148C210.884
63.3248
211.264
63.0648
211.874
63.0648C212.124
63.0648
212.424
63.1148
212.754
63.2148L213.194
63.3448V63.4448ZM215.61
60.4748V65.2148H216.47V62.3148C216.47
61.5448
216.87
61.1548
217.39
61.1548C217.91
61.1548
218.26
61.4948
218.26
62.1448V65.2148H219.13V62.2648C219.13
61.5348
219.54
61.1548
220.06
61.1548C220.57
61.1548
220.93
61.5148
220.93
62.1048V65.2148H221.79V61.9248C221.79
60.9248
221.23
60.3748
220.35
60.3748C219.75
60.3748
219.21
60.7148
218.96
61.3148C218.8
60.7048
218.32
60.3748
217.75
60.3748C217.12
60.3748
216.7
60.6748
216.47
61.1448V60.4748H215.61ZM223.38
60.4748V65.2148H224.24V60.4748H223.38ZM223.33
59.5948H224.29V58.5848H223.33V59.5948ZM226.687
65.2148V62.6148C226.687
61.7048
227.247
61.1348
227.967
61.1348C228.647
61.1348
229.047
61.5648
229.047
62.3948V65.2148H229.917V62.1348C229.917
61.0248
229.257
60.3748
228.247
60.3748C227.577
60.3748
227.017
60.6948
226.687
61.2448V60.4748H225.827V65.2148H226.687ZM231.217
62.8448C231.217
64.2448
232.187
65.3148
233.617
65.3148C235.037
65.3148
236.027
64.2448
236.027
62.8448C236.027
61.4448
235.057
60.3748
233.617
60.3748C232.187
60.3748
231.217
61.4448
231.217
62.8448ZM235.137
62.8448C235.137
63.8448
234.507
64.5348
233.617
64.5348C232.737
64.5348
232.097
63.8348
232.097
62.8448C232.097
61.8548
232.737
61.1548
233.617
61.1548C234.067
61.1548
234.427
61.3148
234.707
61.6348C234.997
61.9448
235.137
62.3548
235.137
62.8448Z"
        fill="#AEAEAE"
      />
      <path
        d="M263.916
48.1948V49.2448H264.956V48.1948H263.916ZM264.006
49.9448V55.1948H264.866V49.9448H264.006ZM266.629
46.2148V53.2148H271.659V52.3448H267.549V50.0248H271.259V49.1548H267.549V47.0848H271.559V46.2148H266.629ZM273.117
53.2148H273.977V46.2148H273.117V53.2148ZM278.266
48.4748V53.2148H279.126V50.1948C279.126
49.5548
279.476
49.1348
280.006
49.1348C280.476
49.1348
280.766
49.4048
280.766
49.8448C280.766
49.9348
280.756
50.0548
280.736
50.1948H281.576C281.616
50.0348
281.636
49.8548
281.636
49.6648C281.636
48.9448
281.106
48.3748
280.336
48.3748C279.766
48.3748
279.336
48.6548
279.126
49.1648V48.4748H278.266ZM286.302
51.4948C286.112
52.1748
285.662
52.5548
284.932
52.5548C284.092
52.5548
283.432
52.0048
283.422
50.9748H286.852C286.892
50.7948
286.912
50.6048
286.912
50.3948C286.912
49.7748
286.722
49.2848
286.332
48.9248C285.942
48.5548
285.432
48.3748
284.802
48.3748C283.452
48.3748
282.542
49.4548
282.542
50.8148C282.542
52.3448
283.542
53.3148
284.932
53.3148C286.022
53.3148
286.732
52.7448
287.052
51.7848L286.302
51.4948ZM283.422
50.3548C283.512
49.6048
284.062
49.1348
284.792
49.1348C285.512
49.1348
286.062
49.5348
286.062
50.2148L286.052
50.3548H283.422ZM288.307
48.4748V55.1948H289.167V52.4748C289.487
53.0048
290.087
53.3148
290.767
53.3148C292.057
53.3148
292.987
52.3148
292.987
50.8448C292.987
49.3748
292.107
48.3748
290.827
48.3748C290.117
48.3748
289.437
48.7948
289.127
49.3648V48.4748H288.307ZM290.627
52.5448C289.887
52.5448
289.127
51.9048
289.127
50.8548C289.127
49.8048
289.887
49.1548
290.627
49.1548C291.407
49.1548
292.127
49.7648
292.127
50.8548C292.127
51.9548
291.407
52.5448
290.627
52.5448ZM294.069
51.8648C294.069
52.3048
294.219
52.6548
294.519
52.9248C294.829
53.1848
295.199
53.3148
295.639
53.3148C296.399
53.3148
297.029
52.9748
297.269
52.4648V53.2148H298.099V50.2448C298.099
49.0748
297.369
48.3748
296.079
48.3748C294.969
48.3748
294.229
48.9548
294.039
49.8648L294.819
50.0848C294.939
49.4748
295.399
49.1048
296.079
49.1048C296.789
49.1048
297.269
49.4848
297.269
50.1748V50.6648L296.839
50.5448C296.519
50.4448
296.179
50.3948
295.829
50.3948C294.799
50.3948
294.069
50.8948
294.069
51.8648ZM297.269
51.4448C297.269
52.1348
296.719
52.5548
295.929
52.5548C295.299
52.5548
294.959
52.2548
294.959
51.8148C294.959
51.3248
295.339
51.0648
295.949
51.0648C296.199
51.0648
296.499
51.1148
296.829
51.2148L297.269
51.3448V51.4448ZM299.686
48.4748V53.2148H300.546V50.1948C300.546
49.5548
300.896
49.1348
301.426
49.1348C301.896
49.1348
302.186
49.4048
302.186
49.8448C302.186
49.9348
302.176
50.0548
302.156
50.1948H302.996C303.036
50.0348
303.056
49.8548
303.056
49.6648C303.056
48.9448
302.526
48.3748
301.756
48.3748C301.186
48.3748
300.756
48.6548
300.546
49.1648V48.4748H299.686ZM304.84
51.8048C304.84
52.7548
305.39
53.3148
306.39
53.3148C307.2
53.3148
307.74
52.8448
307.87
51.9948L307.07
51.8148C306.99
52.3348
306.76
52.5548
306.36
52.5548C305.99
52.5548
305.71
52.2948
305.71
51.8448V49.2048H307.41V48.4748H305.71V47.0048H304.84V48.4748H303.9V49.2048H304.84V51.8048ZM309.072
48.4748V53.2148H309.932V48.4748H309.072ZM309.022
47.5948H309.982V46.5848H309.022V47.5948ZM311.23
50.8748C311.23
52.3248
312.14
53.3148
313.45
53.3148C314.17
53.3148
314.77
52.9348
315.09
52.3648V53.2148H315.92V46.2148H315.05V49.3348C314.74
48.7448
314.16
48.3748
313.43
48.3748C312.14
48.3748
311.23
49.4048
311.23
50.8748ZM315.09
50.8648C315.09
51.9248
314.33
52.5448
313.59
52.5448C312.81
52.5448
312.09
51.9348
312.09
50.8648C312.09
49.8048
312.82
49.1548
313.59
49.1548C314.33
49.1548
315.09
49.8148
315.09
50.8648ZM317.212
50.8448C317.212
52.2448
318.182
53.3148
319.612
53.3148C321.032
53.3148
322.022
52.2448
322.022
50.8448C322.022
49.4448
321.052
48.3748
319.612
48.3748C318.182
48.3748
317.212
49.4448
317.212
50.8448ZM321.132
50.8448C321.132
51.8448
320.502
52.5348
319.612
52.5348C318.732
52.5348
318.092
51.8348
318.092
50.8448C318.092
49.8548
318.732
49.1548
319.612
49.1548C320.062
49.1548
320.422
49.3148
320.702
49.6348C320.992
49.9448
321.132
50.3548
321.132
50.8448ZM323.309
48.4748V53.2148H324.169V50.1948C324.169
49.5548
324.519
49.1348
325.049
49.1348C325.519
49.1348
325.809
49.4048
325.809
49.8448C325.809
49.9348
325.799
50.0548
325.779
50.1948H326.619C326.659
50.0348
326.679
49.8548
326.679
49.6648C326.679
48.9448
326.149
48.3748
325.379
48.3748C324.809
48.3748
324.379
48.6548
324.169
49.1648V48.4748H323.309ZM284.895
65.2148H285.755V58.2148H284.895V65.2148ZM287.342
65.2148H288.202V58.2148H287.342V65.2148ZM293.259
63.4948C293.069
64.1748
292.619
64.5548
291.889
64.5548C291.049
64.5548
290.389
64.0048
290.379
62.9748H293.809C293.849
62.7948
293.869
62.6048
293.869
62.3948C293.869
61.7748
293.679
61.2848
293.289
60.9248C292.899
60.5548
292.389
60.3748
291.759
60.3748C290.409
60.3748
289.499
61.4548
289.499
62.8148C289.499
64.3448
290.499
65.3148
291.889
65.3148C292.979
65.3148
293.689
64.7448
294.009
63.7848L293.259
63.4948ZM290.379
62.3548C290.469
61.6048
291.019
61.1348
291.749
61.1348C292.469
61.1348
293.019
61.5348
293.019
62.2148L293.009
62.3548H290.379ZM295.244
65.8348C295.554
66.8048
296.274
67.2948
297.424
67.2948C298.144
67.2948
298.704
67.0648
299.094
66.6048C299.484
66.1448
299.684
65.5448
299.684
64.7948V60.4748H298.814V61.1848C298.464
60.6448
297.934
60.3748
297.214
60.3748C295.964
60.3748
294.974
61.3848
294.974
62.8648C294.974
64.2848
295.964
65.2148
297.164
65.2148C297.954
65.2148
298.534
64.8548
298.814
64.3248V64.8548C298.814
65.9248
298.344
66.5348
297.424
66.5348C296.684
66.5348
296.214
66.1948
296.024
65.5048L295.244
65.8348ZM298.854
62.8048C298.854
63.7748
298.214
64.4548
297.354
64.4548C296.474
64.4548
295.834
63.8448
295.834
62.8748C295.834
61.7948
296.474
61.1348
297.354
61.1348C298.214
61.1348
298.854
61.8448
298.854
62.8048ZM300.985
62.8448C300.985
64.2448
301.955
65.3148
303.385
65.3148C304.805
65.3148
305.795
64.2448
305.795
62.8448C305.795
61.4448
304.825
60.3748
303.385
60.3748C301.955
60.3748
300.985
61.4448
300.985
62.8448ZM304.905
62.8448C304.905
63.8448
304.275
64.5348
303.385
64.5348C302.505
64.5348
301.865
63.8348
301.865
62.8448C301.865
61.8548
302.505
61.1548
303.385
61.1548C303.835
61.1548
304.195
61.3148
304.475
61.6348C304.765
61.9448
304.905
62.3548
304.905
62.8448ZM302.975
59.6648H303.805L305.015
58.4748V58.3348H303.845L302.975
59.6648ZM307.162
63.4648H308.022V58.2148H307.162V63.4648ZM307.072
65.2148H308.112V64.1648H307.072V65.2148Z"
        fill="#AEAEAE"
      />
    </svg>

  );
}

DeliveryStepper2.propTypes = {
  className: PropTypes.string,
};

DeliveryStepper2.defaultProps = {
  className: '',
};

export default DeliveryStepper2;
