import PropTypes from 'prop-types';
import {
  useContext, useState, createContext, useMemo,
} from 'react';

import Alert from '../components/Alert';
import Modal from '../components/Modal';
import Portal from '../components/Portal';

const Context = createContext({});

function AlertProvider(props) {
  const [paramsAlert, setParamsAlert] = useState({});
  const [paramsModal, setParamsModal] = useState({});
  const showAlert = (args) => setParamsAlert(args);
  const showModal = (args) => setParamsModal(args);
  const { children } = props;
  const {
    onClose: onCloseAlert,
    ...alertProps
  } = paramsAlert;
  const {
    onClose: onCloseModal,
    ...modalProps
  } = paramsModal;

  const closeAlert = () => {
    if (onCloseAlert) {
      onCloseAlert();
    }
    setParamsAlert({});
  };

  const closeModal = () => {
    if (onCloseModal) {
      onCloseModal();
    }
    setParamsModal({});
  };
  const valueProps = useMemo(() => ({
    showAlert, showModal, closeAlert, closeModal,
  }), []);

  return (
    <Context.Provider
      value={valueProps}
    >
      {children}
      {Object.keys(alertProps).length > 0 && (
        <Portal className="alert-portal">
          <Alert
            {...alertProps}
            onClose={closeAlert}
          />
        </Portal>
      )}
      {Object.keys(modalProps).length > 0 && (
        <Portal className="modal-portal">
          <Modal
            {...modalProps}
            onClose={closeModal}
          />
        </Portal>
      )}
    </Context.Provider>
  );
}

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlert = () => useContext(Context);

export default AlertProvider;
