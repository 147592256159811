import PropTypes from 'prop-types';

function Cup(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 0.04317806,9.9568219 V 8.8553059 H 8.8553059 v 1.101516 z M 2.24621,7.75379 C 1.6403762,7.75379
        1.1217495,7.5380801 0.69031871,7.1066493 0.25889124,6.6752186 0.04317806,6.1565918 0.04317806,5.550758
        V 0.04317806 H 8.8553059 c 0.3029169,0 0.562324,0.10776462 0.7782211,0.32329495 0.2155336,0.21589713
        0.3232949,0.47530414 0.3232949,0.77822109 V 2.796968 c 0,0.3029169 -0.1077613,0.5621367 -0.3232949,0.7776703
        C 9.4176299,3.7905354 9.1582228,3.898484 8.8553059,3.898484 H 7.75379 v 1.652274 c 0,0.6058338
        -0.2157099,1.1244606 -0.6471407,1.5558913 C 6.6752186,7.5380801 6.1565918,7.75379 5.550758,7.75379 Z
        m 0,-1.101516 h 3.304548 c 0.3029169,0 0.5623239,-0.1079486 0.778221,-0.3238457 C 6.5445127,6.1128946
        6.652274,5.8536749 6.652274,5.550758 V 1.1446941 H 1.1446941 V 5.550758 c 0,0.3029169 0.1079485,0.5621366
        0.3238457,0.7776703 C 1.6840734,6.5443254 1.9432931,6.652274 2.24621,6.652274 Z M 7.75379,2.796968 H
        8.8553059 V 1.1446941 H 7.75379 Z"
      />
    </svg>
  );
}

Cup.propTypes = {
  className: PropTypes.string,
};

Cup.defaultProps = {
  className: '',
};

export default Cup;
