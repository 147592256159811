import clsx from 'clsx';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useDelivery from 'src/hooks/actions/useDelivery';
import SearchBar from '../../components/SearchBar/SearchBar';
import CommonHeader from '../../components/CommonHeader';
import ButtonGroup from '../../components/ButtonGroup';
import IconRender from '../../components/IconRender';
import { isScreenMin, MEDIA_SCREEN, useWindow } from '../../hooks/useWindow';

function Header(props) {
  const {
    selectedCategoryType,
    categoryTypes,
    onChangeCategoryType,
    children,
    onSearch,
    value,
  } = props;

  const location = useSelector((state) => state.location);
  const currentLocation = location.selectedStore;
  const navigation = useNavigate();
  const { storeID } = useParams();
  const { screen } = useWindow();
  const { deliveryData } = useDelivery();
  const handleShowStores = () => navigation('/stores');
  const handleSelectOrderMethod = () => navigation('/SelectOrderMethod');
  const handleCart = () => navigation(`/store/${storeID}/cart`);
  const handleCategoryChange = (_, categoryType) => {
    const foundCategoryType = categoryTypes.find((ct) => ct.categoryType === categoryType);
    onChangeCategoryType(foundCategoryType);
  };

  const getPaddingTop = () => {
    if (!deliveryData.isDelivery) {
      return categoryTypes.length <= 1 ? 70 : 88;
    }
    return 30;
  };

  const paddingTop = getPaddingTop();

  if (isScreenMin(MEDIA_SCREEN.LG, screen)) {
    return (
      <div className="sticky top-0 w-full bg-white z-10">
        <div className="max-w-7xl px-4 mx-auto">
          <CommonHeader
            onGoToCart={handleCart}
            onGoToSelectOrderMethod={handleSelectOrderMethod}
            onGoToStores={handleShowStores}
            currentLocation={currentLocation}
            endComponent={(
              <>
                {categoryTypes.length > 1 && (
                  <div className="w-full flex justify-center">
                    {
                      !deliveryData.isDelivery
                      && (
                      <ButtonGroup
                        color="primary-gray"
                        className="font-medium"
                        size="sm"
                        selectedProps={{
                          color: 'primary',
                        }}
                        value={selectedCategoryType}
                        onClick={handleCategoryChange}
                        options={categoryTypes.map((categoryType) => ({
                          value: categoryType.categoryType,
                          label: categoryType.categoryType,
                          icon: <IconRender icon={categoryType.icon} className="w-3 h-3" />,
                        }))}
                      />
                      )
                    }
                  </div>
                )}
                <div className="min-w-[320px] max-w-xs">
                  { children || <SearchBar onChange={onSearch} /> }
                </div>
              </>
            )}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <motion.div
        className="fixed flex flex-col w-full px-4 z-20 bg-white overflow-hidden"
        animate={{ height: value !== '' ? 0 : 'auto' }}
      >
        <div>
          <CommonHeader
            onGoToCart={handleCart}
            onGoToSelectOrderMethod={handleSelectOrderMethod}
            onGoToStores={handleShowStores}
            currentLocation={currentLocation}
          />
        </div>
      </motion.div>
      <motion.div
        className="w-full flex justify-center overflow-y-hidden box-content bg-white"
        animate={{
          paddingTop: value !== '' ? 0 : paddingTop,
          height: categoryTypes.length <= 1 || value !== '' ? 0 : 32,
        }}
      >
        {!deliveryData.isDelivery
            && (
              <ButtonGroup
                color="primary-gray"
                className="font-medium"
                selectedProps={{
                  color: 'primary',
                }}
                size="sm"
                value={selectedCategoryType}
                onClick={handleCategoryChange}
                options={categoryTypes.map((categoryType) => ({
                  value: categoryType.categoryType,
                  label: categoryType.categoryType,
                  icon: <IconRender icon={categoryType.icon} className="w-3 h-3" />,
                }))}
              />
            )}
      </motion.div>
      <div
        className={clsx(
          'flex flex-col w-full px-4 pt-4 pb-6 z-10 bg-white',
          { 'sticky top-0': value !== '' },
        )}
      >
        <div>
          <SearchBar onChange={onSearch} />
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  selectedCategoryType: PropTypes.string,
  categoryTypes: PropTypes.array,
  onChangeCategoryType: PropTypes.func,
  children: PropTypes.node,
  onSearch: PropTypes.func,
  value: PropTypes.string,
};

Header.defaultProps = {
  selectedCategoryType: undefined,
  categoryTypes: [],
  onChangeCategoryType: () => null,
  children: null,
  onSearch: () => null,
  value: '',
};

export default Header;
