import { useQuery } from '@tanstack/react-query';
import { getCountryCode } from '../../services/api';

export function useCountryCode() {
  const {
    data: CountryCode,
    isLoading: isLoadingCountryCode,
  } = useQuery(['getCountryCode'], () => getCountryCode(), { });

  return { isLoadingCountryCode, CountryCode };
}

export default useCountryCode;
