import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  addProduct2Cart,
  clearProductsCart,
  clearSessionData,
  markToRemoveCart,
  removeProduct2Cart,
  setAddDiscount,
  setOrderMethod,
  setScheduler,
  setSessionData,
  setTotalAndDiscountCart,
  updateProductsOnCart,
  updateQuantityProductOnCart,
} from '../../redux/actions/shoppingCart';
import { useMenu } from '../services/useMenu';
import { useSessionStorage } from '../useSessionStorage';
import useGoogleAnalytics from '../useGoogleAnalytics';

export function useShoppingCart() {
  const dispatch = useDispatch();
  const { sendEvent } = useGoogleAnalytics();
  const { storeID } = useParams();
  const [sessionCategoryType] = useSessionStorage('sessionCategory', '');
  const { menu: { menuCategories }, isLoading } = useMenu({
    searchValue: '',
    selectedCategoryType: sessionCategoryType,
    storeID,
  });

  const {
    markToRemove,
    items = [],
    notAvailable: itemsNotAvailable = [],
    data: sessionData,
    scheduler = {
      fastSchedule: {
        from: '',
        to: '',
      },
    },
    discount,
    total: totalCart,
    totalWithDiscount: totalWithDiscountCart,
    pickupMethod,
  } = useSelector((state) => state.cart);

  useEffect(() => {
    if (!isLoading) {
      const products = menuCategories?.flatMap((menuCategory) => menuCategory.products) || [];
      let hasChanged = false;
      const notAvailable = [];
      const available = [];

      items.forEach((item) => {
        const product = products.find((p) => p.id === item.id);
        const isAvailable = !product || product.isAvailable;
        hasChanged = hasChanged || item.isAvailable !== isAvailable;

        if (!isAvailable) {
          notAvailable.push({ ...item, isAvailable });
        } else {
          available.push({ ...item, isAvailable });
        }
      });

      if (hasChanged || itemsNotAvailable.length !== notAvailable.length) {
        dispatch(updateProductsOnCart({
          items: available,
          notAvailable,
        }));
      }
    }
  }, [storeID, sessionCategoryType, menuCategories, isLoading]);

  const clearCart = () => {
    dispatch(clearProductsCart());
  };

  const clearSession = () => {
    dispatch(clearSessionData());
  };

  const updateSession = (data) => {
    dispatch(setSessionData(data));
  };

  const addProduct = (product) => {
    sendEvent('add to cart', { name: product.name, id: product.id });
    dispatch(addProduct2Cart(product));
  };

  const removeProduct = (index, product) => {
    sendEvent('remove to cart', { name: product.name, id: product.id });
    dispatch(removeProduct2Cart(index));
  };

  const changeScheduler = (item) => {
    dispatch(setScheduler(item));
  };

  const markToCleanCart = () => {
    dispatch(markToRemoveCart());
  };

  const updateQuantityProduct = (modifiedProducts) => {
    dispatch(updateQuantityProductOnCart(modifiedProducts));
  };

  const setTotalAndDiscount = (total, totalWithDiscount) => {
    dispatch(setTotalAndDiscountCart({ total, totalWithDiscount }));
  };

  const changeOrderMethod = (data) => {
    dispatch(setOrderMethod(data));
  };

  const addDiscount = (data) => {
    dispatch(setAddDiscount(data));
  };

  return {
    addProduct,
    changeOrderMethod,
    changeScheduler,
    clearCart,
    clearSession,
    items,
    itemsNotAvailable,
    discount,
    markToCleanCart,
    markToRemove,
    removeProduct,
    scheduler,
    sessionData,
    setTotalAndDiscount,
    totalCart,
    totalWithDiscountCart,
    updateQuantityProduct,
    updateSession,
    addDiscount,
    ...pickupMethod,
  };
}

export default useShoppingCart;
