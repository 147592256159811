import PropTypes from 'prop-types';
import { formatCurrency } from '../../utils/currency';
import FormInputPanel from '../FormInputPanel';

const Types = {
  checkBoxes: 'checkbox',
  counters: 'counter',
  radioBtn: 'radio',
};

function getType(selectType) {
  return Types[selectType] || 'counter';
}

function ModifierItem(props) {
  const {
    name,
    inputName,
    disabled,
    minAllowed,
    maxAllowed,
    maxDisabled,
    isAvailable,
    price,
    selectType,
    className,
    ...args
  } = props;

  return (
    <FormInputPanel
      id={inputName}
      panelElements={[{
        ...args,
        label: (
          <div className="flex flex-col">
            <p className="m-0">
              {name}
            </p>
            {Boolean(price) && price > 0 && (
              <p className="m-0">
                {formatCurrency(price)}
              </p>
            )}
          </div>
        ),
        name: inputName,
        type: getType(selectType),
        fullWidth: true,
        disabled: !isAvailable || disabled,
        min: minAllowed,
        max: maxAllowed,
        allowMinValue: true,
        maxDisabled,
        direction: 'row',
        classes: [
          'flex justify-between items-center w-full',
          className,
        ],
      }]}
    />
  );
}

ModifierItem.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  minAllowed: PropTypes.number,
  maxAllowed: PropTypes.number,
  price: PropTypes.number,
  selectType: PropTypes.oneOf(['checkBoxes', 'counters', 'radioBtn']),
  isAvailable: PropTypes.bool,
  maxDisabled: PropTypes.bool,
  className: PropTypes.string,
};
ModifierItem.defaultProps = {
  disabled: false,
  price: null,
  minAllowed: null,
  maxAllowed: null,
  selectType: 'counters',
  isAvailable: true,
  maxDisabled: false,
  className: '',
};

export default ModifierItem;
