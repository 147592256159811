import PropTypes from 'prop-types';
import Button from './Button';
import { ChevronRightIcon } from './icons';

function CompositeButton(props) {
  const {
    title, subtitle, icon, onClick,
  } = props;

  return (
    <Button
      className="rounded-2xl px-6 py-2"
      size="md"
      fullWidth
      startIcon={icon}
      endIcon={(
        <ChevronRightIcon className="h-10" />
      )}
      onClick={onClick}
    >
      <span className="inline-block align-bottom w-full text-base text-left px-1">
        {title}
      </span>
      <span className="inline-block align-text-top w-full text-xs text-left px-1">
        {subtitle}
      </span>
    </Button>
  );
}

CompositeButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

CompositeButton.defaultProps = {
  subtitle: '',
  icon: null,
  onClick: () => {},
};

export default CompositeButton;
