import { useQuery } from '@tanstack/react-query';
import { getUserInfo } from '../../services/api';

export function useUserInfo({ token }) {
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    isError: isErrorOnUserInfo,
  } = useQuery(['getUserInfo'], () => getUserInfo(), {
    enabled: !!token,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  return { isLoadingUserInfo, userInfo, isErrorOnUserInfo };
}

export default useUserInfo;
