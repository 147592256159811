import PropTypes from 'prop-types';

function DirectionsWalk(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 5 9"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.739498 8.3292L1.7395 3.2977L0.676998 3.7562V4.8292H0.135498V3.3917L1.927 2.6312C1.99633 2.6032
        2.05883 2.58236 2.1145 2.5687C2.17016 2.5547 2.22566 2.5442 2.281 2.5372C2.45466 2.52353 2.60583
        2.55486 2.7345 2.6312C2.86283 2.70753 2.97566 2.8082 3.073 2.9332L3.094 2.9642C3.28133 3.2562
        3.4775 3.54453 3.6825 3.8292C3.88716 4.11386 4.29166 4.2632 4.896 4.2772V4.8187C4.45833 4.7977
        4.06766 4.6952 3.724 4.5112C3.38033 4.3272 3.11816 4.06503 2.9375 3.7247L2.656 5.2247L3.396
        6.0062V8.3292H2.854V6.2247L1.948 5.4332L1.2605 8.3292H0.739498ZM3 2.1207C2.77066 2.1207 2.578
        2.04253 2.422 1.8862C2.26566 1.7302 2.1875 1.53753 2.1875 1.3082C2.1875 1.07886 2.26566 0.886197
        2.422 0.730197C2.578 0.573864 2.77066 0.495697 3 0.495697C3.22933 0.495697 3.422 0.573864 3.578
        0.730197C3.73433 0.886197 3.8125 1.07886 3.8125 1.3082C3.8125 1.53753 3.73433 1.7302
        3.578 1.8862C3.422 2.04253 3.22933 2.1207 3 2.1207Z"
      />
    </svg>
  );
}

DirectionsWalk.propTypes = {
  className: PropTypes.string,
};

DirectionsWalk.defaultProps = {
  className: '',
};

export default DirectionsWalk;
