const initialState = {
  token: null,
  messaging: null,
};

const fcmReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: data.token,
        messaging: data.messaging,
      };
    default:
      return state;
  }
};

export default fcmReducer;
