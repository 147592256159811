import PropTypes from 'prop-types';

function DeliveryStepper4(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="343"
      height="73"
      viewBox="0 0 343 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.214844" width="343" height="72" rx="12" fill="white" />
      <circle cx="48.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M46.775 20.7336L42.5 16.4586L43.5688 15.3898L46.775 18.5961L53.6562
      11.7148L54.725 12.7836L46.775 20.7336Z"
        fill="white"
      />
      <rect x="68.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="130.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M128.775 20.7336L124.5 16.4586L125.569 15.3898L128.775 18.5961L135.656
      11.7148L136.725 12.7836L128.775 20.7336Z"
        fill="white"
      />
      <rect x="150.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="212.5" cy="16.2148" r="11" stroke="#0F5945" strokeWidth="2" />
      <path
        d="M208.479 18.4648C208.794 20.3248 210.234 21.6148 212.409 21.6148C214.614
      21.6148 216.219 20.0998 216.219 17.9398C216.219 15.9298 214.629 14.7448 213.174 14.7448C212.994
      14.7448 212.829 14.7598 212.679 14.7898L215.754 12.2398V10.9648H208.929V12.4648H213.429L210.129
      15.2398L211.029 16.3648C211.344 16.0498 211.869 15.8398 212.454 15.8398C213.729 15.8398 214.644
      16.6948 214.644 17.9698C214.644 19.2448 213.684 20.1148 212.409 20.1148C211.029 20.1148 210.054
      19.1848 209.979 17.7898L208.479 18.4648Z"
        fill="#0F5945"
      />
      <rect x="232.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="294.5" cy="16.2148" r="11" stroke="#AEAEAE" strokeWidth="2" />
      <path
        d="M294.848
      21.4648H296.423V18.9148H298.148V17.4898H296.423V14.2048H294.848V17.4898H291.773C292.628 16.6348
       293.198 15.7648 293.498 14.8798C293.798 13.9798 293.948 12.8998 293.948 11.6398V10.9648H292.373V11.6398C292.328
       14.3248 292.088 15.2248 290.123 17.2648V18.9148H294.848V21.4648Z"
        fill="#AEAEAE"
      />
      <path
        d="M25.5266
46.2148V53.2148H30.5566V52.3448H26.4466V50.0248H30.1566V49.1548H26.4466V47.0848H30.4566V46.2148H25.5266ZM32.8747
53.2148V50.6148C32.8747
49.7048
33.4347
49.1348
34.1547
49.1348C34.8347
49.1348
35.2347
49.5648
35.2347
50.3948V53.2148H36.1047V50.1348C36.1047
49.0248
35.4447
48.3748
34.4347
48.3748C33.7647
48.3748
33.2047
48.6948
32.8747
49.2448V48.4748H32.0147V53.2148H32.8747ZM42.5756
52.5548C41.7356
52.5548
40.9456
51.8748
40.9456
50.8148C40.9456
49.7248
41.7456
49.1348
42.4756
49.1348C43.2456
49.1348
43.7456
49.5748
43.8956
50.3248L44.6856
50.0848C44.4556
49.0048
43.6356
48.3748
42.4856
48.3748C41.1956
48.3748
40.1056
49.3948
40.1056
50.8148C40.1056
52.3148
41.1056
53.3148
42.5956
53.3148C43.7856
53.3148
44.6456
52.6248
44.9156
51.4248L44.1456
51.1348C43.9556
52.0248
43.4956
52.5548
42.5756
52.5548ZM49.3936
48.4748V50.9948C49.3936
51.9448
48.8236
52.5548
48.0736
52.5548C47.3836
52.5548
47.0036
52.1448
47.0036
51.3148V48.4748H46.1436V51.5148C46.1436
52.6848
46.8036
53.3148
47.8436
53.3148C48.5336
53.3148
49.0736
52.9648
49.3936
52.4148V53.2148H50.2636V48.4748H49.3936ZM51.8429
48.4748V53.2148H52.7029V50.1948C52.7029
49.5548
53.0529
49.1348
53.5829
49.1348C54.0529
49.1348
54.3429
49.4048
54.3429
49.8448C54.3429
49.9348
54.3329
50.0548
54.3129
50.1948H55.1529C55.1929
50.0348
55.2129
49.8548
55.2129
49.6648C55.2129
48.9448
54.6829
48.3748
53.9129
48.3748C53.3429
48.3748
52.9129
48.6548
52.7029
49.1648V48.4748H51.8429ZM56.079
51.9748C56.359
52.7848
57.089
53.3148
58.159
53.3148C59.229
53.3148
60.059
52.6948
60.059
51.8148C60.059
51.0248
59.509
50.5548
58.399
50.4048C57.469
50.2748
57.129
50.0948
57.129
49.6848C57.129
49.3148
57.529
49.0748
58.049
49.0748C58.559
49.0748
58.929
49.3148
59.089
49.7948L59.759
49.4248C59.469
48.7548
58.939
48.3748
58.049
48.3748C57.559
48.3748
57.149
48.4948
56.819
48.7448C56.499
48.9848
56.339
49.2948
56.339
49.6748C56.339
50.4248
56.879
50.8748
57.969
51.0348C58.929
51.1648
59.219
51.4148
59.219
51.8648C59.219
52.2748
58.829
52.5548
58.199
52.5548C57.479
52.5548
57.059
52.2748
56.789
51.6048L56.079
51.9748ZM61.0568
50.8448C61.0568
52.2448
62.0268
53.3148
63.4568
53.3148C64.8768
53.3148
65.8668
52.2448
65.8668
50.8448C65.8668
49.4448
64.8968
48.3748
63.4568
48.3748C62.0268
48.3748
61.0568
49.4448
61.0568
50.8448ZM64.9768
50.8448C64.9768
51.8448
64.3468
52.5348
63.4568
52.5348C62.5768
52.5348
61.9368
51.8348
61.9368
50.8448C61.9368
49.8548
62.5768
49.1548
63.4568
49.1548C63.9068
49.1548
64.2668
49.3148
64.5468
49.6348C64.8368
49.9448
64.9768
50.3548
64.9768
50.8448Z"
        fill="#0F5945"
      />
      <path
        d="M110.102
53.2148V53.0748C109.822
52.7648
109.782
52.4248
109.752
51.6248C109.722
50.6948
109.352
50.1848
108.642
49.9148C109.552
49.6048
110.042
48.9848
110.042
48.1148C110.042
47.0648
109.202
46.2148
107.812
46.2148H104.612V53.2148H105.532V50.2548H107.362C108.252
50.2548
108.722
50.6148
108.732
51.6148C108.742
52.4948
108.812
52.8948
108.972
53.2148H110.102ZM105.532
49.3848V47.0848H107.732C108.572
47.0848
109.072
47.5048
109.072
48.2348C109.072
48.9548
108.572
49.3848
107.732
49.3848H105.532ZM114.824
51.4948C114.634
52.1748
114.184
52.5548
113.454
52.5548C112.614
52.5548
111.954
52.0048
111.944
50.9748H115.374C115.414
50.7948
115.434
50.6048
115.434
50.3948C115.434
49.7748
115.244
49.2848
114.854
48.9248C114.464
48.5548
113.954
48.3748
113.324
48.3748C111.974
48.3748
111.064
49.4548
111.064
50.8148C111.064
52.3448
112.064
53.3148
113.454
53.3148C114.544
53.3148
115.254
52.7448
115.574
51.7848L114.824
51.4948ZM111.944
50.3548C112.034
49.6048
112.584
49.1348
113.314
49.1348C114.034
49.1348
114.584
49.5348
114.584
50.2148L114.574
50.3548H111.944ZM116.828
48.4748V55.1948H117.688V52.4748C118.008
53.0048
118.608
53.3148
119.288
53.3148C120.578
53.3148
121.508
52.3148
121.508
50.8448C121.508
49.3748
120.628
48.3748
119.348
48.3748C118.638
48.3748
117.958
48.7948
117.648
49.3648V48.4748H116.828ZM119.148
52.5448C118.408
52.5448
117.648
51.9048
117.648
50.8548C117.648
49.8048
118.408
49.1548
119.148
49.1548C119.928
49.1548
120.648
49.7648
120.648
50.8548C120.648
51.9548
119.928
52.5448
119.148
52.5448ZM122.591
51.8648C122.591
52.3048
122.741
52.6548
123.041
52.9248C123.351
53.1848
123.721
53.3148
124.161
53.3148C124.921
53.3148
125.551
52.9748
125.791
52.4648V53.2148H126.621V50.2448C126.621
49.0748
125.891
48.3748
124.601
48.3748C123.491
48.3748
122.751
48.9548
122.561
49.8648L123.341
50.0848C123.461
49.4748
123.921
49.1048
124.601
49.1048C125.311
49.1048
125.791
49.4848
125.791
50.1748V50.6648L125.361
50.5448C125.041
50.4448
124.701
50.3948
124.351
50.3948C123.321
50.3948
122.591
50.8948
122.591
51.8648ZM125.791
51.4448C125.791
52.1348
125.241
52.5548
124.451
52.5548C123.821
52.5548
123.481
52.2548
123.481
51.8148C123.481
51.3248
123.861
51.0648
124.471
51.0648C124.721
51.0648
125.021
51.1148
125.351
51.2148L125.791
51.3448V51.4448ZM128.207
48.4748V53.2148H129.067V50.1948C129.067
49.5548
129.417
49.1348
129.947
49.1348C130.417
49.1348
130.707
49.4048
130.707
49.8448C130.707
49.9348
130.697
50.0548
130.677
50.1948H131.517C131.557
50.0348
131.577
49.8548
131.577
49.6648C131.577
48.9448
131.047
48.3748
130.277
48.3748C129.707
48.3748
129.277
48.6548
129.067
49.1648V48.4748H128.207ZM133.362
51.8048C133.362
52.7548
133.912
53.3148
134.912
53.3148C135.722
53.3148
136.262
52.8448
136.392
51.9948L135.592
51.8148C135.512
52.3348
135.282
52.5548
134.882
52.5548C134.512
52.5548
134.232
52.2948
134.232
51.8448V49.2048H135.932V48.4748H134.232V47.0048H133.362V48.4748H132.422V49.2048H133.362V51.8048ZM137.594
48.4748V53.2148H138.454V48.4748H137.594ZM137.544
47.5948H138.504V46.5848H137.544V47.5948ZM139.751
50.8748C139.751
52.3248
140.661
53.3148
141.971
53.3148C142.691
53.3148
143.291
52.9348
143.611
52.3648V53.2148H144.441V46.2148H143.571V49.3348C143.261
48.7448
142.681
48.3748
141.951
48.3748C140.661
48.3748
139.751
49.4048
139.751
50.8748ZM143.611
50.8648C143.611
51.9248
142.851
52.5448
142.111
52.5448C141.331
52.5448
140.611
51.9348
140.611
50.8648C140.611
49.8048
141.341
49.1548
142.111
49.1548C142.851
49.1548
143.611
49.8148
143.611
50.8648ZM145.734
50.8448C145.734
52.2448
146.704
53.3148
148.134
53.3148C149.554
53.3148
150.544
52.2448
150.544
50.8448C150.544
49.4448
149.574
48.3748
148.134
48.3748C146.704
48.3748
145.734
49.4448
145.734
50.8448ZM149.654
50.8448C149.654
51.8448
149.024
52.5348
148.134
52.5348C147.254
52.5348
146.614
51.8348
146.614
50.8448C146.614
49.8548
147.254
49.1548
148.134
49.1548C148.584
49.1548
148.944
49.3148
149.224
49.6348C149.514
49.9448
149.654
50.3548
149.654
50.8448ZM151.83
48.4748V53.2148H152.69V50.1948C152.69
49.5548
153.04
49.1348
153.57
49.1348C154.04
49.1348
154.33
49.4048
154.33
49.8448C154.33
49.9348
154.32
50.0548
154.3
50.1948H155.14C155.18
50.0348
155.2
49.8548
155.2
49.6648C155.2
48.9448
154.67
48.3748
153.9
48.3748C153.33
48.3748
152.9
48.6548
152.69
49.1648V48.4748H151.83ZM109.604
63.8648C109.604
64.3048
109.754
64.6548
110.054
64.9248C110.364
65.1848
110.734
65.3148
111.174
65.3148C111.934
65.3148
112.564
64.9748
112.804
64.4648V65.2148H113.634V62.2448C113.634
61.0748
112.904
60.3748
111.614
60.3748C110.504
60.3748
109.764
60.9548
109.574
61.8648L110.354
62.0848C110.474
61.4748
110.934
61.1048
111.614
61.1048C112.324
61.1048
112.804
61.4848
112.804
62.1748V62.6648L112.374
62.5448C112.054
62.4448
111.714
62.3948
111.364
62.3948C110.334
62.3948
109.604
62.8948
109.604
63.8648ZM112.804
63.4448C112.804
64.1348
112.254
64.5548
111.464
64.5548C110.834
64.5548
110.494
64.2548
110.494
63.8148C110.494
63.3248
110.874
63.0648
111.484
63.0648C111.734
63.0648
112.034
63.1148
112.364
63.2148L112.804
63.3448V63.4448ZM114.821
63.9748C115.101
64.7848
115.831
65.3148
116.901
65.3148C117.971
65.3148
118.801
64.6948
118.801
63.8148C118.801
63.0248
118.251
62.5548
117.141
62.4048C116.211
62.2748
115.871
62.0948
115.871
61.6848C115.871
61.3148
116.271
61.0748
116.791
61.0748C117.301
61.0748
117.671
61.3148
117.831
61.7948L118.501
61.4248C118.211
60.7548
117.681
60.3748
116.791
60.3748C116.301
60.3748
115.891
60.4948
115.561
60.7448C115.241
60.9848
115.081
61.2948
115.081
61.6748C115.081
62.4248
115.621
62.8748
116.711
63.0348C117.671
63.1648
117.961
63.4148
117.961
63.8648C117.961
64.2748
117.571
64.5548
116.941
64.5548C116.221
64.5548
115.801
64.2748
115.531
63.6048L114.821
63.9748ZM120.128
60.4748V65.2148H120.988V60.4748H120.128ZM120.078
59.5948H121.038V58.5848H120.078V59.5948ZM122.556
65.8348C122.866
66.8048
123.586
67.2948
124.736
67.2948C125.456
67.2948
126.016
67.0648
126.406
66.6048C126.796
66.1448
126.996
65.5448
126.996
64.7948V60.4748H126.126V61.1848C125.776
60.6448
125.246
60.3748
124.526
60.3748C123.276
60.3748
122.286
61.3848
122.286
62.8648C122.286
64.2848
123.276
65.2148
124.476
65.2148C125.266
65.2148
125.846
64.8548
126.126
64.3248V64.8548C126.126
65.9248
125.656
66.5348
124.736
66.5348C123.996
66.5348
123.526
66.1948
123.336
65.5048L122.556
65.8348ZM126.166
62.8048C126.166
63.7748
125.526
64.4548
124.666
64.4548C123.786
64.4548
123.146
63.8448
123.146
62.8748C123.146
61.7948
123.786
61.1348
124.666
61.1348C125.526
61.1348
126.166
61.8448
126.166
62.8048ZM129.447
65.2148V62.6148C129.447
61.7048
130.007
61.1348
130.727
61.1348C131.407
61.1348
131.807
61.5648
131.807
62.3948V65.2148H132.677V62.1348C132.677
61.0248
132.017
60.3748
131.007
60.3748C130.337
60.3748
129.777
60.6948
129.447
61.2448V60.4748H128.587V65.2148H129.447ZM134.027
63.8648C134.027
64.3048
134.177
64.6548
134.477
64.9248C134.787
65.1848
135.157
65.3148
135.597
65.3148C136.357
65.3148
136.987
64.9748
137.227
64.4648V65.2148H138.057V62.2448C138.057
61.0748
137.327
60.3748
136.037
60.3748C134.927
60.3748
134.187
60.9548
133.997
61.8648L134.777
62.0848C134.897
61.4748
135.357
61.1048
136.037
61.1048C136.747
61.1048
137.227
61.4848
137.227
62.1748V62.6648L136.797
62.5448C136.477
62.4448
136.137
62.3948
135.787
62.3948C134.757
62.3948
134.027
62.8948
134.027
63.8648ZM137.227
63.4448C137.227
64.1348
136.677
64.5548
135.887
64.5548C135.257
64.5548
134.917
64.2548
134.917
63.8148C134.917
63.3248
135.297
63.0648
135.907
63.0648C136.157
63.0648
136.457
63.1148
136.787
63.2148L137.227
63.3448V63.4448ZM139.354
62.8748C139.354
64.3248
140.264
65.3148
141.574
65.3148C142.294
65.3148
142.894
64.9348
143.214
64.3648V65.2148H144.044V58.2148H143.174V61.3348C142.864
60.7448
142.284
60.3748
141.554
60.3748C140.264
60.3748
139.354
61.4048
139.354
62.8748ZM143.214
62.8648C143.214
63.9248
142.454
64.5448
141.714
64.5448C140.934
64.5448
140.214
63.9348
140.214
62.8648C140.214
61.8048
140.944
61.1548
141.714
61.1548C142.454
61.1548
143.214
61.8148
143.214
62.8648ZM145.336
62.8448C145.336
64.2448
146.306
65.3148
147.736
65.3148C149.156
65.3148
150.146
64.2448
150.146
62.8448C150.146
61.4448
149.176
60.3748
147.736
60.3748C146.306
60.3748
145.336
61.4448
145.336
62.8448ZM149.256
62.8448C149.256
63.8448
148.626
64.5348
147.736
64.5348C146.856
64.5348
146.216
63.8348
146.216
62.8448C146.216
61.8548
146.856
61.1548
147.736
61.1548C148.186
61.1548
148.546
61.3148
148.826
61.6348C149.116
61.9448
149.256
62.3548
149.256
62.8448Z"
        fill="#0F5945"
      />
      <path
        d="M191.297
53.2148V53.0548C191.027
52.7248
190.977
52.3548
190.937
51.5948C190.897
50.7648
190.487
50.2448
189.847
50.0348C190.687
49.7248
191.227
49.0248
191.227
48.1848C191.227
47.0648
190.367
46.2148
188.927
46.2148H185.497V53.2148H186.847V50.4348H188.347C189.077
50.4348
189.487
50.7748
189.487
51.6148C189.487
52.0648
189.507
52.3948
189.537
52.6148C189.567
52.8348
189.627
53.0348
189.717
53.2148H191.297ZM186.847
49.1848V47.4648H188.847C189.517
47.4648
189.877
47.7848
189.877
48.3148C189.877
48.8448
189.517
49.1848
188.847
49.1848H186.847ZM195.775
51.4048C195.625
51.9448
195.255
52.2148
194.675
52.2148C193.995
52.2148
193.435
51.8048
193.425
51.0848H196.645C196.685
50.9048
196.705
50.7148
196.705
50.5048C196.705
49.2048
195.815
48.3748
194.505
48.3748C193.105
48.3748
192.145
49.4648
192.145
50.8148C192.145
52.3148
193.185
53.3148
194.655
53.3148C195.765
53.3148
196.535
52.7548
196.865
51.8348L195.775
51.4048ZM193.425
50.2348C193.545
49.7148
193.985
49.4048
194.505
49.4048C195.075
49.4048
195.465
49.7148
195.465
50.1948L195.455
50.2348H193.425ZM198.007
48.4748V55.1948H199.287V52.6048C199.537
53.0448
200.087
53.3148
200.737
53.3148C202.067
53.3148
203.037
52.3548
203.037
50.8648C203.037
49.3748
202.087
48.3748
200.807
48.3748C200.177
48.3748
199.587
48.6948
199.237
49.1848V48.4748H198.007ZM200.497
52.2248C199.807
52.2248
199.237
51.7048
199.237
50.8648C199.237
50.0148
199.807
49.4748
200.497
49.4748C201.207
49.4748
201.757
50.0148
201.757
50.8648C201.757
51.6948
201.207
52.2248
200.497
52.2248ZM204.034
51.8448C204.034
52.7548
204.774
53.3148
205.594
53.3148C206.364
53.3148
206.854
53.0348
207.164
52.4848V53.2148H208.334V50.2948C208.334
49.0548
207.594
48.3748
206.154
48.3748C205.004
48.3748
204.254
48.9448
204.004
49.8648L205.114
50.1648C205.224
49.6848
205.574
49.3748
206.144
49.3748C206.734
49.3748
207.114
49.6948
207.114
50.2248V50.5248L206.694
50.4248C206.434
50.3548
206.164
50.3248
205.884
50.3248C204.764
50.3248
204.034
50.8348
204.034
51.8448ZM207.114
51.4648C207.114
52.0148
206.694
52.3348
206.104
52.3348C205.594
52.3348
205.334
52.1148
205.334
51.7648C205.334
51.3948
205.614
51.1948
206.084
51.1948C206.274
51.1948
206.524
51.2448
206.824
51.3348L207.114
51.4248V51.4648ZM209.806
48.4748V53.2148H211.086V50.1648C211.086
49.6848
211.346
49.4148
211.746
49.4148C212.106
49.4148
212.346
49.6548
212.346
50.0048C212.346
50.1348
212.336
50.2548
212.306
50.3648H213.516C213.566
50.1748
213.586
49.9848
213.586
49.7748C213.586
48.9648
213.076
48.3748
212.226
48.3748C211.676
48.3748
211.286
48.6448
211.086
49.0848V48.4748H209.806ZM215.303
51.6148C215.303
52.6548
215.933
53.3148
217.083
53.3148C218.083
53.3148
218.723
52.7348
218.853
51.7448L217.673
51.5248C217.633
51.9648
217.443
52.1848
217.113
52.1848C216.793
52.1848
216.573
51.9748
216.573
51.6148V49.5248H218.353V48.4748H216.573V46.9148H215.303V48.4748H214.323V49.5248H215.303V51.6148ZM219.944
48.4748V53.2148H221.224V48.4748H219.944ZM219.904
47.7648H221.274V46.4148H219.904V47.7648ZM222.424
50.8648C222.424
52.3348
223.364
53.3148
224.704
53.3148C225.374
53.3148
225.914
53.0248
226.224
52.5348V53.2148H227.454V46.2148H226.174V49.1848C225.884
48.6948
225.344
48.3748
224.674
48.3748C223.374
48.3748
222.424
49.3748
222.424
50.8648ZM226.224
50.8648C226.224
51.7048
225.654
52.2248
224.964
52.2248C224.254
52.2248
223.704
51.7048
223.704
50.8648C223.704
50.0148
224.254
49.4748
224.964
49.4748C225.654
49.4748
226.224
50.0148
226.224
50.8648ZM228.651
50.8448C228.651
52.2548
229.701
53.3148
231.131
53.3148C232.561
53.3148
233.611
52.2548
233.611
50.8448C233.611
49.4248
232.571
48.3748
231.131
48.3748C229.691
48.3748
228.651
49.4248
228.651
50.8448ZM232.361
50.8448C232.361
51.6348
231.841
52.1648
231.131
52.1648C230.421
52.1648
229.901
51.6348
229.901
50.8448C229.901
50.0548
230.421
49.5248
231.131
49.5248C231.841
49.5248
232.361
50.0548
232.361
50.8448ZM234.816
48.4748V53.2148H236.096V50.1648C236.096
49.6848
236.356
49.4148
236.756
49.4148C237.116
49.4148
237.356
49.6548
237.356
50.0048C237.356
50.1348
237.346
50.2548
237.316
50.3648H238.526C238.576
50.1748
238.596
49.9848
238.596
49.7748C238.596
48.9648
238.086
48.3748
237.236
48.3748C236.686
48.3748
236.296
48.6448
236.096
49.0848V48.4748H234.816ZM193.178
63.4048C193.028
63.9448
192.658
64.2148
192.078
64.2148C191.398
64.2148
190.838
63.8048
190.828
63.0848H194.048C194.088
62.9048
194.108
62.7148
194.108
62.5048C194.108
61.2048
193.218
60.3748
191.908
60.3748C190.508
60.3748
189.548
61.4648
189.548
62.8148C189.548
64.3148
190.588
65.3148
192.058
65.3148C193.168
65.3148
193.938
64.7548
194.268
63.8348L193.178
63.4048ZM190.828
62.2348C190.948
61.7148
191.388
61.4048
191.908
61.4048C192.478
61.4048
192.868
61.7148
192.868
62.1948L192.858
62.2348H190.828ZM196.689
65.2148V62.5948C196.689
61.8448
197.099
61.4148
197.669
61.4148C198.219
61.4148
198.559
61.8148
198.559
62.4648V65.2148H199.839V62.1148C199.839
61.0248
199.159
60.3748
198.129
60.3748C197.529
60.3748
196.999
60.6148
196.689
61.0948V60.4748H195.409V65.2148H196.689ZM206.197
64.1848C205.527
64.1848
204.897
63.6648
204.897
62.8148C204.897
61.9648
205.527
61.4948
206.127
61.4948C206.727
61.4948
207.177
61.8648
207.267
62.4648L208.397
62.0848C208.187
61.0048
207.297
60.3748
206.097
60.3748C204.777
60.3748
203.647
61.3848
203.647
62.8148C203.647
64.3148
204.677
65.3148
206.227
65.3148C207.467
65.3148
208.357
64.6748
208.647
63.4248L207.467
63.0148C207.377
63.6948
206.967
64.1848
206.197
64.1848ZM209.563
63.8448C209.563
64.7548
210.303
65.3148
211.123
65.3148C211.893
65.3148
212.383
65.0348
212.693
64.4848V65.2148H213.863V62.2948C213.863
61.0548
213.123
60.3748
211.683
60.3748C210.533
60.3748
209.783
60.9448
209.533
61.8648L210.643
62.1648C210.753
61.6848
211.103
61.3748
211.673
61.3748C212.263
61.3748
212.643
61.6948
212.643
62.2248V62.5248L212.223
62.4248C211.963
62.3548
211.693
62.3248
211.413
62.3248C210.293
62.3248
209.563
62.8348
209.563
63.8448ZM212.643
63.4648C212.643
64.0148
212.223
64.3348
211.633
64.3348C211.123
64.3348
210.863
64.1148
210.863
63.7648C210.863
63.3948
211.143
63.1948
211.613
63.1948C211.803
63.1948
212.053
63.2448
212.353
63.3348L212.643
63.4248V63.4648ZM215.335
60.4748V65.2148H216.615V62.3548C216.615
61.7848
216.905
61.4648
217.315
61.4648C217.725
61.4648
218.015
61.7448
218.015
62.2548V65.2148H219.285V62.3048C219.285
61.7548
219.555
61.4348
219.985
61.4348C220.395
61.4348
220.685
61.7348
220.685
62.2548V65.2148H221.965V62.0148C221.965
60.9748
221.355
60.3748
220.385
60.3748C219.785
60.3748
219.295
60.6648
219.015
61.2648C218.815
60.6748
218.355
60.3748
217.785
60.3748C217.245
60.3748
216.855
60.6048
216.615
61.0548V60.4748H215.335ZM223.437
60.4748V65.2148H224.717V60.4748H223.437ZM223.397
59.7648H224.767V58.4148H223.397V59.7648ZM227.467
65.2148V62.5948C227.467
61.8448
227.877
61.4148
228.447
61.4148C228.997
61.4148
229.337
61.8148
229.337
62.4648V65.2148H230.617V62.1148C230.617
61.0248
229.937
60.3748
228.907
60.3748C228.307
60.3748
227.777
60.6148
227.467
61.0948V60.4748H226.187V65.2148H227.467ZM231.821
62.8448C231.821
64.2548
232.871
65.3148
234.301
65.3148C235.731
65.3148
236.781
64.2548
236.781
62.8448C236.781
61.4248
235.741
60.3748
234.301
60.3748C232.861
60.3748
231.821
61.4248
231.821
62.8448ZM235.531
62.8448C235.531
63.6348
235.011
64.1648
234.301
64.1648C233.591
64.1648
233.071
63.6348
233.071
62.8448C233.071
62.0548
233.591
61.5248
234.301
61.5248C235.011
61.5248
235.531
62.0548
235.531
62.8448Z"
        fill="#0F5945"
      />
      <path
        d="M263.916
48.1948V49.2448H264.956V48.1948H263.916ZM264.006
49.9448V55.1948H264.866V49.9448H264.006ZM266.629
46.2148V53.2148H271.659V52.3448H267.549V50.0248H271.259V49.1548H267.549V47.0848H271.559V46.2148H266.629ZM273.117
53.2148H273.977V46.2148H273.117V53.2148ZM278.266
48.4748V53.2148H279.126V50.1948C279.126
49.5548
279.476
49.1348
280.006
49.1348C280.476
49.1348
280.766
49.4048
280.766
49.8448C280.766
49.9348
280.756
50.0548
280.736
50.1948H281.576C281.616
50.0348
281.636
49.8548
281.636
49.6648C281.636
48.9448
281.106
48.3748
280.336
48.3748C279.766
48.3748
279.336
48.6548
279.126
49.1648V48.4748H278.266ZM286.302
51.4948C286.112
52.1748
285.662
52.5548
284.932
52.5548C284.092
52.5548
283.432
52.0048
283.422
50.9748H286.852C286.892
50.7948
286.912
50.6048
286.912
50.3948C286.912
49.7748
286.722
49.2848
286.332
48.9248C285.942
48.5548
285.432
48.3748
284.802
48.3748C283.452
48.3748
282.542
49.4548
282.542
50.8148C282.542
52.3448
283.542
53.3148
284.932
53.3148C286.022
53.3148
286.732
52.7448
287.052
51.7848L286.302
51.4948ZM283.422
50.3548C283.512
49.6048
284.062
49.1348
284.792
49.1348C285.512
49.1348
286.062
49.5348
286.062
50.2148L286.052
50.3548H283.422ZM288.307
48.4748V55.1948H289.167V52.4748C289.487
53.0048
290.087
53.3148
290.767
53.3148C292.057
53.3148
292.987
52.3148
292.987
50.8448C292.987
49.3748
292.107
48.3748
290.827
48.3748C290.117
48.3748
289.437
48.7948
289.127
49.3648V48.4748H288.307ZM290.627
52.5448C289.887
52.5448
289.127
51.9048
289.127
50.8548C289.127
49.8048
289.887
49.1548
290.627
49.1548C291.407
49.1548
292.127
49.7648
292.127
50.8548C292.127
51.9548
291.407
52.5448
290.627
52.5448ZM294.069
51.8648C294.069
52.3048
294.219
52.6548
294.519
52.9248C294.829
53.1848
295.199
53.3148
295.639
53.3148C296.399
53.3148
297.029
52.9748
297.269
52.4648V53.2148H298.099V50.2448C298.099
49.0748
297.369
48.3748
296.079
48.3748C294.969
48.3748
294.229
48.9548
294.039
49.8648L294.819
50.0848C294.939
49.4748
295.399
49.1048
296.079
49.1048C296.789
49.1048
297.269
49.4848
297.269
50.1748V50.6648L296.839
50.5448C296.519
50.4448
296.179
50.3948
295.829
50.3948C294.799
50.3948
294.069
50.8948
294.069
51.8648ZM297.269
51.4448C297.269
52.1348
296.719
52.5548
295.929
52.5548C295.299
52.5548
294.959
52.2548
294.959
51.8148C294.959
51.3248
295.339
51.0648
295.949
51.0648C296.199
51.0648
296.499
51.1148
296.829
51.2148L297.269
51.3448V51.4448ZM299.686
48.4748V53.2148H300.546V50.1948C300.546
49.5548
300.896
49.1348
301.426
49.1348C301.896
49.1348
302.186
49.4048
302.186
49.8448C302.186
49.9348
302.176
50.0548
302.156
50.1948H302.996C303.036
50.0348
303.056
49.8548
303.056
49.6648C303.056
48.9448
302.526
48.3748
301.756
48.3748C301.186
48.3748
300.756
48.6548
300.546
49.1648V48.4748H299.686ZM304.84
51.8048C304.84
52.7548
305.39
53.3148
306.39
53.3148C307.2
53.3148
307.74
52.8448
307.87
51.9948L307.07
51.8148C306.99
52.3348
306.76
52.5548
306.36
52.5548C305.99
52.5548
305.71
52.2948
305.71
51.8448V49.2048H307.41V48.4748H305.71V47.0048H304.84V48.4748H303.9V49.2048H304.84V51.8048ZM309.072
48.4748V53.2148H309.932V48.4748H309.072ZM309.022
47.5948H309.982V46.5848H309.022V47.5948ZM311.23
50.8748C311.23
52.3248
312.14
53.3148
313.45
53.3148C314.17
53.3148
314.77
52.9348
315.09
52.3648V53.2148H315.92V46.2148H315.05V49.3348C314.74
48.7448
314.16
48.3748
313.43
48.3748C312.14
48.3748
311.23
49.4048
311.23
50.8748ZM315.09
50.8648C315.09
51.9248
314.33
52.5448
313.59
52.5448C312.81
52.5448
312.09
51.9348
312.09
50.8648C312.09
49.8048
312.82
49.1548
313.59
49.1548C314.33
49.1548
315.09
49.8148
315.09
50.8648ZM317.212
50.8448C317.212
52.2448
318.182
53.3148
319.612
53.3148C321.032
53.3148
322.022
52.2448
322.022
50.8448C322.022
49.4448
321.052
48.3748
319.612
48.3748C318.182
48.3748
317.212
49.4448
317.212
50.8448ZM321.132
50.8448C321.132
51.8448
320.502
52.5348
319.612
52.5348C318.732
52.5348
318.092
51.8348
318.092
50.8448C318.092
49.8548
318.732
49.1548
319.612
49.1548C320.062
49.1548
320.422
49.3148
320.702
49.6348C320.992
49.9448
321.132
50.3548
321.132
50.8448ZM323.309
48.4748V53.2148H324.169V50.1948C324.169
49.5548
324.519
49.1348
325.049
49.1348C325.519
49.1348
325.809
49.4048
325.809
49.8448C325.809
49.9348
325.799
50.0548
325.779
50.1948H326.619C326.659
50.0348
326.679
49.8548
326.679
49.6648C326.679
48.9448
326.149
48.3748
325.379
48.3748C324.809
48.3748
324.379
48.6548
324.169
49.1648V48.4748H323.309ZM284.895
65.2148H285.755V58.2148H284.895V65.2148ZM287.342
65.2148H288.202V58.2148H287.342V65.2148ZM293.259
63.4948C293.069
64.1748
292.619
64.5548
291.889
64.5548C291.049
64.5548
290.389
64.0048
290.379
62.9748H293.809C293.849
62.7948
293.869
62.6048
293.869
62.3948C293.869
61.7748
293.679
61.2848
293.289
60.9248C292.899
60.5548
292.389
60.3748
291.759
60.3748C290.409
60.3748
289.499
61.4548
289.499
62.8148C289.499
64.3448
290.499
65.3148
291.889
65.3148C292.979
65.3148
293.689
64.7448
294.009
63.7848L293.259
63.4948ZM290.379
62.3548C290.469
61.6048
291.019
61.1348
291.749
61.1348C292.469
61.1348
293.019
61.5348
293.019
62.2148L293.009
62.3548H290.379ZM295.244
65.8348C295.554
66.8048
296.274
67.2948
297.424
67.2948C298.144
67.2948
298.704
67.0648
299.094
66.6048C299.484
66.1448
299.684
65.5448
299.684
64.7948V60.4748H298.814V61.1848C298.464
60.6448
297.934
60.3748
297.214
60.3748C295.964
60.3748
294.974
61.3848
294.974
62.8648C294.974
64.2848
295.964
65.2148
297.164
65.2148C297.954
65.2148
298.534
64.8548
298.814
64.3248V64.8548C298.814
65.9248
298.344
66.5348
297.424
66.5348C296.684
66.5348
296.214
66.1948
296.024
65.5048L295.244
65.8348ZM298.854
62.8048C298.854
63.7748
298.214
64.4548
297.354
64.4548C296.474
64.4548
295.834
63.8448
295.834
62.8748C295.834
61.7948
296.474
61.1348
297.354
61.1348C298.214
61.1348
298.854
61.8448
298.854
62.8048ZM300.985
62.8448C300.985
64.2448
301.955
65.3148
303.385
65.3148C304.805
65.3148
305.795
64.2448
305.795
62.8448C305.795
61.4448
304.825
60.3748
303.385
60.3748C301.955
60.3748
300.985
61.4448
300.985
62.8448ZM304.905
62.8448C304.905
63.8448
304.275
64.5348
303.385
64.5348C302.505
64.5348
301.865
63.8348
301.865
62.8448C301.865
61.8548
302.505
61.1548
303.385
61.1548C303.835
61.1548
304.195
61.3148
304.475
61.6348C304.765
61.9448
304.905
62.3548
304.905
62.8448ZM302.975
59.6648H303.805L305.015
58.4748V58.3348H303.845L302.975
59.6648ZM307.162
63.4648H308.022V58.2148H307.162V63.4648ZM307.072
65.2148H308.112V64.1648H307.072V65.2148Z"
        fill="#AEAEAE"
      />
    </svg>
  );
}

DeliveryStepper4.propTypes = {
  className: PropTypes.string,
};

DeliveryStepper4.defaultProps = {
  className: '',
};

export default DeliveryStepper4;
