import PropTypes from 'prop-types';

function Basket(props) {
  const {
    className,
  } = props;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="24px"
      height="24px"
      fill="currentColor"
      viewBox="0 0 512 512"
    >
      <path
        d="
        M410.000000,513.000000
          C293.644653,513.000000 177.289307,513.000000 60.338318,512.620728
          C50.360096,510.648468 41.964924,506.928619 34.407310,501.079498
          C19.817228,489.787628 11.323710,475.141327 11.104793,456.627655
          C10.726731,424.655334 10.790071,392.673492 11.070285,360.699127
          C11.273222,337.542572 9.412401,314.305695 13.027599,291.251556
          C14.655540,280.870117 15.747674,270.303986 18.542269,260.226318
          C25.220629,236.143341 32.787613,212.307266 39.952824,188.358643
          C45.123371,171.076828 50.224770,153.774307 55.147896,137.185669
          C48.670937,133.078827 42.596870,130.167862 37.658363,125.932724
          C16.659508,107.924728 8.374309,84.952164 13.057367,57.695396
          C16.974968,34.893829 30.015469,18.471081 50.442879,8.088410
          C56.577934,4.970136 63.460098,3.321759 70.000000,1.000000
          C181.022018,1.000000 292.044037,1.000000 403.669067,1.385302
          C424.199310,4.609356 439.595123,14.757479 450.975159,31.108263
          C458.762360,42.296829 462.613647,55.324528 466.899353,68.016617
          C473.048401,86.226814 478.613434,104.646767 483.937958,123.118378
          C487.406860,135.152451 490.456909,147.348618 492.871460,159.633957
          C495.431671,172.660339 497.233521,185.849731 498.966949,199.020538
          C500.584076,211.307541 502.733490,223.656189 502.819733,235.990494
          C503.224976,293.942688 503.000092,351.899353 502.999115,409.854401
          C502.999115,411.186615 503.164093,412.547699 502.952972,413.846680
          C500.005035,431.981506 489.694611,445.414001 475.825684,456.704163
          C465.190704,465.361603 454.821838,474.345734 444.324677,483.172760
          C438.331055,488.212738 432.246399,493.147095 426.333374,498.279541
          C420.799286,503.083099 415.437592,508.085266 410.000000,513.000000
        M386.033020,276.836609
          C386.343994,276.256348 386.819885,275.708649 386.941833,275.091064
          C389.975250,259.729279 392.589996,244.273483 396.057037,229.011108
          C402.400269,201.087448 409.811859,173.395874 415.655457,145.373459
          C418.932434,129.658859 419.978424,113.477425 421.975403,97.499550
          C422.220764,95.536217 422.235565,93.527855 422.177612,91.545609
          C421.906433,82.272858 422.625061,72.814140 421.012390,63.774700
          C418.635956,50.454399 405.867859,40.791595 391.421417,41.956322
          C380.971252,42.798855 373.161285,47.354195 367.832611,56.535496
          C362.048920,66.500710 362.370758,76.538765 367.817749,86.101677
          C373.136566,95.439514 381.826782,100.161636 392.745544,100.171066
          C392.745544,113.897438 392.745544,127.307793 392.745544,141.113190
          C347.755005,141.113190 303.017273,141.113190 257.883362,141.113190
          C257.883362,167.914642 257.883362,194.320969 257.883362,220.740692
          C217.747040,220.740692 178.010452,220.740692 137.875732,220.740692
          C137.875732,194.077698 137.875732,167.671280 137.875732,141.000473
          C124.507904,141.000473 111.536407,140.922501 98.569443,141.129547
          C97.365471,141.148758 95.452835,142.774033 95.116669,143.996597
          C91.965607,155.456329 89.473457,167.107300 86.050140,178.480026
          C78.471161,203.658447 70.527580,228.728027 62.613323,253.804337
          C54.658371,279.009613 51.577095,305.015503 51.161278,331.226074
          C50.540127,370.380096 50.944744,409.551514 51.087696,448.714874
          C51.101593,452.522034 51.720982,456.572845 53.127655,460.082336
          C57.126377,470.058685 65.775749,472.945984 75.530815,472.953308
          C176.523590,473.029266 277.516418,473.000946 378.509247,472.990356
          C379.944214,472.990204 381.379211,472.845184 383.000000,472.757751
          C383.000000,439.939301 382.817871,407.461090 383.051270,374.985870
          C383.284332,342.553650 381.304596,310.043274 386.033020,276.836609
        M462.000000,238.633789
          C459.484558,216.744446 456.969147,194.855103 454.453705,172.965744
          C453.811188,172.907272 453.168640,172.848801 452.526123,172.790314
          C452.016876,173.963654 451.320404,175.091995 451.026031,176.317001
          C445.237061,200.407700 439.193207,224.443024 433.850494,248.633011
          C430.366547,264.406952 426.967438,280.307922 425.259613,296.336273
          C423.443146,313.384216 423.260834,330.645538 423.088593,347.820923
          C422.776123,378.983398 423.000000,410.151245 423.000000,441.316864
          C423.000000,443.071259 423.000000,444.825653 423.000000,447.289673
          C432.228516,439.217896 440.401794,431.124268 449.545746,424.338684
          C458.567474,417.643738 463.125732,409.527161 463.037628,398.357635
          C462.620026,345.440887 462.333313,292.523102 462.000000,238.633789
        M181.406586,59.074532
          C180.271057,64.577110 178.202744,70.071228 178.152679,75.583664
          C177.847153,109.223129 177.997147,142.866760 178.009781,176.509003
          C178.010315,177.943298 178.154343,179.377518 178.226303,180.714722
          C191.649994,180.714722 204.716125,180.714722 218.000000,180.714722
          C218.000000,178.787994 218.000000,177.144806 218.000000,175.501617
          C218.000000,143.691376 218.063690,111.880951 217.969467,80.070999
          C217.930313,66.854462 217.271225,53.596836 224.854889,40.503223
          C217.700409,41.061089 211.676071,41.432255 205.672974,42.020386
          C194.729431,43.092548 186.466400,48.127655 181.406586,59.074532
        M86.682816,41.000000
          C84.269012,41.333248 81.864998,41.805714 79.439751,41.976215
          C67.415161,42.821571 56.625877,51.986847 53.223045,64.344040
          C50.240345,75.175545 55.776051,88.251091 65.476006,95.195839
          C71.822334,99.739532 79.009651,100.633934 86.153328,100.850632
          C101.971291,101.330467 117.813850,101.005997 133.646347,100.989784
          C135.069855,100.988327 136.493225,100.843361 138.000000,100.760468
          C138.000000,90.160332 138.269638,79.992455 137.923843,69.845543
          C137.580963,59.784340 139.903412,50.388927 144.236115,41.000000
          C124.962341,41.000000 106.304283,41.000000 86.682816,41.000000
        M323.950684,82.648094
          C323.633789,81.069809 323.101562,79.500671 323.034088,77.911789
          C322.499054,65.316261 322.920593,52.851776 328.900696,41.997875
          C314.457092,41.997875 300.211212,41.617107 285.996552,42.119312
          C274.785858,42.515385 266.605499,48.254932 261.794159,58.631126
          C255.453430,72.305557 259.044250,86.683815 257.877533,100.770973
          C281.973572,100.770973 305.377747,100.770973 328.919342,100.770973
          C327.886932,97.841438 326.806305,95.196754 326.032715,92.465118
          C325.203308,89.536247 324.662750,86.525558 323.950684,82.648094
        z"
      />
    </svg>
  );
}

Basket.propTypes = {
  className: PropTypes.string,
};

Basket.defaultProps = {
  className: '',
};

export default Basket;
