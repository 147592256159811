import PropTypes from 'prop-types';

function Dash(props) {
  const {
    className,
  } = props;

  return (
    <svg className={className} width="4" height="3" viewBox="0 0 4 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.156703 0.912V2.004H3.8387V0.912H0.156703Z" fill="#5C5C5C" />
    </svg>
  );
}

Dash.propTypes = {
  className: PropTypes.string,
};

Dash.defaultProps = {
  className: '',
};

export default Dash;
