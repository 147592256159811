import PropTypes from 'prop-types';

function SupportAgentSmile(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M26.5317 9.66667C25.0872 4.66096 20.4715 1 15.0007 1C9.52991 1 4.91419 4.66096 3.46973 9.66667"
        stroke="#0F5945"
        strokeWidth="2"
      />
      <rect x="24.334" y="9" width="5.33333" height="10.6667" rx="2.66667" fill="#0F5945" />
      <rect x="0.333984" y="9" width="5.33333" height="10.6667" rx="2.66667" fill="#0F5945" />
      <rect
        x="15.6676"
        y="25.6663"
        width="1.33333"
        height="4"
        rx="0.666667"
        transform="rotate(-90 15.6676 25.6663)"
        stroke="#0F5945"
        strokeWidth="1.33333"
      />
      <rect
        x="14.3343"
        y="15.0003"
        width="1.33333"
        height="4"
        rx="0.666667"
        transform="rotate(180 14.3343 15.0003)"
        stroke="#0F5945"
        strokeWidth="1.33333"
      />
      <rect
        x="19.6673"
        y="15.0003"
        width="1.33333"
        height="4"
        rx="0.666667"
        transform="rotate(180 19.6673 15.0003)"
        stroke="#0F5945"
        strokeWidth="1.33333"
      />
      <path d="M17.667 25C21.6473 25 25.0456 22.5085 26.3875 19" stroke="#0F5945" strokeWidth="1.33333" />
      <path
        d="M16.3337 20.9997C15.4448 20.9997 13.667 20.4663 13.667 18.333"
        stroke="#0F5945"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
    </svg>

  );
}

SupportAgentSmile.propTypes = {
  className: PropTypes.string,
};

SupportAgentSmile.defaultProps = {
  className: '',
};

export default SupportAgentSmile;
