import PropTypes from 'prop-types';

function Edit(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 19h1.4l8.625-8.625-1.4-1.4L5 17.6ZM19.3 8.925l-4.25-4.2 1.4-1.4q.575-.575 1.413-.575.837
        0 1.412.575l1.4 1.4q.575.575.6 1.388.025.812-.55 1.387ZM17.85
        10.4 7.25 21H3v-4.25l10.6-10.6Zm-3.525-.725-.7-.7 1.4 1.4Z"
      />
    </svg>
  );
}

Edit.propTypes = {
  className: PropTypes.string,
};

Edit.defaultProps = {
  className: '',
};

export default Edit;
