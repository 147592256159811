import { motion, MotionConfig } from 'framer-motion';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import Modal from 'src/components/Modal';
import FormInputPanel from '../../components/FormInputPanel';
import Button from '../../components/Button';
import Drawer from '../../components/Drawer';
import {
  LogoIcon, ChevronLeftIcon, DeleteIcon, CloseIcon,
} from '../../components/icons';
import { getUserAddresses, deleteUserAddress, getNearestStore } from '../../services/api';
import { useDelivery } from '../../hooks/actions/useDelivery';

function MyAddress(props) {
  const [openRemoveAddressDrawer, setOpenRemoveAddressDrawer] = useState(false);
  const {
    data: userAddresslistResponse = [],
    isLoading: addressesLoading,
  } = useQuery(['user_addresses'], getUserAddresses, {
    refetchOnWindowFocus: false,
  });
  const [userAddresslist, setUserAddresslist] = useState([]);
  const [addressToRemove, setAddressToRemove] = useState({});
  const { updateDelivery } = useDelivery();
  const navigate = useNavigate();

  const [selected, setSelected] = useState('');

  const handleHideRemove = () => {
    setOpenRemoveAddressDrawer(false);
  };

  const handleRemoveAddress = async (id) => {
    await deleteUserAddress(id).then(() => {
      const newUserAdresslist = userAddresslist.filter((address) => address.id !== id);
      setUserAddresslist(newUserAdresslist);
      setSelected('');
      handleHideRemove();
    });
  };
  const { isLoading, onSubmit } = props;
  const methods = useForm({
    defaultValues: '',
    mode: 'onChange',
  });
  const handleHideMyAddress = () => navigate(-1);
  const handleGoHome = () => navigate(-1);
  const handleSelectDeliveryAddress = async () => {
    const addressSelected = userAddresslist.find((address) => address.id === selected);
    await getNearestStore(addressSelected.lat, addressSelected.lng).then((response) => {
      updateDelivery({
        lat: addressSelected.lat,
        lng: addressSelected.lng,
        address: addressSelected.label,
        addressDetail: addressSelected.detail,
        isDelivery: true,
        isNewAddress: false,
        storeId: response.storeId,
      });
      navigate(`/store/${response.storeId}`);
    });
  };
  const handleSubmit = () => {
    handleSelectDeliveryAddress();
    onSubmit();
  };

  useEffect(() => {
    if (!addressesLoading) {
      if (userAddresslistResponse?.length > 0) {
        setUserAddresslist(userAddresslistResponse);
      } else { navigate('/AddressInput'); }
    }
  }, [userAddresslistResponse]);

  const [showModal, setShowModal] = useState(false);
  const onClose = () => setShowModal(false);

  return (
    <MotionConfig transition={{ duration: 0.5 }}>
      <motion.div
        className={clsx('fixed w-screen h-full overflow-auto z-10')}
        initial={{ top: '100%', opacity: 1 }}
        animate={{ top: 0, opacity: 1 }}
        exit={{ top: '100%', opacity: 1 }}
      >
        {/* Desktop View */}
        <div className="block h-full overflow-y-auto py-4 w-full bg-white">
          <nav className="flex justify-between py-6 px-4 lg:w-[80%] lg:mx-auto">
            <button
              className="flex w-10 h-10 justify-between items-center"
              type="button"
              onClick={handleHideMyAddress}
            >
              <ChevronLeftIcon className="fill-baco-primary scale-150" />
            </button>
            <button
              className="flex w-10 h-10 justify-between items-center"
              type="button"
              onClick={handleGoHome}
            >
              <LogoIcon className="fill-baco-primary w-5 h-8 scale-110" />
            </button>
          </nav>
          <div className="flex flex-col w-full max-w-md mx-auto h-3/4">
            <FormProvider {...methods}>
              <form
                className="flex flex-col items-center h-full fill-baco-primary"
                onSubmit={methods.handleSubmit(handleSubmit)}
                noValidate
              >
                <div className="w-full h-full overflow-y-auto pb-4">
                  <h1 className="text-2xl text-center text-baco-primary mb-6">
                    <b>Datos de envío</b>
                  </h1>
                  <div className="flex flex-col items-center h-fit">
                    <div className="w-full max-w-md px-4">
                      <div className="text-sm text-left text-baco-primary mb-6 ml-2">
                        <b>Elige una de tus direcciones o agrega una nueva.</b>
                      </div>
                      <div className="text-2xl text-left text-baco-primary mb-6 ml-2">
                        <b>Mis direcciones</b>
                      </div>
                      <div className="text-lg w-full max-w-md text-baco-primary pb-8 mt-6 underline">
                        {/* <a href="AddressInput">Nueva direccion</a> */}
                        <Button
                          className="font-bold w-full max-w-md text-baco-primary underline"
                          style={{
                            backgroundColor: 'transparent',
                            hover: 'black',
                            active: 'transparent',
                          }}
                          onClick={() => {
                            navigate('/AddressInput');
                          }}
                        >
                          <span className="mr-2">Nueva dirección</span>
                        </Button>
                      </div>
                      <div className="fill-baco-primary flex flex-row justify-between flex-wrap">
                        <FormInputPanel
                          id="address-list"
                          panelElements={[
                            {
                              type: 'radioButtonGroup',
                              name: 'userAddresslist',
                              fullWidth: true,
                              className: 'flex-col w-full',
                              classes: ['!w-full'],
                              options: userAddresslist.map((response) => ({
                                id: response.id,
                                value: response.label,
                                label: (
                                  <div
                                    aria-hidden="true"
                                    className="flex w-full"
                                    onClick={() => setSelected(response.id)}
                                  >
                                    <div
                                      className="btn flex flex-col items-center w-12 h-4"
                                      type="button"
                                    />
                                    <div
                                      className="btn flex flex-col items-center w-full"
                                      type="button"
                                    >
                                      <strong>{response.label}</strong>
                                      <span>{response.detail}</span>
                                    </div>
                                    <div>
                                      <Button
                                        id={response.id}
                                        type="button"
                                        onClick={() => {
                                          setAddressToRemove(response);
                                          setOpenRemoveAddressDrawer(true);
                                        }}
                                        color="bg-bacu-secondary"
                                        className="p-4"
                                      >
                                        <DeleteIcon className="mt-1 h-4 w-4 scale-150" />
                                      </Button>
                                    </div>
                                  </div>
                                ),
                              })),
                            },
                          ]}
                        />
                        <Drawer
                          open={openRemoveAddressDrawer}
                          direction="bottom"
                          keyboard="true"
                          classes={{
                            drawer: 'bg-gray-50',
                            backdrop: 'z-20',
                            content: 'h-full w-full max-w-4xl mx-auto',
                          }}
                        >
                          <div className="flex flex-col w-full checked:bg-baco-primary">
                            <nav className="flex justify-end px-2 items-end">
                              <button
                                className="flex w-5 h-5 justify-between items-center"
                                type="button"
                                onClick={handleHideRemove}
                              >
                                <CloseIcon className="fill-baco-primary w-5 h-5 scale-110" />
                              </button>
                            </nav>
                            <div className="font-bold text-xl py-4 px-3">
                              ¿Quieres borrar esta dirección?
                            </div>
                            <div className="text-md text-left px-4 ">{addressToRemove.address}</div>
                            <div className="text-md text-left px-4 pb-10">
                              {addressToRemove.details}
                            </div>
                            <Button
                              isLoading={isLoading}
                              size="lg"
                              fullWidth
                              className="!text-base"
                              onClick={() => {
                                handleRemoveAddress(addressToRemove.id);
                              }}
                            >
                              <span className="mr-2">Si, borrar</span>
                            </Button>
                          </div>
                        </Drawer>
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full max-w-md mx-auto px-4 py-6 bottom-0
                    shadow-baco-primary-100 shadow-outline absolute
                    shadow-[0_-1px_22px_-5px] lg:shadow-none"
                  >
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      size="lg"
                      fullWidth
                      className="!text-base"
                      disabled={!selected}
                    >
                      <span className="mr-2">Continuar</span>
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
          {showModal && (
          <Modal
            className="w-[90%]"
            show={showModal}
            onClose={onClose}
          >
            <div className="flex flex-col">
              <div className="text-2xl font-bold">¡Lo sentimos!</div>
              <div className="py-4">
                En este momento la dirección seleccionada está fuera de nuestro rango de cobertura.
                Vuelve a intentarlo más tarde o utiliza otra dirección.
              </div>
            </div>
          </Modal>
          )}
        </div>
        {/* </div> */}
      </motion.div>
    </MotionConfig>
  );
}

MyAddress.propTypes = {
  address: PropTypes.any,
  type: PropTypes.any,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

MyAddress.defaultProps = {
  address: {},
  type: 'DELIVERY',
  onSubmit: () => null,
  isLoading: false,
};

export default MyAddress;
