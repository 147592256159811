import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const DIRECTION_PREV = {
  left: {
    from: '0%',
    to: '-100%',
  },
  right: {
    from: '0%',
    to: '100%',
  },
};

const DIRECTION_CURR = {
  left: {
    from: '100%',
    to: '0%',
  },
  right: {
    from: '-100%',
    to: '0%',
  },
};

function AnimatedStepper(props) {
  const {
    direction,
    previous,
    current,
    duration,
  } = props;

  const controlPrev = useAnimation();
  const controlCurr = useAnimation();
  const [prevNode, setPrevNode] = useState();
  const [currNode, setCurrNode] = useState();

  useEffect(() => {
    setPrevNode(previous);
    setCurrNode(current);

    if (previous) {
      controlPrev.start({
        left: [DIRECTION_PREV[direction].from, DIRECTION_PREV[direction].to],
        transition: {
          duration,
          times: [0, 0.25],
        },
      });
      controlCurr.start({
        left: [DIRECTION_CURR[direction].from, DIRECTION_CURR[direction].to],
        transition: {
          duration,
          times: [0, 0.25],
        },
      });
    }
  }, [previous, current, duration]);

  return (
    <div
      className="absolute top-0 left-0 w-full h-full overflow-hidden"
    >
      <motion.div
        className="absolute top-0 left-full w-full h-full"
        animate={controlPrev}
      >
        {prevNode}
      </motion.div>

      <motion.div
        className="absolute top-0 left-0 w-full h-full"
        animate={controlCurr}
      >
        {currNode}
      </motion.div>
    </div>
  );
}

AnimatedStepper.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  previous: PropTypes.node,
  current: PropTypes.node.isRequired,
  duration: PropTypes.number,
};

AnimatedStepper.defaultProps = {
  direction: 'left',
  previous: null,
  duration: 0.25,
};

export default AnimatedStepper;
