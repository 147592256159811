import React from 'react';
import { useNavigate } from 'react-router-dom';
import config from 'src/config';
import {
  useWindow, MEDIA_SCREEN, isScreenMin,
} from 'src/hooks/useWindow';
// import { LogoIcon, SupportAgentSmileIcon, LogoBacuBigIcon } from '../icons';
import { LogoIcon, SupportAgentIcon, LogoBacuBigIcon } from '../icons';

function HeaderWithHelp() {
  const navigate = useNavigate();
  const { screen } = useWindow();

  return (
    <div className="w-full flex justify-between pl-4 pr-3 py-6">
      <button type="button" onClick={() => navigate('/')}>
        {
          isScreenMin(screen, MEDIA_SCREEN.LG) ? (
            <LogoIcon
              className="fill-baco-primary w-5 h-8 scale-110"
            />
          ) : <LogoBacuBigIcon />
        }

      </button>
      <button type="button" onClick={() => window.open(config.whatsAppSupport, '_blank')}>
        {/* <SupportAgentSmileIcon */}
        <SupportAgentIcon
          className="w-8 h-8 scale-110"
        />
      </button>
    </div>
  );
}

export default HeaderWithHelp;
