import PropTypes from 'prop-types';

function Bowl(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 13 8"
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="matrix(0.92615068,0,0,0.92615068,0.4800206,0.29539729)"
      >
        <path
          d="M 4.201962,8.0583921 C 3.9036295,8.0189957 3.6274779,7.7900719 3.5215651,7.4943575
          3.4686813,7.3467029 3.46855,7.1004703 3.5212789,6.9580087 3.5424666,6.9007645
          3.5640922,6.8408804 3.569336,6.8249331 3.5762246,6.8039834 3.3918852,6.6066354
          2.9050009,6.1137164 2.534373,5.7384947 2.0983474,5.2948894 1.9360551,5.127927
          0.89226022,4.0540954 0.24719458,3.1570159 -0.11422514,2.2766439 -0.39306989,1.5974138
          -0.50069091,1.0185988 -0.50087914,0.19711412 l -7.055e-5,-0.3079048 H 6.5182001 13.53735
          l -0.0098,0.47269886 c -0.0063,0.30264617 -0.01994,0.53822375 -0.03802,0.65483962
          -0.129824,0.8375278 -0.456,1.630807 -1.02354,2.4892583 -0.531259,0.8035724 -1.028308,1.3792781
          -2.190152,2.5367416 l -0.8050654,0.8020669 0.036379,0.097232 c 0.076287,0.2038948 0.060125,0.4641527
          -0.041247,0.6642005 -0.093453,0.1844216 -0.2925393,0.3535801 -0.4998991,0.4247526 l -0.1083849,0.037201
          -2.2724239,0.00241 C 5.3353634,8.07194 4.2629079,8.0664402 4.201962,8.0583921 Z M 8.1550771,6.7025442 C
          8.3299634,6.4954409 8.7004875,6.1110589 9.3710889,5.4410524 10.367464,4.4455611 10.629075,4.1654252
          11.032448,3.6620522 11.754879,2.7605229 12.201729,1.936017 12.371125,1.1919926 c 0.01869,-0.082074
          0.03801,-0.1667882 0.04294,-0.1882547 l 0.009,-0.0390304 H 6.515354 0.60767012 l 0.0114938,0.0650499 C
          0.64379923,1.1691922 0.74776859,1.5526791 0.79670048,1.6845971 1.0943374,2.4870109 1.6545361,3.296289
          2.5838988,4.2664316 2.704206,4.3920178 3.1230996,4.8187207 3.5147732,5.2146602 4.2796344,5.9878517
          4.4834764,6.2114041 4.8623913,6.6925861 l 0.2431613,0.308789 1.3989987,-4.841e-4 1.3989986,-4.842e-4 z"
        />
      </g>
    </svg>
  );
}

Bowl.propTypes = {
  className: PropTypes.string,
};

Bowl.defaultProps = {
  className: '',
};

export default Bowl;
