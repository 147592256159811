import {
  init as amplitudeInit, setUserId, track, identify, Identify,
} from '@amplitude/analytics-browser';
import config from '../config';

const API_KEY = config.amplitudeApiKey;
const { isProd } = config;

export function initAmplitude() {
  amplitudeInit(API_KEY);
}

export function setUserInfoProperties(userInfo) {
  if (userInfo) {
    setUserId(`${userInfo.country}_${userInfo.merchant_customer_id}`);
    const userProperties = new Identify();
    userProperties.set('recurrent_user', userInfo.recurrent_user);
    identify(userProperties);
  }
}

export function trackEvent(eventType, eventProperties) {
  if (isProd) {
    track(eventType, eventProperties);
  }
}
