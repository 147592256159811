import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import RadioBoxWithIcon from '.';

function RadioBoxWithIconControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    options,
    classes,
    readOnly,
    disabled,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange,
          onBlur,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          className={clsx(classes)}
        >
          <div>
            {options.map((option) => (
              <>
                <RadioBoxWithIcon
                  className={clsx(classes)}
                  name={name}
                  id={option.value}
                  key={option.value}
                  icon={option.icon}
                  label={option.label}
                  value={option.value}
                  checked={option.value === value}
                  onBlur={onBlur}
                  onChange={onChange}
                  readOnly={readOnly}
                  disabled={disabled}
                />
                {
                  (!!option.subOption && option.value === value)
                    ? option.subOption
                    : undefined
                }
              </>
            ))}
          </div>
        </InputContainer>
      )}
    />
  );
}

RadioBoxWithIconControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  helper: PropTypes.node,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.string,
      subOption: PropTypes.node,
    }),
  ),
};

RadioBoxWithIconControlled.defaultProps = {
  label: null,
  helper: null,
  classes: [],
  readOnly: false,
  disabled: false,
  options: [],
};

export default RadioBoxWithIconControlled;
