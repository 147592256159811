import PropTypes from 'prop-types';

function ChevronDown(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z" />
    </svg>
  );
}

ChevronDown.propTypes = {
  className: PropTypes.string,
};

ChevronDown.defaultProps = {
  className: '',
};

export default ChevronDown;
