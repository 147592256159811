import clsx from 'clsx';
import PropTypes from 'prop-types';
import Portal from '../Portal';
import { ReactComponent as LoadingIcon } from './assets/loading.svg';
import './loader.css';

function Loader(props) {
  const {
    title,
    subtitle,
  } = props;
  return (
    <Portal
      className="payment-status-pop-up"
    >
      <div
        className={clsx(
          'fixed top-0 left-0 w-screen h-full flex flex-col justify-between items-center',
          'z-50 bg-white',
        )}
      >
        <div
          className="w-full h-full flex flex-col justify-center items-center px-2 "
        >
          <span className="blink">
            <LoadingIcon />
          </span>
          <p className="text-2xl text-center font-bold">
            {title}
          </p>
          {subtitle
            && (
            <p className="text-center">
              {subtitle}
            </p>
            )}
        </div>
      </div>
    </Portal>
  );
}

Loader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Loader.defaultProps = {
  title: 'Estamos creando tu cuenta',
  subtitle: '',
};

export default Loader;
