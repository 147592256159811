import SchedulerContext from '../../contexts/Scheduler.context';
import Store from './Store';

function StoreWrapper() {
  return (
    <SchedulerContext>
      <Store />
    </SchedulerContext>
  );
}

export default StoreWrapper;
