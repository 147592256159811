import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import ButtonGroup from '.';

function ButtonGroupControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    size,
    classes,
    readOnly,
    disabled,
    fullWidth,
    direction,
    options,
    ...args
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        fieldState,
        field: {
          onChange,
          onBlur,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          direction={direction}
          fullWidth={fullWidth}
          className={clsx(classes)}
          fieldState={fieldState}
        >
          <ButtonGroup
            size={size}
            onBlur={onBlur}
            onClick={(_, optionValue) => onChange(optionValue)}
            value={value}
            options={options}
            readOnly={readOnly}
            disabled={disabled}
            {...args}
          />
        </InputContainer>
      )}
    />
  );
}

ButtonGroupControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  helper: PropTypes.node,
  size: PropTypes.string,
  classes: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
};

ButtonGroupControlled.defaultProps = {
  label: null,
  helper: null,
  size: 'md',
  classes: [],
  readOnly: false,
  disabled: false,
  fullWidth: false,
  direction: 'column',
  options: [],
};

export default ButtonGroupControlled;
