const initialState = {
  phone: {
    country_code: '',
    number: '',
  },
};

const userReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case 'SET_USER_INFO':
      return {
        ...state,
        phone: data.phone,
      };
    default:
      return state;
  }
};

export default userReducer;
