import PropTypes from 'prop-types';
import { ClearNightIcon } from '../../components/icons';
import { convertToTime } from '../../utils/date';
import IntermidiateScreen from './IntermidiateScreen';

function StoreNotAvailable(props) {
  const { openHour, openMinute } = props;
  const openDate = convertToTime(openHour, openMinute);
  const content = (
    <p className="text-base font-medium text-baco-primary text-center w-full max-w-2xl mx-auto">
      ¡Lo sentimos!
      <br />
      En este momento nuestros restaurantes están cerrados.
    </p>
  );

  const footer = (
    <p className="flex items-center gap-x-2 text-base font-bold text-baco-primary">
      <ClearNightIcon />
      <span>{`Disponible a partir de las ${openDate}`}</span>
    </p>
  );

  return <IntermidiateScreen content={content} footer={footer} />;
}

StoreNotAvailable.propTypes = {
  openHour: PropTypes.number,
  openMinute: PropTypes.number,
};

StoreNotAvailable.defaultProps = {
  openHour: 0,
  openMinute: 0,
};

export default StoreNotAvailable;
