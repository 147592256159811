import PropTypes from 'prop-types';

function Cart(props) {
  const {
    className,
  } = props;

  return (
    <svg
      className={className}
      fill="currentColor"
      height="24px"
      viewBox="0 0 14 14"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m 11.742019,2.9924946 h -0.2576 L 9.3081186,0.85780461 c -0.1738,-0.17052 -0.4571,-0.17052 -0.6374,
        0 -0.1738,0.17052999 -0.1738,0.44838999 0,0.62528999 l 1.5389004,1.5094 H 3.9511186 l 1.5389,-1.5094 c
        0.1738,-0.1706 0.1738,-0.44845 0,-0.62528999 -0.1739,-0.17052 -0.4572,-0.17052 -0.6375,0 l -2.1698,2.13468999 h
        -0.2576 c -0.5795,0 -1.7835,0 -1.7835,1.6169 0,0.6126 0.1288,1.0168 0.3992,1.2821 0.1545,0.1579 0.3412,0.24
        0.5408,0.2842 0.1868,0.0442 0.3864,0.0505 0.5795,0.0505 h 9.8384004 c 0.1996,0 0.3864,-0.0126
        0.5666,-0.0505 0.5409,-0.1263 0.953,-0.5053 0.953,-1.5663 0,-1.6169 -1.2041,-1.6169 -1.7771,-1.6169 z"
      />
      <path
        d="M 11.619719,7.0472946 H 2.4896186 c -0.3992,0 -0.7018,0.3474 -0.6374,0.7326 l 0.5408,3.2463004 c
        0.1803,1.08640.6632,2.3369 2.8073,2.3369 h 3.6121 c 2.1699004,0 2.5562004,-1.0674 2.7880004,-2.2611 l
        0.6503,-3.3031004 c 0.0773,-0.3916 -0.2253,-0.7516 -0.631,-0.7516 z M 7.0804186,11.784095 c
        -1.5066,0 -2.7364,-1.2063 -2.7364,-2.6842004 0,-0.2589 0.2189,-0.4737 0.4829,-0.4737 0.2639,0
        0.4829,0.2148 0.4829,0.4737 0,0.9600004 0.7919,1.7369004 1.7706,1.7369004 0.9787,0 1.7707,-0.7769
        1.7707,-1.7369004 0,-0.2589 0.2189,-0.4737 0.4829,-0.4737 0.264,0 0.4829,0.2148 0.4829,0.4737
        0,1.4779004 -1.2298,2.6842004 -2.7365,2.6842004 z"
      />
    </svg>
  );
}

Cart.propTypes = {
  className: PropTypes.string,
};

Cart.defaultProps = {
  className: '',
};

export default Cart;
