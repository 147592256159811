import PropTypes from 'prop-types';

function DeliveryStepper5(props) {
  const {
    className,
  } = props;

  return (
    <svg
      width="343"
      height="73"
      viewBox="0 0 343 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.214844" width="343" height="72" rx="12" fill="white" />
      <circle cx="48.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M46.775 20.7336L42.5 16.4586L43.5688 15.3898L46.775 18.5961L53.6562
      11.7148L54.725 12.7836L46.775 20.7336Z"
        fill="white"
      />
      <rect x="68.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="130.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M128.775 20.7336L124.5 16.4586L125.569 15.3898L128.775 18.5961L135.656
      11.7148L136.725 12.7836L128.775 20.7336Z"
        fill="white"
      />
      <rect x="150.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="212.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M210.775 20.7336L206.5 16.4586L207.569 15.3898L210.775 18.5961L217.656
      11.7148L218.725 12.7836L210.775 20.7336Z"
        fill="white"
      />
      <rect x="232.5" y="15.2148" width="42" height="2" rx="1" fill="#0F5945" />
      <circle cx="294.5" cy="16.2148" r="12" fill="#0F5945" />
      <path
        d="M292.775 20.7336L288.5 16.4586L289.569 15.3898L292.775 18.5961L299.656 11.7148L300.725
        12.7836L292.775 20.7336Z"
        fill="white"
      />
      <path
        d="M25.5266
46.2148V53.2148H30.5566V52.3448H26.4466V50.0248H30.1566V49.1548H26.4466V47.0848H30.4566V46.2148H25.5266ZM32.8747
53.2148V50.6148C32.8747
49.7048
33.4347
49.1348
34.1547
49.1348C34.8347
49.1348
35.2347
49.5648
35.2347
50.3948V53.2148H36.1047V50.1348C36.1047
49.0248
35.4447
48.3748
34.4347
48.3748C33.7647
48.3748
33.2047
48.6948
32.8747
49.2448V48.4748H32.0147V53.2148H32.8747ZM42.5756
52.5548C41.7356
52.5548
40.9456
51.8748
40.9456
50.8148C40.9456
49.7248
41.7456
49.1348
42.4756
49.1348C43.2456
49.1348
43.7456
49.5748
43.8956
50.3248L44.6856
50.0848C44.4556
49.0048
43.6356
48.3748
42.4856
48.3748C41.1956
48.3748
40.1056
49.3948
40.1056
50.8148C40.1056
52.3148
41.1056
53.3148
42.5956
53.3148C43.7856
53.3148
44.6456
52.6248
44.9156
51.4248L44.1456
51.1348C43.9556
52.0248
43.4956
52.5548
42.5756
52.5548ZM49.3936
48.4748V50.9948C49.3936
51.9448
48.8236
52.5548
48.0736
52.5548C47.3836
52.5548
47.0036
52.1448
47.0036
51.3148V48.4748H46.1436V51.5148C46.1436
52.6848
46.8036
53.3148
47.8436
53.3148C48.5336
53.3148
49.0736
52.9648
49.3936
52.4148V53.2148H50.2636V48.4748H49.3936ZM51.8429
48.4748V53.2148H52.7029V50.1948C52.7029
49.5548
53.0529
49.1348
53.5829
49.1348C54.0529
49.1348
54.3429
49.4048
54.3429
49.8448C54.3429
49.9348
54.3329
50.0548
54.3129
50.1948H55.1529C55.1929
50.0348
55.2129
49.8548
55.2129
49.6648C55.2129
48.9448
54.6829
48.3748
53.9129
48.3748C53.3429
48.3748
52.9129
48.6548
52.7029
49.1648V48.4748H51.8429ZM56.079
51.9748C56.359
52.7848
57.089
53.3148
58.159
53.3148C59.229
53.3148
60.059
52.6948
60.059
51.8148C60.059
51.0248
59.509
50.5548
58.399
50.4048C57.469
50.2748
57.129
50.0948
57.129
49.6848C57.129
49.3148
57.529
49.0748
58.049
49.0748C58.559
49.0748
58.929
49.3148
59.089
49.7948L59.759
49.4248C59.469
48.7548
58.939
48.3748
58.049
48.3748C57.559
48.3748
57.149
48.4948
56.819
48.7448C56.499
48.9848
56.339
49.2948
56.339
49.6748C56.339
50.4248
56.879
50.8748
57.969
51.0348C58.929
51.1648
59.219
51.4148
59.219
51.8648C59.219
52.2748
58.829
52.5548
58.199
52.5548C57.479
52.5548
57.059
52.2748
56.789
51.6048L56.079
51.9748ZM61.0568
50.8448C61.0568
52.2448
62.0268
53.3148
63.4568
53.3148C64.8768
53.3148
65.8668
52.2448
65.8668
50.8448C65.8668
49.4448
64.8968
48.3748
63.4568
48.3748C62.0268
48.3748
61.0568
49.4448
61.0568
50.8448ZM64.9768
50.8448C64.9768
51.8448
64.3468
52.5348
63.4568
52.5348C62.5768
52.5348
61.9368
51.8348
61.9368
50.8448C61.9368
49.8548
62.5768
49.1548
63.4568
49.1548C63.9068
49.1548
64.2668
49.3148
64.5468
49.6348C64.8368
49.9448
64.9768
50.3548
64.9768
50.8448Z"
        fill="#0F5945"
      />
      <path
        d="M110.102
53.2148V53.0748C109.822
52.7648
109.782
52.4248
109.752
51.6248C109.722
50.6948
109.352
50.1848
108.642
49.9148C109.552
49.6048
110.042
48.9848
110.042
48.1148C110.042
47.0648
109.202
46.2148
107.812
46.2148H104.612V53.2148H105.532V50.2548H107.362C108.252
50.2548
108.722
50.6148
108.732
51.6148C108.742
52.4948
108.812
52.8948
108.972
53.2148H110.102ZM105.532
49.3848V47.0848H107.732C108.572
47.0848
109.072
47.5048
109.072
48.2348C109.072
48.9548
108.572
49.3848
107.732
49.3848H105.532ZM114.824
51.4948C114.634
52.1748
114.184
52.5548
113.454
52.5548C112.614
52.5548
111.954
52.0048
111.944
50.9748H115.374C115.414
50.7948
115.434
50.6048
115.434
50.3948C115.434
49.7748
115.244
49.2848
114.854
48.9248C114.464
48.5548
113.954
48.3748
113.324
48.3748C111.974
48.3748
111.064
49.4548
111.064
50.8148C111.064
52.3448
112.064
53.3148
113.454
53.3148C114.544
53.3148
115.254
52.7448
115.574
51.7848L114.824
51.4948ZM111.944
50.3548C112.034
49.6048
112.584
49.1348
113.314
49.1348C114.034
49.1348
114.584
49.5348
114.584
50.2148L114.574
50.3548H111.944ZM116.829
48.4748V55.1948H117.689V52.4748C118.009
53.0048
118.609
53.3148
119.289
53.3148C120.579
53.3148
121.509
52.3148
121.509
50.8448C121.509
49.3748
120.629
48.3748
119.349
48.3748C118.639
48.3748
117.959
48.7948
117.649
49.3648V48.4748H116.829ZM119.149
52.5448C118.409
52.5448
117.649
51.9048
117.649
50.8548C117.649
49.8048
118.409
49.1548
119.149
49.1548C119.929
49.1548
120.649
49.7648
120.649
50.8548C120.649
51.9548
119.929
52.5448
119.149
52.5448ZM122.591
51.8648C122.591
52.3048
122.741
52.6548
123.041
52.9248C123.351
53.1848
123.721
53.3148
124.161
53.3148C124.921
53.3148
125.551
52.9748
125.791
52.4648V53.2148H126.621V50.2448C126.621
49.0748
125.891
48.3748
124.601
48.3748C123.491
48.3748
122.751
48.9548
122.561
49.8648L123.341
50.0848C123.461
49.4748
123.921
49.1048
124.601
49.1048C125.311
49.1048
125.791
49.4848
125.791
50.1748V50.6648L125.361
50.5448C125.041
50.4448
124.701
50.3948
124.351
50.3948C123.321
50.3948
122.591
50.8948
122.591
51.8648ZM125.791
51.4448C125.791
52.1348
125.241
52.5548
124.451
52.5548C123.821
52.5548
123.481
52.2548
123.481
51.8148C123.481
51.3248
123.861
51.0648
124.471
51.0648C124.721
51.0648
125.021
51.1148
125.351
51.2148L125.791
51.3448V51.4448ZM128.208
48.4748V53.2148H129.068V50.1948C129.068
49.5548
129.418
49.1348
129.948
49.1348C130.418
49.1348
130.708
49.4048
130.708
49.8448C130.708
49.9348
130.698
50.0548
130.678
50.1948H131.518C131.558
50.0348
131.578
49.8548
131.578
49.6648C131.578
48.9448
131.048
48.3748
130.278
48.3748C129.708
48.3748
129.278
48.6548
129.068
49.1648V48.4748H128.208ZM133.362
51.8048C133.362
52.7548
133.912
53.3148
134.912
53.3148C135.722
53.3148
136.262
52.8448
136.392
51.9948L135.592
51.8148C135.512
52.3348
135.282
52.5548
134.882
52.5548C134.512
52.5548
134.232
52.2948
134.232
51.8448V49.2048H135.932V48.4748H134.232V47.0048H133.362V48.4748H132.422V49.2048H133.362V51.8048ZM137.594
48.4748V53.2148H138.454V48.4748H137.594ZM137.544
47.5948H138.504V46.5848H137.544V47.5948ZM139.752
50.8748C139.752
52.3248
140.662
53.3148
141.972
53.3148C142.692
53.3148
143.292
52.9348
143.612
52.3648V53.2148H144.442V46.2148H143.572V49.3348C143.262
48.7448
142.682
48.3748
141.952
48.3748C140.662
48.3748
139.752
49.4048
139.752
50.8748ZM143.612
50.8648C143.612
51.9248
142.852
52.5448
142.112
52.5448C141.332
52.5448
140.612
51.9348
140.612
50.8648C140.612
49.8048
141.342
49.1548
142.112
49.1548C142.852
49.1548
143.612
49.8148
143.612
50.8648ZM145.734
50.8448C145.734
52.2448
146.704
53.3148
148.134
53.3148C149.554
53.3148
150.544
52.2448
150.544
50.8448C150.544
49.4448
149.574
48.3748
148.134
48.3748C146.704
48.3748
145.734
49.4448
145.734
50.8448ZM149.654
50.8448C149.654
51.8448
149.024
52.5348
148.134
52.5348C147.254
52.5348
146.614
51.8348
146.614
50.8448C146.614
49.8548
147.254
49.1548
148.134
49.1548C148.584
49.1548
148.944
49.3148
149.224
49.6348C149.514
49.9448
149.654
50.3548
149.654
50.8448ZM151.831
48.4748V53.2148H152.691V50.1948C152.691
49.5548
153.041
49.1348
153.571
49.1348C154.041
49.1348
154.331
49.4048
154.331
49.8448C154.331
49.9348
154.321
50.0548
154.301
50.1948H155.141C155.181
50.0348
155.201
49.8548
155.201
49.6648C155.201
48.9448
154.671
48.3748
153.901
48.3748C153.331
48.3748
152.901
48.6548
152.691
49.1648V48.4748H151.831ZM109.604
63.8648C109.604
64.3048
109.754
64.6548
110.054
64.9248C110.364
65.1848
110.734
65.3148
111.174
65.3148C111.934
65.3148
112.564
64.9748
112.804
64.4648V65.2148H113.634V62.2448C113.634
61.0748
112.904
60.3748
111.614
60.3748C110.504
60.3748
109.764
60.9548
109.574
61.8648L110.354
62.0848C110.474
61.4748
110.934
61.1048
111.614
61.1048C112.324
61.1048
112.804
61.4848
112.804
62.1748V62.6648L112.374
62.5448C112.054
62.4448
111.714
62.3948
111.364
62.3948C110.334
62.3948
109.604
62.8948
109.604
63.8648ZM112.804
63.4448C112.804
64.1348
112.254
64.5548
111.464
64.5548C110.834
64.5548
110.494
64.2548
110.494
63.8148C110.494
63.3248
110.874
63.0648
111.484
63.0648C111.734
63.0648
112.034
63.1148
112.364
63.2148L112.804
63.3448V63.4448ZM114.822
63.9748C115.102
64.7848
115.832
65.3148
116.902
65.3148C117.972
65.3148
118.802
64.6948
118.802
63.8148C118.802
63.0248
118.252
62.5548
117.142
62.4048C116.212
62.2748
115.872
62.0948
115.872
61.6848C115.872
61.3148
116.272
61.0748
116.792
61.0748C117.302
61.0748
117.672
61.3148
117.832
61.7948L118.502
61.4248C118.212
60.7548
117.682
60.3748
116.792
60.3748C116.302
60.3748
115.892
60.4948
115.562
60.7448C115.242
60.9848
115.082
61.2948
115.082
61.6748C115.082
62.4248
115.622
62.8748
116.712
63.0348C117.672
63.1648
117.962
63.4148
117.962
63.8648C117.962
64.2748
117.572
64.5548
116.942
64.5548C116.222
64.5548
115.802
64.2748
115.532
63.6048L114.822
63.9748ZM120.128
60.4748V65.2148H120.988V60.4748H120.128ZM120.078
59.5948H121.038V58.5848H120.078V59.5948ZM122.556
65.8348C122.866
66.8048
123.586
67.2948
124.736
67.2948C125.456
67.2948
126.016
67.0648
126.406
66.6048C126.796
66.1448
126.996
65.5448
126.996
64.7948V60.4748H126.126V61.1848C125.776
60.6448
125.246
60.3748
124.526
60.3748C123.276
60.3748
122.286
61.3848
122.286
62.8648C122.286
64.2848
123.276
65.2148
124.476
65.2148C125.266
65.2148
125.846
64.8548
126.126
64.3248V64.8548C126.126
65.9248
125.656
66.5348
124.736
66.5348C123.996
66.5348
123.526
66.1948
123.336
65.5048L122.556
65.8348ZM126.166
62.8048C126.166
63.7748
125.526
64.4548
124.666
64.4548C123.786
64.4548
123.146
63.8448
123.146
62.8748C123.146
61.7948
123.786
61.1348
124.666
61.1348C125.526
61.1348
126.166
61.8448
126.166
62.8048ZM129.447
65.2148V62.6148C129.447
61.7048
130.007
61.1348
130.727
61.1348C131.407
61.1348
131.807
61.5648
131.807
62.3948V65.2148H132.677V62.1348C132.677
61.0248
132.017
60.3748
131.007
60.3748C130.337
60.3748
129.777
60.6948
129.447
61.2448V60.4748H128.587V65.2148H129.447ZM134.028
63.8648C134.028
64.3048
134.178
64.6548
134.478
64.9248C134.788
65.1848
135.158
65.3148
135.598
65.3148C136.358
65.3148
136.988
64.9748
137.228
64.4648V65.2148H138.058V62.2448C138.058
61.0748
137.328
60.3748
136.038
60.3748C134.928
60.3748
134.188
60.9548
133.998
61.8648L134.778
62.0848C134.898
61.4748
135.358
61.1048
136.038
61.1048C136.748
61.1048
137.228
61.4848
137.228
62.1748V62.6648L136.798
62.5448C136.478
62.4448
136.138
62.3948
135.788
62.3948C134.758
62.3948
134.028
62.8948
134.028
63.8648ZM137.228
63.4448C137.228
64.1348
136.678
64.5548
135.888
64.5548C135.258
64.5548
134.918
64.2548
134.918
63.8148C134.918
63.3248
135.298
63.0648
135.908
63.0648C136.158
63.0648
136.458
63.1148
136.788
63.2148L137.228
63.3448V63.4448ZM139.354
62.8748C139.354
64.3248
140.264
65.3148
141.574
65.3148C142.294
65.3148
142.894
64.9348
143.214
64.3648V65.2148H144.044V58.2148H143.174V61.3348C142.864
60.7448
142.284
60.3748
141.554
60.3748C140.264
60.3748
139.354
61.4048
139.354
62.8748ZM143.214
62.8648C143.214
63.9248
142.454
64.5448
141.714
64.5448C140.934
64.5448
140.214
63.9348
140.214
62.8648C140.214
61.8048
140.944
61.1548
141.714
61.1548C142.454
61.1548
143.214
61.8148
143.214
62.8648ZM145.337
62.8448C145.337
64.2448
146.307
65.3148
147.737
65.3148C149.157
65.3148
150.147
64.2448
150.147
62.8448C150.147
61.4448
149.177
60.3748
147.737
60.3748C146.307
60.3748
145.337
61.4448
145.337
62.8448ZM149.257
62.8448C149.257
63.8448
148.627
64.5348
147.737
64.5348C146.857
64.5348
146.217
63.8348
146.217
62.8448C146.217
61.8548
146.857
61.1548
147.737
61.1548C148.187
61.1548
148.547
61.3148
148.827
61.6348C149.117
61.9448
149.257
62.3548
149.257
62.8448Z"
        fill="#0F5945"
      />
      <path
        d="M192.209
53.2148V53.0748C191.929
52.7648
191.889
52.4248
191.859
51.6248C191.829
50.6948
191.459
50.1848
190.749
49.9148C191.659
49.6048
192.149
48.9848
192.149
48.1148C192.149
47.0648
191.309
46.2148
189.919
46.2148H186.719V53.2148H187.639V50.2548H189.469C190.359
50.2548
190.829
50.6148
190.839
51.6148C190.849
52.4948
190.919
52.8948
191.079
53.2148H192.209ZM187.639
49.3848V47.0848H189.839C190.679
47.0848
191.179
47.5048
191.179
48.2348C191.179
48.9548
190.679
49.3848
189.839
49.3848H187.639ZM196.932
51.4948C196.742
52.1748
196.292
52.5548
195.562
52.5548C194.722
52.5548
194.062
52.0048
194.052
50.9748H197.482C197.522
50.7948
197.542
50.6048
197.542
50.3948C197.542
49.7748
197.352
49.2848
196.962
48.9248C196.572
48.5548
196.062
48.3748
195.432
48.3748C194.082
48.3748
193.172
49.4548
193.172
50.8148C193.172
52.3448
194.172
53.3148
195.562
53.3148C196.652
53.3148
197.362
52.7448
197.682
51.7848L196.932
51.4948ZM194.052
50.3548C194.142
49.6048
194.692
49.1348
195.422
49.1348C196.142
49.1348
196.692
49.5348
196.692
50.2148L196.682
50.3548H194.052ZM198.936
48.4748V55.1948H199.796V52.4748C200.116
53.0048
200.716
53.3148
201.396
53.3148C202.686
53.3148
203.616
52.3148
203.616
50.8448C203.616
49.3748
202.736
48.3748
201.456
48.3748C200.746
48.3748
200.066
48.7948
199.756
49.3648V48.4748H198.936ZM201.256
52.5448C200.516
52.5448
199.756
51.9048
199.756
50.8548C199.756
49.8048
200.516
49.1548
201.256
49.1548C202.036
49.1548
202.756
49.7648
202.756
50.8548C202.756
51.9548
202.036
52.5448
201.256
52.5448ZM204.699
51.8648C204.699
52.3048
204.849
52.6548
205.149
52.9248C205.459
53.1848
205.829
53.3148
206.269
53.3148C207.029
53.3148
207.659
52.9748
207.899
52.4648V53.2148H208.729V50.2448C208.729
49.0748
207.999
48.3748
206.709
48.3748C205.599
48.3748
204.859
48.9548
204.669
49.8648L205.449
50.0848C205.569
49.4748
206.029
49.1048
206.709
49.1048C207.419
49.1048
207.899
49.4848
207.899
50.1748V50.6648L207.469
50.5448C207.149
50.4448
206.809
50.3948
206.459
50.3948C205.429
50.3948
204.699
50.8948
204.699
51.8648ZM207.899
51.4448C207.899
52.1348
207.349
52.5548
206.559
52.5548C205.929
52.5548
205.589
52.2548
205.589
51.8148C205.589
51.3248
205.969
51.0648
206.579
51.0648C206.829
51.0648
207.129
51.1148
207.459
51.2148L207.899
51.3448V51.4448ZM210.315
48.4748V53.2148H211.175V50.1948C211.175
49.5548
211.525
49.1348
212.055
49.1348C212.525
49.1348
212.815
49.4048
212.815
49.8448C212.815
49.9348
212.805
50.0548
212.785
50.1948H213.625C213.665
50.0348
213.685
49.8548
213.685
49.6648C213.685
48.9448
213.155
48.3748
212.385
48.3748C211.815
48.3748
211.385
48.6548
211.175
49.1648V48.4748H210.315ZM215.469
51.8048C215.469
52.7548
216.019
53.3148
217.019
53.3148C217.829
53.3148
218.369
52.8448
218.499
51.9948L217.699
51.8148C217.619
52.3348
217.389
52.5548
216.989
52.5548C216.619
52.5548
216.339
52.2948
216.339
51.8448V49.2048H218.039V48.4748H216.339V47.0048H215.469V48.4748H214.529V49.2048H215.469V51.8048ZM219.702
48.4748V53.2148H220.562V48.4748H219.702ZM219.652
47.5948H220.612V46.5848H219.652V47.5948ZM221.859
50.8748C221.859
52.3248
222.769
53.3148
224.079
53.3148C224.799
53.3148
225.399
52.9348
225.719
52.3648V53.2148H226.549V46.2148H225.679V49.3348C225.369
48.7448
224.789
48.3748
224.059
48.3748C222.769
48.3748
221.859
49.4048
221.859
50.8748ZM225.719
50.8648C225.719
51.9248
224.959
52.5448
224.219
52.5448C223.439
52.5448
222.719
51.9348
222.719
50.8648C222.719
49.8048
223.449
49.1548
224.219
49.1548C224.959
49.1548
225.719
49.8148
225.719
50.8648ZM227.841
50.8448C227.841
52.2448
228.811
53.3148
230.241
53.3148C231.661
53.3148
232.651
52.2448
232.651
50.8448C232.651
49.4448
231.681
48.3748
230.241
48.3748C228.811
48.3748
227.841
49.4448
227.841
50.8448ZM231.761
50.8448C231.761
51.8448
231.131
52.5348
230.241
52.5348C229.361
52.5348
228.721
51.8348
228.721
50.8448C228.721
49.8548
229.361
49.1548
230.241
49.1548C230.691
49.1548
231.051
49.3148
231.331
49.6348C231.621
49.9448
231.761
50.3548
231.761
50.8448ZM233.938
48.4748V53.2148H234.798V50.1948C234.798
49.5548
235.148
49.1348
235.678
49.1348C236.148
49.1348
236.438
49.4048
236.438
49.8448C236.438
49.9348
236.428
50.0548
236.408
50.1948H237.248C237.288
50.0348
237.308
49.8548
237.308
49.6648C237.308
48.9448
236.778
48.3748
236.008
48.3748C235.438
48.3748
235.008
48.6548
234.798
49.1648V48.4748H233.938ZM194.08
63.4948C193.89
64.1748
193.44
64.5548
192.71
64.5548C191.87
64.5548
191.21
64.0048
191.2
62.9748H194.63C194.67
62.7948
194.69
62.6048
194.69
62.3948C194.69
61.7748
194.5
61.2848
194.11
60.9248C193.72
60.5548
193.21
60.3748
192.58
60.3748C191.23
60.3748
190.32
61.4548
190.32
62.8148C190.32
64.3448
191.32
65.3148
192.71
65.3148C193.8
65.3148
194.51
64.7448
194.83
63.7848L194.08
63.4948ZM191.2
62.3548C191.29
61.6048
191.84
61.1348
192.57
61.1348C193.29
61.1348
193.84
61.5348
193.84
62.2148L193.83
62.3548H191.2ZM196.945
65.2148V62.6148C196.945
61.7048
197.505
61.1348
198.225
61.1348C198.905
61.1348
199.305
61.5648
199.305
62.3948V65.2148H200.175V62.1348C200.175
61.0248
199.515
60.3748
198.505
60.3748C197.835
60.3748
197.275
60.6948
196.945
61.2448V60.4748H196.085V65.2148H196.945ZM206.645
64.5548C205.805
64.5548
205.015
63.8748
205.015
62.8148C205.015
61.7248
205.815
61.1348
206.545
61.1348C207.315
61.1348
207.815
61.5748
207.965
62.3248L208.755
62.0848C208.525
61.0048
207.705
60.3748
206.555
60.3748C205.265
60.3748
204.175
61.3948
204.175
62.8148C204.175
64.3148
205.175
65.3148
206.665
65.3148C207.855
65.3148
208.715
64.6248
208.985
63.4248L208.215
63.1348C208.025
64.0248
207.565
64.5548
206.645
64.5548ZM209.993
63.8648C209.993
64.3048
210.143
64.6548
210.443
64.9248C210.753
65.1848
211.123
65.3148
211.563
65.3148C212.323
65.3148
212.953
64.9748
213.193
64.4648V65.2148H214.023V62.2448C214.023
61.0748
213.293
60.3748
212.003
60.3748C210.893
60.3748
210.153
60.9548
209.963
61.8648L210.743
62.0848C210.863
61.4748
211.323
61.1048
212.003
61.1048C212.713
61.1048
213.193
61.4848
213.193
62.1748V62.6648L212.763
62.5448C212.443
62.4448
212.103
62.3948
211.753
62.3948C210.723
62.3948
209.993
62.8948
209.993
63.8648ZM213.193
63.4448C213.193
64.1348
212.643
64.5548
211.853
64.5548C211.223
64.5548
210.883
64.2548
210.883
63.8148C210.883
63.3248
211.263
63.0648
211.873
63.0648C212.123
63.0648
212.423
63.1148
212.753
63.2148L213.193
63.3448V63.4448ZM215.61
60.4748V65.2148H216.47V62.3148C216.47
61.5448
216.87
61.1548
217.39
61.1548C217.91
61.1548
218.26
61.4948
218.26
62.1448V65.2148H219.13V62.2648C219.13
61.5348
219.54
61.1548
220.06
61.1548C220.57
61.1548
220.93
61.5148
220.93
62.1048V65.2148H221.79V61.9248C221.79
60.9248
221.23
60.3748
220.35
60.3748C219.75
60.3748
219.21
60.7148
218.96
61.3148C218.8
60.7048
218.32
60.3748
217.75
60.3748C217.12
60.3748
216.7
60.6748
216.47
61.1448V60.4748H215.61ZM223.379
60.4748V65.2148H224.239V60.4748H223.379ZM223.329
59.5948H224.289V58.5848H223.329V59.5948ZM226.687
65.2148V62.6148C226.687
61.7048
227.247
61.1348
227.967
61.1348C228.647
61.1348
229.047
61.5648
229.047
62.3948V65.2148H229.917V62.1348C229.917
61.0248
229.257
60.3748
228.247
60.3748C227.577
60.3748
227.017
60.6948
226.687
61.2448V60.4748H225.827V65.2148H226.687ZM231.216
62.8448C231.216
64.2448
232.186
65.3148
233.616
65.3148C235.036
65.3148
236.026
64.2448
236.026
62.8448C236.026
61.4448
235.056
60.3748
233.616
60.3748C232.186
60.3748
231.216
61.4448
231.216
62.8448ZM235.136
62.8448C235.136
63.8448
234.506
64.5348
233.616
64.5348C232.736
64.5348
232.096
63.8348
232.096
62.8448C232.096
61.8548
232.736
61.1548
233.616
61.1548C234.066
61.1548
234.426
61.3148
234.706
61.6348C234.996
61.9448
235.136
62.3548
235.136
62.8448Z"
        fill="#0F5945"
      />
      <path
        d="M262.322
48.1948V49.5948H263.722V48.1948H262.322ZM262.422
50.1948V55.1948H263.622V50.1948H262.422ZM265.241
46.2148V53.2148H270.601V51.9648H266.591V50.2348H270.201V48.9848H266.591V47.4648H270.501V46.2148H265.241ZM271.905
53.2148H273.185V46.2148H271.905V53.2148ZM277.258
48.4748V53.2148H278.538V50.1648C278.538
49.6848
278.798
49.4148
279.198
49.4148C279.558
49.4148
279.798
49.6548
279.798
50.0048C279.798
50.1348
279.788
50.2548
279.758
50.3648H280.968C281.018
50.1748
281.038
49.9848
281.038
49.7748C281.038
48.9648
280.528
48.3748
279.678
48.3748C279.128
48.3748
278.738
48.6448
278.538
49.0848V48.4748H277.258ZM285.527
51.4048C285.377
51.9448
285.007
52.2148
284.427
52.2148C283.747
52.2148
283.187
51.8048
283.177
51.0848H286.397C286.437
50.9048
286.457
50.7148
286.457
50.5048C286.457
49.2048
285.567
48.3748
284.257
48.3748C282.857
48.3748
281.897
49.4648
281.897
50.8148C281.897
52.3148
282.937
53.3148
284.407
53.3148C285.517
53.3148
286.287
52.7548
286.617
51.8348L285.527
51.4048ZM283.177
50.2348C283.297
49.7148
283.737
49.4048
284.257
49.4048C284.827
49.4048
285.217
49.7148
285.217
50.1948L285.207
50.2348H283.177ZM287.758
48.4748V55.1948H289.038V52.6048C289.288
53.0448
289.838
53.3148
290.488
53.3148C291.818
53.3148
292.788
52.3548
292.788
50.8648C292.788
49.3748
291.838
48.3748
290.558
48.3748C289.928
48.3748
289.338
48.6948
288.988
49.1848V48.4748H287.758ZM290.248
52.2248C289.558
52.2248
288.988
51.7048
288.988
50.8648C288.988
50.0148
289.558
49.4748
290.248
49.4748C290.958
49.4748
291.508
50.0148
291.508
50.8648C291.508
51.6948
290.958
52.2248
290.248
52.2248ZM293.785
51.8448C293.785
52.7548
294.525
53.3148
295.345
53.3148C296.115
53.3148
296.605
53.0348
296.915
52.4848V53.2148H298.085V50.2948C298.085
49.0548
297.345
48.3748
295.905
48.3748C294.755
48.3748
294.005
48.9448
293.755
49.8648L294.865
50.1648C294.975
49.6848
295.325
49.3748
295.895
49.3748C296.485
49.3748
296.865
49.6948
296.865
50.2248V50.5248L296.445
50.4248C296.185
50.3548
295.915
50.3248
295.635
50.3248C294.515
50.3248
293.785
50.8348
293.785
51.8448ZM296.865
51.4648C296.865
52.0148
296.445
52.3348
295.855
52.3348C295.345
52.3348
295.085
52.1148
295.085
51.7648C295.085
51.3948
295.365
51.1948
295.835
51.1948C296.025
51.1948
296.275
51.2448
296.575
51.3348L296.865
51.4248V51.4648ZM299.557
48.4748V53.2148H300.837V50.1648C300.837
49.6848
301.097
49.4148
301.497
49.4148C301.857
49.4148
302.097
49.6548
302.097
50.0048C302.097
50.1348
302.087
50.2548
302.057
50.3648H303.267C303.317
50.1748
303.337
49.9848
303.337
49.7748C303.337
48.9648
302.827
48.3748
301.977
48.3748C301.427
48.3748
301.037
48.6448
300.837
49.0848V48.4748H299.557ZM305.054
51.6148C305.054
52.6548
305.684
53.3148
306.834
53.3148C307.834
53.3148
308.474
52.7348
308.604
51.7448L307.424
51.5248C307.384
51.9648
307.194
52.1848
306.864
52.1848C306.544
52.1848
306.324
51.9748
306.324
51.6148V49.5248H308.104V48.4748H306.324V46.9148H305.054V48.4748H304.074V49.5248H305.054V51.6148ZM309.696
48.4748V53.2148H310.976V48.4748H309.696ZM309.656
47.7648H311.026V46.4148H309.656V47.7648ZM312.176
50.8648C312.176
52.3348
313.116
53.3148
314.456
53.3148C315.126
53.3148
315.666
53.0248
315.976
52.5348V53.2148H317.206V46.2148H315.926V49.1848C315.636
48.6948
315.096
48.3748
314.426
48.3748C313.126
48.3748
312.176
49.3748
312.176
50.8648ZM315.976
50.8648C315.976
51.7048
315.406
52.2248
314.716
52.2248C314.006
52.2248
313.456
51.7048
313.456
50.8648C313.456
50.0148
314.006
49.4748
314.716
49.4748C315.406
49.4748
315.976
50.0148
315.976
50.8648ZM318.403
50.8448C318.403
52.2548
319.453
53.3148
320.883
53.3148C322.313
53.3148
323.363
52.2548
323.363
50.8448C323.363
49.4248
322.323
48.3748
320.883
48.3748C319.443
48.3748
318.403
49.4248
318.403
50.8448ZM322.113
50.8448C322.113
51.6348
321.593
52.1648
320.883
52.1648C320.173
52.1648
319.653
51.6348
319.653
50.8448C319.653
50.0548
320.173
49.5248
320.883
49.5248C321.593
49.5248
322.113
50.0548
322.113
50.8448ZM324.567
48.4748V53.2148H325.847V50.1648C325.847
49.6848
326.107
49.4148
326.507
49.4148C326.867
49.4148
327.107
49.6548
327.107
50.0048C327.107
50.1348
327.097
50.2548
327.067
50.3648H328.277C328.327
50.1748
328.347
49.9848
328.347
49.7748C328.347
48.9648
327.837
48.3748
326.987
48.3748C326.437
48.3748
326.047
48.6448
325.847
49.0848V48.4748H324.567ZM284.254
65.2148H285.534V58.2148H284.254V65.2148ZM287.004
65.2148H288.284V58.2148H287.004V65.2148ZM293.114
63.4048C292.964
63.9448
292.594
64.2148
292.014
64.2148C291.334
64.2148
290.774
63.8048
290.764
63.0848H293.984C294.024
62.9048
294.044
62.7148
294.044
62.5048C294.044
61.2048
293.154
60.3748
291.844
60.3748C290.444
60.3748
289.484
61.4648
289.484
62.8148C289.484
64.3148
290.524
65.3148
291.994
65.3148C293.104
65.3148
293.874
64.7548
294.204
63.8348L293.114
63.4048ZM290.764
62.2348C290.884
61.7148
291.324
61.4048
291.844
61.4048C292.414
61.4048
292.804
61.7148
292.804
62.1948L292.794
62.2348H290.764ZM295.325
65.8648C295.655
66.8148
296.415
67.2948
297.575
67.2948C299.175
67.2948
300.025
66.3148
300.025
64.7848V60.4748H298.755V61.0348C298.445
60.5948
297.975
60.3748
297.325
60.3748C296.095
60.3748
295.075
61.3348
295.075
62.8348C295.075
64.2248
296.015
65.1848
297.235
65.1848C297.965
65.1848
298.465
64.9348
298.755
64.4348V64.8848C298.755
65.7248
298.375
66.2248
297.605
66.2248C297.015
66.2248
296.605
65.9148
296.455
65.3648L295.325
65.8648ZM298.795
62.8048C298.795
63.5648
298.275
64.1148
297.585
64.1148C296.875
64.1148
296.355
63.6248
296.355
62.8348C296.355
61.9948
296.875
61.4748
297.565
61.4748C298.275
61.4748
298.795
62.0048
298.795
62.8048ZM301.224
62.8448C301.224
64.2548
302.274
65.3148
303.704
65.3148C305.134
65.3148
306.184
64.2548
306.184
62.8448C306.184
61.4248
305.144
60.3748
303.704
60.3748C302.264
60.3748
301.224
61.4248
301.224
62.8448ZM304.934
62.8448C304.934
63.6348
304.414
64.1648
303.704
64.1648C302.994
64.1648
302.474
63.6348
302.474
62.8448C302.474
62.0548
302.994
61.5248
303.704
61.5248C304.414
61.5248
304.934
62.0548
304.934
62.8448ZM303.144
59.6648H304.274L305.564
58.4948V58.3348H303.994L303.144
59.6648ZM307.468
63.2148H308.668V58.2148H307.468V63.2148ZM307.368
65.2148H308.768V63.8148H307.368V65.2148Z"
        fill="#0F5945"
      />
    </svg>
  );
}

DeliveryStepper5.propTypes = {
  className: PropTypes.string,
};

DeliveryStepper5.defaultProps = {
  className: '',
};

export default DeliveryStepper5;
