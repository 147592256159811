import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputContainer from '../InputContainer';
import RadioButton from '.';

function RadioButtonGroupControlled(props) {
  const { control } = useFormContext();
  const {
    label,
    name,
    helper,
    options,
    classes,
    className,
    readOnly,
    disabled,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          onChange,
          onBlur,
          value,
        },
      }) => (
        <InputContainer
          label={label}
          helper={helper}
          className={clsx(classes)}
        >
          <div className={clsx('flex flex-wrap gap-2', className)}>
            {options.map((option) => (
              <RadioButton
                className={clsx(classes)}
                name={name}
                id={option.id || option.value}
                key={option.value}
                value={option.value}
                checked={option.value === value}
                onBlur={onBlur}
                onChange={onChange}
                readOnly={readOnly}
                disabled={disabled}
                onClick={option.onClick}
                isChecked={option.isChecked}
              >
                {option.label}
              </RadioButton>
            ))}
          </div>
        </InputContainer>
      )}
    />
  );
}

RadioButtonGroupControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  helper: PropTypes.node,
  classes: PropTypes.array,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.node,
    }),
  ),
};

RadioButtonGroupControlled.defaultProps = {
  label: null,
  helper: null,
  classes: [],
  className: '',
  readOnly: false,
  disabled: false,
  options: [],
};

export default RadioButtonGroupControlled;
