import {
  trackEvent,
} from '../services/amplitude';

export function useLoginPageEvents() {
  const trackStartLoginAccount = () => {
    trackEvent('login_account');
  };

  const trackStartSignUpCreateAccount = () => {
    trackEvent('signup_create_account');
  };

  const trackLoginAttempt = () => {
    trackEvent('login_attempt');
  };

  const trackLoginSuccess = () => {
    trackEvent('login_success');
  };

  const trackSignUpCreateAccountSuccess = () => {
    trackEvent('signup_create_account_success');
  };

  return {
    trackStartLoginAccount,
    trackStartSignUpCreateAccount,
    trackLoginAttempt,
    trackLoginSuccess,
    trackSignUpCreateAccountSuccess,
  };
}

export default useLoginPageEvents;
