const numberCurrencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'COP',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function formatCurrency(currency) {
  return numberCurrencyFormat.format(currency);
}

export default formatCurrency;
