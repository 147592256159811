import PropTypes from 'prop-types';
import clsx from 'clsx';
import { cloneElement } from 'react';

function InputText(props) {
  const {
    className,
    type,
    size,
    value,
    startAdornment,
    endAdornment,
    placeholder,
    onChange,
    onConfirmOptions,
    fullWidth,
    fieldState,
    multiValueInput,
    autoComplete,
    ...args
  } = props;

  const {
    invalid,
    isDirty,
  } = fieldState;

  const startAdornmentWithDecoration = startAdornment ? cloneElement(startAdornment, {
    withError: invalid && isDirty,
  }) : undefined;

  return (
    <div
      className={
        clsx(
          'flex flex-start disabled:opacity:60 rounded border transition-colors',
          {
            'px-4 py-2 text-sm': size === 'sm',
            'p-4 text-base': size === 'md',
            'p-6 text-lg': size === 'lg',
            'w-full': fullWidth,
            'w-[220px]': !fullWidth,
            'bg-gray-100 border-gray-100 focus-within:border-baco-primary': !(invalid && isDirty),
            'bg-baco-error-light border-baco-error-normal': invalid && isDirty,
            'gap-x-4': !multiValueInput,
            'gap-x-2 px-3': multiValueInput,
          },
        )
      }
    >
      {startAdornmentWithDecoration}
      <input
        className={
          clsx(
            'appearance-none bg-transparent outline outline-0 placeholder:text-gray-400 w-full',
            {
              'text-sm': size === 'sm',
              'text-base': size === 'md',
              'text-lg': size === 'lg',
              'text-black': !(invalid && isDirty),
              'text-baco-error-normal': invalid && isDirty,
            },
          )
        }
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        autoComplete={autoComplete}
        {...args}
      />
      {endAdornment}
    </div>
  );
}

InputText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'email', 'password']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onConfirmOptions: PropTypes.func,
  fullWidth: PropTypes.bool,
  fieldState: PropTypes.any,
  multiValueInput: PropTypes.bool,
  autoComplete: PropTypes.string,
};
InputText.defaultProps = {
  className: '',
  type: 'text',
  size: 'md',
  startAdornment: undefined,
  endAdornment: undefined,
  value: '',
  placeholder: '',
  onChange: () => null,
  onConfirmOptions: () => false,
  fullWidth: false,
  fieldState: {},
  multiValueInput: false,
  autoComplete: '',
};

export default InputText;
